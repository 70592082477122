
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';
import unirClientes from "@/components/UnirClientes.vue";
//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'
//@ts-ignore
import palabras from "@/mixins/palabras";

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/
         unirClientes,
        TablePPoa
    },
    data() {
        return {
            buscadorvalor: '',
            clientesUnir:[],

            columnasd: [
            {
                tag:'addunir',
                data:'id',
                active:false
            },    
            {
                    tag: 'id',
                    data: 'id',
                    active: false
                },

                {
                    tag: 'nombre',
                    data: 'nombre',
                    active: true
                },
                {
                    tag: 'telefono',
                    data: 'telefono',
                    active: true
                },
                {
                    tag: 'influencer',
                    data: 'influencer',
                    active: true
                },
                {
                    tag: 'descuento',
                    data: 'influencer_descuento',
                    active: true
                },
                {
                    tag: 'nota',
                    data: 'notas',
                    active: true
                },
                {
                    tag: 'historial',
                    data: 'historial',
                    active: false
                },
                {
                    tag: 'edit',
                    data: 'edit',
                    active: false
                },
                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },
                /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/

            ],

            paginate: 0,
            actual: 1,
            id: null as any,

            telefono: ''
           ,
            direccion: ''
            ,
            influencer: 2 as any,
            descuento: 10 as any,
            nombre: ''
            ,
            notas: ''
            ,

            clientesdata: [] as any,

        }
    },
    mixins: [clientes],
    validations: {

        nombre: {
            required,
        },
        influencer: {
            required,
        },
        descuento: {
            required,
        },

        /* direccion: {
      required,
    },*/
        telefono: {
            required,
        },

    },
    methods: {
        onUnirExitoso() {
      // Esta función se ejecutará cuando se haga clic en "Unir" y se retorne al padre que fue exitoso
      console.log("Unión exitosa. Hacer algo aquí...");
      this.clientesUnir=[];
      this.getDatalimit();
    },
     verificarExistenciaId(array, nuevoElemento) {
  return !array.some((elemento) => elemento.id === nuevoElemento.id);
},
        AddUnirClientes(row){
console.log(row)
            if(this.verificarExistenciaId(this.clientesUnir,row)){
                this.clientesUnir.push(row)
            }

        },
        getClientefilter() {
            if (this.buscadorvalor == '') {
                this.getDatalimit()
            } else {
                this.getDatalimit()
            }
        },
        paginateto(pag) {
            this.actual = pag;
            this.getDatalimit()

        },

        async getDatalimit() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let dat = {
                pagina: (this.actual-1) * 1000,
                text: this.buscadorvalor,
                limit: 1000
            }
            let data = await this.GET_CLIENTESLIMITE(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)
                    this.clientesdata = response[0];
                    this.paginate = response[1];
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        selCliente(row) {

            this.$store.commit('SETCLIENTE_SEL', row);
            this.$router.push('/su/cliente/historial')
        },

        async eliminar(row) {

            this.$root.$emit('loading', true);
            let data = await this.ELIMINAR_CLIENTES(row).then(response => {

                if (response != null) {

                    this.clientesdata = this.clientesdata.filter(item => item.id !== row.id);

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                }
                this.$root.$emit('loading', false);
            });
        },

        confirm(row) {

            let th = this;
            Notiflix.Confirm.Show('Alerta de confirmacion', 'Desea eliminar este elemento?', 'Aceptar', 'Cancelar', function () {

                th.eliminar(row);
            }, function () {

            });

        },
        limpiar() {
            this.id = null;

            this.telefono = null;
            this.direccion = null;

            this.nombre = null;
            this.notas = null;
            this.influencer = 2;
            this.descuento = 0;

        },
        addRow() {
            this.limpiar();
            $('#ac_modal_indicador_area').modal('show')
        },
        seteditar(row) {

            this.id = row.id;
            this.telefono = row.telefono;
            this.influencer = row.influencer;
            this.descuento = row.influencer_descuento;
            //this.direccion=row.direccion;
            this.nombre = row.nombre;
            this.notas = row.notas;

            $('#ac_modal_indicador_area').modal('show')

        },
        async crear(dat) {
            let data = await this.CREAR_CLIENTES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)

                    this.clientesdata.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear cliente, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_indicador_area').modal('hide')
                //loading.close();
            });

        },
        async editar(dat) {

            let data = await this.EDITAR_CLIENTES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)
                    this.$root.$emit("success", "Cliente modificado exitosamente, refresque la pagina");
                    this.clientesdata = this.clientesdata.filter(item => item.id !== dat.id);
                    this.clientesdata.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar cliente, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_indicador_area').modal('hide')
                //loading.close();
            });

        },
        async GuardarData() {

            console.log('id:' + this.id)

            let dat = {
                id: this.id,
                nombre: this.nombre,
                telefono: this.telefono,
                influencer: this.influencer,
                notas: this.notas,
                influencer_descuento: this.descuento,
                // direccion:this.direccion,

            }
            console.log(dat)
            this.$root.$emit('loading', true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }

        },
       

    },
    mounted() {
        this.getDatalimit();
        //alert('10')
    }
})
export default class Home extends Vue {}
