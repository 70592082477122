
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";

//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import sucursales from '@/mixins/axios/sucursales'
//@ts-ignore
import palabras from "@/mixins/palabras";
@Component({
    components: {
        /*
        Vuetable,
        VuetablePagination,*/
        TablePPoa,
    },
    data() {
        return {
            columnasd: [{
                    tag: "id",
                    data: "id",
                    active: false,
                },

                {
                    tag: "nombre",
                    data: "name",
                    active: false,
                },
                {
                    tag: "sucursales",
                    data: "municipio",
                    active: false,
                },
                {
                    tag: "role",
                    data: "role",
                    active: true,
                },
                {
                    tag: "email",
                    data: "email",
                    active: false,
                },
                {
                    tag: "password_plano",
                    data: "password_plano",
                    active: true,
                },
                {
                    tag: "role_tipo",
                    data: "role",
                    active: false,
                },

                {
                    tag: "edit",
                    data: "edit",
                    active: false,
                },
                {
                    tag: "delete",
                    data: "delete",
                    active: false,
                },

            ],
            roles: [{
                    id: 'admin'
                },
                {
                    id: 'sucursal'
                },
                {
                    id: 'caja'
                },
                {
                    id: 'empleado'
                },

            ],
            // 'name', 'email', 'idtema','password','role','active','password_plano','modifico','id_municipio'
            id: null as any,
            name: "" as any,
            email: "" as any,
            password_plano: "" as any,
            role: "" as any,
            sucursales_id: "" as any,

            usuariosdata: [] as any,
            municipios: [] as any,
            periodosdata: []
        };
    },
    mixins: [usuarios, sucursales, palabras],
    validations: {
        name: {
            required,
        },
        email: {
            required,
        },

        password_plano: {
            required,
        },

        role: {
            required,
        },
        sucursales_id: {
            required,
        },
    },
    methods: {
        asignacionpermisos(row) {
            console.log("suauriooo-------", row)
            this.$store.commit('SETUSUARIO', row);
            this.$router.push("/su/usuarios/asignacion")
        },
        horariossel(row) {
            console.log("suauriooo-------", row)
            this.$store.commit('SETUSUARIO', row);
            this.$router.push("/su/usuarios/horarios")
        },
        categorias_sucursal(row) {
            console.log("suauriooo-------", row)
            this.$store.commit('SETUSUARIO', row);
            this.$router.push("/su/usuarios/categoriasucursal")
        },
        async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_USER(row).then((response) => {
                if (response != null) {
                    this.usuariosdata = this.usuariosdata.filter(
                        (item) => item.id !== row.id
                    );
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar paquete, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },
        confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Desea eliminar este elemento?",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },
        limpiar() {
            this.id = null;

            this.role = "";
            this.password_plano = "";
            this.name = "";
            this.email = "";
            this.sucursales_id = 0;
        },
        addRow() {
            this.limpiar();
            $("#ac_modal_usuarios").modal("show");
        },
        seteditar(row) {
            this.id = row.id;
            this.role = row.role;
            this.password_plano = row.password_plano;
            this.name = row.name;
            this.email = row.email;
            this.sucursales_id = row.sucursales_id;
            $("#ac_modal_usuarios").modal("show");
        },
        async crear(dat) {
            let data = await this.CREAR_USER(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.usuariosdata.push(response);
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear usuario, refresque la pagina"
                    );
                }
                $("#ac_modal_usuarios").modal("hide");
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        async editar(dat) {
            let data = await this.EDITAR_USER(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);
                    this.$root.$emit(
                        "success",
                        "Usuario modificado correctamente."
                    );
                    this.usuariosdata = this.usuariosdata.filter(
                        (item) => item.id !== dat.id);
                    this.usuariosdata.push(response);
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear usuarios, refresque la pagina"
                    );
                }
                $("#ac_modal_usuarios").modal("hide");
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        async GuardarData() {
            console.log("id:" + this.id);

            let dat = {
                id: this.id,
                name: this.name,
                role: this.role,
                sucursales_id: this.sucursales_id,
                password_plano: this.password_plano,
                email: this.email,
            };
            console.log(dat);
            this.$root.$emit("loading", true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }
        },

        async getMunicipios() {
            this.$root.$emit('loading', true);
            console.log('loaddding')
            let data = await this.GET_SUCURSALES().then(response => {

                if (response != null) {
                    //this.plantas = response;
                    this.municipios = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");

                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

        async periodosEmpleado() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                id: this.id
            }
            let data = await this.GET_PERIODOSEMPLEADO(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.periodosdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_USER().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.usuariosdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
    mounted() {
        this.getData();
        this.getMunicipios();

        //alert('10')
    },
})
export default class Home extends Vue {}
