
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import PmdHeader from "@/components/headersSelect/pmd";
//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import cobros from "@/mixins/axios/cobros";
//@ts-ignore
import ventas from "@/mixins/axios/ventas";
//@ts-ignore
import ventagiftcard from "@/mixins/axios/ventagiftcards";
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";

//@ts-ignore
import generales from "@/mixins/functions/generales"
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'
//    protected $fillable = ['id','clientes_id', 'numero', 'activo','sucursales_id','id_empleada','cantidad','precio','metodo','created_at', 'updated_at'];

import ItemTemplate from "./templateselect.vue";
@Component({
    components: {
        TablePPoa
    },
    data() {
        return {
            cobrosdia: [],
            cobro_data: {
                id: 0,
                propina: 0,
                precio: 0,
                metodos: [],

            },
            totmetodos: 0,
            metodos: ['nada',
                'Efectivo',
                'Tarjeta Crédito',
                'Tarjeta Débito'
            ],
            EditCobro: false,
            start: this.actualformatDate(),
            //----data cliente--------
            clientesdata: [],
            template: ItemTemplate,
            newItemCliente: "",
            datesel: {
                start: this.formatFullDate(this.getfisrtDay()),
                end: this.formatFullDate(new Date()),
            },
            rr: this.actualformatDate(),
            sucursalsel: 0,
            venta: 0,
            sucursalesdata: [],

            id: 0,

            cobrosdia: [] as any,
            nota: "",
            metodo: 0,

            influencer: 1,
            cliente: 1,
            precio: 1,
            actual: 0,

            addGiftcard: false,

            ventasgiftcard: [],
            numero: "",
            precio: "",
            cantidad: "",
            descuento: 0,
            metodo: "Efectivo",
            total: 0,
            nombrecliente: "",
            subtotal: 0,
            tarjetas: [],
            columnasd: [{
                    tag: 'id',
                    data: 'id',
                    active: false
                },
                {
                    tag: 'servicios_cobrados',
                    data: 'servicios_cobrados',
                    active: true
                },
                {
                    tag: 'nombre',
                    data: 'cliente',
                    active: true
                },
                {
                    tag: 'descuentop',
                    data: 'pdescuento',
                    active: true
                },
                {
                    tag: 'monto_propina',
                    data: 'monto_propina',
                    active: true
                },
                {
                    tag: 'giftcard',
                    data: 'numero_giftcard',
                    active: true
                },
                {
                    tag: 'cantidad',
                    data: 'cantidad_giftcard',
                    active: true
                },
                {
                    tag: 'comision',
                    data: 'comision',
                    active: true
                },
                {
                    tag: 'precio',
                    data: 'precio',
                    active: true
                },
                {
                    tag: 'detalle_cobros2',
                    data: 'detalle_cobros2',
                    active: true
                },

                {
                    tag: 'metodos',
                    data: 'metodos',
                    active: true
                },

                {
                    tag: 'edit',
                    data: 'edit',
                    active: false
                },
                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },

            ],

        }
    },
    mixins: [giftcards, ventagiftcard, sucursales, clientes, cobros, ventas, generales],
    validations: {
        nombre: {
            required,
        },

        /* direccion: {
        required,
        },*/
        telefono: {
            required,
        },
    },
    methods: {

        confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Desea eliminar este elemento?",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },
        async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_COBROS(row).then((response) => {
                if (response != null) {
                    this.cobro_data = this.cobro_data.filter(
                        (item) => item.id !== row.id
                    );
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar cobro, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },
        verificaCantidad(e, index) {
            let cantidad = 0;

            this.cobro_data.metodos.forEach((element) => {
                cantidad = Number(cantidad) + Number(element.cantidad);
            });
            let cantidad2 = cantidad - this.cobro_data.metodos[index].cantidad;
            let cant = this.cobro_data.precio;
            console.log(cant, "-------cant----");
            if (cantidad > cant) {
                this.cobro_data.metodos[index].cantidad = cant - cantidad2;
            } else {

            }
        },
        calcularMetodos() {
            let total = 0;
            console.log( this.cobro_data,'---cobros---metodos')
          this.cobro_data.metodos.forEach(element => {
                total += Number(element.cantidad);
            });
            return Number(total)
        },
        async limpiar() {

        },
        seteditar(row) {
            console.log(row.metodos)

            this.cobro_data = row;

            if (!Array.isArray(row.metodos)) {
                this.cobro_data.metodos = JSON.parse(row.metodos)
            }

            this.totmetodos = this.calcularMetodos();

            this.EditCobro = true;

        },
        async getClientes(text) {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                text: text,
            };
            let data = await this.GET_CLIENTES2(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        getLabel(item) {
            if (item != null) {
                return item.nombre + "-" + item.telefono;
            } else {
                return "";
            }
        },

        async onSearch(search) {
            if (search.length) {
                this.getClientes(search);
            } else {
                this.clientesdata = [];
            }
            // loading(false);
            //this.search(loading, search, this);
        },

        async getSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;
                    if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }

                    this.$root.$emit("loading", false);
                    this.getData()
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        async GuardarCambios() {

            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.cobro_data.id,
                metodos: this.cobro_data.metodos

            };
            let data = await this.EDITAR_COBRO(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.getData();
                    this.EditCobro = false;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });

        },
        addRow() {
            this.addGiftcard = true;
        },

        agregar() {
            if (
                String(this.numero) == "" &&
                String(this.cantidad) == "" &&
                String(this.precio) == ""
            ) {
                alert("Los campos no pueden estar vacios");
            } else {
                let total = 0;
                this.tarjetas.forEach((element) => {
                    if (Number(element.numero) == Number(this.numero)) {
                        total = total + 1;
                    }
                });
                if (total > 0) {
                    alert("El numero de tarjeta ya fue agregado a la venta");
                } else {
                    this.tarjetas.push({
                        numero: this.numero,
                        precio: this.precio,
                        cantidad: this.cantidad,
                    });
                    this.numero = "";
                    this.cantidad = "";
                    this.precio = "";
                }
            }
        },
        calcularPrecio() {
            let precio = 0;
            this.tarjetas.forEach((element) => {
                precio += Number(element.precio);
            });
            return precio;
        },

        formatTime(date) {
            var time = new Date(date);

            return time.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        },

        randomColor() {
            let colors = [
                "red",
                "yellow",
                "green",
                "blue",
                "indigo",
                "purple",
                "pink",
            ];
            return colors[Math.floor(Math.random() * colors.length)];
        },

        SelectSucursalChange() {
            //aquiiiii

            //this.getData();
            this.getData();
            //console.log(,'----------------sel--change-------------')
        },

        selectdate(val) {
            console.log(val, "------------val----");
            //this.getData()
            this.getData();
        },

        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.sucursalsel,

                inicio: this.datesel.start,
                fin: this.datesel.end,
            };
            let data = await this.GET_COBROS_RANGUE_HISTORIAL(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    let actual = 0;
                    let data = {};

                    this.cobrosdia = response;
                } else {

                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getSucursales() {

            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;

                    this.sucursalsel = response[0].id;
                    // this.getData();
                    this.getData();
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

    },
    mounted() {

        this.getSucursales();

        // this.mapainit();
        //alert('10')
    },
})
export default class Home extends Vue {}
