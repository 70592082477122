
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';

//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'
//@ts-ignore
import cortes from '@/mixins/axios/cortes'

@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
     
     datesel: {
                start: this.getfisrtDay(),
                end: new Date()
            },
        columnasd:[
            
            
            
           {
                tag:'servicios',
                data:'servicios',
                active:true
            },
            
          {
                tag:'comision',
                data:'comision',
                active:true
            },
             {
                tag:'propina',
                data:'propina',
                active:true
            },
            {
                tag:'fecha',
                data:'created_at',
                active:true
            },
             
            
             /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/
      
             
            
           
        ],




        id:null as any,

      telefono:'' as any,
        direccion:'' as any,
         influencer:2 as any,
          descuento:10 as any,
           nombre:'' as any,
              notas:'' as any,
     
         
         
              clientesdata:[] as any,
      
    }
  },
     mixins: [clientes,usuarios,cortes],
     validations: {
    
           nombre: {
      required,
    },
     influencer: {
      required,
    },
    descuento: {
      required,
    },
    
 
      /* direccion: {
      required,
    },*/  telefono: {
      required,
    },

   
  },
  methods:{
       

       getfisrtDay() {
            var myCurrentDate = new Date();
            var myPastDate = new Date(myCurrentDate);
            myPastDate.setDate(myPastDate.getDate() - 7)
            return myPastDate;
        },
        setMasOneDay(date_d) {
         
            var myPastDate = new Date(date_d);
            myPastDate.setDate(myPastDate.getDate() + 1)
            return myPastDate;
        },

     actualformatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
   
    formatFullDate(date) {

            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')


let dat={
    id:this.$store.state.empleado_sel.id,
      inicio: this.formatFullDate(this.datesel.start),
     fin: this.formatFullDate(this.datesel.end)
}
let data= await this.GET_CORTES_EMPLEADO(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.clientesdata=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      //SETCLIENTE_SEL
      console.log(this.$store.state.cliente_sel,'----------sel-----------')
   
     this.getData();
      //alert('10')
  }
})
export default class Home extends Vue {}

