
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';


//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'
//@ts-ignore
import palabras from "@/mixins/palabras";

@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
     
  
        columnasd:[
             {
                tag:'id',
                data:'id',
                active:false
            },
            
            
           
            {
                tag:'nombre',
                data:'nombre',
                active:true
            },
          {
                tag:'telefono',
                data:'telefono',
                active:true
            },
             {
                tag:'influencer',
                data:'influencer',
                active:true
            },
             {
                tag:'descuento',
                 data:'influencer_descuento',
                active:true
            },
             {
                tag:'nota',
                 data:'notas',
                active:true
            },
             {
                tag:'edit',
                data:'edit',
                active:false
            },
            {
                tag:'delete',
                data:'delete',
                active:false
            },
             /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/
      
             
            
           
        ],



actual:0,
paginate:0,
        id:null as any,

      telefono:'' as any,
        direccion:'' as any,
           nombre:'' as any,
           notas:'' as any,
          
     
         
         
              clientesdata:[] as any,
      
    }
  },
     mixins: [clientes],
     validations: {
    
           nombre: {
      required,
    },
 
      /* direccion: {
      required,
    },*/  telefono: {
      required,
    },

   
  },
  methods:{
       
      async eliminar(row){
         
this.$root.$emit('loading', true);
let data= await this.ELIMINAR_CLIENTES(row).then(response => {

                    if (response != null) {

 this.clientesdata=this.clientesdata.filter(item => item.id !== row.id);
 
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                });
      },
 
     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
      limpiar(){
this.id=null;
 
        
     
                 this.telefono=null;
    this.direccion=null;

            this.nombre=null;

this.notas=null;

        
        
    
           
      },
      addRow(){
          this.limpiar();
 $('#ac_modal_indicador_area').modal('show')
      },
seteditar(row){
 

 this.id=row.id;
 this.telefono=row.telefono;
 //this.direccion=row.direccion;
     this.nombre=row.nombre; 
       this.notas=row.notas;        
          
 $('#ac_modal_indicador_area').modal('show')
    

},
     async crear(dat){
let data= await this.CREAR_CLIENTES(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)

  this.clientesdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar indicador areas, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
  $('#ac_modal_indicador_area').modal('hide')
                    //loading.close();
                });

      },
     async editar(dat){


let data= await this.EDITAR_CLIENTES(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)
this.$root.$emit("success", "Cliente modificado exitosamente, refresque la pagina");
  this.clientesdata=this.clientesdata.filter(item => item.id !== dat.id);
 this.clientesdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear pmd, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
  $('#ac_modal_indicador_area').modal('hide')
                    //loading.close();
                });


      }
     ,
     async GuardarData(){



console.log('id:'+this.id)

let dat={
  id:this.id,
     nombre:this.nombre,
           telefono:this.telefono,
                notas:this.notas,
               // direccion:this.direccion,
                
}
console.log(dat)
 this.$root.$emit('loading', true);
 if(this.id==null){
     this.crear(dat);
 }else{
       this.editar(dat);
 }

      },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')

let dat=
{
    init:this.actual
}
let data= await this.GET_CLIENTES(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.clientesdata=response[0];
this.paginate=response[1];
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      this.getData();
      //alert('10')
  }
})
export default class Home extends Vue {}

