
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import categorias_sucursales from '@/mixins/axios/categorias_sucursales'
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {
            dialog: false,
            columnasd: [{
                    tag: 'id',
                    data: 'id',
                    active: true
                },
                {
                    tag: 'nombre',
                    data: 'nombre',
                    active: true
                },
                {
                    tag: 'descripcion',
                    data: 'descripcion',
                    active: true
                },

               
                {
                    tag: 'edit',
                    data: 'edit',
                    active: false
                },
                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },

                /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/

            ],
            sel_sucursal: {},
            id: null as any,

            nombre: ''
            ,
            descripcion: ''
            ,
            relaciones: [],
            categorias: [] as any,
            sucursales: []

        }
    },
    mixins: [categorias_sucursales, sucursales],
    validations: {

        nombre: {
            required,
        },
        descripcion: {
            required,
        },

    },
    methods: {
        seleccionarSucursal() {
          console.log(this.relaciones)
            this.relaciones.push({
                sucursales: this.sel_sucursal,
                sucursales_id: this.sel_sucursal.id,

            });
            this.dialog = false
        },
        limpiar() {

        },
        addRow() {
            this.limpiar();
            $("#addCategorias_sucursal").modal("show");
        },

        async getData() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_CATEGORIAS_SUCURSALES().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.categorias = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        async GuardarData() {
            console.log("id:" + this.id);

            let dat = {
                id: this.id,
                nombre: this.nombre,
                descripcion: this.descripcion,
                relaciones: this.relaciones

            };
            console.log(dat);
            this.$root.$emit("loading", true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }
        },
        async getDataSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then(response => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursales = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        seteditar(row) {
            console.log(row)
            this.id = row.id;
            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.relaciones = row.relacion_categorias_sucursales;

            $("#addCategorias_sucursal").modal("show");
        },
        async crear(dat) {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.CREAR_CATEGORIAS_SUCURSALES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    this.getData()
                    //this.categorias = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        async editar(dat) {
            let data = await this.EDITAR_CATEGORIAS_SUCURSALES(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);
                    this.$root.$emit(
                        "success",
                        "Categoria modificada correctamente."
                    );
                    this.getData();
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar editar categoria, refresque la pagina"
                    );
                }
                $("#addCategorias_sucursal").modal("hide");
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
            confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Desea eliminar este elemento?",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },

         async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_CATEGORIAS_SUCURSALES(row).then((response) => {
                if (response != null) {
                    this.getData();
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar paquete, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },

    },
    mounted() {
        //SETCLIENTE_SEL
        console.log(this.$store.state.cliente_sel, '----------sel-----------')

        this.getData();
        this.getDataSucursales();
        //alert('10')
    }
})
export default class Home extends Vue {}
