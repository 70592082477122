import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Dash from '../views/dash.vue'

import AdminSucursales from '../views/admin/sucursales.vue'
//--cita---
import DashCita from '../views/cita/dash.vue'
import HomeCita from '../views/cita/home.vue'
import SucursalesCita from '../views/cita/sucursales.vue'
import HistorialVentas from '../views/admin/historial_ventas.vue'


//----master ---
import BloqueosMaster from '../views/master/bloqueos.vue'
import TableroGeneralMaster from '../views/master/tablero_general.vue'
import UsuariosMaster from '../views/master/usuarios.vue'
import LogMaster from '../views/master/log_general.vue'
import AccesosMaster from '../views/master/accesos.vue'
import CategoriasSucursalUsuarios from '../views/master/CategoriasSucursal.vue'
import Categorias_sucursales from '../views/master/Categorias_sucursales.vue'


import gidtcardsOnline from '../views/gidtcardsOnline.vue'
import status_page from '../views/status_page.vue'

//-------------------------------


import configuracionesPage from '../views/admin/configuraciones.vue'
import PreviewGiftcard from '../views/admin/previewGiftcard.vue'
import analitica_giftcards from '../views/admin/analitica_giftcards.vue'

import Clientes from '../views/admin/clientes.vue'
import Clientesadmin from '../views/admin/clientesadmin.vue'
import Cliente_historial from '../views/admin/clientesHistorial.vue'
import materia_prima from '../views/admin/materia_prima.vue'
import pedidos from '../views/admin/pedidos.vue'
import ordenes from '../views/admin/ordenes.vue'
import empleado_historial from '../views/admin/EmpleadosPagosHistorial.vue'

import ProductoServicios from '../views/admin/procutos_servicios.vue'
import Productos from '../views/admin/productos.vue'
import DetallesVentas from '../views/admin/sucursales.vue'
import Ventas from '../views/admin/ventas.vue'
import admin_giftcards from '../views/admin/admin_giftcards.vue'
import tarjetasControl from '../views/admin/giftcards/tarjetas.vue'
import admin_certificados from '../views/admin/admin_certificados.vue'
import admin_reutilizar_giftcards from '../views/admin/admin_reutilizar_giftcards.vue'


import AdminUsuarios from '../views/admin/usuarios.vue'
import notificaciones from '../views/admin/notificaciones.vue'
import influencers from '../views/admin/influencers.vue'
import graficas from '../views/admin/graficas.vue'
import HomePage from '../views/admin/homePage.vue'
import HomePageSucursal from '../views/admin/infoSucursal/tablero.vue'

import AdminHome from '../views/admin/home.vue'
import PuntoVenta from '../views/admin/puntoventa.vue'
//import PuntoVentaMultiple from '../views/admin/puntoventaMultiple.vue'
import Nomina from '../views/admin/nomina.vue'
import Servicios from '../views/admin/servicios.vue'
import Caja from '../views/admin/caja.vue'
import Gastos from '../views/admin/gastos.vue'
import Suscription from '../views/admin/suscription.vue'

import MunUsuariosM from '../views/mun/general/usuarios_municipio.vue'
import Categorias from '../views/admin/categorias.vue'
import ComisionesUsuario from '../views/admin/comisionesUsuario.vue'
import HorariosUsuario from '../views/admin/HorariosUsuario.vue'
import AsignacionUsuario from '../views/admin/PeriodosUsuario.vue'
import historial_servicios from '../views/admin/historialServicios.vue'

import homeusuario from '../views/admin/homeusuario.vue'
import AdministradorComisiones from '../views/admin/comisionesGenerales.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [




  {
    path: '/pop',
    name: 'index',
    component: Index
  },
  /*{
    path: '/',
    name: 'index',
    component: HomeadminKorabe
  },*/
  {
    path: '/giftcardsonline',
    name: 'giftcardsonline',
    component: gidtcardsOnline,

  },
  {
    path: '/status_page',
    name: 'status_page',
    component: status_page,

  },

  
  {
    path: '/cita',
    name: 'cita',
    component: DashCita,
    children: [
      {
        path: ':empresa',
        name: 'homecita',
        component: HomeCita,

      },
      {
        path: ':empresa/sucursales',
        name: 'sucursalescita',
        component: SucursalesCita,

      },
    ]
  },
  /*{
    path: '/app',
    name: 'dashs',
    component: DashAppKorabe,
    children: [
      {
        path: '/graficas',
        name: 'homeappkorabe',
        component: HomeAppKorabe,

      },
      {
        path: '/',
        name: 'HomePage',
        component: HomePage,

      },
      {
        path: 'add',
        name: 'AddKorabe',
        component: AddKorabe,

      },
      
      {
        path: 'add2',
        name: 'AddKorabe2',
        component: AddKorabe2,

      },
      {
        path: 'add3',
        name: 'AddKorabe3',
        component: AddKorabe3,

      },
      {
        path: 'add4',
        name: 'AddKorabe4',
        component: AddKorabe4,

      },
      {
        path: 'add5',
        name: 'AddKorabe5',
        component: AddKorabe5,

      },



    ]
  },

  */

  {
    path: '/su',
    name: 'dash',
    component: Dash,
    children: [
      {
        path: 'tablero',
        name: 'HomePageSucursal',
        component: HomePageSucursal,

      },
      {
        path: 'configuraciones',
        name: 'configuraciones',
        component: configuracionesPage,

      },
      {
        path: 'preview_giftcard',
        name: 'preview_giftcard',
        component: PreviewGiftcard,

      },
      {
        path: 'historial_ventas',
        name: 'historial_ventas',
        component: HistorialVentas,

      },
      {
        path: 'estadisticas',
        name: 'estadisticasadmin',
        component: HomePage,

      },
      {
        path: '/',
        name: 'Tablero',
        component: AdminHome,

      },

      {
        path: 'marketing',
        name: 'notificaciones',
        component: notificaciones,

      },
      {
        path: 'suscripcion',
        name: 'suscripcion',
        component: Suscription,

      },

     
      {
        path: 'materia_prima',
        name: 'materia_prima',
        component: materia_prima,

      },
      {
        path: 'pedidos',
        name: 'pedidos',
        component: pedidos,

      },
      {
        path: 'ordenes',
        name: 'ordenes',
        component: ordenes,

      },{
        path: 'analitica_giftcards',
        name: 'analitica_giftcards',
        component: analitica_giftcards,

      },
      {
        path: 'giftcards/reutilizar',
        name: 'admin_reutilizar_giftcards',
        component: admin_reutilizar_giftcards,

      },
      {
        path: 'certificados/admin',
        name: 'admin_certificados',
        component: admin_certificados,

      },
      
      
      {
        path: 'giftcards/control',
        name: 'giftcardspagos',
        component: tarjetasControl,

      },
      {
        path: 'giftcards/admin',
        name: 'adminGiftcards',
        component: admin_giftcards,

      },
      {
        path: 'ventas',
        name: 'Ventas',
        component: Ventas,

      },
      {
        path: 'graficas',
        name: 'Graficas',
        component: graficas,

      },
      {
        path: 'promociones',
        name: 'Promociones',
        component: influencers,

      },

      {
        path: 'productoservicios',
        name: 'Servicios',
        component: ProductoServicios,

      },
      {
        path: 'categorias',
        name: 'Categorias',
        component: Categorias,

      },

      {
        path: 'clientes',
        name: 'Clientes',
        component: Clientes,

      },

      {
        path: 'empleado/historial',
        name: 'Historial Empleados',
        component: empleado_historial,

      },
      {
        path: 'cliente/historial',
        name: 'Historial Clientes',
        component: Cliente_historial,

      },
      {
        path: 'clientesadmin',
        name: 'Clientes',
        component: Clientesadmin,

      },
      {
        path: 'sucursales',
        name: 'Sucursales',
        component: AdminSucursales,

      },
      
      /*{
        path: 'calendario_multiple',
        name: 'calendario_multiple',
        component: PuntoVentaMultiple,

      },*/
      {
        path: 'calendario',
        name: 'Calendario',
        component: PuntoVenta,

      },

      {
        path: 'usuarios',
        name: 'Empleados',
        component: AdminUsuarios,


      },
      {
        path: 'caja',
        name: 'Caja',
        component: Caja,


      },
      {
        path: 'nomina',
        name: 'Nomina',
        component: Nomina,


      },
      {
        path: 'servicios',
        name: 'Servicios',
        component: Servicios,


      },
      {
        path: 'gastos',
        name: 'Gastos',
        component: Gastos,


      },
      {
        path: 'productos',
        name: 'Productos',
        component: Productos,


      },
      {
        path: 'historial_servicios',
        name: 'Ventas Servicios',
        component: historial_servicios,


      },

      {
        path: 'usuarios/comisiones',
        name: 'Comisiones',
        component: ComisionesUsuario,

      },
      {
        path: 'usuarios/horarios',
        name: 'Horiarios Empleado',
        component: HorariosUsuario,

      }
      ,
      {
        path: 'usuarios/asignacion',
        name: 'Intercambio Empleado',
        component: AsignacionUsuario,

      }
      
      ,
      {
        path: 'administrador/comisiones',
        name: 'Administrador Comisiones',
        component: AdministradorComisiones,

      }
      ,
      {
        path: 'usuarios/categoriasucursal',
        name: 'Administrador categoriasucursal',
        component: CategoriasSucursalUsuarios,

      }
    ]

  },
  {
    path: '/sucursal',
    name: 'dashmun',
    component: Dash,
    children: [




      {
        path: 'general/usuarios_sucursal',
        name: 'usuarios_sucursal',
        component: MunUsuariosM,

      },



    ]

  },


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/ms',
    name: 'dashmaster',
    component: Dash,
    children: [



     
      {
        path: 'tablero',
        name: 'tablero_master',
        component: TableroGeneralMaster,

      },
      {
        path: 'categorias/sucursales',
        name: 'Categorias_sucursales',
        component: Categorias_sucursales,

      },
      {
        path: 'usuarios',
        name: 'usuarios_master',
        component: UsuariosMaster,

      },
      {
        path: 'log',
        name: 'log_master',
        component: LogMaster,

      },
      {
        path: 'accesos',
        name: 'accesos_master',
        component: AccesosMaster,

      },
      {
        path: 'bloqueos',
        name: 'bloqueos_master',
        component: BloqueosMaster,

      },



    ]

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
