
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import producto_servicios from '@/mixins/axios/productos'

//@ts-ignore
import materias_primas from '@/mixins/axios/materias_primas'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {
            //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,

            columnasd: [
                {
                    tag: 'categoria',
                    data: 'categoria',
                    active: true
                },
                {
                    tag: 'sku',
                    data: 'sku',
                    active: true
                },
                {
                    tag: 'nombre',
                    data: 'nombre',
                    active: true
                },

                {
                    tag: 'precio',
                    data: 'precio_venta',
                    active: true
                },

             

               
                /* {
                     tag:'color',
                     data:'color',
                     active:true
                 },*/
                {
                    tag: 'edit',
                    data: 'edit',
                    active: false
                },
                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },

            ],

            id: null as any,

            precio: '',

            nombre: '',
            folio: '',
            sku: '',
            descripcion: '',
            categoria: '',

          colores:'',

            materias_primas_data: [] as any,
            categoriasdata: [] as any,
          

        }
    },
    mixins: [materias_primas],
    validations: {

        precio: {
            required,
        },

        nombre: {
            required,
        },

        categoria: {
            required,
        },
        sku: {
            required,
        },
        descripcion: {
            required,
        },
        colores: {
            
        },

       

    },
    methods: {

      
        async eliminar(row) {

            this.$root.$emit('loading', true);
            let data = await this.ELIMINAR_MATERIAS_PRIMAS(row).then(response => {

                if (response != null) {

                    this.materias_primas_data = this.materias_primas_data.filter(item => item.id !== row.id);

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar elemento, refresque la pagina");

                }
                this.$root.$emit('loading', false);
            });
        },

        confirm(row) {

            let th = this;
            Notiflix.Confirm.Show('Alerta de confirmacion', 'Desea eliminar este elemento?', 'Aceptar', 'Cancelar', function () {

                th.eliminar(row);
            }, function () {

            });

        },
        limpiar() {
            this.id = null;

            this.precio = null;
            this.nombre = null;
            this.folio = null;
            this.sku = null;
            this.descripcion = null;
            this.categorria = null;
          

        },
        addRow() {
            this.limpiar();
            $('#ac_modal_materias_primas').modal('show')
        },
        seteditar(row) {

            this.id = row.id;
            this.precio = row.precio_venta;

            this.nombre = row.nombre;
            this.descripcion = row.descripcion;
            this.folio = row.folio;
            this.categoria = row.categoria;
            this.colores = row.colores;
            this.sku = row.sku;

           
            $('#ac_modal_materias_primas').modal('show')

        },
        async crear(dat) {
            let data = await this.CREAR_MATERIAS_PRIMAS(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)

                    this.materias_primas_data.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar elemento, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_materias_primas').modal('hide')
                //loading.close();
            });

        },
        async editar(dat) {

            let data = await this.EDITAR_MATERIAS_PRIMAS(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)
                    this.$root.$emit("success", "Prodcuto editado exitosamente, refresque la pagina");
                    this.materias_primas_data = this.materias_primas_data.filter(item => item.id !== dat.id);
                    this.materias_primas_data.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_materias_primas').modal('hide')
                //loading.close();
            });

        },
        async GuardarData() {

            console.log('id:' + this.id)

            let dat = {
                id: this.id,

                precio_venta: this.precio,
                nombre: this.nombre,
                folio: this.folio,
                sku: this.sku,
                descripcion: this.descripcion,
                categoria:this.categoria,
                colores:this.colores

            }
            console.log(dat)
            this.$root.$emit('loading', true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }

        },
        async getData() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_MATERIAS_PRIMAS().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.materias_primas_data = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    mounted() {
        this.getData();
       
        //alert('10')
    }
})
export default class Home extends Vue {}
