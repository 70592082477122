
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import ventas from "@/mixins/axios/ventas";
//@ts-ignore
import cobros from "@/mixins/axios/cobros";
//@ts-ignore
import cortes_dia from "@/mixins/axios/cortes_dia";
//@ts-ignore
import { email, required } from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";

import VueAxios from "vue-axios";
import axios from "axios";
//@ts-ignore
import palabras from "@/mixins/palabras";
@Component({
  components: {
    TablePPoa,
  },
  data() {
    return {
                                   
      metodos:['nada',
        'E',
         'C',
          'D'
      ],
      collapsedata: false,
      datesel: {
        start: this.formatFullDate(this.getfisrtDay()),
        end:this.formatFullDate( new Date()),
      },
      rr: this.actualformatDate(),
      sucursalsel:   this.$store.state.user.usuario.sucursales_id,
      venta: 0,
      sucursalesdata: [],

      id: 0,
      columnasd: [
        {
          tag: "id",
          data: "id",
          active: false,
        },
        {
          tag: "nombre",
          data: "cliente",
          active: true,
        },
        ,
        /*
                  {
                      tag:'descripcion',
                      data:'descripcion',
                      active:true
                  },*/

        {
          tag: "contacto",
          data: "contacto",
          active: true,
        },
        {
          tag: "delete",
          data: "delete",
          active: false,
        },
        {
          tag: "edit",
          data: "edit",
          active: false,
        },
      ],

      ventasdia: [] as any,
      cobrosdia: [] as any,
      nota: "",
      metodo: 0,
      
      influencer: 1,
      cliente: 1,
      precio: 1,
      actual: 0,
    };
  },
  mixins: [sucursales, ventas, cobros, cortes_dia,palabras],
  validations: {},
  methods: {
    formatTime(date) {
      var time = new Date(date);

      return time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    },
    randomNum() {
      let colors = ["100", "200", "300"];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    randomColor() {
      let colors = [
        "red",
        "yellow",
        "green",
        "blue",
        "indigo",
        "purple",
        "pink",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    foormatlocal(date) {
      let datef = new Date(date);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return datef.toLocaleDateString("es-MX", options);
    },
    
    formatFullDate(date) {
        console.log(date,'------fecha-------')
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + (d.getDate()),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getfisrtDay() {
      var myCurrentDate = new Date();
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() - 7);
      return myPastDate;
    },
    CalcEfectivo(data) {
      let total = 0;
      data = JSON.parse(data);
      data.forEach((element) => {
        if (element.metodo == 1)
          total = Number(total) + Number(element.cantidad);
      });
      return total;
    },
    CalcTarjeta(data) {
      let total = 0;
      data = JSON.parse(data);
      data.forEach((element) => {
        if (element.metodo == 2)
          total = Number(total) + Number(element.cantidad);
      });
      return total;
    },
    CalcTarjetaDebito(data) {
      let total = 0;
      data = JSON.parse(data);
      data.forEach((element) => {
        if (element.metodo == 3)
          total = Number(total) + Number(element.cantidad);
      });
      return total;
    },
    calcCaja() {
      let total = 0;
      this.cobrosdia.forEach((element) => {
        total = Number(total) + Number(element.precio);
      });
      return total;
    },
    calcCajaTarjeta() {
      let total = 0;
      this.cobrosdia.forEach((element) => {
        if (element.metodo != 1) total = Number(total) + Number(element.precio);
      });
      return total;
    },
    calcCajaEfectivo() {
      let total = 0;
      this.cobrosdia.forEach((element) => {
        if (element.metodo == 1) total = Number(total) + Number(element.precio);
      });
      return total;
    },

    openGenerar() {
      $("#modal-generar").modal("show");
    },
    async generarCorte() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data_s = {
        id: this.sucursalsel,
        dia: this.datesel,
        actual: this.actual,
        total: this.calcCaja(),
      };
      let data = await this.GENERAR_CORTE_DIA(data_s).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.corte_dia = response;
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        $("#modal-generar").modal("hide");
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
    validatepago() {
      let cantidad = 0;
      this.metodos.forEach((element) => {
        cantidad = Number(cantidad) + Number(element.cantidad);
      });
      if (this.precio == cantidad) {
        return false;
      } else {
        return true;
      }
    },
    update(e, index) {
      console.log(e.target.value);
      let cantidad = 0;
      this.metodos.forEach((element) => {
        cantidad = Number(cantidad) + Number(element.cantidad);
      });
      if (cantidad > Number(this.precio)) {
        let cant = cantidad - Number(this.precio);
        //calcula la cantidad total de los demas metodos
        let cant2 = cantidad - e.target.value;
        //asigna el valor que falta para la cantidad total
        this.metodos[index].cantidad = Number(this.precio) - cant2;
      }
    },
    SelectSucursalChange() {
      //aquiiiii

      //this.getData();
      this.getCobros();
      //console.log(,'----------------sel--change-------------')
    },
    formatPriceTotal(data){
 let total = 0;
 if(data.hasOwnProperty('metodos')&&data.metodos!=null){
  data = JSON.parse(data.metodos);
            data.forEach((element) => {
                
total = Number(total) + Number(element.cantidad);
                
                    
            });
 }
         
            return total;
    },
   
    formatPrice(value) {
      value = Number(value).toFixed(2);
      value = value.toString().replace(".00", "");
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    selectVenta(row) {
      this.metodos = [];
      this.venta = row.id;
      this.influencer = row.influencer;
      this.cliente = row.cliente;
      this.precio = row.detalle_ventas[0].producto_servicios.precio;
      $("#cobrosmodal").modal("show");
    },
    selectdate(val) {
      console.log(val, "------------val----");
      //this.getData()
      this.getCobros();
    },
    actualformatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    async eliminar(row) {
      this.$root.$emit("loading", true);
      let data = await this.ELIMINAR_SUCURSALES(row).then((response) => {
        if (response != null) {
          this.cobrosdia = this.cobrosdia.filter((item) => item.id !== row.id);
        } else {
          this.$root.$emit(
            "alerta_show",
            "Ocurrio un error al intentar eliminar sucursal, refresque la pagina"
          );
        }
        this.$root.$emit("loading", false);
      });
    },
    confirm(row) {
      let th = this;
      Notiflix.Confirm.Show(
        "Alerta de confirmacion",
        "Desea eliminar este elemento?",
        "Aceptar",
        "Cancelar",
        function () {
          th.eliminar(row);
        },
        function () {}
      );
    },
    limpiar() {
      this.id = null;
    },

    async CrearCobro() {
      this.$root.$emit("loading", true);
      let dat = {
        venta: this.venta,
        // nota:this.nota,
        influencer: this.influencer,
        metodos: this.metodos,
      };

      let data = await this.CREAR_COBROS(dat).then((response) => {
        if (response != null) {
          //this.plantas = response;
          console.log(response);

          this.cobrosdia.push(response);
          this.ventasdia = this.ventasdia.filter(
            (item) => item.id !== this.venta
          );
        } else {
          this.$root.$emit(
            "alerta_show",
            "Ocurrio un error al intentar editar sucursal, refresque la pagina"
          );
        }
        this.$root.$emit("loading", false);
        $("#cobrosmodal").modal("hide");
        //loading.close();
      });
    },

    async getCobros() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data_s = {
        id: this.sucursalsel,
        
        inicio: this.datesel.start,
        fin: this.datesel.end,
      };
      let data = await this.GET_VENTAS_RANGUE(data_s).then((response) => {
        if (response != null) {
          //this.plantas = response;
          let actual = 0;
          let data = {};
          response.forEach((element) => {
            if (data.hasOwnProperty(element.idempleada)) {
              data[element.idempleada]["totalventas"]++;
              data[element.idempleada]["totalclientes"][element.clientes_id] =
                element.clientes_id;
              data[element.idempleada]["total"] =
                Number(data[element.idempleada]["total"]) +
                Number(this.formatPriceTotal(element));
              data[element.idempleada]["propina"] =
                Number(data[element.idempleada]["propina"]) +
                Number(element.propina);
              data[element.idempleada]["totalcomision"] =
                Number(data[element.idempleada]["totalcomision"]) +
                Number(element.comisioncobro);
              if (
                data[element.idempleada]["data"].hasOwnProperty(
                  element.ano + "/" + element.mes + "/" + element.dia
                )
              ) {
                data[element.idempleada]["data"][
                  element.ano + "/" + element.mes + "/" + element.dia
                ]["data"].push(element);
              } else {
                data[element.idempleada]["data"][
                  element.ano + "/" + element.mes + "/" + element.dia
                ] = {
                  fecha: element.ano + "/" + element.mes + "/" + element.dia,
                  data: [],
                };
                data[element.idempleada]["data"][
                  element.ano + "/" + element.mes + "/" + element.dia
                ]["data"].push(element);
              }
            } else {
              data[element.idempleada] = {
                empleada: element.empleada,
                total: 0,
                totalclientes: {},
                totalcomision: 0,
                totalventas: 0,
                propina: 0,
                data: {},
              };
              data[element.idempleada]["totalventas"]++;
              data[element.idempleada]["total"] =
                Number(data[element.idempleada]["total"]) +
                Number(this.formatPriceTotal(element));
              data[element.idempleada]["propina"] =
                Number(data[element.idempleada]["propina"]) +
                Number(element.propina);
              data[element.idempleada]["totalcomision"] =
                Number(data[element.idempleada]["totalcomision"]) +
                Number(element.comisioncobro);
              data[element.idempleada]["totalclientes"][element.clientes_id] =
                element.clientes_id;
              data[element.idempleada]["data"][
                element.ano + "/" + element.mes + "/" + element.dia
              ] = {
                fecha: element.ano + "/" + element.mes + "/" + element.dia,
                data: [],
              };
              data[element.idempleada]["data"][
                element.ano + "/" + element.mes + "/" + element.dia
              ]["data"].push(element);

              //data[element.idempleada].push(element)
            }
          });

          this.cobrosdia = data;
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
    async getSucursales() {
          
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data = await this.GET_SUCURSALES().then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.sucursalesdata = response;

          if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }
         // this.getData();
          this.getCobros();
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },

   
  },
  mounted() {
 
    this.getSucursales();

    // this.mapainit();
    //alert('10')
  },
})
export default class Home extends Vue {}
