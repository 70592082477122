
//@ts-nocheck

//@ts-ignore
import tablero from "@/mixins/axios/tablero";
import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import palabras from "@/mixins/palabras";

//@ts-ignore
import generales from "@/mixins/functions/generales"
@Component({
  components: {},
  mixins: [tablero,palabras,generales],
  mounted() {
    this.getTablero1();
    this.getGraficas();
    //this.mapainit();
  },
  data() {
    return {
      tablero1: {},
      gannancias: 0,
    };
  },
  methods: {
    

    
     async getGraficas() {
      let data = await this.GET_TABLERO_GRAFICAS().then((response) => {
        if (response != null) {
          //this.plantas = response;

        console.log(response)
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
    async getTablero1() {
      let data = await this.GET_TABLERO_DATA().then((response) => {
        if (response != null) {
          //this.plantas = response;

          this.tablero1 = response;
          let ganan = 0;
          let propina = 0;
          this.tablero1.ventas_dia.forEach((element) => {
            propina += Number(element.monto_propina);
            JSON.parse(element.metodos).forEach((metodo) => {
              ganan += Number(metodo.cantidad);
            });
          });

          this.gannancias = Number(ganan) - Number(propina);
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
  },
})
export default class Home extends Vue {}
