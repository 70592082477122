
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import ventas from "@/mixins/axios/ventas";
//@ts-ignore
import cobros from "@/mixins/axios/cobros";
//@ts-ignore
import gastos from "@/mixins/axios/gastos";
//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";

//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import influencers from "@/mixins/axios/influencers";
//@ts-ignore
import cortes_dia from "@/mixins/axios/cortes_dia";
//@ts-ignore
import ventagiftcard from "@/mixins/axios/ventagiftcards";
//@ts-ignore

import {
    email,
    required
} from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//@ts-ignore
import palabras from "@/mixins/palabras";

//@ts-ignore
import generales from "@/mixins/functions/generales"
//@ts-ignore
import producto_servicios from "@/mixins/axios/productos";
import VueAxios from "vue-axios";
import axios from "axios";
import ItemTemplate from "./templateselect.vue";
//@ts-ignore
import palabras from "@/mixins/palabras";
import qz from 'qz-tray';
@Component({
    components: {
        TablePPoa,
    },
    data() {
        return {
            cobrosel_mouse:0,
            ventasdia: [] as any,
            cobrosdia: [] as any,
            ventadetalle: {},
            viewCobrar: false,
            openalert: 0,
            //transferiri saldo
            giftcard1: '',
            giftcard3: '',
            giftcard2: '',
            saldo_giftcard1: '',
            saldo_giftcard2: '',

            //--------totales giftcards-----
            total_tarjeta_giftcards: 0,
            total_efectivo_giftcards: 0,

            //end totales giftcards
            tabactive_c: 0,
            ventasgiftcard: [],
            //----data cliente--------
            clientesdata: [],
            newItemCliente: "",
            //-----end data cliente
            cantidadmanual: 0,
            template: ItemTemplate,
            datesel: this.actualformatDate(),
            sucursalsel: this.$store.state.user.usuario.sucursales_id,
            columnasd: [{
                    tag: "nota",
                    data: "nota",
                    active: true,
                },

                {
                    tag: "cantidad",
                    data: "cantidad",
                    active: true,
                },

                {
                    tag: "edit",
                    data: "edit",
                    active: false,
                },
                {
                    tag: "delete",
                    data: "delete",
                    active: false,
                },
            ],
            venta: null,
            sucursalesdata: [],

            id: 0,
            precio: "",

            nombre: "",
            cantidad: "",

            nota: "",

            gastos_data: [] as any,
            cobro_sel: {},
            show_det_cobro: false,

            propina: null,
            giftcard: "",
            saldogiftcard: "",
            cantidadgiftcard: 0,
            id_giftcard: 0,
            metodo: 0,
            productosData: [],
            metodos: [{
                    text: 'EFECTIVO',
                    metodo: 1,
                    cantidad: 0
                },
                {
                    text: 'TDD',
                    metodo: 3,
                    cantidad: 0
                },
                {
                    text: 'TDC',
                    metodo: 2,
                    cantidad: 0
                }

            ],
            productos: [],
            influencers: [],
            influencer: 1,
            influencer_id: 0,
            pdescuento: 0,
            pdescuento_cliente: 0,
            gastosdata: {
                gasto: 0
            },
            cliente: 1,
            seleccionados: {},
            actual: 0,
            selval: true,
        };
    },
    mixins: [
        generales,
        palabras,
        ventagiftcard,
        clientes,
        sucursales,
        ventas,
        cobros,
        cortes_dia,
        producto_servicios,
        gastos,
        influencers,
        giftcards,
    ],
    validations: {
        cantidad: {
            required,
        },

        nota: {
            required,
        },
    },
    methods: {
        contarsel() {
            return Object.keys(this.seleccionados).length;
        },
        checkMultiple(id, data) {

            console.log(id, data, '----------------multiple click')
            this.selval = 0;

            this.venta = data.id;
            this.cliente = data.cliente;
            this.ventadetalle = data;

            if (this.seleccionados.hasOwnProperty(id)) {
                this.seleccionados = Object.keys(this.seleccionados).reduce(
                    (object, key) => {
                        if (key != id) {
                            object[key] = this.seleccionados[key];
                        }
                        return object;
                    }, {}
                );
            } else {
                let is_cliente = 0;
                Object.entries(this.seleccionados).forEach(([key, value]) => {
                    console.log(key + " " + value);
                    if (data.clientes_id != value.clientes_id) {
                        is_cliente = is_cliente + 1;
                    }
                });
                if (is_cliente > 0) {
                    //en caso de que la venta seleccionada o por seleccionar no sea de el mismo cliente
                    this.$root.$emit(
                        "shalert",
                        "No se puede seleccionar citas de diferentes clientes"
                    );
                    console.log("-----muestra-----");
                } else {
                    console.log("-----no muestra-----");
                    //en caso de que todas las ventas sean de el mismo cliente
                    this.seleccionados[id] = data;
                    this.seleccionados[id].propina = 0;
                }
                console.log(this.seleccionados);
            }
            this.selval = true;
            this.CalcTotalCitasSeleccionadas();
        },
        async getVentasGiftcard() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.sucursalsel,
                inicio: this.datesel,
                fin: this.datesel,

            };
        
            let data = await this.GET_VENTASGIFTCARDS(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.ventasgiftcard = response;
                    this.total_tarjeta_giftcards = 0;
                    this.total_efectivo_giftcards = 0;
                    response.forEach((element) => {

                        this.total_tarjeta_giftcards += Number(element.td);

                        this.total_tarjeta_giftcards += Number(element.tc);

                        this.total_efectivo_giftcards += Number(element.efectivo);

                    });
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getClientes(text) {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                text: text,
            };
            let data = await this.GET_CLIENTES2(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        getLabel(item) {
            if (item != null) {
                return item.nombre + "-" + item.telefono;
            } else {
                return "";
            }
        },

        async onSearch(search) {
            if (search.length) {
                this.getClientes(search);
            } else {
                this.clientesdata = [];
            }
            // loading(false);
            //this.search(loading, search, this);
        },
        calcularRestante() {
            let restante =
                Number(this.precio) +
                Number(this.calcTotProducts()) +
                Number(this.calcPropinas()) -
                Number(
                    Number(this.cantidadmanual) +
                    ((Number(this.precio)+Number(this.calcTotProducts())) / 100) * Number(this.pdescuento)
                );
            return this.formatPrice(
                Number(
                    restante -
                    (Number(this.calcMetodos()) + Number(this.cantidadgiftcard))
                ).toFixed(2)
            );
        },
        async Transferirsaldo() {

            $("#transferir_saldo").modal("show");
        },
        async TransferirSaldoDeA() {
            if (this.giftcard1 == this.giftcard2) {

                this.$root.$emit("alerta_show", "No puede transferir a la misma giftcard");
                this.giftcard2 = '';
                this.saldo_giftcard2 = '';
            } else
            if (this.giftcard1 != '' && this.giftcard2 != '') {
                let data = await this.TRANSFERIR_SALDO_GIFTCARDS({
                    giftcard1: this.giftcard1,
                    giftcard2: this.giftcard2
                }).then(
                    (response) => {
                        if (response != null) {
                            //this.plantas = response;
                            this.$root.$emit(
                                "success",
                                "Se transfirio correctamente"
                            );
                            this.saldo_giftcard1 = '';
                            this.saldo_giftcard2 = '';

                        } else {
                            this.$root.$emit("alerta_show", "el numero de tarjeta no existe");

                        }
                        this.$root.$emit("loading", false);

                        //loading.close();
                    }
                );

            } else {
                this.$root.$emit("alerta_show", "Es necesario ingresar 2 giftcard");
            }

        },
        async ObtenerSaldoGiftcard(tipo, numero) {
            this.$root.$emit("loading", true);
            let data = await this.GET_GIFTCARDS({
                numero: numero
            }).then(
                (response) => {
                    if (response != null) {
                        //this.plantas = response;

                        if (tipo == 1) {

                            this.saldo_giftcard1 = Number(response.cantidad) - Number(response.gastado);
                        } else {

                            this.saldo_giftcard2 = Number(response.cantidad) - Number(response.gastado);
                        }

                    } else {
                        this.$root.$emit("alerta_show", "el numero de tarjeta no existe");

                        if (tipo == 1) {
                            this.giftcard1 = '';
                            this.saldo_giftcard1 = '';
                        } else {
                            this.giftcard2 = '';
                            this.saldo_giftcard2 = '';
                        }
                    }
                    this.$root.$emit("loading", false);

                    //loading.close();
                }
            );

        },
        async Obtenersaldo() {
            this.$root.$emit("loading", true);
            let data = await this.GET_GIFTCARDS({
                numero: this.giftcard
            }).then(
                (response) => {
                    if (response != null) {
                        //this.plantas = response;
                        this.saldogiftcard =
                            Number(response.cantidad) - Number(response.gastado);
                        //this.cantidadgiftcard =
                        //Number(response.cantidad) - Number(response.gastado);
                        this.id_giftcard = response.id;
                    } else {
                        this.$root.$emit("alerta_show", "el numero de tarjeta no existe");
                        this.saldogiftcard = "";
                        this.id_giftcard = 0;
                        this.giftcard = "";
                        this.cantidadgiftcard = 0;
                    }
                    this.$root.$emit("loading", false);
                    $("#ac_gastos").modal("hide");
                    //loading.close();
                }
            );
        },
        printtest() {
            let $html =
                "<style>" +"body {"+"font-size: 10px;"+"font-family:Calibri;"+"}"+"table {"+" font-size: 10px;"+"font-family:Calibri;"+"}"+"</style><style>" +'<table style="width:100%">'+"<tr>"+'<td align ="left">SALE ORDER NO</td>'+'<td align ="right">S01</td>'+"</tr>"+"<tr>"+'<td align ="left">SALE ORDER D/TIME</td>'+'<td align ="right">2009/01/01</td>'+"</tr>"+"<tr>"+'<td align ="left">CUSTOMER</td>'+'<td align ="right">JOHN DOE</td>'+"</tr>"+"</table>";

this.Popup($html);
}

,
Popup(data) {
    var myWindow=window.open("", "Receipt", "height=400,width=600");
    myWindow.document.write("<html><head><title>Receipt</title>");
    /*optional stylesheet*/
    //myWindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
    myWindow.document.write(' *, html {margin:0;padding:0;} </style>');
            myWindow.document.write("</head><body>");
            myWindow.document.write(data);
            myWindow.document.write("</body></html>");
            myWindow.document.close(); // necessary for IE >= 10

            myWindow.onload = function () {
                // necessary if the div contain images

                myWindow.focus(); // necessary for IE >= 10
                myWindow.print();
                myWindow.close();
            };
        },
        CalculaDescuento($event) {
            if ($event.target.value == 0) {
                this.pdescuento = this.pdescuento_cliente;
            } else {
                console.log($event.target.value);
                let data = this.influencers.find(
                    (row) => row.id == $event.target.value
                );
                this.pdescuento_cliente = this.pdescuento;
                this.pdescuento = data.descuento;
                this.cantidadmanual = 0;
            }
        },
        calcGastoDiario() {
            let cant = 0;

            this.gastos_data.forEach((element) => {
                cant += Number(element.cantidad);
            });
            return cant;
        },
        async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_GASTOS(row).then((response) => {
                if (response != null) {
                    this.gastos_data = this.gastos_data.filter(
                        (item) => item.id !== row.id
                    );
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar paquete, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },

        confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Desea eliminar este elemento?",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },
        limpiar() {
            this.id = null;

            this.cantidad = null;
            this.nota = null;
        },
        addRow() {
            this.limpiar();
            $("#ac_gastos").modal("show");
        },
        seteditar(row) {
            this.id = row.id;
            this.cantidad = row.cantidad;

            this.nota = row.nota;

            $("#ac_gastos").modal("show");
        },
        async crear(dat) {
            let data = await this.CREAR_GASTOS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.gastos_data.push(response);
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar registrar gasto, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
                $("#ac_gastos").modal("hide");
                //loading.close();
            });
        },
        async editar(dat) {
            let data = await this.EDITAR_GASTOS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);
                    this.$root.$emit(
                        "success",
                        "Prodcuto o Servicio modificado exitosamente, refresque la pagina"
                    );
                    this.gastos_data = this.gastos_data.filter(
                        (item) => item.id !== dat.id
                    );
                    this.gastos_data.push(response);
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar editar gasto, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
                $("#ac_gastos").modal("hide");
                //loading.close();
            });
        },
        async GuardarData() {
            console.log("id:" + this.id);

            let dat = {
                id: this.id,
                // sucursal:this.sucursalsel,
                cantidad: this.cantidad,
                nota: this.nota,
            };
            console.log(dat);
            this.$root.$emit("loading", true);
            if (this.id == null) {
                dat = {
                    id: this.id,
                    sucursal: this.sucursalsel,
                    cantidad: this.cantidad,
                    nota: this.nota,
                };
                this.crear(dat);
            } else {
                this.editar(dat);
            }
        },
        async getGastos_data() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let dat = {
                dia: this.actualformatDate(),
                sucursal: this.sucursalsel,
            };
            let data = await this.GET_GASTOSDIA_DATA(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.gastos_data = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        calcTotProd(dat) {
            console.log(dat, "----------------dataaaa for---");
            let total = 0;
            dat.forEach((element) => {
                total += Number(element.total);
            });
            return total;
        },
        showDataC(row) {
            console.log(row, "/-----------cobro------");
            this.cobro_sel = row;
            $("#cobro_det").modal("show");
        },
        calcMetodos() {
            let tot = 0;
            this.metodos.forEach((element) => {
                tot += Number(element.cantidad);
            });
            return tot;
        },
        calcPropinas() {
            let tot = 0;
            Object.entries(this.seleccionados).forEach(([key, value]) => {
                tot += Number(value.propina);
            });
            return tot;
        },
        calcTotProducts() {
            let tot = 0;
            this.productos.forEach((element) => {
                tot += Number(element.precio) * Number(element.cantidad);
            });
            return tot;
        },
        SelectProduct(value, index) {
            console.log(value.target.value, "-----------------v--------vv------");
            let data = this.productosData.find((row) => row.id == value.target.value);
            this.productos[index].precio = data.precio;
        },
        async getProductos() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let data = await this.GET_PRODUCTOS().then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.productosData = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        CalcEfectivo(data) {
            let total = 0;
            data = JSON.parse(data);
            data.forEach((element) => {
                if (element.metodo == 1)
                    total = Number(total) + Number(element.cantidad);
            });
            return total;
        },
        CalcTarjeta(data) {
            let total = 0;
            data = JSON.parse(data);
            data.forEach((element) => {
                if (element.metodo == 2)
                    total = Number(total) + Number(element.cantidad);
            });
            return total;
        },
        CalcTarjetaDebito(data) {
            let total = 0;
            data = JSON.parse(data);
            data.forEach((element) => {
                if (element.metodo == 3)
                    total = Number(total) + Number(element.cantidad);
            });
            return total;
        },
        calcCaja() {
            let total = 0;
            this.cobrosdia.forEach((element) => {
                total = Number(total) + Number(element.precio);
            });
            return total;
        },
        calcCajaTarjeta() {
            let total = 0;
            this.cobrosdia.forEach((element2) => {

                if (element2.metodos != null) {
                    JSON.parse(element2.metodos).forEach((element) => {
                        //console.log(element.metodo);
                        if (Number(element.metodo) != 1) {
                            total = Number(total) + Number(element.cantidad);
                        }
                    });
                } else {

                }

            });
            return Number(total);
        },
        calcCajaEfectivo() {
            let total = 0;
            this.cobrosdia.forEach((element2) => {

                //console.log(element);

                if (element2.metodos != null) {
                    JSON.parse(element2.metodos).forEach((element) => {
                        //console.log(element.metodo);
                        if (Number(element.metodo) == 1) {
                            total = Number(total) + Number(element.cantidad);
                        }
                    });
                } else {

                }

            });

            return Number(total);
        },
        calcGiftcard() {
            let total = 0;
            this.cobrosdia.forEach((element2) => {
                total = Number(total) + Number(element2.cantidad_giftcards);
            });
            return total;
        },

        openGenerar() {
            $("#modal-generar").modal("show");
        },
        async generarCorte() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.sucursalsel,
                dia: this.datesel,
                actual: this.actual,
                total: this.calcCaja(),
            };
            let data = await this.GENERAR_CORTE_DIA(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.corte_dia = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                $("#modal-generar").modal("hide");
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        validatepago() {
            let cantidad = 0;
            if (this.influencer == 1) {
                return false;
            } else {
                this.metodos.forEach((element) => {
                    cantidad = Number(cantidad) + Number(element.cantidad);
                });
                if (
                    Number(this.precio) +
                    Number(this.calcTotProducts()) +
                    Number(this.calcPropinas()) ==
                    cantidad
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        updateCantidadgiftcard() {
            if (this.cantidadgiftcard > this.saldogiftcard) {
                this.cantidadgiftcard = this.saldogiftcard;

                return;
            }
            //console.log(e.target.value,'-------cantidad-----');
            let cantidad = 0;

            let precio_descuento = (this.precio / 100) * this.pdescuento;
            precio_descuento = precio_descuento + this.cantidadmanual;
            this.metodos.forEach((element) => {
                cantidad = Number(cantidad) + Number(element.cantidad);
            });
            let cant =
                Number(this.precio) +
                Number(this.calcTotProducts() + Number(this.calcPropinas())) -
                Number(cantidad + Number(precio_descuento));
            console.log(cant, "-------cantidad-----sho---w");
            if (this.cantidadgiftcard > cant) {
                this.cantidadgiftcard = cant;
                console.log(cant, "-------cantidad---");
            } else {}
        },
        update(e, index) {
            let cantidad = 0;

            let precio_descuento = (this.precio / 100) * this.pdescuento;
            precio_descuento = precio_descuento + this.cantidadmanual;
            this.metodos.forEach((element) => {
                cantidad = Number(cantidad) + Number(element.cantidad);
            });
            cantidad = cantidad - this.metodos[index].cantidad;
            let cant =
                Number(this.precio) +
                Number(this.calcTotProducts() + Number(this.calcPropinas())) -
                Number(
                    Number(this.cantidadgiftcard) + cantidad + Number(precio_descuento)
                );
            console.log(cant, "-------cant----");
            if (this.metodos[index].cantidad > cant) {
                this.metodos[index].cantidad = Number(cant).toFixed(2);
            } else {}
        },
        oldup() {
            console.log(e.target.value);
            let cantidad = 0;

            let precio_descuento = (this.precio / 100) * this.pdescuento;

            this.metodos.forEach((element) => {
                cantidad = Number(cantidad) + Number(element.cantidad);
            });
            console.log(cantidad, "-------cantidad inicial---");
            if (
                cantidad >
                Number(this.precio) -
                Number(precio_descuento) +
                Number(this.calcTotProducts()) +
                Number(this.calcPropinas()) -
                Number(this.cantidadgiftcard)
            ) {
                let cant = cantidad - (Number(this.precio) - Number(precio_descuento));

                console.log(cant, "-------cantidad---");

                //calcula la cantidad total de los demas metodos
                let cant2 = cantidad - e.target.value;
                console.log(cant2, "-------cantidad2---");
                cant2 = cant2 + this.cantidadgiftcard;
                //asigna el valor que falta para la cantidad total
                this.metodos[index].cantidad =
                    Number(this.precio) -
                    Number(precio_descuento) +
                    Number(this.calcTotProducts()) +
                    Number(this.calcPropinas()) -
                    cant2;
            }
        },
        //calcula citas seleccionadas

        CalcTotalCitasSeleccionadas() {
            let total = 0;
            Object.entries(this.seleccionados).forEach(([key, value]) => {
                total = Number(total) + Number(value.detalle_ventas[0].producto_servicios.precio)
                console.log(value.detalle_ventas[0].producto_servicios.precio)
            });

            this.precio = total;
            console.log(this.precio, '////precio');

        },

        SelectSucursalChange() {
            //aquiiiii
            this.seleccionados = {}
            this.getData();
            this.getCobros();
            this.getGastos_data();
            this.getVentasGiftcard();
            //console.log(,'----------------sel--change-------------')
        },

        selectVenta(row) {
            this.giftcard = "";
            this.saldogiftcard = "";
            this.cantidadgiftcard = 0;
            this.id_giftcard = 0;
            this.cantidadmanual = 0;

            this.pdescuento_cliente = 0;
            this.newItemCliente = "";
            this.cantidadmanual = 0;
            this.productos = [];
            this.propina = null;
            this.metodos = [{
                    text: 'EFECTIVO',
                    metodo: 1,
                    cantidad: 0
                },
                {
                    text: 'TDD',
                    metodo: 3,
                    cantidad: 0
                },
                {
                    text: 'TDC',
                    metodo: 2,
                    cantidad: 0
                }
            ];

            this.influencer_id = 0;
            this.ventadetalle = {};
            if (row == 'cobro_expontaneo') {
                this.venta = 0;
                this.cliente = 0;
                //this.precio = 0;
                this.influencer = 0;
                this.pdescuento = 0;
                this.viewCobrar = false;

            } else
            if (row == 'multiple') {
                console.log(row, '----row---')
                console.log(this.seleccionados, '----seleccionado---')

                row = this.seleccionados[Object.keys(this.seleccionados)[0]];
                console.log(row, '----row---')
                this.venta = row.id;
                this.cliente = row.cliente;
                this.ventadetalle = row;
                //this.precio = row.detalle_ventas[0].producto_servicios.precio;
                this.influencer = row.influencer;
                this.pdescuento = row.pdescuento;
                this.influencer = row.influencer;
                this.pdescuento = row.pdescuento;
                //calcula total servicios
                this.CalcTotalCitasSeleccionadas();
            } else if (row == 0) {
                this.venta = null;
                this.cliente = 0;
                //this.precio = 0;
                this.influencer = 0;
                this.pdescuento = 0;
            } else {
                console.log(row, 'detalle venta')
                this.seleccionados = {};
                this.seleccionados[row.id] = row;
                this.venta = row.id;
                this.cliente = row.cliente;
                this.ventadetalle = row;
                //this.precio = row.detalle_ventas[0].producto_servicios.precio;
                this.influencer = row.influencer;
                this.pdescuento = row.pdescuento;
                //calcula total servicios
                this.CalcTotalCitasSeleccionadas();
            }

            //$("#cobrosmodal").modal("show");
        },
        selectdate(val) {
            console.log(val, "------------val----");
            this.getData();
            this.getCobros();
            this.getGastos_data();
            this.getVentasGiftcard();
        },
        actualformatDate() {
            var d = new Date(),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        estab() {
            console.log("-----sel--------");

            /*
            
const $estado = document.querySelector("#estado"),
    $listaDeImpresoras = document.querySelector("#listaDeImpresoras");

    const indice = $listaDeImpresoras.selectedIndex;
    if (indice === -1) return loguear("No hay ninguna impresora seleccionada")
    const opcionSeleccionada = $listaDeImpresoras.options[indice];

const loguear = texto => $estado.textContent += (new Date()).toLocaleString() + " " + texto + "\n";
const limpiarLog = () => $estado.textContent = "";*/
            Impresora.setImpresora("POS-80");
            /*const establecerImpresoraComoPredeterminada = nombreImpresora => {
    loguear("Estableciendo impresora...");
    Impresora.setImpresora(nombreImpresora)
        .then(respuesta => {
            refrescarNombreDeImpresoraSeleccionada();
            if (respuesta) {
                loguear(`Impresora ${nombreImpresora} establecida correctamente`);
            } else {
                loguear(`No se pudo establecer la impresora con el nombre ${nombreImpresora}`);
            }
        });
};*/

            const refrescarNombreDeImpresoraSeleccionada = () => {
                Impresora.getImpresora().then((nombreImpresora) => {
                    $impresoraSeleccionada.textContent = nombreImpresora;
                });
            };
            ($texto = document.querySelector("#texto")),
            ($impresoraSeleccionada = document.querySelector(
                "#impresoraSeleccionada"
            )),
            establecerImpresoraComoPredeterminada(opcionSeleccionada.value);
        },
        async imprimirTicket() {


                //  const qz = new QZTray();

                // Conectar a la impresora (asegúrate de que QZ Tray esté en ejecución)

                // Conectar a la impresora POS-80 (asegúrate de que esté configurada previamente)
                const printerName = 'POS-80'; // Nombre de la impresora
              //  const foundPrinter = await qz.printers.find(printerName);

                qz.websocket.connect().then(() => {
                    return  qz.printers.find(printerName);
                }).then((printers) => {
                    console.log(printers);
                    let config = qz.configs.create('POS-80');
                    return qz.print(config, [{
                        type: 'pixel',
                        format: 'html',
                        flavor: 'plain',
                        data: '<h1>Hello JavaScript!</h1>'
                    }]);
                }).then(() => {
                    return qz.websocket.disconnect();
                }).then(() => {
                    // process.exit(0);
                }).catch((err) => {
                    console.error(err);
                    // process.exit(1);
                });

            
        },
        async imprmirr(data) {
            /*

 let dat = {
        venta: this.venta,
        // nota:this.nota,
        influencer: this.influencer,
        descuento: this.pdescuento,
        metodos: this.metodos,
        influencer_id:this.influencer_id,
        productos:this.productos,
        propina:(this.propina==null ||this.propina=='')?0:this.propina
      };
*/

            console.log("------imprimir------");
            const RUTA_API = "http://localhost:8000";

            let texto = "";
            this.metodos.forEach((element) => {
                texto +=
                    "" +
                    (element.metodo == 1 ?
                        "Efectivo       :" :
                        element.metodo == 2 ?
                        "Tarjeta Credito:" :
                        "Tarjeta Debito:") +
                    "   ------------------" +
                    element.cantidad +
                    " MXN\n";
            });
            let texto2 = "";
            this.productos.forEach((element) => {
                let data = this.productosData.find((row) => row.id == element.producto);

                texto2 +=
                    this.pad0(element.cantidad, 2) +
                    " -- " +
                    this.pad(data.nombre, 10) +
                    "   ------------------ " +
                    this.pad(this.formatPrice(element.precio * element.cantidad), 5) +
                    " MXN\n";
            });

            let impresora = new Impresora(RUTA_API);
            //impresora.setFontSize(1, 1);
            //impresora.setEmphasize(0);
            //impresora.setAlign("center");
            //impresora.write("HUELES A POPO JOSELINE\n");
            impresora.write("LUNA27\n");
            impresora.write("VENTA #" + data.venta + "\n");

            //impresora.write("Telefono: 123456789\n");
            //impresora.write("Fecha/Hora: 2019-08-01 13:21:22\n");
            impresora.write("--------------------------------\n");
            //impresora.write("Venta de plugin para impresora\n");
            impresora.setAlign("left");
            impresora.write("");
            impresora.write("Productos *****************************\n\n");
            impresora.setAlign("left");
            impresora.write(texto2);
            impresora.write("Metodos de pago *****************************\n\n");
            impresora.setAlign("left");
            impresora.write(texto);
            impresora.write("\n\n");
            impresora.write("Detalle         ******************************\n\n");

            impresora.write(
                "Costo Servicio:   ------------------ " +
                this.formatPrice(this.precio) +
                " MXN\n"
            );
            impresora.write(
                "Descuento Servicios:  -------------- " +
                data.descuento +
                "%\n" +
                "Descuento Cantidad:   ---------------" +
                this.cantidadmanual +
                "%\n"
            );
            impresora.write(
                "Total servicio:   ------------------ " +
                this.formatPrice(
                    Number(
                        Number(this.precio) -
                        ((Number(this.precio) / 100) * Number(this.pdescuento) +
                            this.cantidadmanual)
                    ).toFixed(2)
                ) +
                " MXN\n"
            );
            impresora.write(
                "Total Productos:  ------------------ " +
                this.formatPrice(Number(this.calcTotProducts()).toFixed(2)) +
                " MXN\n"
            );
            impresora.write(
                "Propina:          ------------------ " + this.calcPropinas() + " MXN\n\n\n\n"
            );

            impresora.write(
                "Total:            ------------------ " +
                this.formatPrice(
                    Number(
                        Number(this.precio) -
                        ((Number(this.precio) / 100) * Number(this.pdescuento) +
                            this.cantidadmanual) +
                        Number(this.calcTotProducts()) +
                        Number(this.calcPropinas())
                    ).toFixed(2)
                ) +
                " MXN\n\n\n"
            );

            impresora.write("\n");
            impresora.setAlign("center");
            impresora.write("***Gracias por su visita***");
            impresora.write("\n");

            //impresora.cut();
            impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial
            impresora.end().then((valor) => {
                console.log("------imprimir------", valor);
                //loguear("Al imprimir: " + valor);
            });
        },
        async CrearCobroMultiples() {
            //Impresora.setImpresora('POS-80')
            if (this.venta == 0 && (this.newItemCliente == null || this.newItemCliente == "")) {
                this.$root.$emit(
                    "alerta_show",
                    "No se selecciono cliente al realizar cobro"
                );
                this.$root.$emit("loading", false);
                return;
            }

            let dat = {
                venta: this.venta,
                sucursal: this.sucursalsel,
                // nota:this.nota,
                clientes_id: this.newItemCliente != null || this.newItemCliente != "" ?
                    this.newItemCliente.id : null,
                influencer: this.influencer,
                descuento: this.pdescuento,
                cantidaddescuento: this.cantidadmanual,
                ventas: this.seleccionados,
                metodos: this.metodos,
                influencer_id: this.influencer_id,
                productos: this.productos,
                propina: this.propina == null || this.propina == "" ? 0 : this.propina,
                cantidadgiftcard: Number(this.cantidadgiftcard),
                giftcard: this.id_giftcard,
            };

            console.log(dat, '----------cobro------------')

            //this.imprmirr(dat);
           // return;
            //return false;
            this.$root.$emit("loading", true);
            let data = await this.CREAR_COBROS_MULTIPLES(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);
                    this.getCobros();
                    this.getData();
                    /* this.cobrosdia.push(response);
                     this.ventasdia = this.ventasdia.filter(
                         (item) => item.id !== this.venta
                     );*/
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear cobro, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
                this.selectVenta(0);
                // $("#cobrosmodal").modal("hide");
                //loading.close();
            });
        },
        async CrearCobro() {
            //Impresora.setImpresora('POS-80')
            if (this.venta == 0 && (this.newItemCliente == null || this.newItemCliente == "")) {
                this.$root.$emit(
                    "alerta_show",
                    "No se selecciono cliente al realizar cobro"
                );
                this.$root.$emit("loading", false);
                return;
            }

            let dat = {
                venta: this.venta,
                sucursal: this.sucursalsel,
                // nota:this.nota,
                clientes_id: this.newItemCliente != null || this.newItemCliente != "" ?
                    this.newItemCliente.id : null,
                influencer: this.influencer,
                descuento: this.pdescuento,
                cantidaddescuento: this.cantidadmanual,
                ventas: this.seleccionados,
                metodos: this.metodos,
                influencer_id: this.influencer_id,
                productos: this.productos,
                propina: this.propina == null || this.propina == "" ? 0 : this.propina,
                cantidadgiftcard: Number(this.cantidadgiftcard),
                giftcard: this.id_giftcard,
            };

            console.log(dat, '----------cobro------------')

            //this.imprmirr(dat);
           // return;
            //return false;
            this.$root.$emit("loading", true);
            let data = await this.CREAR_COBROS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);
                    this.getCobros();
                    this.getData();
                    /* this.cobrosdia.push(response);
                     this.ventasdia = this.ventasdia.filter(
                         (item) => item.id !== this.venta
                     );*/
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear cobro, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
                this.selectVenta(0);
                // $("#cobrosmodal").modal("hide");
                //loading.close();
            });
        },

        async get_influencers() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let data = await this.GET_INFLUENCERS_ALL().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.influencers = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getCobros() {

            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.sucursalsel,
                inicio: this.datesel,
                fin: this.datesel,
            };
            let data = await this.GET_COBROS_RANGUE(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.cobrosdia = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;
                    if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }

                    this.getData();
                    this.getCobros();
                    this.getGastos();
                    this.getGastos_data();
                    this.get_influencers();
                    this.getVentasGiftcard();
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getGastos() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                dia: this.datesel,
                sucursal: this.sucursalsel,
            };
            let data = await this.GET_GASTOSDIA(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.gastosdata = response;
                    console.log(response, "/--------------gastos----------------------");
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let data_s = {
                id: this.sucursalsel,
                inicio: this.datesel,
                // inicio: this.datesel.start,
                fin: this.datesel,
            };
            let data = await this.GET_VENTAS_RANGUE(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.ventasdia = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
    mounted() {
        this.getSucursales();
        this.getProductos();

        /* const RUTA_API = "http://localhost:8000";
         const $estado = document.querySelector("#estado"),
             $listaDeImpresoras = document.querySelector("#listaDeImpresoras"),
             $btnLimpiarLog = document.querySelector("#btnLimpiarLog"),
             $btnRefrescarLista = document.querySelector("#btnRefrescarLista"),
             $btnEstablecerImpresora = document.querySelector(
                 "#btnEstablecerImpresora"
             ),
             $texto = document.querySelector("#texto"),
             $impresoraSeleccionada = document.querySelector("#impresoraSeleccionada");

         const establecerImpresoraComoPredeterminada = (nombreImpresora) => {
             loguear("Estableciendo impresora...");
             Impresora.setImpresora(nombreImpresora).then((respuesta) => {
                 refrescarNombreDeImpresoraSeleccionada();
                 if (respuesta) {
                     loguear(`Impresora ${nombreImpresora} establecida correctamente`);
                 } else {
                     loguear(
                         `No se pudo establecer la impresora con el nombre ${nombreImpresora}`
                     );
                 }
             });
         };

         const refrescarNombreDeImpresoraSeleccionada = () => {
             Impresora.getImpresora().then((nombreImpresora) => {
                 $impresoraSeleccionada.textContent = nombreImpresora;
             });
         };

         const loguear = (texto) =>
             ($estado.textContent += new Date().toLocaleString() + " " + texto + "\n");
         const limpiarLog = () => ($estado.textContent = "");

         $btnLimpiarLog.addEventListener("click", limpiarLog);

         const limpiarLista = () => {
             for (let i = $listaDeImpresoras.options.length; i >= 0; i--) {
                 $listaDeImpresoras.remove(i);
             }
         };

         const obtenerListaDeImpresoras = () => {
             loguear("Cargando lista...");
             Impresora.getImpresoras().then((listaDeImpresoras) => {
                 refrescarNombreDeImpresoraSeleccionada();
                 loguear("Lista cargada");
                 limpiarLista();
                 listaDeImpresoras.forEach((nombreImpresora) => {
                     const option = document.createElement("option");
                     option.value = option.text = nombreImpresora;
                     $listaDeImpresoras.appendChild(option);
                 });
             });
         };

         // En el init, obtenemos la lista
         obtenerListaDeImpresoras();
         // Y también la impresora seleccionada
         refrescarNombreDeImpresoraSeleccionada();

         // this.mapainit();
         //alert('10')
         */
    },
})
export default class Home extends Vue {}
