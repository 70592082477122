import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
  data() {
    return {};
  },

  created() {},

  methods: {
    GET_GIFTCARDS_RECICLADAS: async function (data) {
      var result = await axios({
        method: "GET",
        url:
          this.$store.state.baseapi +
          "giftcards/recicladas/",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    VENTAGIFTCARD_ONLINE: async function (data) {
      var result = await axios({
        method: "POST",
        url: this.$store.state.baseapi + "giftcard",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: data.id,
          nombre: data.nombre,
          tipo: data.tipo,
          correo: data.correo,
          total: data.total,
          telefono: data.telefono,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    GET_GIFTCARDS_DETALLE_MES_SUCURSAL: async function (data) {
      var result = await axios({
        method: "GET",
        url:
          this.$store.state.baseapi +
          "giftcards/detalle/sucursal/" +
          data.id +
          "/mes/" +
          data.mes +
          "/ano/" +
          data.ano +
          "/",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    GET_GIFTCARDS_MES_SUCURSAL: async function (data) {
      var result = await axios({
        method: "GET",
        url:
          this.$store.state.baseapi +
          "giftcards/mes/" +
          data.mes +
          "/ano/" +
          data.ano +
          "/",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    GET_RECARGASGIFTCARDS: async function (data) {
      var result = await axios({
        method: "GET",
        url:
          this.$store.state.baseapi +
          "giftcards/recargas/sucursal/" +
          data.id +
          "/" +
          data.inicio +
          "/" +
          data.fin +
          "",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    EDICION_VENTAGIFTCARD: async function (data) {
      var result = await axios({
        method: "PUT",
        url: this.$store.state.baseapi + "giftcards/venta/"+data.id,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        data: {
          nombrecliente: data.nombrecliente,
          descuento: data.descuento,
          efectivo: data.efectivo,
          td: data.td,
          tc: data.tc,
          metodo: data.metodo,
          total: data.total,
          subtotal: data.subtotal,
          data: data.data,
          //sucursal_id: data.sucursal_id,
          clientes_id: data.clientes_id,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    VENTAGIFTCARD: async function (data) {
      var result = await axios({
        method: "POST",
        url: this.$store.state.baseapi + "giftcards/venta",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        data: {
          nombrecliente: data.nombrecliente,
          descuento: data.descuento,
          efectivo: data.efectivo,
          td: data.td,
          tc: data.tc,
          metodo: data.metodo,
          total: data.total,
          subtotal: data.subtotal,
          data: data.data,
          sucursal_id: data.sucursal_id,
          clientes_id: data.clientes_id,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    TRANSFERIR_SALDO_GIFTCARDS: async function (data) {
      var result = await axios({
        method: "POST",
        url: this.$store.state.baseapi + "giftcards/transferir",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
        data: {
          giftcard1: data.giftcard1,
          giftcard2: data.giftcard2,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },

    /*GET_GIFTCARDS: async function (data) {
      var result = await axios({
        method: "GET",
        url:
          this.$store.state.baseapi +
          "influencers/" +
          data.id +
          "/historialservicios",
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },*/

    RECICLAR_GIFTCARD: async function (data) {
      var result = await axios({
        method: "GET",
        url: this.$store.state.baseapi + "giftcards/reciclar/" + data.numero,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
    GET_GIFTCARDS: async function (data) {
      var result = await axios({
        method: "GET",
        url: this.$store.state.baseapi + "giftcards/" + data.numero,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },

  

   

    ELIMINAR_GIFTCARDS: async function (data) {
      var result = await axios({
        method: "delete",
        url: this.$store.state.baseapi + "giftcards/concepto/" + data.id,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.user.token,
        },
      }).then(
        (response) => {
          //context.commit('SET_USER_DEALS', { deals: response.data.data })
          return response.data;
        },
        (err) => {
          return null;
        }
      );
      return result;
    },
  },
};
