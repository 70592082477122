<template>
<div class="inline-flex w-full relative ">
    <label @click="openPicker" class="px-2 py-1 rounded border cursor-pointer w-full">

        {{ labelValue }}
    </label>
  
    <div v-if="pickerVisible" class="absolute bg-yellow-100 border rounded py-1 justify-between z-40 justify-center p-1" style="left:70px;top: -30px;width:250px">
        
        <div class="w-full flex flex-wrap justify-between mb-1">
            <select v-model="selectedHour" class="custom-select custom-select-sm w-1/3 h-8 text-base text-center bg-white border rounded px-2 focus:outline-none">
                <option v-for="hour in selectableHours" :key="hour">{{ hour }}</option>
            </select>
            
            
            
            <select v-model="selectedMinute" class="custom-select custom-select-sm  w-1/3 h-8 text-base bg-white text-center border rounded px-2 focus:outline-none">
                <option v-for="minute in minutes" :key="minute">{{ minute }}</option>
            </select>
            
            <select v-model="selectedPeriod" class="custom-select custom-select-sm  w-1/3 h-8 text-base bg-white text-center border rounded px-2 focus:outline-none" v-if="showPeriod">
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select>
        </div>
        <div class="w-full flex flex-wrap justify-end">
        
        <button @click="confirmSelection" class="px-3 py-1 bg-blue-500 w-1/2 text-white h-8 rounded text-sm">
            <i class="las la-check-square"></i> Confirmar
        </button>
        <button @click="pickerVisible=false" class="px-3 py-1 bg-red-500 w-1/2 text-white h-8 rounded text-sm">
            <i class="las la-times-circle"></i> Cancelar
        </button>
    </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        initialTime: String, // Initial time in HH:mm:ss format
    },
    data() {
        return {
            initial: true,
            minTime: '09:00:00',
            maxTime: '20:30:00',
            selectedHour: "00",
            selectedMinute: "00",
            selectedPeriod: "AM",
            minutes: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
            confirmed: false, // To track if selection has been confirmed
            pickerVisible: false, // To track if the picker is visible
            labelValue: "", // Value displayed in the label
        };
    },
    computed: {
        
        showPeriod() {
            return this.initialTime && this.initialTime.length > 5;
        },
        selectableHours() {
            const minHour = parseInt(this.minTime.split(":")[0]);
            const maxHour = parseInt(this.maxTime.split(":")[0]);
            const hours = [];

            for (let hour = 0; hour <= 24; hour++) {
                if (this.selectedPeriod === "AM" && hour >= 9 && hour <= 11) {
                    hours.push(hour.toString().padStart(2, "0"));
                } else if (this.selectedPeriod === "PM" && ((hour >= 0 && hour <= 8))) {
                    hours.push(hour.toString().padStart(2, "0"));
                }
            }

            return hours;
        },
        formattedTime() {
            const formattedHour = String(this.selectedHour).padStart(2, "0");
            const formattedMinute = String(this.selectedMinute).padStart(2, "0");
            const formattedSecond = "00"; // Assuming seconds are not needed in this component
            const formattedPeriod = this.showPeriod ? `${this.selectedPeriod}` : "";

            return `${formattedHour}:${formattedMinute} ${formattedPeriod}`;
        }
    },
    methods: {
       
        
        getActualTime() {
            const selectedHour24 = this.selectedPeriod === "AM" ? parseInt(this.selectedHour) : parseInt(this.selectedHour) + 12;
            const formattedMinute = String(this.selectedMinute).padStart(2, "0");
            const formattedSecond = "00"; // Assuming seconds are not needed in this component
            return `${selectedHour24}:${formattedMinute}:${formattedSecond}`;
        },
        emitUpdatedTime() {
            const act = this.getActualTime();
            this.$emit("update-time", act);
        },
        confirmSelection() {
            if (!this.confirmed) {
                if (this.isTimeValid()) {
                    this.confirmed = true;
                    this.labelValue = this.formattedTime;
                    this.emitUpdatedTime();
                    this.pickerVisible = false;
                } else {
                    alert("La hora seleccionada no es válida dentro del rango permitido.");
                }
            }
        },
        isTimeValid() {
            const selectedHour = parseInt(this.selectedHour);
            const selectedMinute = parseInt(this.selectedMinute);
            const selectedHour24 = this.selectedPeriod === "AM" ? selectedHour : selectedHour + 12;
            const minHour24 = parseInt(this.minTime.split(":")[0]);
            const minMinute = parseInt(this.minTime.split(":")[1]);
            const maxHour24 = parseInt(this.maxTime.split(":")[0]);
            const maxMinute = parseInt(this.maxTime.split(":")[1]);

            if (selectedHour24 < minHour24 || (selectedHour24 === minHour24 && selectedMinute < minMinute)) {
                this.selectedHour = minHour24 < 12 ? minHour24.toString() : (minHour24 - 12).toString().padStart(2, "0");
                this.selectedMinute = minMinute.toString().padStart(2, "0");
                this.selectedPeriod = minHour24 < 12 ? "AM" : "PM";
                return false;
            }

            if (selectedHour24 > maxHour24 || (selectedHour24 === maxHour24 && selectedMinute > maxMinute)) {
                this.selectedHour = maxHour24 < 12 ? maxHour24.toString() : (maxHour24 - 12).toString().padStart(2, "0");
                this.selectedMinute = maxMinute.toString().padStart(2, "0");
                this.selectedPeriod = maxHour24 < 12 ? "AM" : "PM";
                return false;
            }

            return true;
        },

        confirmSelectionold() {
            if (!this.confirmed) {
                this.confirmed = true;
                this.emitUpdatedTime();
                this.pickerVisible = false;
            }
        },
        openPicker() {
            this.pickerVisible = true;
        }
        
    },
    watch: {
        initialTime(newTime) {
            const [hour, minute] = newTime.split(":");
            this.selectedHour = hour < 12 ? parseInt(hour) : (parseInt(hour) - 12).toString().padStart(2, "0");
            this.selectedMinute = minute;
            this.selectedPeriod = hour < 12 ? "AM" : "PM";
        },
        selectedHour(newd) {
            if (!this.initial && this.confirmed) {
                this.confirmed = false;
            }
        },
        selectedMinute(newd) {
            if (!this.initial && this.confirmed) {
                this.confirmed = false;
            }
        },
        selectedPeriod(newd) {
            if (!this.initial && this.confirmed) {
                this.confirmed = false;
            }
        }
    },
    mounted() {
        if (this.initialTime) {
            const [hour, minute] = this.initialTime.split(":");
            this.selectedHour = hour < 12 ? parseInt(hour) : (parseInt(hour) - 12).toString().padStart(2, "0");
            this.selectedMinute = minute;
            this.selectedPeriod = hour < 12 ? "AM" : "PM";
            this.initial = false;
            this.labelValue = this.formattedTime;
        }
    }
};
</script>

<style>
.selectNotArrow {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    padding-right: 1em;
    background-image: none;
  }
  
</style>
