
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_SERVICIOS_RANGUE: async function(data) {
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"ventas/sucursal/servicios/"+data.inicio+'/'+data.fin,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },  
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
      },
     GET_COBROS: async function(data) {
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"cobros/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },  
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },

    GET_COBROS_PENDIENTES: async function(data) {
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"cobros/"+data.id+"/empleado/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },  
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },

    GET_COBROS_RANGUE_HISTORIAL: async function(data) {
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"cobros/historial/sucursal/"+data.id+"/"+data.inicio+"/"+data.fin,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },  
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },
    GET_COBROS_RANGUE: async function(data) {
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"cobros/sucursal/"+data.id+"/"+data.inicio+"/"+data.fin,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },  
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },

    GET_COBROS_DIA: async function(data) {
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"cobros/sucursal/"+data.id+"/"+data.inicio+"/"+data.fin,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },  
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },






    CREAR_COBROS_MULTIPLES: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"cobros/multiples" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          sucursal:data.sucursal,
          venta:data.venta,
          ventas:data.ventas,
          metodos:data.metodos,
       productos:data.productos,
         propina:data.propina,
         influencer_id:data.influencer_id,
         influencer:data.influencer,
         cantidaddescuento:data.cantidaddescuento,
         descuento:data.descuento,
         cantidadgiftcard:data.cantidadgiftcard,
         giftcard:data.giftcard,
         clientes_id:data.clientes_id

              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },


    CREAR_COBROS: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"cobros/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          sucursal:data.sucursal,
          venta:data.venta,
          ventas:data.ventas,
          metodos:data.metodos,
       productos:data.productos,
         propina:data.propina,
         influencer_id:data.influencer_id,
         influencer:data.influencer,
         cantidaddescuento:data.cantidaddescuento,
         descuento:data.descuento,
         cantidadgiftcard:data.cantidadgiftcard,
         giftcard:data.giftcard,
         clientes_id:data.clientes_id

              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_COBRO: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"cobros/"+data.id+"/metodos" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            metodos:data.metodos,
         
            //nota:data.nota,
          
             
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_COBROS: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"cobros/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }