<template>
<div>

    <div @click.self="hidden()" class="min-h-screen w-full bg-gray-800 bg-opacity-40 flex items-center justify-center fixed top-0 left-0 z-30">
        <div class="bg-gray-100 w-full mx-4 p-4 rounded-xl md:w-1/2 lg:w-8/12">
            <!--MODAL HEADER-->
            <div class="flex justify-between items center border-b border-gray-200 py-3">
                <div class="flex items-center justify-center">
                    <p class="text-xl font-bold text-gray-800">Historial Giftcards</p>
                </div>

                <div>
                    <div @click="hidden()" class="bg-gray-300 hover:bg-gray-500 cursor-pointer hover:text-gray-300 font-sans text-gray-500 w-8 h-8 flex items-center justify-center rounded-full">
                        x
                    </div>
                </div>
            </div>

            <!--MODAL BODY-->
            <div class="mb-4">
                <div class="w-full bg-white h-full flex flex-wrap">
                    <div class="w-full h-full px-4 py-4 flex flex-wrap">
                        <div v-if="$store.state.user.usuario.role=='admin'">
                            <span v-if="Number(giftcarddata.cantidad)-Number(giftcarddata.gastado)<=0" @click="ReciclarGiftcard()" class="text-red-500 underline">Reciclar Giftcard</span>
                            <span v-else class="text-red-600 underline">La giftcard no puede ser reciclada, es necesario que es el saldo sea 0</span>
                           
                        </div>
                        <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20 h-12   text-center tetx-blue font-bold">
                            <div class=" h-18  w-full flex justify-center content-center ">

                                <input v-model="giftcard" class="border w-full  bg-white p-2" placeholder="Numero de giftcard" type="text">
                                <div  @click="searchGiftcard" class="cursor-pointer hover:bg-green-400  treansition w-fit border rounded-r bg-green-200 flex flex-wrap justify-center content-center text-green-800 h-18">
                                    <button  class="px-2 text-green-800 h-full" type="button" >Buscar</button>
                                </div>
                            </div>
                        </div>

                        <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-start text-sm text-blue-600 font-bold">Saldo en giftcard: <strong class="text-green-500">$ {{Number(giftcarddata.cantidad)-Number(giftcarddata.gastado)}} </strong></div>
                        <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-start text-sm text-red-600 font-bold">Saldo utilizado giftcard: <strong class="text-red-700">$ {{Number(giftcarddata.gastado)}} </strong></div>
                        
                        <div class="bg-white w-full">
                            <nav class="flex flex-wrap  w-full bg-white h-12 overflow-hidden">
                                <button type="button" @click="tab='recargas'" class="h-full py-2 px-6 block hover:text-blue-500 focus:outline-none " :class="tab=='recargas'?'text-blue-600 bg-green-200':'text-gray-600'">
                                   Recargas
                                </button>
                                <button type="button" @click="tab='cobros'" class="h-full py-2 px-6 block hover:text-blue-500 focus:outline-none " :class="tab=='cobros'?'text-blue-600 bg-green-200':'text-gray-600'">
                                   Cobros
                                </button>
                            </nav>
                        </div>
                        <section class="antialiased bg-gray-100   w-full">
                            <div class="flex h-full overflow-y-scroll h-64 w-full bg-gray-100">
                                <table v-if="tab=='recargas'"  class="table w-full uppercase text-xs bg-gray-100">
                                    <thead>
                                        <tr>
                                            <td class="sticky top-0 bg-gray-200">
                                                Cantidad
                                            </td>
                                           
                                            <td class="sticky top-0 bg-gray-200">
                                                Clientes
                                            </td>

                                            
                                           
                                            <td class="sticky top-0 bg-gray-200">
                                                Empleado
                                            </td>
                                            <td class="sticky top-0 bg-gray-200">
                                                Efectivo
                                            </td>
                                            <td class="sticky top-0 bg-gray-200">
                                                Tarjeta Credito
                                            </td>
                                            <td class="sticky top-0 bg-gray-200">
                                                Tarjeta Debito
                                            </td>
                                            <td class="sticky top-0 bg-gray-200"> 
                                                Fecha
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row,index) in giftcarddata.historial" v-bind:key="index">
        
                                            <td>
                                                {{row.cantidad}}
                                            </td>
                                            
                                            <td>
                                               <span v-if="row.clientes!=null">{{row.clientes.nombre}}</span> 
                                            </td>
                                            <td>
                                                {{row.nota_transferencia==''?row.nombre:(row.cantidad>0?'se transfirio de '+row.nota_transferencia:'se transfirio a '+row.nota_transferencia)}}
                                            </td>
                                            
                                            <td>
                                               <span v-if="row.ventagiftcards!=null">{{row.ventagiftcards.efectivo}}</span> 
                                            </td>
                                            <td>
                                                <span v-if="row.ventagiftcards!=null">{{row.ventagiftcards.tc}}</span> 
                                            </td>
                                            <td>
                                                <span v-if="row.ventagiftcards!=null">{{row.ventagiftcards.td}}</span> 
                                            </td>
                                            <td>
                                                {{new Date(row.created_at).toLocaleDateString()}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-if="tab=='cobros'" class="table w-full uppercase text-xs">
                                    <thead>
                                        <tr>
                                            <td class="sticky top-0 bg-gray-200">
                                                Cantidad
                                            </td>
                                           
                                            <td class="sticky top-0 bg-gray-200">
                                                Clientes
                                            </td>
                                            <td class="sticky top-0 bg-gray-200">
                                                Notas
                                            </td>
                                           
                                            <td class="sticky top-0 bg-gray-200">
                                                Empleado
                                            </td>
                                            <td class="sticky top-0 bg-gray-200"> 
                                                Fecha
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row,index) in giftcarddata.cobros" v-bind:key="index">
        
                                            <td>
                                                {{row.cantidad_giftcards}}
                                            </td>
                                            
                                            <td>
                                                {{row.nombre}}
                                            </td>
                                            <td>
                                                {{row.empleado}}
                                            </td>
                                            
                                            <td>
                                                - - - - -
                                            </td>
                                            <td>
                                                {{new Date(row.created_at).toLocaleDateString()}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>




                               
                            </div>
                        </section>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

  
  
<script>
//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import generales from "@/mixins/functions/generales"
import giftcard from "@/mixins/axios/giftcards";
export default {
    props: {

    },
    mixins: [clientes, palabras, generales,giftcard],
    data() {
        return {
            modalShow: false,
            selectedItem: null,
            tab:'recargas',
             //----datos giftcard
             saldogiftcard: 0,
            cantidadgiftcard: 0,
            giftcard: "",
            id_giftcard: null,
            giftcarddata: {},
            //-------end
        };
    },
    methods: {
        hidden() {
            this.$emit('ocultar-modal');
        },
        async ReciclarGiftcard() {
            this.$root.$emit("loading", true);
            let data = await this.RECICLAR_GIFTCARD({
                numero: this.giftcard
            }).then(
                (response) => {
                    if (response != null) {
                        this.$root.$emit("success",'Giftcard reciclada correctamente');
                        this.searchGiftcard();
                    } else {
                        this.$root.$emit("alerta_show",response.message);
                       
                    }
                    this.$root.$emit("loading", false);

                    //loading.close();
                }
            );
        },


        async searchGiftcard() {
            this.$root.$emit("loading", true);
            let data = await this.GET_GIFTCARDS({
                numero: this.giftcard
            }).then(
                (response) => {
                    if (response != null) {
                        //this.plantas = response;
                        this.saldogiftcard =
                            Number(response.cantidad) - Number(response.gastado);
                        this.cantidadgiftcard =
                            Number(response.cantidad) - Number(response.gastado);
                        this.id_giftcard = response.id;
                        this.giftcarddata = response;
                    } else {
                        this.$root.$emit("alerta_show", "el numero de tarjeta no existe");
                        this.saldogiftcard = "";
                        this.id_giftcard = 0;
                        this.giftcard = "";
                        this.cantidadgiftcard = 0;
                        this.giftcarddata = {};
                    }
                    this.$root.$emit("loading", false);

                    //loading.close();
                }
            );
        },
    },
};
</script>
  
  
<style>
/* Asegúrate de que Font Awesome esté importado */
</style>
