
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'

//@ts-ignore
import tablero from '@/mixins/axios/tablero'
//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";

@Component({
    components: {

        TablePPoa
    },
    mixins: [tablero],
    mounted() {

    },
    data() {
        return {

        }
    },
    methods: {

    },

})
export default class TableroGeneral extends Vue {}
