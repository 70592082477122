
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import sucursales from '@/mixins/axios/sucursales'
//@ts-ignore
import ventas from '@/mixins/axios/ventas'
//@ts-ignore
import cobros from '@/mixins/axios/cobros'
//@ts-ignore
import cortes_dia from '@/mixins/axios/cortes_dia'
//@ts-ignore
import { email, required } from 'vuelidate/lib/validators'
//@ts-ignore
import Notiflix from 'notiflix'

import VueAxios from 'vue-axios'
import axios from 'axios'

//@ts-ignore
import palabras from '@/mixins/palabras'
@Component({
  components: {
    TablePPoa,
  },
  data() {
    return {
      datesel: {
        start: this.getfisrtDay(),
        end: new Date(),
      },
      rr: this.actualformatDate(),
      sucursalsel: this.$store.state.user.usuario.sucursales_id,
      venta: 0,
      sucursalesdata: [],

      id: 0,

      ventasdia: [] as any,
      cobrosdia: [] as any,
      nota: '',
      metodo: 0,
      metodos: [],
      influencer: 1,
      cliente: 1,
      precio: 1,
      actual: 0,
    }
  },
  mixins: [sucursales, ventas, cobros, cortes_dia, palabras],
  validations: {},
  methods: {
    setMasOneDay(date_d) {
      var myPastDate = new Date(date_d)
      myPastDate.setDate(myPastDate.getDate() + 1)
      return myPastDate
    },
    formatFullDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },
    calcTotales(data) {
      const reducer = (accumulator, currentValue) =>
        accumulator + Number(currentValue.cantidad)

      // 1 + 2 + 3 + 4
      return data.reduce(reducer, 0)
    },
    formatTime(date) {
      var time = new Date(date)

      return time.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    },

    randomColor() {
      let colors = [
        'red',
        'yellow',
        'green',
        'blue',
        'indigo',
        'purple',
        'pink',
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    },

    getfisrtDay() {
      var myCurrentDate = new Date()
      var myPastDate = new Date(myCurrentDate)
      myPastDate.setDate(myPastDate.getDate() - 7)
      return myPastDate
    },

    openGenerar() {
      $('#modal-generar').modal('show')
    },

    SelectSucursalChange() {
      //aquiiiii

      this.getData()
      this.getCobros()
      //console.log(,'----------------sel--change-------------')
    },
    formatPrice(value) {
      value = Number(value).toFixed(2)
      value = value.toString().replace('.00', '')
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },

    selectdate(val) {
      console.log(val, '------------val----')
      //this.getData()
      this.getServicios()
    },
    actualformatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },

    async getServicios() {
      this.$root.$emit('loading', true)
      console.log('loaddding')
      let data_s = {
        id: this.sucursalsel,
        inicio: this.formatFullDate(this.datesel.start),
        fin: this.formatFullDate(this.setMasOneDay(this.datesel.end)),
      }
      let data = await this.GET_SERVICIOS_RANGUE(data_s).then((response) => {
        if (response != null) {
          //this.plantas = response;
          let actual = 0
          let data = {}
          console.log(response, '---------------------servicios---------------')
          this.cobrosdia = response
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        this.$root.$emit('loading', false)

        //loading.close();
      })
    },
    async getSucursales() {
      this.$root.$emit('loading', true)
      console.log('loaddding')
      let data = await this.GET_SUCURSALES().then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.sucursalesdata = response

          if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }

          this.getServicios()
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        this.$root.$emit('loading', false)

        //loading.close();
      })
    },
  },
  mounted() {
    this.getSucursales()

    // this.mapainit();
    //alert('10')
  },
})
export default class Home extends Vue {}
