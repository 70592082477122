
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
  
     GET_CONFIGURACIONES: async function(context) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"configuraciones/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },








    CREAR_CONFIGURACIONES: async function( data) {



      console.log(data)
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"configuraciones/" ,
        
          headers: {
        
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:data.data
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_CONFIGURACIONES: async function(data) {




      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"configuraciones/"+data.id ,
         
          headers: {
        
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:data.data,
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_CONFIGURACIONES: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"configuraciones/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }