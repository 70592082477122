
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
     
     GET_EMPLEADOVENTAS_DIA: async function(data) {


    

      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"empleados/"+data.sucursal+"/dia/"+data.dia,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
           
            return {error:'Error: timeout of exceeded'};
          }
        );
        return result;





    },






  }
  }