
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";

//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import sucursales from '@/mixins/axios/sucursales'
//@ts-ignore
import cobros from '@/mixins/axios/cobros'
//@ts-ignore
import cortes from '@/mixins/axios/cortes'

@Component({
    components: {
        /*
        Vuetable,
        VuetablePagination,*/
        TablePPoa,
    },
    data() {
        return {
            data_detalle: null,
            data_productos:null,
            cobrosdiacomision: 0,
            pendientespago: [],
            cobrospendientes: [],
            comisiontotal: 0,
            totalpropinas:0,
            totalcortesias: 0,
            columnasd: [{
                    tag: "id",
                    data: "id",
                    active: false,
                },

                {
                    tag: "nombre",
                    data: "name",
                    active: false,
                },
                /* {
                   tag: "sucursales",
                   data: "municipio",
                   active: false,
                 },
                  {
                   tag: "role",
                   data: "role",
                   active: true,
                 },*/
                {
                    tag: "email",
                    data: "email",
                    active: false,
                },

                /*{
          tag: "idtema",
          data: "idtema",
          active: false,
        },
        
       
        {
          tag: "modifico",
          data: "modifico",
          active: false,
        },*/
                {
                    tag: "generarpago",
                    data: "generarpago",
                    active: false,
                },
                {
                    tag: "historialpagos",
                    data: "historialpagos",
                    active: false,
                },

            ],
            roles: [{
                    id: 'admin'
                },

                {
                    id: 'sucursal'
                },
                {
                    id: 'caja'
                },
                {
                    id: 'empleado'
                },

            ],
            sucursal: 0,
            // 'name', 'email', 'idtema','password','role','active','password_plano','modifico','id_municipio'
            id: null as any,
            name: "" as any,
            email: "" as any,
            password_plano: "" as any,
            role: "" as any,
            idmunicipio: "" as any,

            usuariosdata: [] as any,
            sucursalesdata: [] as any,
            cortesEmpleado: [] as any,
            sucursalsel: 0,
            idempleada: 0,
        };
    },
    mixins: [usuarios, sucursales, cobros, cortes],
    validations: {
        name: {
            required,
        },
        email: {
            required,
        },

        password_plano: {
            required,
        },

        role: {
            required,
        },
        idmunicipio: {
            required,
        },
    },
    methods: {
        
         selCliente(row){
          
               this.$store.commit('SETEMPLEADO_SEL',row);
                this.$router.push('/su/empleado/historial')
      },
        formatDat(date) {
            var options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            let dat = new Date(date);
            return dat.toLocaleDateString("es-ES", options)
        },
        historialpagos(row) {
            this.getCortesEmpleado(row.id)
            this.detalle_ventas = null;
            $('#cortesEmpleado').modal('show')
        },

        async crearCorte() {
            this.$root.$emit("loading", true);
            let dat = {
                idempleada: this.idempleada
            }
            let data = await this.CREAR_CORTES(dat).then((response) => {
                if (!response.hasOwnProperty('message')) {
                    //this.plantas = response;
                    console.log(response);
                    $('#comisionesM').modal('hide')
                    this.$root.$emit(
                        "success",
                        "Corte generado correctamente"
                    );

                } else {
                    this.$root.$emit(
                        "alerta_show",
                        response.message
                    );
                }
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        SelectSucursalChange() {

            //aquiiiii

            this.getData()
            //console.log(,'----------------sel--change-------------')
        },
        formatPrice(value) {
            value = (value).toString().replace('.00', '')
            return (value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
        generarPago(row) {
            console.log("generar pago-------", row)
            this.getCobrosPendientes(row.id)
            this.idempleada = row.id;
            $('#comisionesM').modal('show')
            //this.$store.commit('SETUSUARIO',row);
            //this.$router.push("/su/usuarios/horarios")
        },
        select(row) {
            console.log("suauriooo-------", row)
            this.$store.commit('SETUSUARIO', row);
            this.$router.push("/su/usuarios/comisiones")
        },
        async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_USER(row).then((response) => {
                if (response != null) {
                    this.usuariosdata = this.usuariosdata.filter(
                        (item) => item.id !== row.id
                    );
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar paquete, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },
        confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Desea eliminar este elemento?",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },
        limpiar() {
            this.id = null;

            this.role = "";
            this.password_plano = "";
            this.name = "";
            this.email = "";
            this.idmunicipio = 0;
        },
        addRow() {
            this.limpiar();

            $("#ac_modal_usuarios").modal("show");
        },
        seteditar(row) {
            this.id = row.id;
            this.role = row.role;
            this.password_plano = row.password_plano;
            this.name = row.name;
            this.email = row.email;
            this.idmunicipio = row.sucursales_id;
            $("#ac_modal_usuarios").modal("show");
        },
        async crear(dat) {
            let data = await this.CREAR_USER(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.usuariosdata.push(response);
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear usuario, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        async editar(dat) {
            let data = await this.EDITAR_USER(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);
                    this.$root.$emit(
                        "alerta_show",
                        "Usuario modificado correctamente."
                    );
                    this.usuariosdata = this.usuariosdata.filter(
                        (item) => item.id !== dat.id);
                    this.usuariosdata.push(response);
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        response.message
                    );
                }
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        async GuardarData() {
            console.log("id:" + this.id);

            let dat = {
                id: this.id,
                name: this.name,
                role: this.role,
                idmunicipio: this.idmunicipio,
                password_plano: this.password_plano,
                email: this.email,
            };
            console.log(dat);
            this.$root.$emit("loading", true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }
        },

        async getCortesEmpleado(id) {

            this.$root.$emit('loading', true);
            console.log('loaddding')
            let dat = {
                id: id
            }
            let data = await this.GET_CORTES_EMPLEADO(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    this.cortesEmpleado = response;

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");

                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

        async getCobrosPendientes(id) {
            this.comisiontotal = 0;
this.totalpropinas=0;
            this.totalcortesias = 0;
            this.$root.$emit('loading', true);
            console.log('loaddding')
            let dat = {
                id: id
            }
            let data = await this.GET_COBROS_PENDIENTES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    this.cobrospendientes = response;
                    for (let index = 0; index < this.cobrospendientes.length; index++) {
                        const element = this.cobrospendientes[index];
                        this.comisiontotal += Number(element.comision);
                        this.totalpropinas+=Number(element.monto_propina);
                        if (element.infleuncer == 1) {
                            this.totalcortesias += Number(element.influencer)
                        }

                    }

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");

                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

        async getMunicipios() {
            this.$root.$emit('loading', true);
            console.log('loaddding')
            let data = await this.GET_SUCURSALES().then(response => {

                if (response != null) {
                    //this.plantas = response;
                    this.municipios = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");

                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let dat = {
                sucursal: this.sucursalsel
            }
            let data = await this.GET_EMPLEADOS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.usuariosdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        async getSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then(response => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;

                    this.sucursalsel = response[0].id;
                    this.getData();

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
    mounted() {

        this.getSucursales();

        //alert('10')
    },
})
export default class Home extends Vue {}
