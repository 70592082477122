
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {

            columnasd: [

                {
                    tag: 'fecha',
                    data: 'startDate',
                    active: true
                },
                {
                    tag: 'productService',
                    data: '',
                    active: true
                },
                {
                    tag: 'idempleada',
                    data: 'empleada',
                    active: true
                },

                {
                    tag: 'precio',
                    data: 'precio',
                    active: true
                },
                {
                    tag: 'descuentop',
                    data: 'pdescuento',
                    active: true
                },
                {
                    tag: 'propina',
                    data: 'propina',
                    active: true
                },
                {
                    tag: 'giftcard',
                    data: 'giftcards',
                    active: true
                },

                {
                    tag: 'productos',
                    data: 'detalle_cobros',
                    active: true
                },
                {
                    tag: 'metodos',
                    data: 'metodos',
                    active: true
                },
                {
                    tag: 'sucursal',
                    data: 'sucursales',
                    active: true
                },

                /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/

            ],

            id: null as any,

            telefono: '',
            direccion: '',
            influencer: 2 as any,
            descuento: 10 as any,
            nombre: '',
            notas: '',

            clientesdata: [] as any,

        }
    },
    mixins: [clientes],
    validations: {

        nombre: {
            required,
        },
        influencer: {
            required,
        },
        descuento: {
            required,
        },

        /* direccion: {
      required,
    },*/
        telefono: {
            required,
        },

    },
    methods: {

        async getData() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let dat = {
                id: this.$store.state.cliente_sel.id
            }
            let data = await this.GET_HISTORIAL_CLIENTES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    mounted() {
        //SETCLIENTE_SEL
        console.log(this.$store.state.cliente_sel, '----------sel-----------')

        this.getData();
        //alert('10')
    }
})
export default class Home extends Vue {}
