
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import PmdHeader from "@/components/headersSelect/pmd";
//@ts-ignore
import ListaPagosCobros from "@/components/pages/giftcards/ListaPagosCobros.vue";
//@ts-ignore
import ListaDetalleSucursal from "@/components/pages/giftcards/ListaDetalleSucursal.vue";

//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import ventagiftcard from "@/mixins/axios/ventagiftcards";
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";

//@ts-ignore
import generales from "@/mixins/functions/generales"
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import tableroSucursal from "@/mixins/axios/tableroSucursal";
//@ts-ignore
import pagos_giftcards from "@/mixins/axios/pagos_giftcards";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'
//    protected $fillable = ['id','clientes_id', 'numero', 'activo','sucursales_id','id_empleada','cantidad','precio','metodo','created_at', 'updated_at'];
import {
    MonthPickerInput
} from 'vue-month-picker'
import ItemTemplate from "../templateselect.vue";

@Component({
    components: {
        TablePPoa,
        MonthPickerInput,
        ListaPagosCobros,
        ListaDetalleSucursal
    },
    data() {
        return {
            //----data cliente--------

            template: ItemTemplate,
            sucursalsel: this.$store.state.user.usuario.sucursales_id,
            venta: 0,
            sucursalesdata: [],
            //-----end data cliente
            listDetalle: [],
            datesel: {
                start: this.formatFullDate(this.getfisrtDay()),
                end: this.formatFullDate(new Date()),
            },
            rr: this.actualformatDate(),

            mes: {

                "month": this.getmes(),
                monthIndex: new Date().getMonth() + 1,
                year: 2022,
                selectedYear: 2022,
            },

        };
    },
    mixins: [generales, giftcards, ventagiftcard, sucursales, clientes, pagos_giftcards, tableroSucursal],
    validations: {
        nombre: {
            required,
        },

        /* direccion: {
          required,
        },*/
        telefono: {
            required,
        },
    },
    methods: {
        SelectSucursalChange() {
            //aquiiiii

            //this.getData();
            this.getDataTablero();
            //console.log(,'----------------sel--change-------------')
        },
        selectdate(val) {
            console.log(val, "------------val----");
            //this.getData()
            this.getDataTablero();
        },

        async getSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;
                    if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }

                    this.$root.$emit("loading", false);
                    this.getDataTablero()
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        async getDataTablero() {
            this.$root.$emit("loading", true);
            console.log(this.datesel, '----')
            let data_s = {
                sucursal: this.sucursalsel,

                inicio: this.datesel.start,
                fin: this.datesel.end,

            };
            let data = await this.GET_TABLERO_SUCURSAL(data_s).then((response) => {
                if (response != null) {

                    //this.$root.$emit("alerta_show", "Se inserto");
                    //this.getData();
                    console.log(response)
                    this.listDetalle = response;

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        recargar($ev) {
            console.log($ev)
            this.mes = $ev;
            this.getDataTablero();
        },
        getmes() {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            const d = new Date();
            return monthNames[d.getMonth()];
        },

        async limpiar() {
            this.mes = {

                "month": this.getmes(),
                monthIndex: new Date().getMonth() + 1,
                year: 2022,
                selectedYear: 2022,
            }

        },

    },
    mounted() {
        this.getSucursales();

    },
})
export default class Home extends Vue {}
