
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      
      GET_HISTORIAL_INFLUENCERS: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"influencers/"+data.id+"/historialservicios",
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_INFLUENCERS_ALL: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"influencers/" ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_INFLUENCERS: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"influencers/"+data.inicio+"/"+data.fin ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },








    CREAR_INFLUENCERS: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"influencers/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          nombre:data.nombre,
          descuento:data.descuento,
         
          //direccion:data.direccion,
         // sucursales_id:data.sucursales_id,
      
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_INFLUENCERS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"influencers/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'nombre':data.nombre,
            'telefono':data.telefono,
         
            //'direccion':data.direccion,
          //  sucursales_id:data.sucursales_id,
        
             
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_INFLUENCERS: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"influencers/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }