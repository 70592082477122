<template>
<div id="app">

    <v-app>

        <loading :active.sync="isLoading" :can-cancel="false" color="#80A6FF" background-color="#ffffff" :height="106" :width="106" :is-full-page="fullPage"></loading>
        <!--  <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->

        <div style="z-index:9999900" v-if="messages!=null" @click.self="messages=null"  :class="type=='success'?'bg-green-600':'bg-red-600'" class=" bg-opacity-50 px-72  h-screen w-full flex px-40 content-center justify-center  fixed  top-0 right-0 bottom-0 left-0">
            <div class=" bg-white w-96 h-64  self-center  flex-wrap px-5 py-8 rounded-md text-start  content-center justify-center items-center">
           
        <div class="w-full flex flex-wrap justify-center content-center h-1/6">
            <span  :class="type=='success'?'border-green-50 bg-green-100 text-green-500':'border-red-50 bg-red-100 text-red-500'" class="mb-4 inline-flex justify-center items-center content-center w-[62px] h-[62px] rounded-full border-4 ">
                <i v-if="type=='success'"  class="las la-check-square"></i>
                <i v-else class="las la-exclamation-triangle"></i>
                </span>
        </div>
       
                <div class="text-xs mb-4 text-start font-bold h-full h-4/6 p-4" :class="type=='success'?'text-green-500':'text-red-500'" v-html="messages">
                </div>
                <div class="flex-wrap flex justify-between  w-full h-1/6">

                    <div class="w-full px-2 itemns-end flex flex-wrap justify-end">
                        <button class="w-3/12  font-extrabold  px-4 py-2 rounded-md text-md text-white border-2 border-blue-500" :class="type=='success'?'bg-green-600':'bg-red-600'" @click="messages=null">Cerrar</button>
                    </div>

                </div>
            </div>
        </div>

        <router-view />
    </v-app>

</div>
</template>

<style lang="stylus">

</style>
   
<script src="https://js.pusher.com/7.1/pusher.min.js"></script>
<script>
//@ts-nocheck
import Vue from 'vue'
import update from './mixins/update'
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
//@ts-ignore
//import * as Pusher from "pusher"
import Pusher from 'pusher-js'
import Notiflix from "notiflix";
// Pusher.logToConsole = true;

//@ts-ignore
import general from '@/mixins/axios/general'

export default {
    name: 'App',
    components: {
        Loading
    },
    data: function () {
        return {
            type:'success',
            fullscreen: false,
            isLoading: false,
            fullPage: true,
            messages: null,
            elemento: null,
            datas: false,
            isLoading: false,
            fullPage: true,
            active_alerta: false,
            msg_alerta: "",
            active_success: false,
            msg_success: "",
            info: {}
        }
    },
    mixins: [update, general],
    methods: {
        shalert(msg) {

            Notiflix.Notify.Failure('"' + msg + '." ', 'Aceptar');
        },
        show_alert(msg) {
            this.type='error';
            this.messages=msg;
            return;
            Notiflix.Report.Failure(this.$store.state.app_name,
                '"' + msg + '." ', 'Aceptar');

        },
        success(msg) {
            this.type='success';
            this.messages=msg;
            return;
            Notiflix.Report.Success(this.$store.state.app_name,
                '"' + msg + '." ', 'Aceptar');
        },
        splashs(status) {
            console.log(this.splash);
            this.splash = false;
            console.log(this.splash);
        },
        loadingf(status) {
            this.isLoading = status;
        },
        async getinfo() {
            //console.log(this.$router.currentRoute,'....jj....')
            if(['index','giftcardsonline','status_page'].includes(this.$router.currentRoute.name)){
                this.$store.commit('SETCLIENT_TAG', this.$route.params.origen);
            }
           
            this.$root.$emit('loading', true);
            let data = await this.GET_INFO().then(response => {

                if (response != null) {

                    this.info = {};
                    response.forEach(element => {
                        this.info[element.id] = element.valor;
                    });
                    //this.comp=this.comp+1;
                    console.log(this.info, '--------')
                    this.$store.commit('SETINFO', this.info);
                    this.$root.$emit('loading', false);
                }
            });
        },
    },
    created() {
      this.getinfo();

    },
    mounted() {
        var th = this;
        var pusher = new Pusher('b60ad32a1d95e1f43980', {
            cluster: 'us2'
        });
        console.log(pusher)
        var channel = pusher.subscribe('canales');
        channel.bind('alertaluna', function (data) {
            console.log(th)
            if (th.$store.state.info.nombre == 'Luna27') {
                th.messages = JSON.stringify(data);
            }

        });
        channel.bind('refresh', function (data) {
            console.log(th)
            th.getinfo();
        });

        //Notiflix.Loading.Standard('Loading...');

        this.$store.subscribe((mutation, state) => {
            console.log('state ----')
            console.log(state)
            localStorage.setItem('store', JSON.stringify(state));
        });
        this.$root.$on('loading', function (status) {
            //console.log('siii')
            th.loadingf(status);
        });

        this.$root.$on('shalert', function (msg) {
            th.shalert(msg);

        });
        this.$root.$on('alerta_show', function (msg) {
            th.show_alert(msg);

        });
        this.$root.$on('success', function (msg) {

            th.success(msg);

        });

        this.$root.$on('splash', function (status) {

            th.splashs(status)

        });
    },
    beforeCreate() {

        if (localStorage.getItem('store')) {
            let store = JSON.parse(localStorage.getItem('store'));
            this.$store.commit('initialiseStore', store);

        }

    },
    metaInfo() {
        return {
            title: this.info.hasOwnProperty('nombre')?this.info.nombre:'Sistema|Agenda',
            meta: [{
                    name: 'description',
                    content: 'Administra a tus empleados, horarios, comisiones y mucho mas'
                },
                {
                    propiedad: 'og: title',
                    content: this.info.hasOwnProperty('nombre')?this.info.nombre:'Sistema|Agenda'
                },
                {
                    propiedad: 'og: site_name ',
                    contenido: (this.elemento == null) ? this.info.nombre : 'popcing.mx'
                },
                {
                    propiedad: ' og: descripción ',
                    contenido: (this.elemento == null) ? 'Administra a tus empleados, horarios, comisiones y mucho mas' : this.elemento.parrafo
                },
                {
                    propiedad: ' og : tipo ',
                    contenido: ' Software '
                },
                {
                    propiedad: 'og: url',
                    contenido: 'https://agenda.depopcing.com'
                },
                {
                    propiedad: ' og: image ',
                    content: (this.elemento == null) ? 'https://res.cloudinary.com/dgzea9m7q/image/upload/v1646961372/popcing/2mil_ajbdkq_1.png' : this.elemento.icon
                }
            ]
        }
    },

}
</script>
<style
>
.hide_b{
  display:none!important
}
.fullCard{
    position:fixed;
    z-index:400000;
    left:0px;
    top:0px;
    height:100vh;
    width:100vw;
}

.modal-backdrop {
  z-index: 12!important;
}
.text-blue2{
  color:#99D2C1!important;
}

@media print {
  .hpp{
    display:none
  }
  tr{
    -webkit-print-color-adjust: exact;
  }
    td{
    -webkit-print-color-adjust: exact;
  }

tr:nth-child(even) td {
background-color: rgba(0,0,0,.1) !important;
-webkit-print-color-adjust: exact;
}
  /*
tr:nth-child(even) td {
background-color: red !important;
-webkit-print-color-adjust: exact;
}*/
}

</style>
<style>
.before {
    top: 50px;
    right: 20px;
    width: 300px;

    position: fixed !important;
}

.after {
    right: 50px;
}

.tooltipp {
    display: none;
    position: absolute;
    margin-left: 65px;
    margin-top: calc(20px - 12px)
}

.has-tooltip:hover .tooltipp {
    display: block !important;
    z-index: 900000 !important;
}
.vs__dropdown-menu{
    display:block!important;
    z-index: 3!important;
}
</style>
