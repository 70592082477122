
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_PRODUCTOS_SERVICIOS_ONLINE: async function() {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"productos_online/",
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
             
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_PRODUCTOS_SERVICIOS: async function() {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"producto_servicios/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },








    CREAR_PRODUCTOS_SERVICIOS: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"producto_servicios/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          precio:data.precio,
          nombre:data.nombre,
          comision:data.comision,
          online:data.online,
          imagen:data.imagen,
          color:data.color,
          tiempo:data.tiempo,
          categorias_id:data.categorias_id,
          

             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_PRODUCTOS_SERVICIOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"producto_servicios/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'precio':data.precio,
            'nombre':data.nombre,
            'comision':data.comision,
            'tiempo':data.tiempo,
            'color':data.color,
            'type':data.type,
            online:data.online,
            imagen:data.imagen,
  
               
             
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_PRODUCTOS_SERVICIOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"producto_servicios/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }