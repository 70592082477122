
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_SUCURSALES_USER: async function(context) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"sucursales/user" ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
  
     GET_SUCURSALES: async function(context) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"sucursales/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },








    CREAR_SUCURSALES: async function( data) {



      console.log(data)
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"sucursales/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:{

          //QUITAR COMILLAS SOLO EN DATA 

            //idtema:data.idtema,
           
             numero:data.numero,
             nombre:data.nombre,
            // descripcion:data.descripcion, 
            // latitud:data.latitud, 
            // longitud:data.longitud, 
             contacto:data.contacto, 
            
           // logotipo:data.logotipo,
           // fuente:data.fuente, 
          //  tamano_titulos:data.tamano_titulos,
            // tamano_textos:data.tamano_textos,
              tipo:data.tipo,
             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_SUCURSALES: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"sucursales/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:{
            //idtema:data.idtema,
           
             numero:data.numero,
             nombre:data.nombre,
            // descripcion:data.descripcion, 
            // latitud:data.latitud, 
            // longitud:data.longitud, 
             contacto:data.contacto, 
            
            tipo:data.tipo,
          
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_SUCURSALES: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"sucursales/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }