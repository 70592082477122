<template>
<div class="w-auto">
    <span @click="mostrarPicker" class=" text-base rounded cursor-pointer text-success underline ">
        <i class="las la-plus-circle"></i>
    </span>
    <div v-if="mostrarModal" class="absolute bg-yellow-100 border rounded py-1 justify-between z-40 justify-center p-1 h-36" style="top:180px;width:300px">
        <LInComponents v-if="loaders.crear"></LInComponents>
        <div v-else class="h-full  p-2 rounded shadow-md w-full">
         
            <div  class="w-full h-full">
                <div class="w-full flex flex-wrap justify-between content-start">
                    <div class="w-3/6 px-2 flex flex-wrap justify-between content-start">
                        <label class="block mb-2">Nombre:</label>
                        <input type="text" v-model="nombre" class="w-full border rounded py-1 px-2 mb-2">
    
                    </div>
                    <div class="w-3/6 px-2 flex flex-wrap justify-between content-start">
                        <label class="block mb-2">Teléfono:</label>
                        <input type="tel" v-model="telefono" class="w-full border rounded py-1 px-2 mb-2">
                    </div>
                
    
                </div>
                <div class="w-full flex flex-wrap justify-between content-start">
                    <div class="w-full px-2 flex flex-wrap justify-end content-end">
                        <button @click="GuardarData" class="bg-blue-500 text-white px-4 py-2 rounded mr-2">
                            Guardar
                        </button>
                       
    
                  
                        <button @click="cerrarPicker" class="bg-gray-300 text-gray-700 px-4 py-2 rounded">
                            Cancelar
                        </button>
                    </div>
                
    
                </div>
            </div>
          
        </div>

       
    </div>
</div>
</div>
</template>

  
  
<script>
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
export default {
    mixins: [clientes],
    data() {
        return {
            loaders:{
crear:false
            },
            mostrarModal: false,
            nombre: '',
            telefono: ''
        };
    },
    methods: {
        mostrarPicker() {
            this.mostrarModal = true;
        },
        cerrarPicker() {
            this.mostrarModal = false;
            this.nombre = '';
            this.telefono = '';
        },

        async GuardarData() {
            this.loaders.crear=true;
            let dat = {
                nombre: this.nombre,
                telefono: this.telefono,
                // direccion:this.direccion,
            };
            console.log(dat);
           // this.$root.$emit("loading", true);

            this.crearCliente(dat);
        },
        async crearCliente(dat) {
            let data = await this.CREAR_CLIENTES(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                   // this.clientesdata.push(response);
                    this.$root.$emit("success", "Cliente agregado excitosamente");
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear cliente, refresque la pagina"
                    );
                }
                this.loaders.crear=false;
                this.cerrarPicker();
                //this.$root.$emit("loading", false);
                //loading.close();
            });
        },
    }
};
</script>
  
  
<style>
/* Puedes dejar esta sección vacía, ya que los estilos están en línea con Tailwind CSS */
</style>
