
//@ts-nocheck
import {
    Component,
    Vue
} from "vue-property-decorator";
import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import Notiflix from "notiflix";
import Fuse from "fuse.js";
import clndar from "@/components/calndarEmpleados.vue";
import clndarVuetify from "@/components/calndarVuetify.vue";
import clndarVc from "@/components/calndarVc.vue";
import ModalEditarVenta from "@/components/calendario/ModalEditarVenta.vue";
import ModalAgregarVenta from "@/components/calendario/ModalAgregarVenta.vue";

import {
    CalendarView,
    CalendarViewHeader,
    CalendarMathMixin,
} from "vue-simple-calendar";

//@ts-ignore
import ventas from "@/mixins/axios/ventas";
//@ts-ignore
import HistorialClientes from '@/views/admin/clientesHistorial.vue'
//@ts-ignore
import usuarios from "@/mixins/axios/user";

//@ts-ignore
import categorias from "@/mixins/axios/categorias";

//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import empleado from "@/mixins/axios/empleado";
//@ts-ignore
import calendario from "@/mixins/axios/calendario";

//@ts-ignore
import producto_servicios from "@/mixins/axios/producto_servicios";
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
import giftcard from "@/mixins/axios/giftcards";
//@ts-ignore
import generales from "@/mixins/functions/generales"
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";
import ItemTemplate from "./templateselect.vue";
@Component({
    components: {
        ModalEditarVenta,
        ModalAgregarVenta,
        CalendarView,
        CalendarViewHeader,
        clndar,
        clndarVuetify,
        clndarVc,
        HistorialClientes
    },
    mixins: [
        CalendarMathMixin,
        usuarios,
        clientes,
        categorias,
        ventas,
        producto_servicios,
        calendario,
        sucursales,
        empleado,
        giftcard,
        generales
    ],

    data() {
        const min = new Date();
        min.setHours(9);
        min.setMinutes(0);
        const max = new Date();
        max.setHours(20);
        max.setMinutes(30);
        return {
            loaders: {
                calendario: false,
                sucursales: false,
                productos: false
            },

            clienteHistorial: false,
            expand: false,
            expand_multi: false,
            ConfirmMultiple: false,
            nota: "",
            openGiftcard: false,

            generateErr: false,
            showidcal: 0,
            template: ItemTemplate,
            citasdia: {},
            showcal: true,
            minTime: min,
            maxTime: max,
            start: "",
            end: "",
            defaultHour: new Date().getHours(),
            defaultMinute: new Date().getMinutes(),
            showchangue: false,
            objectVenta: null,
            timeline: null,
            keycalendar: 0,
            telefono: "",
            direccion: "",
            nombre: "",
            EmpleadosSucursal: [],
            selEmpleadoSucursal: null,
            sucursalchangesel: {},
            sucursalselchange: null,
            tipoview: "calendario_empleado",
            showp: true,
            dateselchangue: "",
            productos_sel: [],
            productosData: [],
            productosDataEmpleadosVc: [],
            empleadosSplit: [],
            productosDataEmpleados: [],
            productos: null,
            categoria_parent: null,
            usuariosdata: [],
            clientesdata: [],
            showcreate: true,
            showDate: this.thisMonth(1),
            message: "hola",
            startingDayOfWeek: 0,
            disablePast: false,
            disableFuture: false,
            displayPeriodUom: "week",
            displayPeriodCount: 1,
            displayWeekNumbers: true,
            showTimes: true,
            newItemProducto: "",
            selectionStart: null,
            selectionEnd: null,
            newItemServicio: "",
            id: null,
            fechastart: "",
            timestart: "",
            timeend: "",
            newItemUsuario: "",
            newItemCliente: "",
            newItemDate: "",
            newItemStartTime: new Date(),
            newItemEndTime: new Date(),
            useDefaultTheme: true,
            useHolidayTheme: true,
            useTodayIcons: false,
            sucursalsel: this.$store.state.user.usuario.sucursales_id,
            datesel: this.actualformatDate(),
            sucursalesdata: [],
            categoriasdata: [],
            ventas_dia: [],
            items: [

                {
                    id: "e9",
                    title: "con horas y minuto",
                    startDate: "2021-02-03 12:04:06",
                    endDate: "2021-02-03 14:04:06",
                },
                {
                    id: "e10",
                    title: "con horas y minuto",
                    startDate: "2021-02-03 15:04:06",
                    endDate: "2021-02-03 16:04:06",
                },

            ],
        };
    },
    validations: {
        nombre: {
            required,
        },

        /* direccion: {
              required,
            },*/
        telefono: {
            required,
        },
    },
    computed: {

        userLocale() {
            return this.getDefaultBrowserLocale;
        },
        dayNames() {
            return this.getFormattedWeekdayNames(this.userLocale, "long", 0);
        },
        themeClasses() {
            return {
                "theme-default": this.useDefaultTheme,
                "holiday-us-traditional": this.useHolidayTheme,
                "holiday-us-official": this.useHolidayTheme,
            };
        },
        myDateClasses() {
            // This was added to demonstrate the dateClasses prop. Note in particular that the
            // keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
            // for those keys are strings or string arrays. Keep in mind that your CSS to style these
            // may need to be fairly specific to make it override your theme's styles. See the
            // CSS at the bottom of this component to see how these are styled.
            const o = {};
            const theFirst = this.thisMonth(1);
            const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13;
            const idesDate = this.thisMonth(ides);
            o[this.isoYearMonthDay(idesDate)] = "ides";
            o[this.isoYearMonthDay(this.thisMonth(21))] = [
                "do-you-remember",
                "the-21st",
            ];
            return o;
        },
    },
    mounted() {
        this.newItemStartDate = this.isoYearMonthDay(this.today());
        this.newItemEndDate = this.isoYearMonthDay(this.today());

        this.getSucursales();

        this.getClientes("");
        this.getData_Categorias();
        this.getDataVentas();
        this.getProductos();
    },
    methods: {
        OcultarModalHistorial() {
            this.openGiftcard = false;
        },
        selCliente(row) {
            console.log(row);
            //return;
            this.clienteHistorial = row;
            this.$store.commit('SETCLIENTE_SEL', row);
            //this.$router.push('/su/cliente/historial')
        },
        FnconfirmMultiple() {
            this.newItemUsuario = null;
            this.ConfirmMultiple = false;
        },

        async returnVal(val) {
            this.$root.$emit("loading", true);
            let data_c = {
                id: val.event.id,
                idempleada: val.event.split,

                fecha_inicio: val.event.start,
                fecha_fin: val.event.end,
            };

            let data = await this.EDITAR_VENTASCALENDAR(data_c).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        this.getEmpleadosRet()

                        this.limpiar();
                    }
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        cambiarid(id) {
            console.log("-----click cambiar---");
            this.showidcal = id;
        },
        setColor(el) {
            //console.log(el)

            console.log(el, "//---------el--------");
            if (el.detalle_ventas.length > 0) {
                if (el.estatus == 2) {
                    return "#16cc64";
                }
                return el.detalle_ventas[0].producto_servicios.color;
            }

            return "#a0ca8e";
        },
        showEmp(index) {
            this.citasdia = this.productosDataEmpleados[index];
            $("#modalCitas").modal("show");
        },
        reordenar(index, id) {
            let el = this.productosDataEmpleados[index];
            this.productosDataEmpleados = this.productosDataEmpleados.filter(
                (item) => item.id !== id
            );
            this.productosDataEmpleados.unshift(el);
        },
        getLabel(item) {
            if (item != null) {
                return item.nombre + "-" + item.telefono;
            } else {
                return "";
            }
        },

        async onSearch(search) {
            if (search.length) {
                this.getClientes(search);
            } else {
                this.clientesdata = [];
            }
            // loading(false);
            //this.search(loading, search, this);
        },
        async onSearchant(search, loading) {
            if (search.length) {
                loading(true);
                await this.getClientes(search);
                loading(false);
                //this.search(loading, search, this);
            }
        },
        async search(loading, search, vm) {
            await this.getClientes(search);
            loading(false);
            /* fetch(
                     `https://api.github.com/search/repositories?q=${escape(search)}`
                   ).then(res => {
                     res.json().then(json => (vm.options = json.items));
                     loading(false);
                   });*/
        },
        searchtext($ev) {
            console.log($ev.target.value, "-------------");
            this.getClientes($ev.target.value);
        },

        timeChangeHandler(ddd) {
            console.log(ddd);
        },
        async EditarEstatus(item, status) {
            this.$root.$emit("loading", true);
            console.log("---------entre---------------");
            var today = new Date();
            var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            var time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

            let dat = {
                id: item.id,
                fecha: date + " " + time,
                //fecha:'2021-05-11 17:10:00',
                estatus: status,
                // direccion:this.direccion,
            };

            let data = await this.EDITAR_ESTATUS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        this.getEmpleadosRet()
                        //$('#cambiarSucursales').modal('hide')
                    }

                    this.limpiar();
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
            console.log("cambiar");
        },
        setBackDate() {
            var day = new Date(this.datesel);
            console.log(day); // Apr 30 2000

            let daynew = new Date(day);
            daynew.setDate(day.getDate());
            this.datesel = this.formatDate(daynew);
            this.getEmpleadosRet()
        },
        setNextDate() {
            var day = new Date(this.datesel);
            console.log(day); // Apr 30 2000

            let daynew = new Date(day);
            daynew.setDate(day.getDate() + 2);
            this.datesel = this.formatDate(daynew);
            this.getEmpleadosRet()
        },
        parseFecha(data) {
            let feches =
                this.datesel +
                " " +
                String(String(data).replace(" am", "")).replace(" pm", "");

            return feches;
        },

        showCreateClient() {
            $("#ac_modal_indicador_areas").modal("show");
        },
        async GuardarData() {
            let dat = {
                nombre: this.nombre,
                telefono: this.telefono,
                // direccion:this.direccion,
            };
            console.log(dat);
            this.$root.$emit("loading", true);

            this.crearCliente(dat);
        },
        async crearCliente(dat) {
            let data = await this.CREAR_CLIENTES(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.clientesdata.push(response);
                    this.$root.$emit("success", "Cliente agregado excitosamente");
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear cliente, refresque la pagina"
                    );
                }
                $("#ac_modal_indicador_area").modal("hide");
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        viewDay({
            date
        }) {
            this.focus = date;
            this.type = "day";
        },
        getEventColor(event) {
            return event.color;
        },
        setToday() {
            this.focus = "";
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        showEvent({
            nativeEvent,
            event
        }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                setTimeout(() => {
                    this.selectedOpen = true;
                }, 10);
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                setTimeout(open, 10);
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
        updateRange({
            start,
            end
        }) {
            const events = [];

            const min = new Date(`${start.date}T00:00:00`);
            const max = new Date(`${end.date}T23:59:59`);
            const days = (max.getTime() - min.getTime()) / 86400000;
            const eventCount = this.rnd(days, days + 20);

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0;
                const firstTimestamp = this.rnd(min.getTime(), max.getTime());
                const first = new Date(firstTimestamp - (firstTimestamp % 900000));
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
                const second = new Date(first.getTime() + secondTimestamp);

                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                });
            }

            this.events = events;
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a;
        },

        async getSucursales() {
            this.loaders.calendario = true;
            this.loaders.sucursales = true;
            console.log("loaddding");
            let data = await this.GET_SUCURSALES_USER().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;
                    if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }

                    this.getEmpleadosRet()
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.loaders.sucursales = false;

                //loading.close();
            });
        },
        getEmpleadosRet() {
            //this.$root.$emit("loading", true);
            console.log('espero')
            this.getEmpleadosDia()

        },

        async getEmpleadosDia() {
            this.loaders.calendario = true;

            console.log("------------getempleados in-------");

            console.log("loaddding");
            let dat = {
                sucursal: this.sucursalsel,
                dia: this.datesel,
            };
            let dataa = await this.GET_EMPLEADOVENTAS_DIA(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('error')) {
                    //this.plantas = response;
                    console.log(response, "empleados");
                    this.showcal = false;
                    this.productosDataEmpleados = response;
                    this.productosDataEmpleadosVc = [];
                    this.empleadosSplit = [];
                    let pos = 1;
                    this.productosDataEmpleados.forEach((empleado) => {
                        this.empleadosSplit.push({
                            label: empleado.empleado,
                            color: "blue",
                            class: "split" + pos,
                            id: empleado.id,
                        });

                        empleado.data.forEach((cita) => {
                            //elemento cita
                            let color = cita.detalle_ventas[0].producto_servicios.color;
                            let classe = color.split("#");

                            this.productosDataEmpleadosVc.push({
                                id: cita.id,
                                start: cita.fecha_inicio,
                                end: cita.fecha_fin,

                                title: cita.clientes.nombre,

                                class: cita.fechaFinalizacion == null ?
                                    "card" + classe[1] : "cardfinalizada",
                                data: cita,
                                drag: true,

                                resizable: true,

                                split: empleado.id,
                            });
                        });
                        ///----- asignaciones de sucursal -------

                        if (empleado.hasOwnProperty('asignaciones')) {
                            empleado.asignaciones.forEach((cita) => {
                                //elemento cita
                                //let color = cita.detalle_ventas[0].producto_servicios.color;
                                //let classe = color.split("#");
                                cita.asignacion = true;
                                this.productosDataEmpleadosVc.push({
                                    drag: false,
                                    asignacion: true,

                                    draggable: false,
                                    background: true,
                                    id: cita.id,
                                    start: cita.fecha_inicio,
                                    end: cita.fecha_fin,
                                    title: 'En ' + cita.sucursales.nombre,
                                    content: '',
                                    class: 'cardcambiosucursal',
                                    data: cita,

                                    resizable: false,

                                    split: empleado.id,
                                });
                            });
                        } else {

                        }

                        pos = pos + 1;
                    });
                    //-------asignaciones cambios sucursal-----------

                    this.showcal = true;
                    //this.$root.$emit("success", "Comisiones guardadas excitosamente");
                } else {
                    console.log('asas')
                    this.$root.$emit("alerta_show", response.error);

                }

                this.loaders.calendario = false;

                //loading.close();
            });
        },
        SelectInitTimeend(event) {
            if (String(event).includes("am")) {
                this.newItemEndTime = String(
                    String(String(event).replace("mm", "30")).replace("hh", "09")
                ).replace(" am", "");
            } else {
                let date = String(
                    String(String(event).replace("mm", "30")).replace("hh", "12")
                ).replace(" pm", "");
                date = date.replace("01:", "13:");
                date = date.replace("02:", "14:");
                date = date.replace("03:", "15:");
                date = date.replace("04:", "16:");
                date = date.replace("05:", "17:");
                date = date.replace("06:", "18:");
                date = date.replace("07:", "19:");
                this.newItemEndTime = date;
            }
        },

        ParseDateEnd(event) {
            let data = String(event).split(":");
            let dat = "";
            if (Number(data[0]) > 12 || Number(data[0]) == 0) {
                dat = data[0].replace("13", "01");
                dat = data[0].replace("14", "02");
                dat = data[0].replace("15", "03");
                dat = data[0].replace("16", "04");
                dat = data[0].replace("17", "05");
                dat = data[0].replace("18", "06");
                dat = data[0].replace("19", "07");
                dat = data[0].replace("20", "08");
                this.timeend = dat + ":" + data[1] + " pm";
            } else {
                this.timeend = dat + ":" + data[1] + " am";
            }
        },
        ParseDateStart(event) {
            let data = String(event).split(":");
            let dat = "";
            if (Number(data[0]) > 12 || Number(data[0]) == 0) {
                dat = data[0].replace("13", "01");
                dat = data[0].replace("14", "02");
                dat = data[0].replace("15", "03");
                dat = data[0].replace("16", "04");
                dat = data[0].replace("17", "05");
                dat = data[0].replace("18", "06");
                dat = data[0].replace("19", "07");
                dat = data[0].replace("20", "08");
                this.timestart = dat + ":" + data[1] + " pm";
            } else {
                this.timestart = dat + ":" + data[1] + " am";
            }
        },

        SelectInitTimeendChangue(event) {
            if (String(event).includes("am")) {
                this.timeend =
                    String(
                        String(String(event).replace("mm", "30")).replace("hh", "09")
                    ).replace(" am", "") + " am";
                console.log(
                    String(
                        String(String(event).replace("mm", "30")).replace("hh", "09")
                    ).replace(" am", ""),
                    "-----------sel-----------"
                );
            } else {
                let date = String(
                    String(String(event).replace("mm", "30")).replace("hh", "12")
                ).replace(" pm", "");
                date = date.replace("01:", "13:");
                date = date.replace("02:", "14:");
                date = date.replace("03:", "15:");
                date = date.replace("04:", "16:");
                date = date.replace("05:", "17:");
                date = date.replace("06:", "18:");
                date = date.replace("07:", "19:");
                this.timeend = date + " pm";
            }
        },

        addMinutes(date, minutes) {
            console.log("tiempo------", date.getTime());
            let data_set = new Date(date.getTime() + minutes * 60000);

            //this.newItemEndTime=data_set;
            return data_set;

            //	console.log(data_set.getHours())
            //console.log(data_set.getMinutes())
            //console.log(data_set.getSeconds())
            let horas =
                data_set.getHours() < 10 ?
                0 + "" + data_set.getHours() :
                data_set.getHours();
            let minutos =
                data_set.getMinutes() < 10 ?
                0 + "" + data_set.getMinutes() :
                data_set.getMinutes();
            console.log(horas + ":" + minutos);
            this.newItemEndTime =
                horas + ":" + minutos + " " + (horas > 12 ? "pm" : "am");

            //this.newItemEndTime=horas+':'+minutos;
            //this.newItemEndTime=horas+':'+minutos;
            return data_set;
        },
        SelectInitTimeOld(event) {
            console.log(event.target.value);
            console.log(this.newItemDate);
            let data = this.addMinutes(
                new Date(this.newItemDate + " " + event.target.value),
                90
            );
            console.log("------data----time--", data);
        },

        async SelectCalendario() {
            if (this.tipoView == "calendario_empleado") {

                this.getEmpleadosRet()
            } else {
                this.getDataVentas();
            }

            this.limpiar();
        },
        resizeMapEvent(event) {
            //this.limpiar();
            console.log(event.event.data, event.event.start, "-------rezicemapevent-------");
            let item = event.event.data;

            let fechastart = event.event.start.split(" ")[0];
            let timestart = event.event.start.split(" ")[1] //new Date(this.objectVenta.fecha_inicio);

            let timeend = event.event.end.split(" ")[1] //new Date(this.objectVenta.fecha_fin);

            let dat = {
                id: item.id,
                productos: [{
                    nombre: item.detalle_ventas[0].producto_servicios.nombre,
                    id: item.detalle_ventas[0].producto_servicios.id,
                    precio: item.detalle_ventas[0].precio,
                    cantidad: item.detalle_ventas[0].cantidad,
                }],
                sucursal: item.sucursales_id,
                idempleada: item.idempleada,
                nota: item.nota,
                fechainicio: fechastart + " " + timestart,
                fechafin: fechastart + " " + timeend

                // direccion:this.direccion,
            };
            this.CambiarSucursal(dat)

        },
        async CambiarSucursal(dat) {

            //this.$root.$emit("loading", true);

            if (dat.fechainicio > dat.fechafin) {
                this.$root.$emit(
                    "alerta_show",
                    "Error en fechas, La hora de inicio no puede ser mayor a la hora fin "
                );
                //this.$root.$emit("loading", false);
                return;
            }
            let data = await this.EDITAR_SUCURSAL(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        this.getEmpleadosRet();
                        //this.showchangue = false;
                        // 	$('#cambiarSucursales').modal('hide')
                    }

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
            console.log("cambiar");
        },

        ClickEmpleado(item) {
            console.log(item, '---click--empleado')
            this.newItemUsuario = this.productosDataEmpleados.filter(
                (e) => e.id == item.split
            )[0]

        },
        createEvent(item) {
            //this.limpiar();

            let now = item.date;

            //console.log(new Date(item.date),'------',now.getHours())

            const f = {
                year: now.getFullYear(),
                month: (now.getMonth() + 1) < 10 ?
                    0 + "" + (now.getMonth() + 1) : (now.getMonth() + 1), // Note: Months are zero-based, so we add 1 to get the correct month (1-12).
                day: now.getDate() < 10 ?
                    0 + "" + now.getDate() : now.getDate(),
                hours: now.getHours() < 10 ?
                    0 + "" + now.getHours() : now.getHours(),
                minutes: now.getMinutes() < 10 ?
                    0 + "" + now.getMinutes() : now.getMinutes(),
                seconds: now.getSeconds(),
            };
            let fech = [f.year, f.month, f.day].join("-") + " " + (f.hours + ':' + f.minutes);

            this.new = now
            this.newItemStartTime = now //fech.split(" ")[1]
            this.newItemDate = fech.split(" ")[0]
            console.log(this.newItemStartTime, '---fecha---')
            if (this.newItemProducto != '' && this.newItemProducto != null) {
                let data = this.addMinutes(now, this.newItemProducto.tiempo);
                this.newItemEndTime = data;
            } else {
                let data = this.addMinutes(now, 30);
                this.newItemEndTime = data;
            }
            this.newItemUsuario = this.productosDataEmpleados.filter(
                (e) => e.id == item.split
            )[0]
            /*

            this.newItemStartTime=  this.formatFullDate(item.date) //f.hours + ":" + f.minutes + " " + (f.hours > 12 ? "pm" : "am");//f.hours+':'+f.minutes+':'+f.seconds;

            */

            //console.log(fullDate);

        },
        AddVenta() {
            this.getEmpledosSucursal(this.sucursalsel);
            this.expand = true;
        },
        newVenta() {

            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();

            // Suma 30 minutos a la hora actual
            const endHours = hours + Math.floor((minutes + 30) / 60);
            const endMinutes = (minutes + 30) % 60;

            // Convierte las horas, minutos y segundos a formato de 24 horas (agregando ceros a la izquierda si es necesario)
            const formattedStartHours = hours < 10 ? `0${hours}` : hours;
            const formattedStartMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const formattedEndHours = endHours < 10 ? `0${endHours}` : endHours;
            const formattedEndMinutes = endMinutes < 10 ? `0${endMinutes}` : endMinutes;

            // Genera la hora de inicio en formato de 24 horas (HH:mm)
            const startTime24 = `${formattedStartHours}:${formattedStartMinutes}:00`;

            // Genera la hora de finalización en formato de 24 horas (HH:mm)
            const endTime24 = `${formattedEndHours}:${formattedEndMinutes}:00`;

            // Obtiene la zona horaria de tu sistema en minutos
            const timezoneOffsetMinutes = now.getTimezoneOffset();

            // Calcula la fecha y hora local teniendo en cuenta la zona horaria
            const localMillis = now.getTime() - timezoneOffsetMinutes * 60 * 1000;
            const localDate = new Date(localMillis);

            // Genera la fecha de hoy en formato "YYYY-MM-DD"
            const todayDate = localDate.toISOString().slice(0, 10);

            let item = {
                data: {
                    "id": 0,
                    "sucursales_id": this.sucursalsel,
                    "fecha_inicio": todayDate + ' ' + startTime24,
                    "fecha_fin": todayDate + ' ' + endTime24,

                }
            }
            this.changueSucursal(item)
        },
        changueSucursal(item) {
            this.objectVenta=null;
            this.limpiar();
            console.log(item, "-------data-------");
            item = item.data;
            this.getEmpledosSucursal(item.sucursales_id);
            this.objectVenta = item;
            /*this.sucursalchangesel = item;
            this.nota = item.nota;
            this.sucursalselchange = item.sucursales_id;
            this.sucursalselchange = item.sucursales_id;
            this.sucursalselchange = item.sucursales_id;
            this.fechastart = item.fecha_fin.split(" ")[0];
            this.timestart = new Date(item.fecha_inicio);
            this.timeend = new Date(item.fecha_fin);
            this.newItemProducto = this.productosData.filter(
                (itemm) => itemm.id == item.detalle_ventas[0].producto_servicios_id
            )[0];
            this.productos_sel = [];
            this.productos_sel.push({
                nombre: item.detalle_ventas[0].producto_servicios.nombre,
                id: item.detalle_ventas[0].producto_servicios.id,
                precio: item.detalle_ventas[0].precio,
                cantidad: item.detalle_ventas[0].cantidad,
            });

            this.getEmpledosSucursal(item.sucursales_id);

            this.selEmpleadoSucursal = item.idempleada;
           */
      
            this.showchangue = true;
        },
        RefreshCalendario(data) {
            this.getEmpleadosRet();
        },
        CloseModalAgregarVenta(status) {
            this.showchangue = false;
            this.expand = false;
        },
        CambioSucursalModal(sucursal) {

            this.newItemUsuario = null;
            this.selEmpleadoSucursal = null;
            $('.vs__selected').html('')
            this.sucursalsel = sucursal;
            this.getEmpledosSucursal(sucursal);
            this.getEmpleadosRet()
        },

        SelectSucursalChange() {
            //aquiiiii
            this.newItemUsuario = null;
            this.selEmpleadoSucursal = null;
            $('.vs__selected').html('')
            this.sucursalsel = this.sucursalselchange;
            this.getEmpledosSucursal(this.sucursalselchange);
            this.getEmpleadosRet()
            //console.log(,'----------------sel--change-------------')
        },
        SelectSucursalSemanal() {
            this.getDataVentas();
        },

        SelectSucursal(val) {
            console.log(val, "------------jj-------");
            //this.sucursalsel=val.id;
            console.log("------------getempleados-------");
            this.getEmpleadosRet()
            this.newItemUsuario = null;
            this.selEmpleadoSucursal = null;
            $('.vs__selected').html('')
            // console.log(event.target.value)
            //this.formula=this.productosData[event.target.value];
            //this.productos_sel=[{nombre:val.nombre,id:val.id,precio:val.precio,cantidad:0}]

            //this.indicador_administradordata=this.indicador_administradordata.filter(item => item.id !== row.id);
        },

        async getEmpledosSucursal(id) {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let dataa = await this.GET_EMPLEADOS_DIA({
                id: id,
                dia: this.datesel,
            }).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.EmpleadosSucursal = response;
                    //this.$root.$emit("success", "Comisiones guardadas excitosamente");
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getProductos() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let dataa = await this.GET_PRODUCTOS_SERVICIOS().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.productosData = response;
                    //this.$root.$emit("success", "Comisiones guardadas excitosamente");
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        sucusalMult(options, search) {
            const fuse = new Fuse(options, {
                keys: ["nombre", "id"],
                shouldSort: true,
            });
            return search.length ?
                fuse.search(search).map(({
                    item
                }) => item) :
                fuse.list;
        },

        editarTable(row) {
            this.editar({
                originalItem: row,
            });
            $("#ventasdia").modal("hide");
        },
        async editarVenta() {
            console.log("loaddding");
            if (this.productos_sel.length == 0) {
                this.$root.$emit(
                    "alerta_show",
                    "Es necesario agregar uno o mas servicios/productos a la venta"
                );
                return;
            }
            console.log(this.newItemStartTime, "----------------start time---------");
            this.$root.$emit("loading", true);
            let data_c = {
                id: this.id,
                sucursal: this.sucursalsel,
                productos: this.productos_sel,
                idempleada: this.newItemUsuario.id,
                clientes_id: this.newItemCliente.id,
                fecha_inicio: this.newItemDate +
                    " " +
                    this.formatFullDate(this.newItemStartTime).split(" ")[1],
                fecha_fin: this.newItemDate +
                    " " +
                    this.formatFullDate(this.newItemEndTime).split(" ")[1],
                /*
                                fecha_inicio: this.formatFullDate(this.newItemStartTime),
                                fecha_fin: this.formatFullDate(this.newItemEndTime),*/
            };
            if (data_c.fecha_inicio > data_c.fecha_fin) {
                this.$root.$emit(
                    "alerta_show",
                    "Error en fechas, La hora de inicio no puede ser mayor a la hora fin "
                );
                return;
            }

            console.log(this.newItemStartTime)

            let data = await this.EDITAR_VENTAS(data_c).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        if (this.tipoview == "calendario_empleado") {
                            this.datesel = this.newItemDate;
                            this.getEmpleadosRet()
                        } else {
                            this.items.push(response);
                        }
                        this.limpiar();
                    }

                    this.limpiar();
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async limpiar() {
            // this.productos_sel = [];
            //this.newItemProducto = null;
            this.newItemCliente = null;
            this.newItemUsuario = null;
            this.selEmpleadoSucursal = null;
            this.newItemDate = null;
            this.newItemStartTime = new Date();
            this.newItemEndTime = new Date();
            this.id = null;
            this.nota = "";
            console.log("-----limpiar-------");
            $("#clienteinput input").val("");
        },

        async editarCalendarioDiario(data) {
            console.log("//-----------datops select------");
            console.log(data, "//-----------datops select------");

            this.productos_sel = data.detalle_ventas;
            this.productos_sel = [];
            /*
data.originalItem.detalle_ventas.forEach(element => {
	console.log(element)
	console.log(this.productosData)
	console.log(this.productosData.filter(item => item.id == data.originalItem.detalle_ventas[0].producto_servicios_id)[0])
});*/
            console.log(
                this.productosData.filter(
                    (item) => item.id == data.detalle_ventas[0].producto_servicios_id
                )
            );
            this.newItemProducto = this.productosData.filter(
                (item) => item.id == data.detalle_ventas[0].producto_servicios_id
            )[0];
            this.productos_sel.push({
                nombre: data.detalle_ventas[0].producto_servicios.nombre,
                id: data.detalle_ventas[0].producto_servicios.id,
                precio: data.detalle_ventas[0].precio,
                cantidad: data.detalle_ventas[0].cantidad,
            });

            this.newItemCliente = this.clientesdata.filter(
                (item) => item.id == data.clientes_id
            )[0];

            this.newItemUsuario = this.productosDataEmpleados.filter(
                (item) => item.id == data.idempleada
            )[0];

            console.log(
                this.productosDataEmpleados,
                "/-------------------usuarios----------------"
            );
            console.log(
                this.newItemUsuario,
                "/-------------------usuarios----------------"
            );
            this.newItemDate = data.fecha_inicio.split(" ")[0];

            this.newItemStartTime = new Date(data.fecha_inicio);
            this.newItemEndTime = new Date(data.fecha_fin);

            this.id = data.id;
            // newItemUsuario:"",
            // newItemCliente: "",
            // newItemDate: "",
            // newItemStartTime: "",
            //  newItemEndTime: "",

            this.showcreate = true;
        },

        async editar(data) {
            console.log(data);
            this.productos_sel = data.originalItem.detalle_ventas;
            this.productos_sel = [];
            /*
data.originalItem.detalle_ventas.forEach(element => {
	console.log(element)
	console.log(this.productosData)
	console.log(this.productosData.filter(item => item.id == data.originalItem.detalle_ventas[0].producto_servicios_id)[0])
});*/
            console.log(
                this.productosData.filter(
                    (item) =>
                    item.id == data.originalItem.detalle_ventas[0].producto_servicios_id
                )
            );
            this.newItemProducto = this.productosData.filter(
                (item) =>
                item.id == data.originalItem.detalle_ventas[0].producto_servicios_id
            )[0];
            this.productos_sel.push({
                nombre: data.originalItem.detalle_ventas[0].producto_servicios.nombre,
                id: data.originalItem.detalle_ventas[0].id,
                precio: data.originalItem.detalle_ventas[0].precio,
                cantidad: data.originalItem.detalle_ventas[0].cantidad,
            });

            this.newItemCliente = this.clientesdata.filter(
                (item) => item.id == data.originalItem.clientes_id
            )[0];
            this.newItemUsuario = this.productosDataEmpleados.filter(
                (item) => item.id == data.originalItem.idempleada
            )[0];
            this.newItemDate = data.originalItem.startDate.split(" ")[0];
            this.newItemStartTime = data.originalItem.startDate.split(" ")[1];
            this.newItemEndTime = data.originalItem.endDate.split(" ")[1];
            this.id = data.originalItem.id;
            // newItemUsuario:"",
            // newItemCliente: "",
            // newItemDate: "",
            // newItemStartTime: "",
            //  newItemEndTime: "",

            this.showcreate = true;
        },
        async Eliminar(item) {
            this.$root.$emit("loading", true);
            let data_del = {
                id: item.id,
            };

            let data = await this.ELIMINAR_VENTAS(data_del).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    //if (this.tipoview == "calendario_empleado") {
                    this.getEmpleadosRet()
                    //} else {
                    //  this.items = this.items.filter((item) => item.id !== id);
                    // }
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar la venta, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        actualformatDate() {
            var d = new Date(),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        async getDataVentas() {
            var today = new Date();
            var date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            var time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

            this.timeline = time;
            //this.timeline='16:40';

            this.$root.$emit("loading", true);
            console.log("loaddding--------------------------");
            let data_del = {
                sucursal: this.sucursalsel,
            };

            let data = await this.GET_VENTAS(data_del).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.items = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        async crear_venta() {
            console.log("loaddding");
            if (this.productos_sel.length == 0) {
                this.$root.$emit(
                    "alerta_show",
                    "Es necesario agregar uno o mas servicios/productos a la venta"
                );
                return;
            }
            //await this.SelectInitTimeend(this.newItemEndTime);
            console.log(this.newItemStartTime, "----------------start time---------");
            this.$root.$emit("loading", true);
            let data_c = {
                sucursal: this.sucursalsel,
                productos: this.productos_sel,
                idempleada: this.newItemUsuario.id,
                clientes_id: this.newItemCliente.id,
                nota: this.nota,
                fecha_inicio: this.newItemDate +
                    " " +
                    this.formatFullDate(this.newItemStartTime).split(" ")[1],
                fecha_fin: this.newItemDate +
                    " " +
                    this.formatFullDate(this.newItemEndTime).split(" ")[1],
            };

            let data = await this.CREAR_VENTAS(data_c).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        if (this.tipoview == "calendario_empleado") {
                            this.datesel = this.newItemDate;
                            this.getEmpleadosRet()
                        } else {
                            this.items.push(response);
                        }
                    }
                    this.ConfirmMultiple = true;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        addProduct(row) {
            this.showp = false;
            const index = this.productos_sel.findIndex((el) => el["id"] == row.id);
            console.log(index);
            if (index >= 0) {
                this.productos_sel[index].cantidad =
                    this.productos_sel[index].cantidad + 1;
            } else {
                this.productos_sel.push({
                    nombre: row.nombre,
                    id: row.id,
                    precio: row.precio,
                    cantidad: 0,
                });
            }
            this.showp = true;
        },
        async getData_Categorias() {
            this.$root.$emit("loading", true);
            console.log("loaddding");

            let data = await this.GET_CATEGORIAS_PARENT().then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.categoriasdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        periodChanged() {
            // range, eventSource) {
            // Demo does nothing with this information, just including the method to demonstrate how
            // you can listen for changes to the displayed range and react to them (by loading items, etc.)
            //console.log(eventSource)
            //console.log(range)
        },
        thisMonth(d, h, m) {
            const t = new Date();
            console.log(
                "fecha-----",
                new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
            );
            return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
        },
        onClickDay(d) {
            this.selectionStart = null;
            this.selectionEnd = null;
            this.message = `You clicked: ${d.toLocaleDateString()}`;
            console.log(d);
            //	console.log((this.showDate).getMonth()+1)
            //	console.log((this.showDate).getFullYear())
            let mes = this.showDate.getMonth() + 1;
            let ano = this.showDate.getFullYear();
            //	const array1 = [5, 12, 8, 130, 44];

            let found = this.items.filter((element) => {
                if (
                    parseInt(element.dia) == parseInt(d.getDate()) &&
                    parseInt(element.mes) == parseInt(mes) &&
                    parseInt(element.ano) == parseInt(ano)
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            if (found == undefined) {} else {
                $("#ventasdia").modal("show");
                this.ventas_dia = found;
            }
            console.log(found);
        },
        onClickItem(e) {
            let context = this;
            Notiflix.Confirm.Show(
                "Confirme su acción",
                "Que desea hacer?",
                "Eliminar",
                "Editar",
                function () {
                    // Yes button callback alert('Thank you.');
                    context.eliminar(e.id);
                },
                function () {
                    context.editar(e);
                    // No button callback alert('If you say so...');
                }
            );
        },
        setShowDate(d) {
            this.message = `Changing calendar view to ${d.toLocaleDateString()}`;
            this.showDate = d;
        },
        setSelection(dateRange) {
            this.selectionEnd = dateRange[1];
            this.selectionStart = dateRange[0];
        },
        finishSelection(dateRange) {
            this.setSelection(dateRange);
            this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`;
        },
        onDrop(item, date) {
            this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`;
            // Determine the delta between the old start date and the date chosen,
            // and apply that delta to both the start and end date to move the item.
            const eLength = this.dayDiff(item.startDate, date);
            item.originalItem.startDate = this.addDays(item.startDate, eLength);
            item.originalItem.endDate = this.addDays(item.endDate, eLength);
        },
        clickTestAddItem() {
            this.items.push({
                startDate: this.newItemDate + " " + this.newItemStartTime,
                endDate: this.newItemDate + " " + this.newItemEndTime,
                title: this.newItemCliente,
                newItemUsuario: this.newItemUsuario,
                newItemCliente: this.newItemCliente,
                newItemServicio: this.newItemServicio,

                //	id: "e" + Math.random().toString(36).substr(2, 10),
            });
            //this.message = "You added a calendar item!"
        },
        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_USER().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.usuariosdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getClientes(text) {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                text: text,
            };
            let data = await this.GET_CLIENTES2(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
    created() {
        window.addEventListener("keydown", (e) => {
            console.log(e.key);
            switch (e.key) {
                case "F1":
                    this.showcreate = true;
                    break;
                case "F2":
                    this.showcreate = false;
                    break;
                case "F3":
                    break;

                default:
                    break;
            }
        });

        this.$emit("option:selected", () => {
            alert("ass");
        });
    },
})
export default class Home extends Vue {}
