
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';


//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import tablero from '@/mixins/axios/tablero'

//@ts-ignore
import generales from "@/mixins/functions/generales"
//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'


@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
  
    TablePPoa
  },
    mixins: [tablero,generales],
  mounted(){
   this.getTablero1()

//this.mapainit();
  },
   data() {
    return {
        series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
        }, /*{
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
          }*/],
          chartOptions: {
            chart: {
              height: 250,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
           tablero1:{} ,
           gannancias:0
       
    }
    },
  methods:{
      grafica6(data){


let empleados=[];
let labels=[];
data.forEach(element => {
    console.log(element)
    let datos=[];
    datos.push(element.enero)
    datos.push(element.febrero)
    datos.push(element.marzo)
    datos.push(element.abril)
    datos.push(element.mayo)
    datos.push(element.junio)
    datos.push(element.julio)
    datos.push(element.agosto)
    datos.push(element.septiembre)
    datos.push(element.octubre)
    datos.push(element.noviembre)
    datos.push(element.diciembre)
    let valor={
            name: element.name,
            data: datos
          }
          empleados.push(valor)
});




var options = {
          series: empleados
          /*{
            name: 'South',
            data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
              min: 10,
              max: 60
            })
          },
          {
            name: 'North',
            data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
              min: 10,
              max: 20
            })
          },
          {
            name: 'Central',
            data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
              min: 10,
              max: 15
            })
          }*/
        ,
          chart: {
          type: 'area',
          height: 570,
          stacked: true,
          events: {
            selection: function (chart, e) {
              console.log(new Date(e.xaxis.min))
            }
          },
        },
        colors: ['#008FFB', '#00E396', '#CED4DC','#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
		  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
           xaxis: {
          type: 'string',
          categories: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        },
       
        };

        var chart = new ApexCharts(document.querySelector("#chart7"), options);
        chart.render();
      },

graficaComisiones(data){
let datos=[];
let labels=[];

data.forEach(element => {
    datos.push(element.total)
    labels.push(element.nombre)
});

  



   var options = {
          series: [{
          name: 'Comisiones',
          data: datos
          }/*, {
          name: 'series2',
          data: [11, 32, 45, 32, 34, 52, 41]
        }*/],
          chart: {
          height: 250,
          type: 'bar'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          categories: labels
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        };

        var chart = new ApexCharts(document.querySelector("#comisioneschart"), options);
        chart.render();


      },

       grafica5(data){
let datos=[];
let labels=[];

data.forEach(element => {
    datos.push(element.cantidad)
    labels.push(element.producto)
});

  



   var options = {
          series: [{
          name: 'Servicios realizados',
          data: datos
          }/*, {
          name: 'series2',
          data: [11, 32, 45, 32, 34, 52, 41]
        }*/],
          chart: {
          height: 250,
          type: 'bar'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          categories: labels
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        };

        var chart = new ApexCharts(document.querySelector("#chart6"), options);
        chart.render();


      },
       grafica4(data){
let datos=[];
let labels=[];

data.forEach(element => {
    datos.push(element.cantidad)
    labels.push(element.producto)
});

  



   var options = {
          series: [{
          name: 'Servicios realizados',
          data: datos
          }/*, {
          name: 'series2',
          data: [11, 32, 45, 32, 34, 52, 41]
        }*/],
          chart: {
          height: 250,
          type: 'bar'
        },
        dataLabels: {
          enabled: false
        },
        fill: {
        colors: ['#049669']
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          categories: labels
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        };

        var chart = new ApexCharts(document.querySelector("#chart5"), options);
        chart.render();


      },
      grafica3(data){
let datos=[];
let labels=[];

data.forEach(element => {
    datos.push(element.total)
    labels.push(element.nombre)
});

  



   var options = {
          series: [{
          name: 'Ventas',
          data: datos
          }/*, {
          name: 'series2',
          data: [11, 32, 45, 32, 34, 52, 41]
        }*/],
          chart: {
          height: 250,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        fill: {
        colors: ['#dc2626']
        },
       
colors: ["#dc2626", "#dc2626"],

        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          categories: labels
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        };

        var chart = new ApexCharts(document.querySelector("#chart4"), options);
        chart.render();


      },
        grafica2(data){
let datos=[];
let labels=[];

data.forEach(element => {
    datos.push(element.total)
    labels.push(element.nombre)
});

  



   var options = {
          series: [{
          name: 'Ventas',
          data: datos
          }/*, {
          name: 'series2',
          data: [11, 32, 45, 32, 34, 52, 41]
        }*/],
          chart: {
          height: 250,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          categories: labels
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        };

        var chart = new ApexCharts(document.querySelector("#chart3"), options);
        chart.render();


      },
      grafica1(data){
let datos=[];


    datos.push(data[0].total)
    datos.push(data[1].total)
    datos.push(data[2].total)
    datos.push(data[3].total)
    datos.push(data[4].total)
    datos.push(data[5].total)
    datos.push(data[6].total)
    datos.push(data[7].total)
    datos.push(data[8].total)
    datos.push(data[9].total)
    datos.push(data[10].total)
    datos.push(data[11].total)




   var options = {
          series: [{
          name: 'Ventas',
          data: datos
          }/*, {
          name: 'series2',
          data: [11, 32, 45, 32, 34, 52, 41]
        }*/],
          chart: {
          height: 250,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        fill: {
        colors: ['#049669']
        },
       
colors: ["#049669", "#049669"],

        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'string',
          categories: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        };

        var chart = new ApexCharts(document.querySelector("#chart2"), options);
        chart.render();


      },
     
    async getTablero1(){
      



let data= await this.GET_TABLERO_GRAFICAS().then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        console.log(response)
this.grafica1(response.ventas_globales);
this.grafica2(response.ventas_sucursal1)
//this.grafica3(response.ventas_sucursal_pasado)
//this.grafica4(response.productos_mas_vendidos_pasado)
this.grafica5(response.productos_mas_vendidos)

this.graficaComisiones(response.comisiones)
//this.grafica6(response.generado_por_empleado)

                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
    }
     
  ,

     
    
 


  },
  
})
export default class Home extends Vue {}

