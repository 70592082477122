<template>
<div class="relative flex flex-wrap justify-center content-start h-full">

    <div class="vuecal__event-title font-extrabold w-full text-black text-start pl-6" v-html=" cita.clientes.nombre" />
    <template v-if=" cita.clientes.notas &&
        cita.clientes.notas !== 0">
        <i class="absolute top-1 right-1 las la-exclamation-triangle"></i>
    </template>
    <div class="bg-opacity-50 h-full  absolute top-0 left-0 w-full " @mouseover="showInfo = true" @mouseout="showInfo = false">
    </div>
    <div class="flex flex-wrap justify-center ">
        <!-- <span style="font-weight: bold; font-size: 11px " class="w-full  px-2 rounded-md">
        Servicio
    </span><br>-->
        <span class="bg-black text-white px-2 rounded-md w-10/12 text-xs capitalize font-bold">{{ cita.detalle_ventas[0].producto_servicios.nombre }}</span>
    </div>

    <small class="vuecal__event-time w-full" v-if="!event.hasOwnProperty('asignacion')">

        <strong>De</strong> <span>{{ formatTime(formatearFecha(event.start).split(' ')[1]) }} </span>
        <strong>a</strong> <span>{{ formatTime(formatearFecha(event.end).split(' ')[1])  }}</span>
    </small>

    <div class="fixed max-w-md p-4 mx-auto border border-gray-200 bg-white  bottom-6 dark:border-gray-700 overflow-hidden" :class="showInfo?'h-78 ':'h-0 hidden max-h-0'" style="left: 0px;bottom:64px; width: 250px; transition:  ease-all 2s;">

        <h2 class="font-semibold text-black uppercase">🌕🌙✨ Servicio <strong class="text-yellow-600 text-xs uppercase">{{
        cita.detalle_ventas[0].producto_servicios.nombre
      }}</strong></h2>

        <p class="mt-4 text-sm text-gray-600 dark:text-gray-300"> </p>

        <p class="mt-3 text-sm text-black dark:text-black" v-if="cita.clientes.notas && cita.clientes.notas !== 0"> Nota Cliente : <strong class="text-red-500 text-xs">{{
        cita.clientes.notas
      }}</strong></p>

        <div class="w-full flex flex-wrap justify-start">
            <button class="w-full text-start  content-start justify-start text-xs bg-gray-100  font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                <span class="text-black font-extrabold">Hora</span> <br><strong class="text-black text-xs capitalize">de {{
                parseTimeN(cita.fecha_inicio)
              }}<br> a {{ parseTimeN(cita.fecha_fin) }}</strong>
            </button>

            <button class="mt-2 w-full text-start  content-start  justify-start text-xs  bg-yellow-500 bg-opacity-70  text-gray-800 hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                <span class="text-yellow-700 font-extrabold">Modifico</span> <br> <strong class="text-black text-xs">{{
                cita.modificou
              }}<br>{{formatearFechaNew(cita.updated_at)}}</strong>
            </button>
            <button class="mt-2 w-full text-start  content-start  justify-start text-xs  bg-yellow-500 bg-opacity-70  text-gray-800 hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                <span class="text-yellow-700 font-extrabold">Creo</span> <br> <strong class="text-black text-xs">{{
                cita.creador
              }}<br>{{formatearFechaNew(cita.created_at)}}</strong>
            </button>
        </div>

    </div>

</div>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
        cita: {
            type: Object,
            required: true,
        },
    },
    methods: {
        formatTime(time) {
            let tiempo = time.split(':')
            return tiempo[0] + ":" + tiempo[1];
        },
        formatearFechaNew(fecha) {
            fecha = new Date(fecha);
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            return fecha.toLocaleDateString("es-MX", options);
        },
        formatearFecha(fecha) {
            fecha = new Date(fecha);
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };

            return fecha.toLocaleString('en-US', options).replace(/,/g, '');
        },
        parseTimeN(date) {
            const event = new Date(date);

            return event.toLocaleTimeString("es-MX", {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
            });
        },
    },
    data() {
        return {
            showInfo: false
        }
    },
    mounted() {
        console.log(this.event, '-e-eeevent-')
    }
};
</script>

<style>
/* Add any required styles here */
</style>
