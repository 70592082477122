
//@ts-nocheck
import L from 'leaflet';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import {
    VueGoodTable
} from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
//@ts-ignore
import palabras from "@/mixins/palabras";
import generales from "@/mixins/functions/generales";
@Component({
    mixins: [palabras, generales],
    data() {
        return {
            icon: L.icon({
                iconUrl: 'https://img.icons8.com/external-xnimrodx-blue-xnimrodx/64/undefined/external-pointer-location-xnimrodx-blue-xnimrodx.png',
                iconSize: [37, 37],
                iconAnchor: [16, 16]
            }),
            markerLatLng: null,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

            showconfirm: false,
            buscar: '',
            show: false
        }
    },
    components: {
        VueGoodTable,
    },
    mounted() {

        console.log('ss-----')
        console.log(this.columns)
        //this.formatrow();
        this.show = true;

    },
    methods: {
        verificarTipo(tipo) {
            switch (tipo) {
                case 'distancia_permitir':
                    return 'Permite estas Cordenadas'
                    break;
                case 'distancia_bloquear':
                    return 'Bloquea estas Cordenadas'
                    break;
                case 'bloqueo_hora':
                    return 'Bloquea por horas'
                    break;

                default:
                    break;
            }
        },
        buscadorpropiedades(row) {
            let ocultar = 0;

            if (this.buscador == false) {
                return true;
            }
            if (this.buscar == '') {
                return true;
            }

            /*
             Array(this.filtros).forEach(element => {
             
                     //se busca por esta columna
                     if (this.columns[element].search(this.buscar) != -1) {
                         //si contiene si muestra
                     } else {
                         //no contiene no muestra
                         ocultar =ocultar+ 1;
                     }
               
             });*/
           /* this.columns.forEach(element => {

                if ((this.filtros).includes(element.data)) {
                    //console.log(' filtrar',[element.data],this.filtros)
                    //se busca por esta columna
                    if (row[element.data].search(this.buscar) != -1) {
                        //si contiene si muestra
                        // console.log('si muestra',this.buscar,row[element.data])
                    } else {
                        //console.log('no muestra',this.buscar,row[element.data])
                        //no contiene no muestra
                        ocultar = ocultar + 1;
                    }
                } else {
                    //console.log('no filtrar',[element.data],this.filtros)
                    //no se busca por esta columna
                }
            });*/

            this.columns.forEach(element => {
                if ((this.filtros).includes(element.data)) {
                    // Se busca por esta columna
                    const valorColumna = row[element.data].toUpperCase(); // Convertir a mayúsculas
                    const valorBuscado = this.buscar.toUpperCase(); // Convertir a mayúsculas

                    if (valorColumna.includes(valorBuscado)) {
                        // Si contiene, se muestra
                        // console.log('si muestra', this.buscar, row[element.data]);
                    } else {
                        // No contiene, no se muestra
                        // console.log('no muestra', this.buscar, row[element.data]);
                        ocultar = ocultar + 1;
                    }
                } else {
                    // No se busca por esta columna
                }
            });

            if (ocultar > 0) {
                return false
            } else {
                return true;
            }

        },

        getExistTag(prop) {
            let valores = this.columns.filter((row) => row.data == prop)
            if (valores.length > 0) {
                return true
            } else {
                return false;
            }
        },
        formatrow() {
            let data = [];
            let labels = this.es.table;
            //console.log(labels);
            this.columns.forEach(element => {

                data.push({
                    label: labels[element.tag],
                    field: element.data,
                    filterOptions: {
                        enabled: element.active, // enable filter for this column
                        placeholder: 'Buscar', // placeholder for filter input
                        filterValue: '', // initial populated value for this filter
                        //filterDropdownItems: [], // dropdown (with selected values) instead of text input
                        //filterFn: this.columnFilterFn, //custom filter function that
                        trigger: 'keyup', //only trigger on enter not on keyup 
                    }
                });

            })
            return data;
        }
    }
})
export default class HelloWorld extends Vue {

    @Prop() private idp!: string;
    @Prop() private rows!: any;
    @Prop() private columns!: any;
    @Prop({

    }) private filtros: any[];
    @Prop() private add!: bool;
    @Prop({
        default: false
    }) private buscador: bool;
    @Prop() private refr!: bool;
    @Prop({
        default: ''
    }) private mensajeconfirm!: string;

    @Prop() private nameButton!: any;

}
