
//@ts-nocheck
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import ModalHistorialCliente from "@/components/calendario/ModalHistorialCliente.vue";
var body = document.body,
    html = document.documentElement;

var height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight);
console.log(height, '----he------')

@Component({
    components:{ModalHistorialCliente},
    data() {
        return {
            isOpen: false,
            selectedStatus: {
                id: null,
                tooltip: "Cita Reservada",
                color: "bg-blue-500"
            },

            statuses: [{
                    id: null,
                    tooltip: "Cita Reservada",
                    color: "bg-blue-500"
                },
                {
                    id: 2,
                    tooltip: "Cita Realizado",
                    color: "bg-green-500"
                },
                {
                    id: 3,
                    tooltip: "Cita Confirmada",
                    color: "bg-yellow-500"
                },
                {
                    id: 4,
                    tooltip: "Cita Cancelada",
                    color: "bg-red-500"
                },
                {
                    id: 5,
                    tooltip: "Cliente No asistió",
                    color: "bg-purple-500"
                }
            ],
            activeView: 'day',
            selectedEvent: null,
            showDialog: false,
            dataeliminar: null,
            height: height - 64,

            demoExample: {
                splits: [],
                editable: {
                    create: true,
                    title: false,
                    drag: true,
                    resize: true
                },

                events: []
            },

            selectedDate: this.dia
        }
    },

    computed: {
        // Get the Monday of the real time current week.
        previousFirstDayOfWeek() {
            //  return new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 6) % 7))
        }
    },
    methods: {
        getActualStatusCard(data){
            console.log('----',data)
          
          
                return this.statuses.filter(item => item.id == data.estatus)[0];
          
            

        },
        getActualStatus(){
            if(this.selectedEvent!=null){
                return this.statuses.filter(item => item.id == this.selectedEvent.data.estatus)[0];
            }else{
return {
                id: null,
                tooltip: "Reservado",
                color: "bg-blue-500"
            }
            }
            

        },
        selectStatus(status) {
            console.log(status);
      this.selectedStatus = status;
      this.isOpen = false;
      this.$parent.EditarEstatus(this.selectedEvent,status.id);
    },
        openPopup(event) {

            this.showDialog = true;
            this.selectedEvent = event;
            //this.$parent.CloseModalAgregarVenta(true)
            const popup = document.getElementById("popup");
            const buttonRect = this.$refs['popups' + event.id].getBoundingClientRect();
            setTimeout(() => {
                const popupWidth = popup.offsetWidth;
                const popupHeight = popup.offsetHeight;

                const spaceBelow = window.innerHeight - buttonRect.bottom;

                // Calcular la posición vertical
                if (spaceBelow < popupHeight) {
                    // Mostrar el popup fijo abajo de la pantalla si el espacio debajo es insuficiente
                    popup.style.top = `${window.innerHeight - popupHeight}px`;
                } else {
                    // Mostrar el popup debajo del botón si hay suficiente espacio
                    popup.style.top = `${buttonRect.bottom}px`;
                }

                // Calcular la posición horizontal
                const spaceLeft = buttonRect.left;
                const spaceRight = window.innerWidth - buttonRect.right;

                if (spaceRight >= popupWidth || spaceRight >= spaceLeft) {
                    // Mostrar el popup a la derecha del botón si hay suficiente espacio a la derecha o si hay más espacio a la derecha que a la izquierda
                    popup.style.left = `${buttonRect.left}px`;
                } else {
                    // Mostrar el popup a la izquierda del botón si hay más espacio a la izquierda que a la derecha
                    popup.style.left = `${buttonRect.right - popupWidth}px`;
                }

            }, 100);

        },
        formatTime(time) {
            let tiempo = time.split(':')
            return tiempo[0] + ":" + tiempo[1];
        },

        FormatTi(time) {
            const event = new Date('August 19, 2022 ' + time + ':00 ');
            //console.log(event.toLocaleTimeString('en-US'))
            let st = String(event.toLocaleTimeString('en-US')).split(':');
            return st[0] + ':' + st[1] + ' ' + (st[2].split(' ')[1])
        },
        onCopy: function (e) {
            //  alert('Acabas de copiar el siguiente texto en el portapapeles: ' + e.text)
        },
        onError: function (e) {
            // alert('No se pudo copiar el texto al portapapeles')
            console.log(e);
        },
        onEventClick(event, e) {
            console.log(event, 'event-click', e)
            this.selectedEvent = event
            //this.$parent.changueSucursal(this.selectedEvent)
            this.showDialog = true

            // Prevent navigating to narrower view (default vue-cal behavior).
            e.stopPropagation()
        },
        resizeEvents(event, e) {
            this.selectedEvent = null
            this.showDialog = false;
            console.log(event, '-----resize---')
            e.stopPropagation()

        },
        logEvents(type, event) {
            // console.log(type)

            switch (type) {
                case 'event-duration-change':
                    this.$parent.resizeMapEvent(event)

                    break;

                case 'event-click':
                    //this.$parent.ClickEmpleado(event)
                    console.log(event, type)
                    break;
                case 'cell-click':
                    //this.$parent.ClickEmpleado(event)
                    //console.log(event,type)
                    break;
                case 'cell-dblclick':
                    this.$parent.createEvent(event)
                    //console.log(event,type)
                    break;
                case 'event-drop':
                    this.$parent.returnVal(event)
                    break;
                case 'event-drag-create':
                    console.log(event, 'drag-create')
                    break;

                default:
                    break;
            }
            //   event.stopPropagation()

        },
        onEventDragStart(e, draggable) {
            // Passing the event's data to Vue Cal through the DataTransfer object.
            e.stopPropagation()
            e.dataTransfer.setData('event', JSON.stringify(draggable))
            e.dataTransfer.setData('cursor-grab-at', e.offsetY)

        },
        eventoDrag(event) {
            console.log(event, '-----drag---')
            if (event.event.data.hasOwnProperty('asignacion')) {
                // event.stopPropagation();

            }

            this.$parent.returnVal(event)
            /*if (window.confirm("¿Esta segura de realizar este cambio?")) {
             
            }else{
              //cancelo
              console.log('----cancelo----')
            this.$parent.returnVal(event.originalEvent)
             
            }*/

        }
    },
    mounted() {
        this.$refs.vuecal.switchView('day')
        console.log(this.dataempleados, '-------')
    },

    created() {

        console.log(this.selectedDate)

        // Place all the events in the real time current week.
        /* for (let i = 0; i < 5; i++) {
           const day = this.previousFirstDayOfWeek.addDays(i).format()

           this.demoExample.events.push(
             {
               start: `${day} 12:00`,
               end: `${day} 13:00`,
               title: 'LUNCH',
               class: 'lunch',
               background: true,
               deletable: false,
               resizable: false,
               split: 1
             },
             {
               start: `${day} 12:00`,
               end: `${day} 13:00`,
               title: 'LUNCH',
               class: 'lunch',
               background: true,
               deletable: false,
               resizable: false,
               split: 2
             }
           )
         }*/

    }
})
export default class CalendarVc extends Vue {
    @Prop() private msg!: string;
    @Prop() private dataempleados!: any;
    @Prop() private splits!: any;
    @Prop() private hora!: any;
    @Prop() private showid!: any;
    @Prop() private dia!: any;

}
