
    //@ts-nocheck
    //@ts-ignore
    import {
        Component,
        Vue
    } from "vue-property-decorator";
    //@ts-ignore
    import TablePPoa from '@/components/tables/tablev1'
    //@ts-ignore
    import CrearGiftcard from '@/components/CrearGiftcard'
    
    //@ts-ignore
    import PmdHeader from "@/components/headersSelect/pmd";
    
    //@ts-ignore
    import clientes from "@/mixins/axios/clientes";
    //@ts-ignore
    import giftcards from "@/mixins/axios/giftcards";
    //@ts-ignore
    import ventagiftcard from "@/mixins/axios/ventagiftcards";
    //@ts-ignore
    import {
        email,
        required
    } from "vuelidate/lib/validators";
    //@ts-ignore
    import sucursales from "@/mixins/axios/sucursales";
    
    //@ts-ignore
    import generales from "@/mixins/functions/generales"
    //@ts-ignore
    import Notiflix from "notiflix";
    //import {Vuetable,VuetablePagination} from 'vuetable-2'
    //    protected $fillable = ['id','clientes_id', 'numero', 'activo','sucursales_id','id_empleada','cantidad','precio','metodo','created_at', 'updated_at'];
    
    @Component({
        components: {
            TablePPoa,
            CrearGiftcard
        },
        data() {
            return {
                //----data cliente--------
                addGiftcard: false,
                datGiftcard:null,
                //-----end data cliente
                datesel: this.actualformatDate(),
                datas: {
                    start: this.formatFullDate(this.getfisrtDay()),
                    end: this.formatFullDate(new Date()),
                },
                sucursalsel: this.$store.state.user.usuario.sucursales_id,
                sucursalesdata: [],
    
                ventasgiftcard: [],
    
                columnasd: [
                    {
                        tag: "delete",
                        data: "delete",
                        active: false,
                    },
                    {
                        tag: "edit",
                        data: "edit",
                        active: false,
                    },
                    {
                        tag: 'id',
                        data: 'id',
                        active: false
                    },
                    {
                        tag: 'num_venta_gif',
                        data: 'id',
                        active: false
                    },
                    
    
                    {
                        tag: 'clientes_id',
                        data: 'cliente',
                        active: true
                    },
                   
                    {
                        tag: 'folio',
                        data: 'numero',
                        active: true
                    },
                    {
                        tag: 'cantidad',
                        data: 'cantidad',
                        active: true
                    },
                    {
                        tag: 'precio',
                        data: 'precio',
                        active: true
                    },
                    
                    {
                        tag: 'descuento_gift',
                        data: 'descuento',
                        active: true
                    },
                    
                    {
                        tag: 'url_gift',
                        data: 'url',
                        active: true
                    },
    
    
                    {
                        tag: 'fecha',
                        data: 'created_at',
                        active: true
                    },
                    {
                        tag: 'nota',
                        data: 'nombrecliente',
                        active: true
                    },
                    {
                        tag: 'correo_venta',
                        data: 'correo_venta',
                        active: true
                    },
                    {
                        tag: 'telefono_venta',
                        data: 'telefono_venta',
                        active: true
                    },
                    
                ],
    
            };
        },
        mixins: [giftcards, ventagiftcard, sucursales, clientes, generales],
        validations: {
            nombre: {
                required,
            },
    
            /* direccion: {
              required,
            },*/
            telefono: {
                required,
            },
        },
        methods: {
            OcultarGiftcardAdd() {
                this.datGiftcard = null;
            },
            seteditar(row) {
                alert('editargift')
                this.datGiftcard = row;
            },
            addRow() {
                this.datGiftcard = {};
            },
            async getSucursales() {
                this.$root.$emit("loading", true);
                console.log("loaddding");
                let data = await this.GET_SUCURSALES().then((response) => {
                    if (response != null) {
                        //this.plantas = response;
                        this.sucursalesdata = response;
                        this.sucursalesdata.push({
                            "id": 0,
                            "numero": "0000000000",
                            "nombre": "VENTAS ONLINE",
                            "descripcion": "SUCURSAL",
                            "latitud": "25.608302908921",
                            "longitud": "-103.46791182798",
                            "contacto": "NA",
                            "colorprimario": null,
                            "colorsecundario": null,
                            "colorterciario": null,
                            "logotipo": null,
                            "fuente": "arial",
                            "tamano_titulos": 12,
                            "tamano_textos": 10,
                            "tamano_botones": 9,
    
                            "modifico": 1,
                            "created_at": "2021-05-04 21:12:29",
                            "updated_at": "2021-07-16 05:07:29",
                            "activo": 1,
                            "tipo": "MATRIZ"
                        })
                        if (this.$store.state.user.usuario.role == "admin") {
                            this.sucursalsel = response[0].id;
                        }
    
                        this.$root.$emit("loading", false);
                        this.getData()
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    }
                    this.$root.$emit("loading", false);
    
                    //loading.close();
                });
            },
    
            
            async eliminar(row) {
    console.log(row,'----')
    this.$root.$emit('loading', true);
    let data = await this.ELIMINAR_GIFTCARDS(row).then(response => {
    
        if (response != null) {
    
            this.ventasgiftcard = this.ventasgiftcard.filter(item => item.id !== row.id);
    
            this.$root.$emit('loading', false);
        } else {
            this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");
    
        }
        
    });
    },
    
    confirm(row) {
    
    let th = this;
    Notiflix.Confirm.Show('Alerta de confirmacion', 'Desea eliminar este elemento?', 'Aceptar', 'Cancelar', function () {
    
        th.eliminar(row);
    }, function () {
    
    });
    
    },
            SelectSucursalChange() {
                //aquiiiii
    
                this.getData()
                //console.log(,'----------------sel--change-------------')
            },
    
            async getData() {
                this.$root.$emit("loading", true);
                console.log("loaddding");
                let data_s = {
                    id: this.sucursalsel,
                    inicio: this.formatFullDate(this.datas.start),
                    fin: this.formatFullDate(this.setMasOneDay(this.datas.end))
                };
                let data = await this.GET_RECARGASGIFTCARDS(data_s).then((response) => {
                    if (response != null) {
                        //this.plantas = response;
                        this.ventasgiftcard = response;
                        //this.total_tarjeta_giftcards = 0;
                        //this.total_efectivo_giftcards = 0;
                        /*response.forEach(element => {
                            switch (element.metodo) {
                                case 'Tarjeta Credito':
                                    this.total_tarjeta_giftcards += Number(element.total);
                                    break;
                                case 'Tarjeta Débito':
                                    this.total_tarjeta_giftcards += Number(element.total);
                                    break;
                                case 'Efectivo':
                                    this.total_efectivo_giftcards += Number(element.total);
                                    break;
    
                                default:
                                    break;
                            }
    
                        });*/
    
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    }
                    this.$root.$emit("loading", false);
    
                    //loading.close();
                });
    
            },
    
        },
        mounted() {
            this.getSucursales()
    
        },
    })
    export default class Home extends Vue {}
    