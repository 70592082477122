
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';


//@ts-ignore
import categorias from '@/mixins/axios/categorias'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import palabras from "@/mixins/palabras";
@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
        //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,
  
        columnasd:[
             {
                tag:'id',
                data:'id',
                active:false
            },
            
          
           
           
              {
                tag:'nombre',
                data:'nombre',
                active:true
            },
             {
                tag:'color',
                data:'color',
                active:true
            },
             {
                tag:'edit',
                data:'edit',
                active:false
            },
              {
                tag:'delete',
                data:'delete',
                active:false
            },
            /*  {
                tag:'icono',
                data:'icono',
                active:true
            },
              {
                tag:'categoria',
                data:'categoria',
                active:true
            },*/
              
           
             
            
           
        ],



        id:null as any,

      nombre:'' as any,
     
      color:'#1CA085' as any,
      
      icono:'' as any,
      
      categorias_id:0 as any,
      
      
     
         
         
              categoriasdata:[] as any,
      
    }
  },
     mixins: [categorias],
     validations: {
    
           nombre: {
      required,
    },
 
           color: {
      required,
    },
 
     
     
         /*  icono: {
      required,
    },
 
     
     
           categorias_id: {
      required,
    },
 */
     
     
 
     
     

   
  },
  methods:{
       
      async eliminar(row){
         
this.$root.$emit('loading', true);
let data= await this.ELIMINAR_CATEGORIAS(row).then(response => {

                    if (response != null) {

 this.categoriasdata=this.categoriasdata.filter(item => item.id !== row.id);
 
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                });
      },

     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
      limpiar(){
this.id=null;
 
        
                 
                // this.icono=null;
           this.nombre=null;
                      this.color=null;
                                 //this.categorias_id=null;
        
            
           
      },
      addRow(){
          this.limpiar();
 $('#ac_modal_productos_servicios').modal('show')
      },
seteditar(row){



 this.id=row.id;
 this.color=row.color;

 this.nombre=row.nombre;
 //this.icono=row.icono;
 
 //this.categorias_id=row.categorias_id;

            
          
 $('#ac_modal_productos_servicios').modal('show')
    

},
     async crear(dat){
let data= await this.CREAR_CATEGORIAS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)

  this.categoriasdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar categorias, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                    //loading.close();
                });

      },
     async editar(dat){


let data= await this.EDITAR_CATEGORIAS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)
this.$root.$emit("success", "Categorias modificado exitosamente, refresque la pagina");
  this.categoriasdata=this.categoriasdata.filter(item => item.id !== dat.id);
 this.categoriasdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear el categorias, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                    //loading.close();
                });


      }
     ,
     async GuardarData(){



console.log('id:'+this.id)

let dat={
  id:this.id,
    
            nombre:this.nombre,
            color:this.color,
           // icono:this.icono,
           // categorias_id:this.categorias_id,
          
    
        

}
console.log(dat)
 this.$root.$emit('loading', true);
 if(this.id==null){
     this.crear(dat);
 }else{
       this.editar(dat);
 }

      },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')



let data= await this.GET_CATEGORIAS().then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.categoriasdata=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      this.getData();
      //alert('10')
  }
})
export default class Home extends Vue {}

