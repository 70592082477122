
    //@ts-nocheck
    //@ts-ignore
    import {
        Component,
        Vue
    } from "vue-property-decorator";
    //@ts-ignore
    import TablePPoa from '@/components/tables/tablev1'
    //@ts-ignore
    import giftcards from "@/mixins/axios/giftcards";
    //@ts-ignore
    import TablePPoa from "@/components/tables/tablev1";
    //@ts-ignore
    import PmdHeader from "@/components/headersSelect/pmd";
    //@ts-ignore
    import ListaPagosCobros from "@/components/pages/giftcards/ListaPagosCobros.vue";

    
    //@ts-ignore
    import giftcards from "@/mixins/axios/giftcards";
    //@ts-ignore
    import clientes from "@/mixins/axios/clientes";
        //@ts-ignore
        import cobros from "@/mixins/axios/cobros";
    //@ts-ignore
    import ventagiftcard from "@/mixins/axios/ventagiftcards";
    //@ts-ignore
    import {
        email,
        required
    } from "vuelidate/lib/validators";
    
    //@ts-ignore
    import generales from "@/mixins/functions/generales"
    //@ts-ignore
    import sucursales from "@/mixins/axios/sucursales";
    //@ts-ignore
    import tableroSucursal from "@/mixins/axios/tableroSucursal";
    //@ts-ignore
    import pagos_giftcards from "@/mixins/axios/pagos_giftcards";
    //@ts-ignore
    import Notiflix from "notiflix";
    //import {Vuetable,VuetablePagination} from 'vuetable-2'
    //    protected $fillable = ['id','clientes_id', 'numero', 'activo','sucursales_id','id_empleada','cantidad','precio','metodo','created_at', 'updated_at'];
    import {
        MonthPickerInput
    } from 'vue-month-picker'
    import ItemTemplate from "@/views/admin/templateselect.vue";
    
    @Component({
        components: {
            TablePPoa,
            MonthPickerInput,
            ListaPagosCobros,
            
            
        },
        data() {
            return {
                //----data cliente--------
    total_cobros:0,
    total_recargas:0,
                template: ItemTemplate,
                sucursalsel: 0,
                venta: 0,
                sucursalesdata: [],
                listDetalleCobros:[],
                //-----end data cliente
                listDetalle: [],
                datesel: {
                    start: this.formatFullDate(this.getfisrtDay()),
                    end: this.formatFullDate(new Date()),
                },
                rr: this.actualformatDate(),
    
               
    
            };
        },
        mixins: [generales, giftcards, ventagiftcard, sucursales, clientes, pagos_giftcards, tableroSucursal,cobros],
        validations: {
            nombre: {
                required,
            },
    
            /* direccion: {
              required,
            },*/
            telefono: {
                required,
            },
        },
        methods: {
            getDiferencia(){
                return Number(this.total_recargas-this.total_cobros).toFixed(2)
                
            },
            SelectSucursalChange() {
                //aquiiiii
    
                //this.getData();
                this.getDataGiftcards();
                this.getDataCobros();
                //console.log(,'----------------sel--change-------------')
            },
            selectdate(val) {
                console.log(val, "------------val----");
                //this.getData()
                this.getDataGiftcards();
                this.getDataCobros();
            },
    
            async getSucursales() {
                this.$root.$emit("loading", true);
                console.log("loaddding");
                let data = await this.GET_SUCURSALES().then((response) => {
                    if (response != null) {
                        //this.plantas = response;
                        this.sucursalesdata = response;
                        if (this.$store.state.user.usuario.role == "admin") {
                            this.sucursalsel = response[0].id;
                        }
    
                        this.$root.$emit("loading", false);
                        this.getDataGiftcards();
                this.getDataCobros();
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    }
                    this.$root.$emit("loading", false);
    
                    //loading.close();
                });
            },
            async getDataCobros() {
                console.log(this.datesel,'---fecha')
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.sucursalsel,
                inicio: this.formatFullDate(this.datesel.start),
                fin: this.formatFullDate(this.setMasOneDay(this.datesel.end))
            };
            let data = await this.GET_COBROS_RANGUE(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.listDetalleCobros = response;
                    this.total_cobros=0;
                    response.forEach(element => {
                        this.total_cobros+=Number(element.cantidad_giftcards)
                    });
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
            async getDataGiftcards() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data_s = {
                id: this.sucursalsel,
                inicio: this.formatFullDate(this.datesel.start),
                fin: this.formatFullDate(this.setMasOneDay(this.datesel.end))
            };
            let data = await this.GET_VENTASGIFTCARDS(data_s).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.listDetalle = response;
                    this.total_recarga=0;
                    response.forEach(element => {
                        this.total_recargas+=Number(element.total)
                    });
                    //this.total_tarjeta_giftcards = 0;
                    //this.total_efectivo_giftcards = 0;
                    /*response.forEach(element => {
                        switch (element.metodo) {
                            case 'Tarjeta Credito':
                                this.total_tarjeta_giftcards += Number(element.total);
                                break;
                            case 'Tarjeta Débito':
                                this.total_tarjeta_giftcards += Number(element.total);
                                break;
                            case 'Efectivo':
                                this.total_efectivo_giftcards += Number(element.total);
                                break;

                            default:
                                break;
                        }

                    });*/

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });

        },
           
           
    
        },
        mounted() {
            this.getSucursales();
    
        },
    })
    export default class Home extends Vue {}
    