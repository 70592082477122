
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {


      
      GET_CLIENTESLIMITE: async function(data) {


                
                //console.log(data.text,'--')

                     
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"client/limit/"+data.limit+"/search/***"+data.text+"/pagina/"+data.pagina,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      
      GET_CLIENTES2: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"clientes/search/"+data.text,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_HISTORIAL_CLIENTES: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"clientes/"+data.id+"/historialservicios",
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_CLIENTES_ACTIVOS: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"clientes/activos/"+data.page,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_CLIENTES: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"clientes/"+data.init,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    
    CLIENTES_UNIR: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"clientes/unir" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          clientes:data.clientes,
          principal:data.principal,
         
          //direccion:data.direccion,
         // sucursales_id:data.sucursales_id,
      
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    CREAR_CLIENTES: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"clientes/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          nombre:data.nombre,
          telefono:data.telefono,
          influencer:data.influencer,
          notas:data.notas,
          influencer_descuento:data.influencer_descuento
          //direccion:data.direccion,
         // sucursales_id:data.sucursales_id,
      
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_CLIENTES: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"clientes/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'nombre':data.nombre,
            'telefono':data.telefono,
            influencer:data.influencer,
            notas:data.notas,
            influencer_descuento:data.influencer_descuento
            //'direccion':data.direccion,
          //  sucursales_id:data.sucursales_id,
        
             
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_CLIENTES: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"clientes/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }