
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';
import PreviewGiftcard from "@/components/PreviewGiftcard.vue"; 
//@ts-ignore
import configuraciones from '@/mixins/axios/configuraciones'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'

//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa,
        PreviewGiftcard
    },
    mixins: [configuraciones],
    data() {

        return {
           

        }

    },
    mounted() {

        console.log('data---')
    },
    created() {
        this.getData()
    },
    methods: {
   
    }

})
export default class Home extends Vue {}
