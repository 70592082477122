
//@ts-nocheck
//@ts-ignore
//@ts-ignore
import generales from '@/mixins/functions/generales'
import producto_servicios from '@/mixins/axios/producto_servicios'
import giftcards from '@/mixins/axios/giftcards'
import {
    Component,
    Vue
} from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

    },
    data() {
        return {
            //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,

            id: null as any,
            servicio_seleccionado: 0,
            precio: '',

            nombre: '',
          
            telefono: '',
            correo: '',
            preview:0,
            comision: 0 as any,
            color: '#1CA085',

            tiempo: 0 as any,

            categorias_id: 0 as any,

            producto_serviciosdata: {},

        }
    },
    mixins: [producto_servicios, giftcards, generales],

    methods: {
        verifiedInputs(){

            if(this.correo!=''&&this.telefono!=''){
                return true;
            }else{
                return false
            }


        },

        limpiar() {
            this.id = null;

            this.precio = null;
            this.nombre = null;
            this.correo = null;
            this.comision = null;
            this.tiempo = null;

            this.type = null;

        },
        addRow() {
            this.limpiar();
            $('#ac_modal_productos_servicios').modal('show')
        },

        async crear() {
            if (this.servicio_seleccionado != 0) {
                this.nombre = this.producto_serviciosdata[this.servicio_seleccionado].nombre;
                this.precio = this.producto_serviciosdata[this.servicio_seleccionado].precio;

            } else {
                if(this.precio<200){
                    this.$root.$emit("alerta_show",'No se puede comprar una cantidad menor a $200');
                    return
                }

            }

            let dat = {
                id: this.servicio_seleccionado,
                tipo: this.servicio_seleccionado==0?'cantidad':'servicio',
                nombre: this.nombre,
                telefono: this.telefono,
                correo: this.correo,
                total: this.precio,

            }
            let data = await this.VENTAGIFTCARD_ONLINE(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)

                    window.location.href = response
                } else {
                    this.$root.$emit("alerta_show",response.error);

                }
                this.$root.$emit('loading', false);
                //loading.close();
            });

        },

        async GuardarData() {

            console.log('id:' + this.id)

            let dat = {
                id: this.id,

                precio: this.precio,
                nombre: this.nombre,
                comision: this.comision,
                color: this.color,
                tiempo: this.tiempo,
                categorias_id: this.categorias_id,

            }
            console.log(dat)
            this.$root.$emit('loading', true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }

        },
        async getData() {
        var count=0;
            console.log(this.producto_serviciosdata, '--caca--')
            this.producto_serviciosdata = {}
            // this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_PRODUCTOS_SERVICIOS_ONLINE().then(response => {

                if (response != null) {
                    //this.plantas = response;
                    response.forEach(element => {

                        if(this.$route.query.tipo=='servicio'&&count==0){
                            this.servicio_seleccionado=element.id;
                            count++;
                        }
                        this.producto_serviciosdata[element.id] = element;
                    });


                    console.log(this.producto_serviciosdata, '--cac2a--')
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                //this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    created() {
        this.getData();

        //alert('10')
    }
})
export default class Home extends Vue {}
