
            
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      

      POST_GUARDAR_PAGO_COBRO: async function(data) {
         
        var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"pagos_giftcards",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token
  
          },
          data:{
            cantidad:data.cantidad,
         sucursales_id:data.sucursales_id,
         tipo:data.tipo,
         mes:data.mes,
         ano:data.ano,
       
      
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
  
  
  
  
        },

   
        GET_PAGOS: async function(data) {




      
          var result =  await axios
            ({
              method: 'GET',
              url: this.$store.state.baseapi+"pagos_giftcards/sucursal/"+data.id+"/mes/"+data.mes+"/ano/"+data.ano+"/tipo/"+data.tipo,
              responseType: 'json',
              headers: {
            
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' +this.$store.state.user.token
    
              },
                
              }
              
            )
            .then(
              response => {
             
                //context.commit('SET_USER_DEALS', { deals: response.data.data })
                return response.data;
              },
              err => {
                
                return null;
              }
            );
            return result;
    
    
    
    
    
        }, 





    },
  }