
//@ts-nocheck
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component({
    data() {
        return {

            metodos: ['nada',
                'Efectivo',
                'T. Crédito',
                'T. Débito'
            ],

        }
    },
    mounted() {

    },
    methods: {
        parsedArrayJson() {
            console.log(this.arrayJson)
            try {
                return JSON.parse(this.arrayJson);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return []; // Return an empty array or handle the error as needed
            }
        },
    },
})
export default class spanArrayJson extends Vue {
    @Prop() arrayJson!: string;
    @Prop({
        default: false
    }) plano!: boolean;

}
