<template>
  <div class="text-center">
    
   <!-- <b class="text-blue-500 mx-2">#{{item.id}}</b>-->
    <span class="mx-2 text-center" >{{ item.nombre }}</span><br>
        <abbr class="text-xs text-blue-800 mx-2">Telefono: {{item.telefono}}</abbr>
  
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script>