
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import { email, required } from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";

import VueAxios from "vue-axios";
import axios from "axios";
@Component({
  components: {
    TablePPoa
  },
  data() {
    return {
      columnasd: [
        {
          tag: "id",
          data: "id",
          active: false
        },
        {
          tag: "nombre",
          data: "nombre",
          active: true
        },
        {
          tag: "tipo",
          data: "tipo",
          active: true
        },
        {
          tag: "telefono",
          data: "numero",
          active: true
        } /*
            {
                tag:'descripcion',
                data:'descripcion',
                active:true
            },*/

        ,
        {
          tag: "contacto",
          data: "contacto",
          active: true
        },
        {
          tag: "delete",
          data: "delete",
          active: false
        },
        {
          tag: "edit",
          data: "edit",
          active: false
        }

        /* {
                tag:'latitud',
                data:'latitud',
                active:false
            },
            {
                tag:'longitud',
                data:'longitud',
                active:false
            }*/
      ],
      id: null as any,
      numero: null as any,
      idtema: "" as any,
      nombre: "" as any,
      tipo: "SUCURSAL" as any,
      descripcion: "" as any,
      latitud: "" as any,
      longitud: "" as any,
      contacto: "" as any,

      logotipo: "" as any,

      municipiosdata: [] as any,
      marker: null,
      file: null,
      filesSelected: 0,
      cloudName: "leadpatt",
      preset: "fdykxij4",
      tags: "poa-upload",

      fileContents: null,
      formData: null
    };
  },
  mixins: [sucursales],
  validations: {
    tipo: {
      required
    },
    nombre: {
      required
    },
    numero: {
      required
    },
    /* latitud: {
      required,
    },
      longitud: {
      required,
    },*/
    contacto: {
      required
    }
  },
  methods: {
    handleFileChange: function(event) {
      console.log("handlefilechange", event.target.files);
      //returns an array of files even though multiple not used
      this.file = event.target.files[0];
      this.filesSelected = event.target.files.length;
      this.upload();
    },
    prepareFormData: function() {
      this.formData = new FormData();
      this.formData.append("upload_preset", this.preset);
      this.formData.append("tags", this.tags); // Optional - add tag for image admin in Cloudinary
      this.formData.append("file", this.file);
    },

    upload() {
      this.$root.$emit("loading", true);

      //let reader = new FileReader();
      // attach listener to be called when data from file

      // this.fileContents = reader.result;
      this.prepareFormData();
      let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/leadpatt/upload`;
      let requestObj = {
        url: cloudinaryUploadURL,
        method: "POST",
        data: this.formData
      };
      //show progress bar at beginning of post
      // this.showProgress = true;
      axios(requestObj)
        .then(response => {
          this.results = response.data;
          console.log(this.results);
          console.log("public_id", this.results.public_id);
          this.logotipo = this.results.secure_url;
          this.$root.$emit("loading", false);
        })
        .catch(error => {
          //this.errors.push(error);
          console.log(this.error);
          this.$root.$emit("loading", false);
        });
    },

    addMarker(map: google.maps.Map) {
      var myLatLng = new google.maps.LatLng(25.5444, -103.442);
      if (this.latitud != "" && this.longitud != "") {
        console.log(this.latitud);
        console.log(this.longitud);
        myLatLng = new google.maps.LatLng(this.latitud, this.longitud);
      }
      if (this.marker != null) {
        this.marker.setMap(null);
      }
      this.marker = new google.maps.Marker({
        position: myLatLng,
        map,
        title: "Ubicación"
      });
    },
    mapainit() {
      let map: google.maps.Map;

      map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: { lat: 25.5444, lng: -103.442 },
        zoom: 8
      });

      const input = document.getElementById("pac-input") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      let th = this;
      th.addMarker(map);
      google.maps.event.addListener(map, "click", event => {
        console.log(event.latLng);
        let data = event.latLng;
        th.latitud = data.lat();
        th.longitud = data.lng();
        th.addMarker(map);
      });
    },
    async eliminar(row) {
      this.$root.$emit("loading", true);
      let data = await this.ELIMINAR_SUCURSALES(row).then(response => {
        if (response != null) {
          this.municipiosdata = this.municipiosdata.filter(
            item => item.id !== row.id
          );
        } else {
          this.$root.$emit(
            "alerta_show",
            "Ocurrio un error al intentar eliminar sucursal, refresque la pagina"
          );
        }
        this.$root.$emit("loading", false);
      });
    },
    confirm(row) {
      let th = this;
      Notiflix.Confirm.Show(
        "Alerta de confirmacion",
        "Desea eliminar este elemento?",
        "Aceptar",
        "Cancelar",
        function() {
          th.eliminar(row);
        },
        function() {}
      );
    },
    limpiar() {
      this.id = null;
      this.idtema = "";

      this.numero = null;
      this.nombre = "";
      this.descripcion = "";
      this.latitud = "";
      this.longitud = "";
      this.contacto = "";

      this.logotipo = "";
    },
    addRow() {
      this.limpiar();
      $("#ac_modal_municipios").modal("show");
    },
    seteditar(row) {
      this.id = row.id;
      //this.idtema=row.idtema;

      this.numero = row.numero;
      this.nombre = row.nombre;
      // this.descripcion=row.descripcion;
      // this.latitud=row.latitud;
      //this.longitud=row.longitud;
      this.contacto = row.contacto;

      this.tipo = row.tipo;
      $("#ac_modal_municipios").modal("show");
      this.mapainit();

      const input = this.$refs.inputfile;
      input.type = "text";
      input.type = "file";
    },
    async crear(dat) {
      let data = await this.CREAR_SUCURSALES(dat).then(response => {
        if (response != null) {
          //this.plantas = response;
          console.log(response);

          this.municipiosdata.push(response);
        } else {
          this.$root.$emit(
            "alerta_show",
            "Ocurrio un error al intentar editar sucursal, refresque la pagina"
          );
        }
        this.$root.$emit("loading", false);
        //loading.close();
      });
    },
    async editar(dat) {
      let data = await this.EDITAR_SUCURSALES(dat).then(response => {
        if (response != null) {
          //this.plantas = response;
          console.log(response);
          this.$root.$emit("success", "Sucursal editada correctamente.");
          this.municipiosdata = this.municipiosdata.filter(
            item => item.id !== dat.id
          );
          this.municipiosdata.push(response);
        } else {
          this.$root.$emit(
            "alerta_show",
            "Ocurrio un error al intentar crear sucursal, refresque la pagina"
          );
        }
        this.$root.$emit("loading", false);
        //loading.close();
      });
    },
    async GuardarData() {
      let dat = {
        id: this.id,
        //idtema:this.idtema,

        numero: this.numero,
        nombre: this.nombre,
        // descripcion:this.descripcion,
        //latitud:this.latitud,
        //longitud:this.longitud,
        contacto: this.contacto,

        tipo: this.tipo
      };

      //this.$refs.inputFile.value = '';
      this.$refs.formm.reset();
      //this.$refs.inputFile.reset();
      this.$root.$emit("loading", true);
      if (this.id == null) {
        this.crear(dat);
      } else {
        this.editar(dat);
      }
    },
    async getData() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data = await this.GET_SUCURSALES().then(response => {
        if (response != null) {
          //this.plantas = response;
          this.municipiosdata = response;
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    }
  },
  mounted() {
    this.getData();
    //this.mapainit();
    //alert('10')
  }
})
export default class Home extends Vue {}
