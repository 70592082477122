
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from "vue-property-decorator";

//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import { email, required } from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import sucursales from '@/mixins/axios/sucursales'
@Component({
  components: {
  TablePPoa
  },
  data() {
    return {
      id:0,
      sucursalsel:'',
      sucursalesdata:[],
        //-----fechas
          datesel: this.actualformatDate(),
         datas:{
             start: this.getfisrtDay(),
               end: this.actualformatDate()
             },
             //-------fechas end
      addEditModal:false,
      periodosdata:[],
       columnasd: [
        {
          tag: "id",
          data: "id",
          active: false,
        },
         
        
        

        {
          tag: "fecha",
          data: "fecha_inicio",
          active: false,
        },
         {
          tag: "fecha",
          data: "fecha_fin",
          active: true,
        },
        {
          tag: "sucursalname",
          data: "sucursal",
          active: false,
        },
       
       
        {
          tag: "edit",
          data: "edit",
          active: false,
        },
        {
          tag: "delete",
          data: "delete",
          active: false,
        },
        
      ],
     dias:{1:"Lunes",2:"Martes",3:"Miercoles",4:"Jueves",5:"Viernes",6:"Sabado",0:"Domingo"}
    };
  },
  mixins: [usuarios,sucursales],
  validations: {
   
  },
  methods: {
    
     setMasOneDay(date_d) {
         
            var myPastDate = new Date(date_d);
            myPastDate.setDate(myPastDate.getDate() + 1)
            return myPastDate;
        },
          formatFullDate(date) {

            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear(),
                horas = "" +d.getHours(),
                minutos = "" +d.getMinutes();

            if (month.length < 2) month = "0" + month;
            if (month.length < 2) month = "0" + month;
            if (horas.length < 2) horas = "0" + horas;
            if (minutos.length < 2) minutos = "0" + minutos;

            return [year, month, day].join("-")+' '+[horas, minutos].join(":");
        },
 
       getfisrtDay() {
            var myCurrentDate = new Date();
            var myPastDate = new Date(myCurrentDate);
            myPastDate.setDate(myPastDate.getDate() - 7)
            return myPastDate;
        },
          async getSucursales() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data = await this.GET_SUCURSALES().then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.sucursalesdata = response;
         
            
          

          this.$root.$emit("loading", false);
        
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
    limpiar(){
  this.id=0;
      this.sucursalsel='';
 
        //-----fechas
          this.datesel= this.actualformatDate();
         this.datas={
             start: this.getfisrtDay(),
               end: this.actualformatDate()
             }
    },
       actualformatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
                horas = "" +d.getHours(),
                minutos = "" +d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  if (minutos.length < 2) minutos = "0" + minutos;

            return [year, month, day].join("-")+' '+[horas, minutos].join(":");
    },
       async seteditar(row) {
         console.log(row,'----sucursal----')
         this.addEditModal=true;
         this.id=row.id,
         this.datas.start=this.formatFullDate(row.fecha_inicio);
           this.datas.end=this.formatFullDate(row.fecha_fin);
           this.sucursalsel=row.sucursales_id;

       },
   addRow(){
     console.log('----agregar nuevo')
     this.limpiar();
this.addEditModal=true;
   },
   
    confirm(row) {
      let th = this;
      Notiflix.Confirm.Show(
        "Alerta de confirmacion",
        "Desea eliminar este elemento?",
        "Aceptar",
        "Cancelar",
        function () {
          th.eliminar(row);
        },
        function () {}
      );
    },
   
     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
    async eliminar(row){

  this.$root.$emit("loading", true);
      console.log("loaddding");
      let dat={
       id:row.id
      }
      let data = await this.ELIMINAR_ASIGNACIONEMPLEADO(dat).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.getData()
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
    
      async editAsignacion() {
    
    
     this.$root.$emit("loading", true);
      console.log("loaddding");
      let dat={
        id:this.id,
        user_id:this.$store.state.usuario_sel.id,
        sucursal_id:this.sucursalsel,
         fecha_inicio: this.formatFullDate(this.datas.start),
                fecha_fin: this.formatFullDate(this.datas.end)
      }
      let data = await this.EDIT_ASIGNACIONEMPLEADO(dat).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.getData()
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
  },
  async createAsignacion() {
    
    
     this.$root.$emit("loading", true);
      console.log("loaddding");
      let dat={
        user_id:this.$store.state.usuario_sel.id,
        sucursal_id:this.sucursalsel,
         fecha_inicio: this.formatFullDate(this.datas.start),
                fecha_fin: this.formatFullDate(this.datas.end)
      }
      let data = await this.CREATE_ASIGNACIONEMPLEADO(dat).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.limpiar();
this.addEditModal=false;
          this.getData()
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
  },
   
async getData() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let dat={
        id:this.$store.state.usuario_sel.id
      }
      let data = await this.GET_PERIODOSEMPLEADO(dat).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.periodosdata = response;
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
 
 
  
  },
  mounted() {
 this.getData() 
    
  this.getSucursales();
    //alert('10')
  },
})
export default class Home extends Vue {}
