
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import ordenes from '@/mixins/axios/ordenes'
//@ts-ignore
import general from '@/mixins/axios/general'

//@ts-ignore
import materias_primas from '@/mixins/axios/materias_primas'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import generales from '@/mixins/functions/generales'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {
            //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,

            columnasd: [{
                    tag: 'id',
                    data: 'id',
                    active: false
                },
                {
                    tag: 'sucursal',
                    data: 'sucursales',
                    active: false
                },
                {
                    tag: 'fecha',
                    data: 'created_at',
                    active: false
                },
                {
                    tag: 'total',
                    data: 'total',
                    class: 'bg-green-500 rounded-md text-black font-extrabold px-2 py-1',
                    active: false
                },
                {
                    tag: 'subtotal',
                    data: 'subtotal',
                    active: false
                },
                {
                    tag: 'envio',
                    data: 'costo_envio',
                    class: 'bg-yellow-500 rounded-md text-black font-extrabold px-2 py-1',
                    active: false
                },

                /* {
                     tag:'color',
                     data:'color',
                     active:true
                 },*/
                {
                    tag: 'status_actual',
                    data: 'status_orden',
                    active: false
                },
                {
                    tag: 'comprobante',
                    data: 'factura',
                    active: false
                },
                {
                    tag: 'status_pedido',
                    data: 'status_orden',
                    active: false
                },
                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },

            ],

            id: null as any,

            precio: '',

            nombre: '',
            subtotal: 0,
            envio:0,
            usuario_ped:'',
            created_at:'',
            openModal: false,
            openModalDp:false,
            txtbuscador:'',
            sucursales_sel:{nombre:''},
            status_orden: '',
            ComprobanteModal: false,
            ordenes_data: [],
            cambio: 0,
            comentario:'',
                        porpedido:false,
            materias_primas_data: [] as any,
            materias_primas_data_init: [] as any,

        }
    },
    mixins: [materias_primas, ordenes, generales,general],
    validations: {

        precio: {
            required,
        },

        nombre: {
            required,
        },

        comision: {
            required,
        },

        color: {
            required,
        },

        categorias_id: {
            required,
        },

    },
    methods: {
        hayCambios(){
let count=0;
            this.materias_primas_data.forEach(element => {

                
    if (element.hasOwnProperty('cantidad_surtir_inicial')&& element.cantidad_surtir!= element.cantidad_surtir_inicial) {
        console.log(element.cantidad_surtir,'-----',element.cantidad_surtir_inicial)
        count=count+1;
    }

   

});
if(count>0){
        console.log('--cambios')
        return true;
    }else{
        return false
    }
        },

        async onCapture() {
           
            let dat = {
                id: 'capture',
                scale:.6,
                landscape:true,
            }
            await this.SEND_HTML(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    
                } else {

                    this.$root.$emit("alerta_show", response.error);

                }
                this.$root.$emit("loading", false)
            });
            // var prnt = window.open('', '', 'height=500, width=500');
            //prnt.document.write(doc);
            //prnt.document.close();
            //prnt.print();

            //  window.print();
            return;
        },
        viewImg(row) {
            this.status_orden = row.status_orden;
            this.id = row.id;
            this.ComprobanteModal = true;
            this.factura = row.factura;
        },
        OpcionesPedido(row) {
            this.status_orden = row.status_orden;
            switch (row.status_orden) {
                case 'ACEPTADO':
                    this.id = row.id;
                    this.comprobanteModal = true;
                    break;
                case 'PAGADA':
                    this.viewImg(row);
                    break;
                    
                case 'EN PROCESO DE ENTREGA':
                this.id = row.id;
                    this.MarcarEntrega();
                    break;

                default:
              

                


                
                    break;
            }
        },
        async MarcarEntrega() {

            this.$root.$emit('loading', true);
            let dat = {
                id: this.id
            }
            await this.MARCAR_ENTREGA(dat).then(response => {

                if (response != null) {

                    this.$root.$emit('loading', false);
                    this.getData();
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar aceptar pago, refresque la pagina");
                    this.$root.$emit('loading', false);
                }

            });
        },
        async AceptarPago() {

            this.$root.$emit('loading', true);
            let dat = {
                id: this.id
            }
            await this.ACEPTAR_PAGO(dat).then(response => {

                if (response != null) {

                    this.id = null;
                    this.ComprobanteModal = false;
                    this.$root.$emit('loading', false);
                    this.getData();
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar aceptar pago, refresque la pagina");
                    this.$root.$emit('loading', false);
                }

            });
        },
        async RechazarPago() {

            this.$root.$emit('loading', true);
            let dat = {
                id: this.id
            }
            await this.RECHAZAR_PAGO(dat).then(response => {

                if (response != null) {

                    this.id = null;
                    this.ComprobanteModal = false;

                    this.$root.$emit('loading', false);
                    this.getData();

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar rechazar pago, refresque la pagina");
                    this.$root.$emit('loading', false);
                }

            });
        },
        Modificado() {
            var materias_modificadas = 0;
            this.materias_primas_data.forEach(element => {
                console.log(element, '---el--')
                if (element.cantidad_surtir > element.cantidad || element.cantidad_surtir < element.cantidad) {
                    console.log('---mayor o menos-----')
                    materias_modificadas = materias_modificadas + 1;
                }

            });
            return materias_modificadas > 0;
        },
        calcularSubTotal() {
            let total = 0;
            this.materias_primas_data.forEach(element => {
                if (element.cantidad_surtir > 0) {
                    total = Number(total) + (Number(element.cantidad_surtir) * Number(element.precio_venta))
                }

            });
            return total;
        },
        async getMaterias_primas() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_MATERIAS_PRIMAS().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.materias_primas_data = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        async eliminar(row) {

            this.$root.$emit('loading', true);
            let data = await this.ELIMINAR_ORDENES(row).then(response => {

                if (response != null) {

                    this.ordenes_data = this.ordenes_data.filter(item => item.id !== row.id);

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                }
                this.$root.$emit('loading', false);
            });
        },

        confirm(row) {

            let th = this;
            Notiflix.Confirm.Show('Alerta de confirmacion', 'Desea eliminar este elemento?', 'Aceptar', 'Cancelar', function () {

                th.eliminar(row);
            }, function () {

            });

        },
        limpiar() {
            this.id = null;

            this.precio = null;
            this.nombre = null;
            this.comision = null;
            this.tiempo = null;
            this.subtotal=0;
            this.envio=0;
            this.type = null;

        },
        addRow() {
            this.limpiar();
            this.openModal = true;
        },
        DetallePedido(row) {
            if (this.materias_primas_data.length < 1) {
                this.$root.$emit("alerta_show", "No se han cargado las materias primas");
                return
            }
            console.log(row, '----info---')
          
            this.id = row.id;
            this.precio = row.precio;
            this.subtotal = row.subtotal;
            this.envio = row.envio;
            this.sucursales_sel=row.sucursales;
            this.usuario_ped=row.usuario;
            this.created_at=row.created_at;
            this.nombre = row.nombre;
            this.color = row.color;
            this.comision = row.comision;

            this.tiempo = row.tiempo;
            this.categorias_id = row.categorias_id;

            let mt_det = row.mp_detalle_ordenes;

            this.materias_primas_data.forEach(element => {
                element.cantidad=0;
                element.cantidad_surtir=0;
                element.canticantidad_surtir_inicialdad=0;
                element.colores_data=[];
                mt_det.forEach(prod => {
                    if (prod.materias_primas_id == element.id) {
                        element.cantidad = parseInt(prod.cantidad_pedido);
                        if(row.status_orden=='EN REVISION'){
                            element.cantidad_surtir = parseInt(prod.cantidad_pedido);
                           
                        }else{
                            element.cantidad_surtir = parseInt(prod.cantidad_surtir);
                        
                        }element.colores_data = JSON.parse(prod.colores);
                      
                    }
                });

            });
           console.log(row,'------mm----')
          this.porpedido=true;
            this.openModalDp = true;

        },
        verfificarPedido(row) {
            if (this.materias_primas_data.length < 1) {
                this.$root.$emit("alerta_show", "No se han cargado las materias primas");
                return
            }
            console.log(row, '----info---')
            this.id = row.id;
            this.precio = row.precio;
            this.subtotal = row.subtotal;
            this.nombre = row.nombre;
            this.color = row.color;
            this.comision = row.comision;

            this.tiempo = row.tiempo;
            this.categorias_id = row.categorias_id;

            let mt_det = row.mp_detalle_ordenes;

            this.materias_primas_data.forEach(element => {
                element.cantidad=0;
                element.cantidad_surtir=0;
                element.canticantidad_surtir_inicialdad=0;
                element.colores_data=[];
                mt_det.forEach(prod => {
                    if (prod.materias_primas_id == element.id) {
                        element.cantidad = parseInt(prod.cantidad_pedido);
                        element.cantidad_surtir = parseInt(prod.cantidad_pedido);
                        element.cantidad_surtir_inicial = parseInt(prod.cantidad_pedido);
                        element.colores_data = JSON.parse(prod.colores);
                    }
                });

            });
            this.porpedido=true;
            this.openModal = true;

        },

        async GuardarAjustePedido(status) {
            let prod_sel = [];
            this.materias_primas_data.forEach(element => {
                if (Number(this.isNullTo0(element.cantidad_surtir)) > 0 || Number(this.isNullTo0(element.cantidad)) > 0) {
                    prod_sel.push(element)
                }

            });
            if (prod_sel.length == 0) {
                this.$root.$emit("alerta_show", "Es necesario agregar al menos un producto");
                return;
            }

            let dat = {
                id: this.id,
                productos: prod_sel,
                status:status,
                comentario:this.comentario,
            }
            let data = await this.GUARDAR_AJUSTE_ORDENES(dat).then(response => {

                if (response != null) {
                    this.openModal = false;
                    this.$root.$emit('loading', false);
                    this.getData();

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar guardar ajuste de orden, refresque la pagina");

                }
                this.$root.$emit('loading', false);

            });
        },
        async AceptarPedido() {
            let dat = {
                id: this.id
            }
            let data = await this.ACEPTAR_ORDENES(dat).then(response => {

                if (response != null) {
                    this.openModal = false;
                    this.$root.$emit('loading', false);
                    this.getData();

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar aceptar orden, refresque la pagina");

                }
                this.$root.$emit('loading', false);

            });
        },

        async GenerarPedido() {

            let prod_sel = [];
            this.materias_primas_data.forEach(element => {
                if (Number(this.isNullTo0(element.cantidad)) > 0) {
                    prod_sel.push(element)
                }

            });
            if (prod_sel.length == 0) {
                this.$root.$emit("alerta_show", "Es necesario agregar al menos un producto");
                return;
            }

            let dat = {
                productos: prod_sel
            }
            let data = await this.CREAR_ORDENES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear orden, refresque la pagina");

                }
                this.$root.$emit('loading', false);

            });
        },
        async crear(dat) {
            let data = await this.CREAR_ORDENES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)

                    this.ordenes_data.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar producto, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_productos_servicios').modal('hide')
                //loading.close();
            });

        },
        async editar(dat) {

            let data = await this.EDITAR_ORDENES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)
                    this.$root.$emit("success", "Prodcuto o Servicio modificado exitosamente, refresque la pagina");
                    this.ordenes_data = this.ordenes_data.filter(item => item.id !== dat.id);
                    this.ordenes_data.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear el producto, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_productos_servicios').modal('hide')
                //loading.close();
            });

        },
        async GuardarData() {

            console.log('id:' + this.id)

            let dat = {
                id: this.id,

                precio: this.precio,
                nombre: this.nombre,
                comision: this.comision,
                color: this.color,
                tiempo: this.tiempo,
                categorias_id: this.categorias_id,

            }
            console.log(dat)
            this.$root.$emit('loading', true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }

        },
        async getData() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_ORDENES().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.ordenes_data = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    mounted() {
        this.getData();
        this.getMaterias_primas();
        //alert('10')
    }
})
export default class Home extends Vue {}
