
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import PmdHeader from "@/components/headersSelect/pmd";
//@ts-ignore
import ListaPagosCobros from "@/components/pages/giftcards/ListaPagosCobros.vue";
//@ts-ignore
import ListaDetalleSucursal from "@/components/pages/giftcards/ListaDetalleSucursal.vue";

//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import ventagiftcard from "@/mixins/axios/ventagiftcards";
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import pagos_giftcards from "@/mixins/axios/pagos_giftcards";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'
//    protected $fillable = ['id','clientes_id', 'numero', 'activo','sucursales_id','id_empleada','cantidad','precio','metodo','created_at', 'updated_at'];
import {
    MonthPickerInput
} from 'vue-month-picker'
import ItemTemplate from "../templateselect.vue";
@Component({
    components: {
        TablePPoa,
        MonthPickerInput,
        ListaPagosCobros,
        ListaDetalleSucursal
    },
    data() {
        return {
            //----data cliente--------
            clientesdata: [],
            template: ItemTemplate,
            newItemCliente: "",
            //-----end data cliente
            cantidad: 0,
            sucursal_id: 0,
            sucursal_name: '',
            tipo: '',
            sucursalsel: this.$store.state.user.usuario.sucursales_id,
            sucursales: [],
            addGiftcard: false,
            listpagosCobros: [],
             listRecargasCobros: {},
            mes: {

                "month": this.getmes(),
                monthIndex: new Date().getMonth() + 1,
                year: 2022,
                selectedYear: 2022,
            },

            tarjetas: [],

        };
    },
    mixins: [giftcards, ventagiftcard, sucursales, clientes, pagos_giftcards],
    validations: {
        nombre: {
            required,
        },

        /* direccion: {
          required,
        },*/
        telefono: {
            required,
        },
    },
    methods: {
        clearList(){
this.listpagosCobros=[];
this.listRecargasCobros= {};
        },

        async getDetalleRecargasCobros(row) {
            this.$root.$emit("loading", true);

            let data_s = {
                id: row.id,
                mes: this.mes.monthIndex,
                ano: this.mes.year,

            };
            let data = await this.GET_GIFTCARDS_DETALLE_MES_SUCURSAL(data_s).then((response) => {
                if (response != null) {

                    //this.$root.$emit("alerta_show", "Se inserto");
                    //this.getData();
                    console.log(response)
                    this.listRecargasCobros=response;

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getPagosCobros(row, tipo) {
            this.$root.$emit("loading", true);

            let data_s = {
                id: row.id,
                mes: this.mes.monthIndex,
                ano: this.mes.year,
                tipo: tipo,

            };
            let data = await this.GET_PAGOS(data_s).then((response) => {
                if (response != null) {

                    //this.$root.$emit("alerta_show", "Se inserto");
                    //this.getData();
                    console.log(tipo)
                    console.log(this.tipo)
                    this.tipo = tipo;
                    console.log(this.tipo)
                    this.listpagosCobros = response;
                    // this.limpiar();

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async guardarPagoCobro() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            console.log(this.mes)
            let data_s = {
                sucursales_id: this.sucursal_id,
                mes: this.mes.monthIndex,
                ano: this.mes.year,
                tipo: this.tipo,
                cantidad: this.cantidad
            };
            let data = await this.POST_GUARDAR_PAGO_COBRO(data_s).then((response) => {
                if (response != null) {

                    //this.$root.$emit("alerta_show", "Se inserto");
                    this.getData();
                    this.limpiar();

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        showPagar(row, tipo) {
            this.sucursal_id = row.id;
            this.sucursal_name = row.nombre;
            this.cantidad = this.verficarPagos(row);
            this.cantidad = Number(String(this.cantidad).replace('-', ''));
            console.log(this.cantidad)
            this.tipo = tipo;
        },
        verficarPagos(data) {
            //verifica el monto que debe mediante el calculo de recargas y cobros y pagos realizados ya a la sucursal
            let cantidad = 0;
            //se optiene lo que se le debe de pagar
            cantidad = Number(data.cobrado) - Number(data.recargas)
            cantidad = Number(cantidad) - Number(data.pago_a_sucursal);
            cantidad = cantidad + Number(data.recibio_de_sucursal);
            return cantidad;

        },
        recargar($ev) {
            console.log($ev)
            this.mes = $ev;
            this.getData();
        },
        getmes() {
            const monthNames = ["January", "February", "March", "April", "May", "junio",
                "July", "August", "September", "October", "November", "December"
            ];

            const d = new Date();
            return monthNames[d.getMonth()];
        },
        randomArray(arr) {

            // get random index value
            const randomIndex = Math.floor(Math.random() * arr.length);

            // get random item
            const item = arr[randomIndex];

            return item;
        },
        async limpiar() {
            this.mes = {

                "month": this.getmes(),
                monthIndex: new Date().getMonth() + 1,
                year: 2022,
                selectedYear: 2022,
            }

            this.cantidad = 0,
                this.sucursal_id = 0,
                this.sucursal_name = ''
            this.tipo = ''
        },

        formatFullDate(date) {

            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },

        async getSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursalesdata = response;
                    if (this.$store.state.user.usuario.role == "admin") {
                        this.sucursalsel = response[0].id;
                    }

                    this.$root.$emit("loading", false);
                    this.getData()
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        SelectSucursalChange() {
            //aquiiiii

            this.getData()
            //console.log(,'----------------sel--change-------------')
        },

        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            console.log(this.mes)
            let data_s = {
                id: this.sucursalsel,
                mes: this.mes.monthIndex,
                ano: this.mes.year
            };
            let data = await this.GET_GIFTCARDS_MES_SUCURSAL(data_s).then((response) => {
                if (response != null) {

                    console.log(response)
                    this.sucursales = response

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });

        },

    },
    mounted() {
        this.getSucursales()

    },
})
export default class Home extends Vue {}
