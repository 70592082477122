<template>
    <div>
    
      <div class="w-full">
        <div class="text-xs border shadow h-10 w-full justify-center flex flex-wrap items-center content-center text-center uppercase"  @click="seleccionando = true" v-if="seleccionando!=
        true&&opcionSeleccionada!=null"><strong class="text-red-400">{{opcionSeleccionada.nombre}}</strong></div>
        <input 
        class="border shadow h-10 w-full"
          type="text"
          placeholder="Seleccionar opción..."
          v-model="filtro"
          @click="seleccionando = true"
        />
        <ul v-if="seleccionando" class="bg-white border shadow w-full   h-24 overflow-y-scroll w-full">
          <li
          class="text-xs border-b cursor-pointer"
            v-for="(opcion, index) in opcionesFiltradas"
            :key="index"
            @click="seleccionar(opcion)"
          >
            {{ opcion.nombre }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  
  export default {
    props: {
       
        opcionesData: {
            type: Array, // Assuming it's an array
            required: true,
        },
       
    },
    data() {
      return {
     
        filtro: '',
        seleccionando: false,
        opcionSeleccionada: null,
      };
    },
    computed: {
      opcionesFiltradas() {
        return this.opcionesData.filter(opcion =>{

      
        if(String(opcion.nombre).toUpperCase().indexOf(String(this.filtro.toUpperCase()))!=-1){
            console.log(String(opcion.nombre).toUpperCase().indexOf(String(this.filtro.toUpperCase())),'------ff--')
console.log(this.filtro.toUpperCase(),'---filro')
console.log(opcion.nombre.toUpperCase(),'---nombre')

return true;
        }else{
            return false;
        }
    })
      },
    },
    watch: {
      opcionSeleccionada(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update', newVal);
        }
      },
    },
    methods: {
      seleccionar(opcion) {
        this.opcionSeleccionada = opcion;
        this.seleccionando = false;
      },
    },
  };
  </script>
  