import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from "vue-axios";
import axios from "axios";

//import municipios from '@/datatablecolumns/municipios';
Vue.use(VueAxios, axios);
let host = (window.location.host).replace(':8080', '');
 host = (host).replace(':8082', '');
 host = (host).replace(':8081', '');
//let subdomain=process.env.VUE_APP_API_URL;
let subdomain=process.env.VITE_APP_URL_API;
if(host=='localhost'){
//subdomain='http://127.0.0.1:8000/api/origen/luna27/';
subdomain='http://luna27.lndo.site/api/';

//subdomain='http://localhost:51592/api/';
//subdomain = 'https://api-popcing-luna27.herokuapp.com/api/';
}else{
    subdomain=process.env.VITE_APP_URL_API;
    subdomain ='https://api-popcing-luna27.herokuapp.com/api/'
}

//subdomain = 'https://api-popcing-luna27.herokuapp.com/api/';
Vue.use(Vuex)
//const base="https://api-popcing-agenda.herokuapp.com/api/";
const base=subdomain;
export default new Vuex.Store({
  state: {
  //baseapi:"https://api-popcing-agenda.herokuapp.com/api/",
  baseapi:subdomain,
  //base:"http://127.0.0.1:8000",
  //base:"https://api-popcing-agenda.herokuapp.com",
  
    //app_name:'korabe',
   app_name:'Sistema|Agenda',
    user:{
      role:'admin'
    },
    info:null,
    client_tag:'',
    edit:0,
    print:{},
    data_cliente:{
      datosgenerales:{
        nombre: "",
        apodo: "",
        edad: "",
        domicilio: "",
        estado: "",
        codigo_postal: "",
        genero: "",
        fecha_nacimiento: null,
        lugar_nacimiento: "",
        peso: "",
        ocupacion: "",
        recomendo: "",
        email: "",
        celular: "",
        telparticular: "",
        celular2: "",
        medio_contacto: "",
        mejorhorario: "",
        motivo_visita: ""
      },
      habitosdesalud:{
        especifique:'',
        fecha_consulta:null,
        motivo:'',
        positivo_visita:'',
        mejora_visita:'',
        salud_boca:'',
      },
      antecedentesmedicos:{
        complicaciones:'',
        alergicolatex:'',
        consimiomedicamento:'',
        estaembarazada:'',
      },
      servicios:{
        factura:'',
        legalmentees:'',
        metodopago:'',
        fecha:null,
        termino:'',
      },
      firma:null

    },



    sucursal:
    {},
    arbol_problema:null,
    cliente_sel:null,
    empleado_sel:null,
    backclStyle:{
      
      version1:{
            backgroundColor:"rgb(0 146 63 / 48%)", 
            },
            version2:{
            backgroundColor:"rgb(6 51 142 / 52%)" 
            },
            version3:{
            backgroundColor:"rgb(191 25 6 / 42%)" 
            }
            },
    backStyle:{
      
      version1:{
            backgroundColor:"#00923f", 
            },
            version2:{
            backgroundColor:"#06338e" 
            },
            version3:{
            backgroundColor:"#bf1906" 
            }
            },
             colorStyle:{
      pri:{
            color:"#00923f", 
            },
             pan:{
            color:"#06338e" 
            },
             morena:{
            color:"#bf1906" 
            }
            },
    datatables:{
      //municipios:new municipios()
    },
    secretaria_sel:null,
    usuario_sel:null,
  

    menus:{
     

      
    },

            
          
    
  },
  mutations: {
    initialiseStore(state,store:any) {
			// Check if the ID exists
		
				this.replaceState(
					Object.assign(state, store)
				);
			
    },
    SETCLIENT_TAG: (state, info:any) =>
    {state.client_tag = info;},
    SETINFO: (state, info:any) =>
    {state.info = info;},
    SETPRINT: (state, data:any) =>
    {state.print = data;},
    SETBASEAPI: (state, data:any) =>
    {state.baseapi = data;},
    SETEDIT: (state, data:any) =>
    {state.edit = data;},
    SETDATA_CLIENTE: (state, data:any) =>
    {state.data_cliente = data;},
    SETEMPLEADO_SEL: (state, data:any) =>
    {state.empleado_sel = data;},
    SETCLIENTE_SEL: (state, data:any) =>
    {state.cliente_sel = data;},
      SETPROBLEMA: (state, data:any) =>
    {state.arbol_problema = data;},
      SETUSER: (state, usuario:any) =>
    {state.user = usuario;},
    SETUSERSUCURSAL: (state, usuario:any) =>
    {state.sucursal = usuario;},
    
       SETSECRETARIA: (state, data:any) =>
    {state.secretaria_sel = data;},



    SETUSUARIO: (state, usuario:any) =>
    {state.usuario_sel = usuario;},
    
  },
  actions: {

    LOGIN: async function(context, data) {

     

      
      // url: base+'origen/'+data.client_tag+"/auth/login" ,
      var result =  await axios
      
        ({
          method: 'POST',
          url: base+'auth/login',
          responseType: 'json',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              "Content-Type": "application/json",

            },data: {

              email:data.usuario,
              password:data.contrasena,
              lat:data.lat,
              lng:data.lng,
              version_os:data.version_os,
              nombre_os:data.nombre_os,
              marca_os:data.marca_os,
              version_cliente:data.version_cliente,
              nombre_cliente:data.nombre_cliente,
              tipo:data.tipo,
              ip:data.ip,
              ciudad: data.ciudad,
              estado: data.estado,
              country: data.country,
              codigo_postal: data.codigo_postal,
              proveedor: data.proveedor,
              latlng_ip: data.latlng_ip,
             
            
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    
  },
  modules: {
  }
})