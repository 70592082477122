
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from "vue-property-decorator";

//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import producto_servicios from "@/mixins/axios/producto_servicios";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import { email, required } from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import sucursales from '@/mixins/axios/sucursales'
@Component({
  components: {
  
  },
  data() {
    return {
     comisiones:[],
     productos:[],
     producto:0,
       comision:0,
       usuarios:[]
    };
  },
  mixins: [usuarios,sucursales,producto_servicios],
  validations: {
   
  },
  methods: {

    async getProductos(){

this.$root.$emit("loading", true);
      console.log("loaddding");
     
      let dataa = await this.GET_PRODUCTOS_SERVICIOS().then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.productos = response;
          //this.$root.$emit("success", "Comisiones guardadas excitosamente");
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });

    },
   async Guardar(){

      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data1={
        id:this.producto,
        data:this.usuarios,
        comision:this.comision
      }
      let dataa = await this.GUARDAR_USER_COMISIONES_COMISIONES(data1).then((response) => {
        if (response != null) {
          //this.plantas = response;
          //this.comisiones = response;
          this.$root.$emit("success", "Comisiones guardadas excitosamente");
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });

    },
   
    confirm(row) {
      let th = this;
      Notiflix.Confirm.Show(
        "Alerta de confirmacion",
        "Desea eliminar este elemento?",
        "Aceptar",
        "Cancelar",
        function () {
          th.eliminar(row);
        },
        function () {}
      );
    },
    limpiar() {
      this.id = null;

      this.role = "";
      this.password_plano = "";
      this.name = "";
      this.email = "";
       this.idmunicipio = 0;
    },
   
   

 
    async getData() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data1={
        id:this.producto
      }
      let data = await this.GET_USER_PRODUCTO_COMISIONES(data1).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.usuarios = response;
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
  },
  mounted() {
    //this.getData();
    this.getProductos();
  
    //alert('10')
  },
})
export default class Home extends Vue {}
