
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      

      GET_CORTES_DIA: async function(context) {
        var result =  await axios
            ({
              method: 'GET',
              url: this.$store.state.baseapi+"cortes/" ,
              responseType: 'json',
              headers: {
            
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' +this.$store.state.user.token
    
              },
                
              }
              
            )
            .then(
              response => {
             
                //context.commit('SET_USER_DEALS', { deals: response.data.data })
                return response.data;
              },
              err => {
                
                return null;
              }
            );
            return result;
          },








          GENERAR_CORTE_DIA: async function( data) {



      console.log(data)
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"cortes_dia/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:{
            id:data.id,
            dia:data.dia,
            actual:data.actual,
            total:data.total
             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            console.log(err)
            return err.data;
          }
        );
        return result;





    },





    EDITAR_CORTES: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"cortes/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:{
            //idtema:data.idtema,
           
             numero:data.numero,
             nombre:data.nombre,
            // descripcion:data.descripcion, 
            // latitud:data.latitud, 
            // longitud:data.longitud, 
             contacto:data.contacto, 
            
            tipo:data.tipo,
          
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_CORTES: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"cortes/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }