
//@ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component(
  {
   data() {
    return {
    
      
       
      }
},

   
  

  computed: {
   
  },
  methods:{
getColor(){
  if(this.$route.query.tipo_g=='cantidad'){
    return this.$store.state.info.color_dinero;
  }
  return this.$store.state.info.color_servicio;
}
  ,
  getColor_Texto(){
  if(this.$route.query.tipo_g=='cantidad'){
    return this.$store.state.info.texto_color_dinero;
  }
  return this.$store.state.info.texto_color_servicio;
}
},
mounted(){
  
},
 
  created () {}
})
 export default class HelloWorld extends Vue {
  @Prop() private initcodigo!:string;
  @Prop() private codigo!:string;
  
   @Prop() private nombre!:string;
   @Prop() private total!:string;
  
   

}
