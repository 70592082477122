
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import categorias_sucursales from '@/mixins/axios/categorias_sucursales'
//@ts-ignore
import sucursales from "@/mixins/axios/sucursales";
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {

            columnasd: [

                {
                    tag: 'fecha',
                    data: 'startDate',
                    active: true
                },
                {
                    tag: 'productService',
                    data: '',
                    active: true
                },
                {
                    tag: 'idempleada',
                    data: 'empleada',
                    active: true
                },

                {
                    tag: 'precio',
                    data: 'precio',
                    active: true
                },
                {
                    tag: 'descuentop',
                    data: 'pdescuento',
                    active: true
                },
                {
                    tag: 'propina',
                    data: 'propina',
                    active: true
                },
                {
                    tag: 'giftcard',
                    data: 'giftcards',
                    active: true
                },

                {
                    tag: 'productos',
                    data: 'detalle_cobros2',
                    active: true
                },
                {
                    tag: 'metodos',
                    data: 'metodos',
                    active: true
                },
                {
                    tag: 'sucursal',
                    data: 'sucursales',
                    active: true
                },

                /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/

            ],

            id: null as any,
            dialog:false,
            categorias:[],
            sucursales:[],
            sel_categoria:null,
            sel_sucursal:null,
            categorias_sel:[],
            categorias_user:[],

            clientesdata: [] as any,

        }
    },
    mixins: [clientes, categorias_sucursales, sucursales],
    validations: {

        nombre: {
            required,
        },
        influencer: {
            required,
        },
        descuento: {
            required,
        },

        /* direccion: {
      required,
    },*/
        telefono: {
            required,
        },

    },
    methods: {
        alerta(text){
            alert(text)
        },
    
        async eliminarCategoriaSucursal(id){

            
            this.$root.$emit("loading", true);
            
            let dat={
                
                id:id
            }
            console.log(dat)
            let data = await this.ELIMINAR_CATEGORIAS_SUCURSALES_USUARIO(dat).then(response => {
                if (response != null) {
                    //this.plantas = response;
                    this.getDataUsuarioCategoriasSucursales();
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar categoria a sucursal, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async seleccionarSucursal(){
            if(this.sel_categoria==null){
               this.$root.$emit("alerta_show", "No ha seleccionado categoria");
               
                return;
            }
 this.$root.$emit("loading", true);
            console.log("loaddding -- -- sel categoria",this.categorias_sel);
            let dat={
                categoria:this.sel_categoria.id,
                id:this.$store.state.usuario_sel.id
            }
            console.log(dat)
            let data = await this.CREATE_CATEGORIAS_SUCURSALES_USUARIO(dat).then(response => {
                if (response != null) {
                    //this.plantas = response;
                    this.getDataUsuarioCategoriasSucursales();
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar asignar categoria a sucursal, refresque la pagina");
                }
                this.$root.$emit("loading", false);
                this.dialog=false;

                //loading.close();
            });
        },
      async getCategoriasSucursal() {

             this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_CATEGORIAS_SUCURSALES().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.categorias = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        
        async getDataUsuarioCategoriasSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
             let dat={
                
                id:this.$store.state.usuario_sel.id
            }
            let data = await this.GET_CATEGORIAS_SUCURSALES_USUARIO(dat).then(response => {
                if (response != null) {
                    //this.plantas = response;
                    this.categorias_user = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
async getDataSucursales() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SUCURSALES().then(response => {
                if (response != null) {
                    //this.plantas = response;
                    this.sucursales = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        async getData() {

            //this.$root.$emit('loading', true);
            console.log('loaddding')

            let dat = {
                id: this.$store.state.usuario_sel.id
            }

            let data = await this.GET_HISTORIAL_CLIENTES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    mounted() {
        //SETCLIENTE_SEL
        console.log(this.$store.state.cliente_sel, '----------sel-----------')
        this.getCategoriasSucursal();
        this.getData();
        this.getDataSucursales();
        this.getDataUsuarioCategoriasSucursales();
        //alert('10')
    }
})
export default class Home extends Vue {}
