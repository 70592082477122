
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import producto_servicios from '@/mixins/axios/productos'

//@ts-ignore
import categorias from '@/mixins/axios/categorias'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {
            //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,

            columnasd: [{
                    tag: 'id',
                    data: 'id',
                    active: false
                },

                {
                    tag: 'nombre',
                    data: 'nombre',
                    active: true
                },

                {
                    tag: 'precio',
                    data: 'precio',
                    active: true
                },

                {
                    tag: 'pcomision',
                    data: 'comision',
                    active: true
                },

                {
                    tag: 'categoria',
                    data: 'categoria',
                    active: true
                },
                /* {
                     tag:'color',
                     data:'color',
                     active:true
                 },*/
                {
                    tag: 'edit',
                    data: 'edit',
                    active: false
                },
                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },

            ],

            id: null as any,

            precio: '',

            nombre: '',

            comision: 0 as any,
            color: '#1CA085',

            tiempo: 0 as any,

            categorias_id: 0 as any,

            producto_serviciosdata: [] as any,
            categoriasdata: [] as any,
            ComisionesData: [

                {
                    id: 5,
                    nombre: '15%'

                },
                {
                    id: 10,
                    nombre: '20%'

                },
                {
                    id: 15,
                    nombre: '15%'

                },
                {
                    id: 20,
                    nombre: '20%'

                },
                {
                    id: 25,
                    nombre: '15%'

                },
                {
                    id: 30,
                    nombre: '20%'

                },
                {
                    id: 35,
                    nombre: '15%'

                },
                {
                    id: 20,
                    nombre: '20%'

                }

            ],
            tiempoData: [

                {
                    id: 15,
                    nombre: '15 minutos'

                },
                {
                    id: 20,
                    nombre: '20 minutos'

                },
                {
                    id: 25,
                    nombre: '25 minutos'

                },
                {
                    id: 30,
                    nombre: '30 minutos'

                },
                {
                    id: 45,
                    nombre: '45 minutos'

                },
                {
                    id: 60,
                    nombre: '1 hora'

                },
                {
                    id: 75,
                    nombre: '1 hora 15 minutos'

                },
                {
                    id: 90,
                    nombre: '1 hora 30 minutos'

                },
                {
                    id: 120,
                    nombre: '2 horas'

                }
            ]

        }
    },
    mixins: [producto_servicios, categorias],
    validations: {

        precio: {
            required,
        },

        nombre: {
            required,
        },

        comision: {
            required,
        },

        color: {
            required,
        },

        categorias_id: {
            required,
        },

    },
    methods: {

        async getData_Categorias() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_CATEGORIAS_CHILD().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.categoriasdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        async eliminar(row) {

            this.$root.$emit('loading', true);
            let data = await this.ELIMINAR_PRODUCTOS(row).then(response => {

                if (response != null) {

                    this.producto_serviciosdata = this.producto_serviciosdata.filter(item => item.id !== row.id);

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                }
                this.$root.$emit('loading', false);
            });
        },

        confirm(row) {

            let th = this;
            Notiflix.Confirm.Show('Alerta de confirmacion', 'Desea eliminar este elemento?', 'Aceptar', 'Cancelar', function () {

                th.eliminar(row);
            }, function () {

            });

        },
        limpiar() {
            this.id = null;

            this.precio = null;
            this.nombre = null;
            this.comision = null;
            this.tiempo = null;

            this.type = null;

        },
        addRow() {
            this.limpiar();
            $('#ac_modal_productos_servicios').modal('show')
        },
        seteditar(row) {

            this.id = row.id;
            this.precio = row.precio;

            this.nombre = row.nombre;
            this.color = row.color;
            this.comision = row.comision;

            this.tiempo = row.tiempo;
            this.categorias_id = row.categorias_id;

            $('#ac_modal_productos_servicios').modal('show')

        },
        async crear(dat) {
            let data = await this.CREAR_PRODUCTOS(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)

                    this.producto_serviciosdata.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar producto, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_productos_servicios').modal('hide')
                //loading.close();
            });

        },
        async editar(dat) {

            let data = await this.EDITAR_PRODUCTOS(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)
                    this.$root.$emit("success", "Prodcuto o Servicio modificado exitosamente, refresque la pagina");
                    this.producto_serviciosdata = this.producto_serviciosdata.filter(item => item.id !== dat.id);
                    this.producto_serviciosdata.push(response)
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear el producto, refresque la pagina");

                }
                this.$root.$emit('loading', false);
                $('#ac_modal_productos_servicios').modal('hide')
                //loading.close();
            });

        },
        async GuardarData() {

            console.log('id:' + this.id)

            let dat = {
                id: this.id,

                precio: this.precio,
                nombre: this.nombre,
                comision: this.comision,
                color: this.color,
                tiempo: this.tiempo,
                categorias_id: this.categorias_id,

            }
            console.log(dat)
            this.$root.$emit('loading', true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }

        },
        async getData() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_PRODUCTOS().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.producto_serviciosdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    mounted() {
        this.getData();
        this.getData_Categorias();
        //alert('10')
    }
})
export default class Home extends Vue {}
