var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-inner no-page-title"},[_c('div',{staticClass:"w-full flex flex-wrap px-2 py-1"},[_c('v-date-picker',{staticClass:"mr-1",attrs:{"color":"yellow","masks":{
        input: 'YYYY-MM-DD',
      },"model-config":{
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },"mode":"date","is-range":""},on:{"input":_vm.selectdate},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents, isDragging }){return [_c('div',{staticClass:"flex flex-wrap justify-start items-center"},[_c('div',{staticClass:"relative flex-grow"},[_c('svg',{staticClass:"text-gray-600 w-4 h-full mx-2 absolute pointer-events-none",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"}})]),_c('input',_vm._g({staticClass:"flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full",class:isDragging ? 'text-gray-600' : 'text-gray-900',domProps:{"value":inputValue.start}},inputEvents.start))]),_c('div',{staticClass:"relative flex-grow"},[_c('svg',{staticClass:"text-gray-600 w-4 h-full mx-2 absolute pointer-events-none",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"}})]),_c('input',_vm._g({staticClass:"flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full",class:isDragging ? 'text-gray-600' : 'text-gray-900',domProps:{"value":inputValue.end}},inputEvents.end))])])]}}]),model:{value:(_vm.datesel),callback:function ($$v) {_vm.datesel=$$v},expression:"datesel"}})],1),_c('div',{staticClass:"w-full h-full justify-center content-center flex flex-wrap"},[_c('div',{staticClass:"row h-full"},[_c('div',{staticClass:"w-full bg-white"},[_c('div',{staticClass:"flex flex-col h-full"},[_c('div',{staticClass:"grid grid-flow-col overflow-x-scroll px-2"},[_vm._l((_vm.cobrosdia),function(row,key,index){return [(
                  _vm.$store.state.user.usuario.role == 'admin' ||
                  _vm.$store.state.user.usuario.sucursales_id == row.sucursal.id
                )?_c('div',{key:index,staticClass:"bg-white",class:index == 0 ? ' col-span-4 ' : ' w-full '},[_c('table',{staticClass:"table rounded w-full"},[_c('thead',[_c('tr',{staticClass:"font-bold text-xs capitalize",class:row.sucursal.nombre == 'general-c'
                          ? 'bg-purple-200'
                          : 'bg-green-200'},[(
                          (index == 0 &&
                            _vm.$store.state.user.usuario.role == 'admin') ||
                          _vm.$store.state.user.usuario.sucursales_id ==
                            row.sucursal.id
                        )?_c('td',{staticClass:"capitalize",attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.es.p016)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row.sucursal.nombre == 'general-c' ? _vm.es.p017 : row.sucursal.nombre)+" ")])])]),_c('tbody',_vm._l((row.data),function(row_n,index_n){return _c('tr',{key:index_n,staticClass:"font-bold text-xs capitalize"},[(
                          (index == 0 &&
                            _vm.$store.state.user.usuario.role == 'admin') ||
                          _vm.$store.state.user.usuario.sucursales_id ==
                            row.sucursal.id
                        )?_c('td',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(row_n.producto)+" ")]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(row_n.cantidad)+" ")])])}),0),_c('tfoot',[_c('tr',{staticClass:"font-bold text-xs capitalize",class:row.sucursal.nombre == 'general-c'
                          ? 'bg-purple-200'
                          : 'bg-green-200'},[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_c('span',{staticClass:"px-2 py-1 rounded-full",class:row.sucursal.nombre == 'general-c'
                              ? 'bg-purple-400'
                              : 'bg-green-400'},[_vm._v(" "+_vm._s(_vm.calcTotales(row.data))+" ")])])])])])]):_vm._e()]})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }