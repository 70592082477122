
//@ts-nocheck

//@ts-ignore
import clientes from '@/mixins/axios/clientes'
//@ts-ignore
import tablero from "@/mixins/axios/tablero";
//@ts-ignore
import notificaciones from "@/mixins/axios/notificaciones";
import {
    Component,
    Vue
} from "vue-property-decorator";

import palabras from "@/mixins/palabras";
@Component({
    components: {},
    mixins: [palabras, notificaciones, clientes],
    mounted() {
        this.getData(1);
        //this.mapainit();
    },
    data() {
        return {
            refrescar: 1,
            buscar: '',
            clientesdata: {data:[]},
            tablero1: {},
            gannancias: 0,
            numero: 5216181485736,
            titulo: '',
            mensaje: '',
            tipo: 'whatsappchatapi',
            imagen: 'https://res.cloudinary.com/dgzea9m7q/image/upload/v1649652401/software/logo.6b1c9aa7.jpg',
            template: 1
        };
    },
    methods: {
        isback(){
            if(this.clientesdata.hasOwnProperty('current_page')){
return this.clientesdata.current_page==1?false:true
            }else{
                return false
            }
        },
        isNext(){
   if(this.clientesdata.hasOwnProperty('current_page')){
return this.clientesdata.current_page<this.clientesdata.last_page?true:false
            }else{
                return false
            }
        },
        async MarcarTodos() {

            await this.clientesdata.data.forEach(row => {
                if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                    row.selected = 1;
                } else {

                }
            });
            this.refrescar = this.refrescar + 1;
            console.log('--buscador---')

        },
        buscador(row) {
            if (String(row.telefono).toUpperCase().search(String(this.buscar).toUpperCase()) != -1 || (String(row.nombre)).toUpperCase().search(String(this.buscar).toUpperCase()) != -1) {
                return true;
            } else {
                return false;
            }
        },
        selectedNames() {
            return this.clientesdata.data
                .filter(cliente => cliente.selected)
                .map(cliente => cliente.id);
        },

        async getData(page) {

            this.$root.$emit('loading', true);
            console.log('loaddding')
            let dat = {
                page: page
            }
            let data = await this.GET_CLIENTES_ACTIVOS(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

        async enviarmensaje() {
            this.$root.$emit("loading", true);
            let dat = {
                numeros: this.selectedNames(),
                mensaje: this.mensaje,
                tipo: this.tipo,
                template: this.template,
                titulo: this.titulo,
                imagen: this.imagen
            }
            let data = await this.CREAR_NOTIFICACION(dat).then((response) => {
                if (response != null && !response.hasOwnProperty('msg')) {
                    //this.plantas = response;
                    let correctos = 0;
                    let fallidos = 0;
                    response.respuestas.forEach(element => {
                        if (element.sent) {
                            correctos = correctos + 1
                        } else {
                            fallidos = fallidos + 1
                        }
                    });
                    this.$root.$emit("success", `Correctos (${correctos}), Fallidos (${fallidos})`);

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
})
export default class Home extends Vue {}
