import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
//@ts-ignore
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate)
// all modules
//@ts-ignore
import Notiflix from "notiflix";
Vue.use(Notiflix);
import PortalVue from "portal-vue";
Vue.use(PortalVue);
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
//@ts-ignore
import overBody from 'vue-over-body'
Vue.component('vue-over-body', overBody)
Vue.component('v-select', vSelect)
//@ts-ignore
import 'vue-event-calendar/dist/style.css' //1.1.10之后的版本，css被放在了单独的文件中，方便替换
//@ts-ignore
import vueEventCalendar from 'vue-event-calendar'
Vue.use(vueEventCalendar, {locale: 'es'}) //可以设置语言，支持中文和英文
import VSwatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.css"
Vue.component('v-swatches',VSwatches)

import VueFontAwesomePicker from "vfa-picker";
Vue.use(VueFontAwesomePicker);
import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css';
const VueClockPickerPlugin = require('@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.plugin.js')
Vue.use(VueClockPickerPlugin)

Vue.component('vue-timepicker',VueTimepicker)
import VueTimepicker from 'vue2-timepicker'
/*import VueClockPicker from 'vue-clock-picker'
Vue.component('vue-clock-picker2',VueClockPicker)*/
// CSS

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.component('vue-timepicker',VueTimepicker)


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.config.ignoredElements = [/^ion-/]
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import spanArrayJson from '@/components/spanArrayJson.vue';
import ViewHistorialGiftcard from "@/components/ViewHistorialGiftcard.vue";
import cardCitaCalendario from "@/components/calendario/cardCitaCalendarioHover.vue";
import loadingInComponents from "@/components/loadingInComponents.vue";
Vue.component('LInComponents', loadingInComponents);
Vue.component('spanArrayJson', spanArrayJson);
Vue.component('ViewHistorialGiftcard', ViewHistorialGiftcard);
Vue.component('cardCitaCalendar', cardCitaCalendario);



import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

Vue.use(VueDatePicker);

// Options: You can set lang by default
Vue.use(VueDatePicker, {
  lang: 'es'
});

Vue.use(Buefy)
import Vuetify from 'vuetify'
// index.js or main.js
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
Vue.use(Vuetify)

import VueCal from 'vue-cal'

//import 'vue-cal/dist/i18n/es.js'
import 'vue-cal/dist/vuecal.css'
Vue.component('vue-cal', VueCal)
import Autocomplete from 'v-autocomplete'

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-autocomplete/dist/v-autocomplete.css'

Vue.use(Autocomplete)
import VueClipboard from 'vue-clipboard2'
import VueFullscreen from 'vue-fullscreen'

Vue.use(VueFullscreen)
Vue.use(VueClipboard)
Vue.component('calendar', Calendar)
Vue.component('v-date-picker', DatePicker)
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import device from 'vue-device-detector-js'

import { LMap, LTileLayer, LMarker,LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.use(MonthPicker)
Vue.use(device)
Vue.use(MonthPickerInput)
new Vue({
  router,
  store,
  
  vuetify: new Vuetify(),
  //@ts-ignore
  render: h => h(App)
}).$mount('#app')
