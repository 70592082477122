
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';


//@ts-ignore
import influencers from '@/mixins/axios/influencers'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'


@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
        datesel: {
                start: this.getfisrtDay(),
                end: this.getendDay()
            },
  
        columnasd:[
             {
                tag:'id',
                data:'id',
                active:false
            },
            
            
           
            {
                tag:'nombre',
                data:'nombre',
                active:true
            },
          {
                tag:'descuentop',
                data:'descuento',
                active:true
            },
             {
                tag:'recomendados',
                data:'recomendados',
                active:true
            },
            
             
             {
                tag:'edit',
                data:'edit',
                active:false
            },
            {
                tag:'delete',
                data:'delete',
                active:false
            },
             /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/
      
             
            
           
        ],




        id:null as any,

      telefono:'' as any,
      descuento:'' as any,
        direccion:'' as any,
           nombre:'' as any,
           notas:'' as any,
          
     
         
         
              clientesdata:[] as any,
      
    }
  },
     mixins: [influencers],
     validations: {
    
           nombre: {
      required,
    },
     descuento: {
      required,
    },
 
      /* direccion: {
      required,
    }, telefono: {
      required,
    },*/

   
  },
  methods:{
        getfisrtDay() {
            var myCurrentDate = new Date();
            var myPastDate = new Date(myCurrentDate);
            myPastDate.setDate(myPastDate.getDate() - 7)
            return myPastDate;
        },
         getendDay() {
            var myCurrentDate = new Date();
            var myPastDate = new Date(myCurrentDate);
            myPastDate.setDate(myPastDate.getDate() +1);

            let month = "" + (myPastDate.getMonth() + 1);
       let  day = "" + myPastDate.getDate();
      let   year = myPastDate.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
            
        },

     actualformatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatFullDateend(date) {
console.log(date,'-------------------------')
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = Number(d.getDate()) ,
                year = d.getFullYear();
//console.log(day,'-------------------------')
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
//console.log([year, month, day].join("-"),'-------------------------')
            return [year, month, day].join("-");
        },
    formatFullDate(date) {
console.log(date,'-------------------------')
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = Number(d.getDate()) ,
                year = d.getFullYear();
//console.log(day,'-------------------------')
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
//console.log([year, month, day].join("-"),'-------------------------')
            return [year, month, day].join("-");
        },
      async eliminar(row){
         
this.$root.$emit('loading', true);
let data= await this.ELIMINAR_INFLUENCERS(row).then(response => {

                    if (response != null) {

 this.clientesdata=this.clientesdata.filter(item => item.id !== row.id);
 
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar influencer, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                });
      },
 
     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
      limpiar(){
this.id=null;
 
        
     
                 this.descuento=0;


            this.nombre=null;


        
        
    
           
      },
      addRow(){
          this.limpiar();
 $('#ac_modal_indicador_area').modal('show')
      },
seteditar(row){
 

 this.id=row.id;
 this.descuento=row.descuento;
 //this.direccion=row.direccion;
     this.nombre=row.nombre; 
  
          
 $('#ac_modal_indicador_area').modal('show')
    

},
     async crear(dat){
let data= await this.CREAR_INFLUENCERS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)

  this.clientesdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar influencer, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
  $('#ac_modal_indicador_area').modal('hide')
                    //loading.close();
                });

      },
     async editar(dat){


let data= await this.EDITAR_INFLUENCERS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)
this.$root.$emit("success", "influencer modificado exitosamente, refresque la pagina");
  this.clientesdata=this.clientesdata.filter(item => item.id !== dat.id);
 this.clientesdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar influencer, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
  $('#ac_modal_indicador_area').modal('hide')
                    //loading.close();
                });


      }
     ,
     async GuardarData(){



console.log('id:'+this.id)

let dat={
  id:this.id,
     nombre:this.nombre,
           descuento:this.descuento,
            
               // direccion:this.direccion,
                
}
console.log(dat)
 this.$root.$emit('loading', true);
 if(this.id==null){
     this.crear(dat);
 }else{
       this.editar(dat);
 }

      },
      selectdate(val) {
            console.log(val, '------------val----')
            //this.getData()
            this.getData()
        },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')


let dat={
   
      inicio: this.formatFullDate(this.datesel.start),
     fin: this.datesel.end
}
let data= await this.GET_INFLUENCERS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.clientesdata=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      this.getData();
      //alert('10')
  }
})
export default class Home extends Vue {}

