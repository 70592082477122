import { render, staticRenderFns } from "./usuarios_municipio.vue?vue&type=template&id=68965150&"
import script from "./usuarios_municipio.vue?vue&type=script&lang=ts&"
export * from "./usuarios_municipio.vue?vue&type=script&lang=ts&"
import style0 from "./usuarios_municipio.vue?vue&type=style&index=0&id=68965150&prod&scope=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports