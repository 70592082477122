
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from "vue-property-decorator";
import L from 'leaflet';
//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import {
    email,
    required
} from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import bloqueos from '@/mixins/axios/bloqueos'
//@ts-ignore
import palabras from "@/mixins/palabras";
@Component({
    components: {
        /*
        Vuetable,
        VuetablePagination,*/
        TablePPoa,
    },
    data() {
        return {
            tipos: [{
                text: 'Bloquear posicion',
                id: 'distancia_bloquear'
            }, {
                text: 'Aceptar posicion',
                id: 'distancia_permitir'
            }
            , {
                text: 'Bloqueo por horas',
                id: 'bloqueo_hora'
            }],
            columnasd: [{
                    tag: "id",
                    data: "id",
                    active: false,
                },

                {
                    tag: "tipo",
                    data: "tipo",
                    active: false,
                },
                {
                    tag: "data_bloqueo",
                    data: "data_bloqueo",
                    active: false,
                },
                {
                    tag: "edit",
                    data: "edit",
                    active: false,
                },
                {
                    tag: "delete",
                    data: "delete",
                    active: false,
                },

            ],

            // 'name', 'email', 'idtema','password','role','active','password_plano','modifico','id_municipio'
            id: null as any,
            tipo: "distancia_permitir",
            lat: "",
            lng: "",
            role: "",
            horainicio: '20:00',
            horafin: '23:59',

            bloqueosdata: [],
            icon: L.icon({
                iconUrl: 'https://img.icons8.com/external-xnimrodx-blue-xnimrodx/64/undefined/external-pointer-location-xnimrodx-blue-xnimrodx.png',
                iconSize: [37, 37],
                iconAnchor: [16, 16]
            }),
            center: {
                lat: 25.674894,
                lng: -100.315822
            },
            markerLatLng: {
                lat: 25.674894,
                lng: -100.315822
            },
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

        };
    },
    mixins: [bloqueos, palabras],
  
    methods: {
        addMarker(event) {
            console.log(event.latlng)
            this.markerLatLng = event.latlng;
        },
        asignacionpermisos(row) {
            console.log("suauriooo-------", row)
            this.$store.commit('SETUSUARIO', row);
            this.$router.push("/su/usuarios/asignacion")
        },

        async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_BLOQUEOS(row).then((response) => {
                if (response != null) {
                    this.bloqueosdata = this.bloqueosdata.filter(
                        (item) => item.id !== row.id
                    );
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar bloqueo, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },
        confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Desea eliminar este elemento?",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },
        limpiar() {
            this.id = null;

            this.latlng = "";
            this.tipo = "distancia_permitir";
        },
        addRow() {
            this.limpiar();
            $("#ac_bloqueos").modal("show");
        },
        seteditar(row) {
            this.id = row.id;
            this.latlng = row.latlng;
            this.tipo = row.tipo;
            this.horainicio=row.horainicio;
            this.horafin=row.horafin;

            $("#ac_bloqueos").modal("show");
        },
        async crear(dat) {
            let data = await this.CREAR_BLOQUEOS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.getData();
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear bloqueo, refresque la pagina"
                    );
                }
                $("#ac_bloqueos").modal("hide");
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        async editar(dat) {
            let data = await this.EDITAR_BLOQUEOS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    thi.getData();
                    this.$root.$emit(
                        "success",
                        "Bloqueo modificado correctamente."
                    );

                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear bloqueo, refresque la pagina"
                    );
                }
                $("#ac_bloqueos").modal("hide");
                this.$root.$emit("loading", false);
                //loading.close();
            });
        },
        async GuardarData() {

            console.log("id:" + this.id);
            if (this.markerLatLng.lat == '') {
                this.$root.$emit("alerta_show", "Es necesario un seleccionar latitud, refresque la pagina");
                return false
            }
            if (this.markerLatLng.lng == '') {
                this.$root.$emit("alerta_show", "Es necesario un seleccionar longitud, refresque la pagina");
                return false;
            }
            let dat = {
                id: this.id,
                latlng: this.markerLatLng.lat + "," + this.markerLatLng.lng,
                tipo: this.tipo,
                horainicio:this.horainicio,
                horafin:this.horafin

            };
            console.log(dat);
            this.$root.$emit("loading", true);
            if (this.id == null) {
                console.log('crear')
                this.crear(dat);
            } else {
                console.log('editar')
                this.editar(dat);
            }
        },

        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_BLOQUEOS().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.bloqueosdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
    mounted() {
        this.getData();

        //alert('10')
    },
})
export default class Home extends Vue {}
