<template>
<div @click.self="CerrarModal" class=" h-full  items-center  bg-black bg-opacity-20  w-2/6 margin-0 p-0">

    <div v-if="ConfirmMultiple" @click.self="CerrarModal" class="w-full h-full bg-black bg-opacity-20 z-30 fixed top-0 left-0 justify-center content-center  flex flex-wrap">

        <div class="w-80 h-48 bg-white self-center justify-center flex-flex-wrap">
            <div class="w-full border-b bg-gray-100 bg-opacity-10 hover:bg-opacity-20 h-12 px-2 py-2 text-center tetx-blue font-bold">
                Cita creada correctamente,

            </div>

            <div class="w-full  bg-gray-100 bg-opacity-10 hover:bg-opacity-20  px-2 py-2 text-center text-sm text-gray-500 font-bold">¿Desea crear otra cita para el mismo usuario?</div>
            <div @click="FnconfirmMultiple()" class="w-full  bg-gray-100 bg-opacity-10 hover:bg-opacity-20 py-8  px-2 py-2 text-center text-sm text-white font-bold flex flex-wrap justify-between content-center">
                <div class="flex items-center p-2  w-4/12 mx-2 h-full bg-gray-400 rounded-lg shadow-xs cursor-pointer hover:bg-gray-800 hover:text-gray-100">

                    <div class="flex flex-wrap justify-center content-center h-full">
                        <p class=" text-xs font-medium m-0 p-0 self-center ">
                            SI
                        </p>

                    </div>
                </div>
                <div @click="CerrarModal" class="flex items-center p-2 mx-2 w-4/12 h-full bg-yellow-200 rounded-lg shadow-xs cursor-pointer hover:bg-yellow-500 hover:text-gray-100">

                    <div class="flex flex-wrap justify-center content-center h-full">
                        <p class=" text-xs font-medium m-0 p-0 self-center text-black">
                            NO
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="cursor-auto flex-1   w-full  h-full  rounded-lg relative overflow-hidden bg-gray-100 px-2">
        <div style="padding-top:68px" class="cursor-auto flex-1     max-w-full min-w-4/6  rounded-lg relative overflow-hidden bg-gray-100 h-full">

            <div class="flex flex-wrap  h-full   w-full space-y-1 py-3 overflow-y-scroll">
                <div class="flex flex-col ml-4   sticky top-0 ">

                    <div class="text-gray-400 text-2xl font-extrabold ">{{ objectVenta.id==0?'Crear':'Editar' }} Cita 🖐</div>

                </div>
                <div class="z-10 space-y-1 w-full px-3 overflow-y-scroll h-full">
                    <div v-if="this.sucursalchangesel.id==0">
                        <div class="text-xs text-black font-bold flex flex-wrap">

                            <!--<span v-if="cliente!=null && cliente!=''" @click="selCliente(cliente)" class="text-xs text-blue-600 font-bold mb-2 mt-2">Ver Historial</span>-->
                            <div class="w-auto m-0 p-0 text-base">Cliente</div>
                            <ModalHistorialCliente class="px-2" v-if="cliente!=null && cliente!=''" :Cliente="cliente"></ModalHistorialCliente>
                            <ModalCrearCliente class="px-2"></ModalCrearCliente>
                            <span v-if="verifiedNote()" class="text-red-500 text-xs font-bold w-full flex flex-wrap"> {{cliente.notas}}</span><br v-if="verifiedNote()">

                            <!--<span v-on:click="showCreateClient" style="cursor:pointer" class="text-success underline text-xs w-full text-start">Nuevo Cliente</span>-->
                        </div>

                        <v-autocomplete class="w-full rounded h-12 bg-gray-100 border-2 border-radius text-center" id="clienteinput" :min-len="0" :cache-items="false" :items="clientesdata" v-model="cliente" :get-label="getLabelCliente" :component-item='template_cliente' @update-items="onSearch">
                        </v-autocomplete>
                        <!--
                        <span v-if="newItemCliente!=null && newItemCliente!=''" @click="selCliente(newItemCliente)" class="text-xs text-blue-600 font-bold mb-2 mt-2">Ver Historial</span>
                        <span v-if="verifiedNote()" class="text-red-500 text-xs font-bold w-full flex flex-wrap"> {{newItemCliente.notas}}</span><br v-if="verifiedNote()">
-->

                    </div>
                    <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md py-2 flex   shadow-sm  flex flex-wrap w-full">
                        <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md w-6/12">
                            <div class="text-xs text-black ml-3  font-bold">Sucursal</div>
                            <div class="w-full px-4 flex flex-wrap justify-center content-center mb-2 mt-1">

                                <select class="custom-select custom-select-sm w-full input-sm" id="sucursal_sel" v-model="sucursalselchange" @change="SelectSucursalChange">
                                    <option v-for="(row,index) in sucursalesdata" :value="row.id" :selected="(row.id===sucursalselchange)">{{row.nombre}}</option>
                                </select>

                            </div>

                        </div>
                        <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md w-6/12">
                            <div class="text-xs text-black ml-3  font-bold">Empleado</div>
                            <div class="w-full px-4 flex flex-wrap justify-center content-center mb-2 mt-1">

                                <select class="custom-select custom-select-sm w-full" id="sucursal_sel" v-model="selEmpleadoSucursal">
                                    <option v-for="(row,index) in EmpleadosSucursal" :value="row.id" :selected="(row.id===selEmpleadoSucursal)">{{row.name}}</option>
                                </select>

                            </div>
                        </div>

                    </div>

                    <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex z-0 flex-col  shadow-sm ">
                        <div class="text-xs text-black ml-3  font-bold">Servicio</div>
                        <v-select class="w-full " style="background:white" :filter="fuseSearchProductos" :options="productosData" @input="SelectProduct" @change="SelectProduct" v-model="newItemProducto" :getOptionLabel="option => option.nombre">
                            <template #option="{ categoria, nombre }">
                                {{ nombre }}
                                <br>
                                <!--<cite>{{categoria }}</cite>-->
                            </template>
                        </v-select>

                    </div>

                    <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex z-0 flex-col  shadow-sm ">
                        <div class="text-xs text-black ml-3  font-bold">Nota</div>

                        <p class="text-xs text-green-500">Letras restantes: <span v-bind:class="{'text-danger': generateErr }">{{totalRemainCount}}</span></p>
                        <textarea class="form-control w-full" maxlength="50" v-on:keyup="liveCountDown" v-model="nota" placeholder=""></textarea>

                    </div>

                    <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex z-0 flex-col  shadow-sm ">
                        <div class="text-xs text-black ml-3 mt-1 font-bold">Dia</div>
                        <div class="inline-flex py-2 px-2 bg-gray-100">
                            <input v-model="fechastart" class=" w-full" type="date">

                        </div>

                    </div>
                    <div class="bg-white border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex flex-wrap  shadow-sm  w-full">

                        <div class="w-full flex flex-wrap justify-between">

                            <div v-if="timestart!=null" class="w-full flex flex-wrap justify-center content-center mr-2">

                                <div class="bg-gray-100 border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex flex-col  shadow-sm ">
                                    <div class="text-xs text-black ml-3   font-bold">Hora Inicio</div>

                                    <SelectorHora class="text-center" :initialTime="timestart" @update-time="handleTimeChange('start', $event)"></SelectorHora>

                                </div>

                            </div>
                          
                        </div>
                        <div class="w-full flex flex-wrap justify-between">

                           
                            <div v-if="timeend!=null"  class="w-full flex flex-wrap justify-center content-center">
                                <div class="bg-gray-100 border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex  flex-col  shadow-sm ">
                                    <div class="text-xs text-black ml-3  font-bold">Hora fin</div>

                                    <SelectorHora v-bind:key="timeend" class="text-center" :initialTime="timeend" @update-time="handleTimeChange('end', $event)"></SelectorHora>

                                </div>

                            </div>
                        </div>

                    </div>

                    <!--<div class="bg-white border-0 border-t-4 border-purple-600 rounded-md px-4 py-2 flex z-0 flex-col  shadow-md ml-3 mr-3">
                        <div class="text-xs text-black ml-3 mt-1 font-bold">Hora Inicio / Hora Fin </div>
                        <div v-if="timestart!=null" class="w-full flex flex-wrap justify-center content-center">

                            <SelectorHora :initialTime="timestart"  @time-changed="handleTimeChange('start', $event)"></SelectorHora>
                            <SelectorHora :initialTime="timeend"  @time-changed="handleTimeChange('end', $event)"></SelectorHora>
                                      
<b-clockpicker open-on-focus="true" auto-switch="true" append-to-body="true" size="is-small" mobile-native="true" position="is-top-right" class="w-6/12" v-model="timestart" :min-time="minTime" :max-time="maxTime" :type="'is-warning'" locale="es-MX" hour-format="12" icon="clock" @input="SelectInitTimeChangue"></b-clockpicker>
                        <b-clockpicker open-on-focus="true" auto-switch="true" append-to-body="true" size="is-small" mobile-native="true" position="is-top-right" class="w-6/12" v-model="timeend" :min-time="minTime" :max-time="maxTime" :type="'is-warning'" locale="es-MX" hour-format="12" icon="clock"></b-clockpicker>

                        </div>

                    </div>-->

                </div>
                <div class=" border-0  h-20 rounded-b-md flex flex-wrap w-full items-start   py-4  z-20 sticky bottom-0 bg-white">

                    <button type="button" class="w-6/12 flex bg-green-700 text-sm text-white font-semibold items-center justify-center ml-3 mr-3 px-4 py-2 rounded-full gap-2" @click="Guardar()">
                        <svg class="text-white w-5 h-5 -ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                        </svg>
                        Guardar cambios
                    </button>
                    <button type="button" class="w-4/12 flex bg-red-700  text-sm text-white font-semibold items-center justify-center ml-3 mr-3 px-4 py-2 rounded-full gap-2" @click="CerrarModal">

                        Cancelar
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
//@ts-ignore
import ventas from "@/mixins/axios/ventas";
//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import generales from "@/mixins/functions/generales"
import SelectorHora from "@/components/calendario/SelectorHora.vue";
import Fuse from "fuse.js";
import ItemTemplate from "@/views/admin/templateselect.vue";
import ModalCrearCliente from "@/components/calendario/ModalCrearCliente.vue";
import ModalHistorialCliente from "@/components/calendario/ModalHistorialCliente.vue";
export default {
    components: {
        SelectorHora,
        ModalHistorialCliente,
        ModalCrearCliente
    },
    mixins: [generales, ventas, clientes],
    props: {
        objectVenta: {
            type: Object,
            required: true,
        },
        productosData: {
            type: Array, // Assuming it's an array
            required: true,
        },
        sucursalesdata: {
            type: Array, // Assuming it's an array
            required: true,
        },
        EmpleadosSucursal: {
            type: Array, // Assuming it's an array
            required: true,
        },
    },
    data() {

        return {
            id: 0,
            editing: false,
            sucursalselchange: 0,
            sucursalchangesel: "",
            nota: "",
            newItemCliente: "",
            ConfirmMultiple:false,

            limitmaxCount: 50,
            totalRemainCount: 50,
            generateErr: false,
            fechastart: "",
            timestart: null,
            timeend: null,
            newItemProducto: {},
            productos_sel: [],
            selEmpleadoSucursal: "",
            // Rest of the data properties...
            cliente: "",
            clientesdata: [],
            template_cliente: ItemTemplate,
        };
    },
    mounted() {
        if (this.objectVenta) {

            this.editing = true;
            this.sucursalchangesel = this.objectVenta;
            this.fechastart = this.objectVenta.fecha_fin.split(" ")[0];
            this.timestart = this.objectVenta.fecha_inicio.split(" ")[1] //new Date(this.objectVenta.fecha_inicio);

            this.timeend = this.objectVenta.fecha_fin.split(" ")[1] //new Date(this.objectVenta.fecha_fin);

            if (this.objectVenta.id == 0) {
                this.sucursalselchange = this.objectVenta.sucursales_id;
                this.nota = '';

                this.newItemProducto = {}

                this.productos_sel = [];

                this.selEmpleadoSucursal = 0;
            } else {
                this.sucursalselchange = this.objectVenta.sucursales_id;
                this.nota = this.objectVenta.nota;
                this.newItemProducto = this.productosData.find(
                    (itemm) => itemm.id === this.objectVenta.detalle_ventas[0].producto_servicios_id
                );

                this.productos_sel = [{
                    nombre: this.objectVenta.detalle_ventas[0].producto_servicios.nombre,
                    id: this.objectVenta.detalle_ventas[0].producto_servicios.id,
                    precio: this.objectVenta.detalle_ventas[0].precio,
                    cantidad: this.objectVenta.detalle_ventas[0].cantidad,
                    duracion: this.objectVenta.detalle_ventas[0].producto_servicios.tiempo,
                }];

                this.selEmpleadoSucursal = this.objectVenta.idempleada;
            }
        }
    },
    methods: {
        FnconfirmMultiple() {
            this.selEmpleadoSucursal = 0;
            this.ConfirmMultiple = false;
        },
        SelectInitTime(producto) {
            this.timeend = null;
            console.log(this.fechastart + ' ' + this.timestart,'----fecha-----actual----')
            let data = this.addMinutes(new Date(this.fechastart + ' ' + this.timestart), producto.duracion);
console.log(data,'----fecha')
            data = data.split(" ");
            console.log(data[1],'----fecha - time-end')
            if (data.length > 0) {
                this.timeend = data[1]
            }

        },
        formatTime(time) {
            return time < 10 ? "0" + time : time;
        },

        addMinutes(date, minutes) {
            const data_set = new Date(date.getTime() + minutes * 60000);

            const year = data_set.getFullYear();
            const month = this.formatTime(data_set.getMonth() + 1);
            const day = this.formatTime(data_set.getDate());
            const hours = this.formatTime(data_set.getHours());
            const minutesFormatted = this.formatTime(data_set.getMinutes());
            const seconds = this.formatTime(data_set.getSeconds());

            return `${year}-${month}-${day} ${hours}:${minutesFormatted}:${seconds}`;
        },

        CerrarModal($event) {
            this.$emit("close-mv", false);
        },
        refreshCalendario($event) {
            this.$emit("refresh-calendario", true);
        },

        SelectSucursalChange($event) {
            this.$emit("cambio-sucursal", this.sucursalselchange);
        },
        fuseSearchProductos(options, search) {
            const fuse = new Fuse(options, {
                keys: ["nombre", "categoria"],
                shouldSort: true,
            });
            return search.length ?
                fuse.search(search).map(({
                    item
                }) => item) :
                fuse.list;
        },
        fuseSearchClientes(options, search) {
            const fuse = new Fuse(options, {
                keys: ["nombre", "telefono"],
                shouldSort: true,
            });
            return fuse.list;

        },
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["name", "name", "email"],
                shouldSort: true,
            });
            return search.length ?
                fuse.search(search).map(({
                    item
                }) => item) :
                fuse.list;
        },

        SelectProduct(val) {
            console.log(val, '------------prpducto-sel');
            if (val == null) {
                this.productos_sel = [];
            } else {
                this.productos_sel = [{
                    duracion:val.tiempo,
                    nombre: val.nombre,
                    id: val.id,
                    precio: val.precio,
                    cantidad: 1,
                }];
                this.SelectInitTime(this.productos_sel[0]);
            }

            //this.SelectInitTime({target:{value:this.newItemStartTime}})
            //this.indicador_administradordata=this.indicador_administradordata.filter(item => item.id !== row.id);
        },
        handleTimeChange(key, newTime) {
            console.log(newTime, '---hora--')
            switch (key) {
                case 'start':
                    this.timestart = newTime;
                    console.log(this.productos_sel[0], '---prod--',this.productos_sel.length)
                    if (this.productos_sel.length==1) {
                        console.log(this.productos_sel[0],'-----------------end time-------------')
                        this.SelectInitTime(this.productos_sel[0]);
                    }

                    break;
                case 'end':
                    this.timeend = newTime

                    break;

                default:
                    break;
            }

        },
        liveCountDown: function () {
            this.totalRemainCount = this.limitmaxCount - this.nota.length;
            this.generateErr = this.totalRemainCount < 0;
        },

        verifiedNote() {
            if (this.cliente == null) {
                return false;
            }
            if (this.cliente == 0) {
                return false;
            } else {
                if (this.cliente.notas == null) {
                    return false;
                }

                if (this.cliente.notas == "") {
                    return false;
                } else {
                    return true;
                }
            }
        },
        Guardar() {

            if (this.sucursalchangesel.id == 0) {
                if (this.cliente == "" || this.cliente == null) {
                    this.$root.$emit(
                        "alerta_show",
                        "Es necesario selecionar un cliente"
                    );
                    return;
                }
                if(this.selEmpleadoSucursal==0){
                    this.$root.$emit(
                        "alerta_show",
                        "Es necesario selecionar un empleado"
                    );
                    return;
                }

                let dat = {
                    sucursal: this.sucursalselchange,
                    productos: this.productos_sel,
                    idempleada: this.selEmpleadoSucursal,
                    clientes_id: this.cliente.id,
                    nota: this.nota,
                    fecha_inicio: this.fechastart + " " + this.timestart,
                    fecha_fin: this.fechastart + " " + this.timeend
                };
                if (dat.fechainicio > dat.fechafin) {
                    this.$root.$emit(
                        "alerta_show",
                        "Error en fechas, La hora de inicio no puede ser mayor a la hora fin "
                    );
                    this.$root.$emit("loading", false);
                    return;
                }
               
                console.log(dat, '----crear--')
                //return;
                this.CrearCita(dat);
            } else {
                let dat = {
                    id: this.sucursalchangesel.id,
                    productos: this.productos_sel,
                    sucursal: this.sucursalselchange,
                    idempleada: this.selEmpleadoSucursal,
                    nota: this.nota,
                    fechainicio: this.fechastart + " " + this.timestart,
                    fechafin: this.fechastart + " " + this.timeend

                    // direccion:this.direccion,
                };
                if (dat.fechainicio > dat.fechafin) {
                    this.$root.$emit(
                        "alerta_show",
                        "Error en fechas, La hora de inicio no puede ser mayor a la hora fin "
                    );
                    this.$root.$emit("loading", false);
                    return;
                }
                if(this.selEmpleadoSucursal==0){
                    this.$root.$emit(
                        "alerta_show",
                        "Es necesario selecionar un empleado"
                    );
                    return;
                }
                
                this.EditarCita(dat);
            }

        },
        async CrearCita(dat) {

            this.$root.$emit("loading", true);

            let data = await this.CREAR_VENTAS(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        this.refreshCalendario()
                        
                        //this.CerrarModal();
                        this.ConfirmMultiple=true;
                        //this.showchangue = false;
                        // 	$('#cambiarSucursales').modal('hide')
                    }

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
            console.log("cambiar");
        },
        async EditarCita(dat) {

            this.$root.$emit("loading", true);

            let data = await this.EDITAR_SUCURSAL(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        this.refreshCalendario()
                        this.CerrarModal();
                        //this.showchangue = false;
                        // 	$('#cambiarSucursales').modal('hide')
                    }

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
            console.log("cambiar");
        },

        submitForm() {
            // Form submission logic, handle create/update
        },
        getLabelCliente(item) {
            if (item != null) {
                return item.nombre + "-" + item.telefono;
            } else {
                return "";
            }
        },

        async onSearch(search) {
            console.log(search, '---search')
            if (search.length) {
                this.getClientes(search);
            } else {
                this.clientesdata = [];
            }
            // loading(false);
            //this.search(loading, search, this);
        },
        async getClientes(text) {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                text: text,
            };
            let data = await this.GET_CLIENTES2(dat).then((response) => {
                console.log(response, '-clientes 22--')
                if (response != null) {
                    console.log(response, '-clientes--')

                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
    },
};
</script>

<style scoped>
/* Your component styles here */
</style>
