
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';


//@ts-ignore
import gastos from '@/mixins/axios/gastos'

//@ts-ignore
import categorias from '@/mixins/axios/categorias'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'


@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
        //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,
  
        columnasd:[
             {
                tag:'id',
                data:'id',
                active:false
            },
            
           
            {
                tag:'nota',
                data:'nota',
                active:true
            },
             
            {
                tag:'cantidad',
                data:'cantidad',
                active:true
            },
             
           {
                tag:'edit',
                data:'edit',
                active:false
            },
              {
                tag:'delete',
                data:'delete',
                active:false
            },
            
           
        ],



        id:null as any,

      precio:'' as any,
     
      nombre:'' as any,
       cantidad:'' as any,
     
      nota:'' as any,
      
         
              gastos_data:[] as any,
      comision:0 as any,
       color:'#1CA085' as any,
      
      tiempo:0 as any,
      
      categorias_id:0 as any,
     
         
              categoriasdata:[] as any,
                ComisionesData:[

                  {
                      id:5,
                      nombre:'15%'

                  },
                  {
                      id:10,
                      nombre:'20%'
                      
                  },
                  {
                      id:15,
                      nombre:'15%'

                  },
                  {
                      id:20,
                      nombre:'20%'
                      
                  },
                   {
                      id:25,
                      nombre:'15%'

                  },
                  {
                      id:30,
                      nombre:'20%'
                      
                  },
                  {
                      id:35,
                      nombre:'15%'

                  },
                  {
                      id:20,
                      nombre:'20%'
                      
                  }
                  
                  ],
              tiempoData:[

                  {
                      id:15,
                      nombre:'15 minutos'

                  },
                  {
                      id:20,
                      nombre:'20 minutos'
                      
                  },
                  {
                      id:25,
                      nombre:'25 minutos'
                      
                  },
                  {
                      id:30,
                      nombre:'30 minutos'
                      
                  },
                  {
                      id:45,
                      nombre:'45 minutos'
                      
                  },
                  {
                      id:60,
                      nombre:'1 hora'
                      
                  },
                    {
                      id:75,
                      nombre:'1 hora 15 minutos'
                      
                  }
                  ,
                    {
                      id:90,
                      nombre:'1 hora 30 minutos'
                      
                  }
                  ,
                    {
                      id:120,
                      nombre:'2 horas'
                      
                  }
              ]
      
    }
  },
     mixins: [gastos,categorias],
     validations: {
    
           cantidad: {
      required,
    },
 
           nota: {
      required,
    },
 
     
     
     
     
     
     
     

   
  },
  methods:{
   
      async eliminar(row){
         
this.$root.$emit('loading', true);
let data= await this.ELIMINAR_GASTOS(row).then(response => {

                    if (response != null) {

 this.gastos_data=this.gastos_data.filter(item => item.id !== row.id);
 
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                });
      },

     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
      limpiar(){
this.id=null;
 
        
                 
                 this.cantidad=null;
           this.nota=null;
                    
           
      },
      addRow(){
          this.limpiar();
 $('#ac_modal_productos_servicios').modal('show')
      },
seteditar(row){



 this.id=row.id;
 this.cantidad=row.cantidad;

 this.nota=row.nota;
            
          
 $('#ac_modal_productos_servicios').modal('show')
    

},
     async crear(dat){
let data= await this.CREAR_GASTOS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)

  this.gastos_data.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar producto, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
 $('#ac_modal_productos_servicios').modal('hide')
                    //loading.close();
                });

      },
     async editar(dat){


let data= await this.EDITAR_GASTOS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)
this.$root.$emit("success", "Prodcuto o Servicio modificado exitosamente, refresque la pagina");
  this.gastos_data=this.gastos_data.filter(item => item.id !== dat.id);
 this.gastos_data.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear el producto, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
 $('#ac_modal_productos_servicios').modal('hide')
                    //loading.close();
                });


      }
     ,
     async GuardarData(){



console.log('id:'+this.id)

let dat={
  id:this.id,
    
           cantidad:this.cantidad,
           nota:this.nota,
            
    
        

}
console.log(dat)
 this.$root.$emit('loading', true);
 if(this.id==null){
     this.crear(dat);
 }else{
       this.editar(dat);
 }

      },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')



let data= await this.GET_GASTOS().then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.gastos_data=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      this.getData();
      
      //alert('10')
  }
})
export default class Home extends Vue {}

