
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
  data() {
    return {
      es: {
        p001: 'EMPLEADO CON MÁS PROPINAS DEL DÍA',
        p002: 'TOTAL SERVICIOS DEL DÍA',
        p003: 'GANANCIAS DEL DÍA',
        p004: 'ASIGNACIÓN SUCURSAL',
        p005: 'Gastos del día',
        p006: 'Cobro espontáneo',
        p007: 'Cuentas por cobrar',
        p008: 'Cobros servicios',
        p009: 'Productos del día',
        p010: 'Tarjeta crédito',
        p011: 'Tarjeta débito',
        p012: 'Página actual',
        p013: 'MÉTODOS',

        p014: 'NÓMINA',
        p015: 'Lista de ventas de el día seleccionado',

        p016: 'Lista de servicios realizados',
        p017: 'Servicios vendidos generales',
        p018: '',
        pmd: 'pmd',
        general: 'GENERAL',
        usuarios: 'USUARIOS',
        dashboard: 'TABLERO GENERAL',
        dash_graficas: 'GRAFICAS ESTADISTICAS',

        caja: 'CAJA',
        tablero: 'ESTADISTICAS',
        nomina: 'NÓMINA',
        servicios: 'SERVICIOS',
        productos: 'PRODUCTOS',
        historial: 'HISTORIAL',
        gastos: 'GASTOS',
        graficas: 'GRÁFICAS',
        influencers: 'INFLUENCERS',
        tarjetas: 'ADMIN GIFTCARDS',
        tarjetasControl: 'GIFTCARDS CUENTAS',
        promociones: 'PROMOCIONES',
        cobros: 'COBROS',
        analitica_giftcards: 'ANALITICA GIFTCARDS',
        admin_certificados:'ADMIN CERTIFICADOS',
        admin_reutilizar_giftcards:'REUTILIZAR GIFTCARDS',
        historialServicios: 'HISTORIAL SERVICIOS',
        Administradorcom: 'COMISIONES',
        sucursales: 'SUCURSALES',
        usuarios_sucursal: 'USUARIOS SUCURSAL',
        procutos_servicios: 'SERVICIOS',
        clientes: 'CLIENTES',
        ventas: 'VENTA',
        categorias: 'CATEGORÍAS',
        calendario: 'CALENDARIO',
        categorias_sucursales:'CATEGORIAS DE SUCURSALES',
          tablero_master:'TABLERO GENERAL',
          usuarios_master:'USUARIOS',
          log_master:'HISTORIAL SISTEMA',
          accesos_master:'USUARIOS LOGUEADOS',
          bloqueos:'BLOQUEOS DE ACCESO',
          marketing:'MENSAJES MASIVOS',
          pedidos:'PEDIDOS',
          materia_prima:'MATERIA PRIMA',
          ordenes:'ORDENES DE COMPRA',
          configuraciones:'CONFIGURACIONES',
          preview_giftcard:'PREVIEW GIFTCARD',
        table: {
          servicios_cobrados:'Servicios',
          num_venta_gif:'NUMERO VENTA',
          descuento_gift:'DESCUENTO',
          telefono_venta:'TELEFONO',
          correo_venta:'CORREO',
          monto_propina:'PROPINA',
          tipo_log:'ACCION',
          sku:'SKU',
          envio:'ENVÍO',
          ver_perido:'VER PEDIDO',
          online:'ONLINE',
          imagen:'IMAGEN',
          comprobante:'COMPROBANTE',
          subtotal:'SUBTOTAL',
          total:'TOTAL',
          verificar_pedido:'VERIFICAR PEDIDO',
          tabla_log:'SECCION',
          text_log:'MENSAJE',
          status_actual:'ESTATUS ORDEN',
          status_pedido:'ESTATUS',
          status_entrega:'ESTATUS',
          data_bloqueo:'Datos Bloqueo',
          latlng:'Cordenadas',
          user:'Usuario',
          user_rel:'Usuario de acceso',
          cliente_software:'Navegador',
          os:'Sistema operativo',
          role_tipo:'ASIGNAR CATEGORIAS DE SUCURSAL',
          asignacionsucursal: 'ASIGNACIÓN SUCURSAL',
          graficas: 'GRÁFICAS',
          productos: 'PRODUCTOS',
          sucursalname: 'SUCURSAL',
          sucursal: 'SUCURSAL',
          metodos: 'MÉTODOS',
          giftcard: 'GIFTCARD',
          recomendados: 'RECOMENDADOS',
          nota: 'NOTA',
          cantidad: 'CANTIDAD',
          historial: 'HISTORIAL',
          productService: 'SERVICIO',
          generarpago: 'GENERAR PAGO',
          historialpagos: 'HISTORIAL PAGOS',
          add: 'CREAR',
          folio: 'FOLIO',
          delete: 'ELIMINAR',
          edit: 'EDITAR',
          select: 'SELECCIONAR',
          comisiones: 'COMISIONES',
          pcomision: 'PORCENTAJE COMISIÓN',
          horarios: 'HORARIOS',
          servicios: 'SERVICIOS',
          role: 'ROL',
          modifico: 'MODIFICO',
          influencer: 'INFLUENCER',
          municipio: 'MUNICIPIO',
          password_plano: 'PASSWORD',
          email: 'CORREO',
          id: '#',
          id_session:'# IDENTIFICADOR',
          idtema: 'TEMA',
          descripcion: 'DESCRIPCIÓN',
          monto: 'MONTO',
          latitud: 'LATITUD',
          longitud: 'LONGITUD',
          nombre: 'NOMBRE',
          numero: 'TELÉFONO',
          contacto: 'CONTACTO',
          logotipo: 'LOGOTIPO',

          icono: 'ICONO',
          color: 'COLOR',
          categoria: 'CATEGORÍA',
          sucusales: 'SUCURSALES',
          descuento: 'DESCUENTO',
          descuentop: 'DESCUENTO',


          //Poa_techo_capitulos
          capitulo: 'CAPÍTULO',
          importe: 'IMPORTE',
          estatus: 'ESTATUS',
          serie: 'SERIE',
          created_at: 'created_at',

          //Poa_series
          ano: 'AÑO',
          // estatus:'ESTATUS',//////////////

          //Poa Finalidades
          clave: 'CLAVE',
          finalidad: 'FINALIDAD',
          //estatus:'ESTATUS',

          //Poa_Clasifcicacion
          tipo_gasto: 'TIPO_GASTO',
          // estatus: 'ESTATUS',

          //Poa_Clasificacion_funcionalidad
          otro_tipo_gasto: 'otro_tipo_gasto',


          pagina_web: 'PÁGINA_WEB',
          //estatus:'ESTATUS',

          //General Secrre
          //nombre:'NOMBRE',
          fecha_alta: 'FECHA_ALTA',
          tipo: 'TIPO',
          total_empleados: 'TOTAL_EMPLEADOS',
          nombre_titular: 'NOMBRE_TITULAR',
          correo: 'CORREO',
          abreviacion: 'ABREVIACIÓN',
          //estatus :'ESTATUS', 

          //general informes resultados
          fecha: 'FECHA',
          nombre_documento: 'NOMBRE_DOCUMENTO',
          tipo_informe: 'TIPO_INFORME',
          orden: 'ORDEN',
          //ano:'AÑO', 
          //estatus:'ESTATUS',
          documento: 'DOCUMENTO',
          dimension: 'DIMENSIÓN',
          frecuencia: 'FRECUENCIA',
          mes: 'MES',
          num_mes: 'NUM_MES',
          fuente: 'FUENTE',
          //Departamentos
          NOMBRE: 'NOMBRE',
          plantas_id: 'PLANTAS_ID',
          active: 'ACTIVE',
          identificacion: 'IDENTIFICACIÓN',
          problema: 'PROBLEMA',
          efectos: 'EFECTOS',
          causas: 'CAUSAS',
          unidad_medida: 'UNIDAD MEDIDA',
          objetivo_indicador: 'OBJETIVO INDICADOR',
          variables_agregadas: 'VARIABLES AGREGADAS',
          formula: 'FÓRMULA',
          unidad_medida_resultado: 'UNIDAD MEDIDA RESULTADO',
          valor_min: 'VALOR MÍNIMO',
          valor_max: 'VALOR MÁXIMO',
          meta: 'META',
          aceptado: 'ACEPTADO',
          motivo: 'MOTIVO',
          abreviasion: 'ABREVIACIÓN',
          funciones: 'FUNCIONES',
          nombre_catalogo: 'NOMBRE',
          propina: 'PROPINA',

          comision: 'COMISIÓN',
          tiempo: 'TIEMPO',
          type: 'TYOPE',
          precio: 'PRECIO',
          telefono: 'TELÉFONO',
          direccion: 'DIRECCIÓN',
          sucursales: 'SUCURSALES',
          usuarios_sucursal: 'USUARIOS SUCURSAL',

          calendario: 'AGENDA',
          idvendedor: 'VENDEDOR',
          idempleada: 'EMPLEADA',
          clientes_id: 'CLIENTE',
          sucursales_id: 'SUCURSAL',
          fecha_inicio: 'FECHA INICIO',
          fecha_fin: 'FECHA FIN',





        },
        //----------------datos diago-------


      },
    }
  },

  created() {

  },


}