<template>
<div>
    <div @click.self="hiddenAddGiftcard()" class="min-h-screen w-full bg-gray-800 bg-opacity-40 flex items-center justify-center fixed top-0 left-0">
        <div class="bg-gray-100 w-full mx-4 p-4 rounded-xl md:w-1/2 lg:w-8/12">
            <!--MODAL HEADER-->
            <div class="flex justify-between items center border-b border-gray-200 py-3">
                <div class="flex items-center justify-center">
                    <p class="text-xl font-bold text-gray-800">Recarga de giftcard</p>
                </div>

                <div>
                    <div @click="hiddenAddGiftcard()" class="bg-gray-300 hover:bg-gray-500 cursor-pointer hover:text-gray-300 font-sans text-gray-500 w-8 h-8 flex items-center justify-center rounded-full">
                        x
                    </div>
                </div>
            </div>

            <!--MODAL BODY-->
            <div class="my-4">
                <div class="w-full bg-white h-full flex flex-wrap">
                    <div class="w-6/12 h-full px-4 py-4 flex flex-wrap">
                        <h1></h1>
                        <div class="flex flex-row w-full bg-yellow-200 px-2 py-2">
                            <div class="flex flex-col">
                                <span for="email" class="self-start px-2 font-extrabold text-gray-800">Numero</span>
                                <div class="input-group input-group-sm mb-3">
                                    <input type="text" v-model="numero" id="email" placeholder="Folio de giftcard" class="
                            form-control
                            outline-none
                            pl-2
                            py-2
                            border
                         bg-gray-50
                            placeholder-gray-500
                            opacity-70
                            rounded
                          " autocomplete="off" />
                                </div>
                            </div>
                            <div class="flex flex-col px-2">
                                <span for="pass" class="self-start px-2 font-extrabold text-gray-800">Cantidad</span>

                                <div class="input-group input-group-sm mb-3">
                                    <input type="number" v-model="cantidad" id="pass" placeholder="Saldo a depositar en la giftcard" class="
                            form-control
                            outline-none
                            pl-2
                            py-2
                            border
                         bg-gray-50
                            placeholder-gray-500
                            opacity-70
                            rounded
                          " autocomplete="off" />
                                </div>
                            </div>
                            <div class="flex flex-col px-2">
                                <span for="pass" class="self-start px-2 font-extrabold text-gray-800">Precio</span>

                                <div class="input-group input-group-sm mb-3">
                                    <input type="number" v-model="precio" id="pass" placeholder="Monto a cobrar" class="
                            form-control
                            outline-none
                            pl-2
                            py-2
                            border
                         bg-gray-50
                            placeholder-gray-500
                            opacity-70
                            opacity-50
                            rounded
                          " autocomplete="off" />
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center justify-between">
                            <button @click="agregar()" class="
                      mt-2
                      bg-yellow-500
                      hover:bg-yellow-700
                      text-white
                      font-bold
                      py-2
                      px-4
                      rounded
                      focus:outline-none focus:shadow-outline
                    " type="button">
                                Agregar
                            </button>
                        </div>
                        <table class="w-full h-3/6 bg-white mb-2">
                            <thead class="
                text-xs
                font-semibold
                uppercase
                text-gray-400
                bg-gray-50
              ">
                                <tr>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Numero</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Precio</div>
                                    </th>
                                    <th class="p-2 whitespace-nowrap">
                                        <div class="font-semibold text-left">Cantidad</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-sm divide-y divide-gray-100 overflow-y-scroll">
                                <tr v-for="(row, index) in tarjetas" v-bind:key="index" class="h-10">
                                    <td class="p-2 h-10">
                                        {{ row.numero }}
                                    </td>
                                    <td class="
                    p-2
                    h-10
                    text-left
                    font-medium
                    text-yellow-500
                    font-bold
                  ">
                                        ${{ row.precio }}
                                    </td>
                                    <td class="
                    p-2
                    h-10
                    text-lg text-left text-green-500
                    font-bold
                  ">
                                        $ {{ row.cantidad }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="w-6/12 bg-gray-100 h-full py-2 px-2">
                        <section class="antialiased text-gray-600 h-full">
                            <div class="flex h-full">
                                <!-- Table -->
                                <div class="w-full border-gray-200 h-full">
                                    <header class="px-5 py-4 border-b border-gray-100"></header>

                                    <div class="flex items-center justify-left px-2">
                                        <span class="text-black font-bold text-xs w-full">Cliente</span>
                                        <br>

                                    </div>
                                    <div class="flex flex-wrap justify-left px-2 mb-1 w-full">
                                        <v-autocomplete class="w-full border-0 text-green-500 m-0" id="clienteinput" :min-len="0" :cache-items="false" :items="clientesdata" v-model="newItemCliente" :get-label="getLabel" :component-item='template' @update-items="onSearch">
                                        </v-autocomplete>

                                    </div>

                                    <!--<div class="flex items-center justify-left px-2 ">
                                      <span class="text-black font-bold text-xs w-3/6">Metodo</span><span class="text-green-500 text-base w-full">
                                          <select class="form-control text-xs rounded-0 custom-select custom-select-sm  w-full" v-model="metodo">
                                              <option value="Efectivo">Efectivo</option>
                                              <option value="Tarjeta Credito">Tarjeta Crédito</option>
                                              <option value="Tarjeta Débito">Tarjeta Débito</option>
                                          </select>
                                      </span>
                                  </div>-->
                                    <div class="flex items-center justify-left px-2 mt-1">
                                        <span class="text-black font-bold text-xs w-3/6">Efectivo</span><span class="text-green-500 text-base w-full">
                                            <div class="input-group input-group-sm mb-3">
                                                <input type="text" v-model="efectivo" class="form-control" />
                                            </div>
                                        </span>
                                    </div>
                                    <div class="flex items-center justify-left px-2 mt-1">
                                        <span class="text-black font-bold text-xs w-3/6">Tarjeta debito</span><span class="text-green-500 text-base w-full">
                                            <div class="input-group input-group-sm mb-3">
                                                <input type="text" v-model="td" class="form-control" />
                                            </div>
                                        </span>
                                    </div>
                                    <div class="flex items-center justify-left px-2 mt-1">
                                        <span class="text-black font-bold text-xs w-3/6">Tarjeta Credito</span><span class="text-green-500 text-base w-full">
                                            <div class="input-group input-group-sm mb-3">
                                                <input type="text" v-model="tc" class="form-control" />
                                            </div>
                                        </span>
                                    </div>

                                    <div class="flex items-center justify-left px-2 mt-1">
                                        <span class="text-black font-bold text-xs w-3/6">Nota</span><span class="text-green-500 text-base w-full">
                                            <div class="input-group input-group-sm mb-3">
                                                <input type="text" v-model="nombrecliente" class="form-control" />
                                            </div>
                                        </span>
                                    </div>

                                    <div class="flex items-center justify-left px-2">
                                        <span class="text-black font-bold text-xs w-3/6">Descuento</span><span class="text-green-500 text-base w-full">
                                            <div class="input-group input-group-sm mb-3">
                                                <input type="number" v-model="descuento" class="form-control" />
                                            </div>
                                        </span>
                                    </div>

                                    <div class="flex items-center justify-left w-full px-2">
                                        <span class="text-red-700 font-bold text-xs w-3/6">Subtotal</span><span class="text-red-500 text-base w-full text-end">$ {{ calcularPrecio() }}</span>
                                    </div>
                                    <div class="flex items-center justify-left px-2">
                                        <span class="text-green-600 font-bold text-xs w-3/6">Total</span><span class="text-green-500 text-base text-end w-full">$
                                            {{ Number(calcularPrecio()) - Number(descuento) }}</span>
                                    </div>
                                    <div class="flex items-center justify-left px-2">
                                        <span class="text-green-600 font-bold text-xs w-3/6">Restan</span><span class="text-green-500 text-base text-end w-full">$
                                            {{ (Number(this.calcularPrecio()) - Number(this.descuento))-(Number(this.efectivo)+Number(this.td)+Number(this.tc))}}</span>
                                    </div>

                                    <button @click="tarjetas.length > 0 ? setAction() : null" :class="{
                          'bg-gray-200 cursor-none text-black ':
                            tarjetas.length == 0,
                          'bg-blue-500 text-white  hover:bg-blue-700 cursor-pointer':
                            tarjetas.length > 0,
                        }" class="
                          mt-2
                          px-4
                          font-bold
                          py-2
                          mx-2
                          rounded
                          focus:outline-none focus:shadow-outline
                        " type="button">
                                        Cobrar
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ItemTemplate from "@/views/admin/templateselect.vue";
//@ts-ignore
import palabras from "@/mixins/palabras";

//@ts-ignore
import generales from "@/mixins/functions/generales"
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import giftcards from "@/mixins/axios/giftcards";
//@ts-ignore
import ventagiftcard from "@/mixins/axios/ventagiftcards";
export default {
    props: {
        sucursalsel: {
            type: Number,

        },
        data: {
            type: Object,

        },
    },
    mixins: [clientes, palabras, generales, ventagiftcard, giftcards],
    data() {
        return {
            tarjetas: [],
            clientesdata: [],
            template: ItemTemplate,
            newItemCliente: "",
            id: null,
            subtotal: 0,
            numero: "",
            precio: "",
            cantidad: "",
            efectivo: 0,
            td: 0,
            tc: 0,
            descuento: 0,
            metodo: "Efectivo",
            total: 0,
            nombrecliente: "",
            modalShow: false,
            selectedItem: null,

        };
    },
    mounted() {

        if (this.data.hasOwnProperty('id')) {

            console.log(this.data.ventagiftcards, '---')
            this.tarjetas = this.data.ventagiftcards.giftcards;
            this.newItemProducto = null;
            this.newItemCliente = this.data.ventagiftcards.clientes;
            this.nombrecliente = this.data.ventagiftcards.nombrecliente;
            this.numero = "";
            this.precio = "";
            this.cantidad = "";
            this.td = this.data.ventagiftcards.td
            this.tc = this.data.ventagiftcards.tc
            this.efectivo = this.data.ventagiftcards.efectivo
            this.efectivo = this.data.ventagiftcards.efectivo
            this.descuento = this.data.ventagiftcards.descuento;
            this.id = this.data.ventagiftcards.id;

        }

        this.getClientes()
    },
    methods: {

        hiddenAddGiftcard() {
            this.$emit('ocultar-addgiftcard');
        },
        getLabel(item) {
            if (item != null) {
                return item.nombre + "-" + item.telefono;
            } else {
                return "";
            }
        },

        async limpiar() {
            this.tarjetas = [];
            this.newItemProducto = null;
            this.newItemCliente = null;

            this.numero = "";
            this.precio = "";
            this.cantidad = "";
            this.descuento = 0;
            this.id = null;

        },

        async onSearch(search) {
            if (search.length) {
                this.getClientes(search);
            } else {
                this.clientesdata = [];
            }
            // loading(false);
            //this.search(loading, search, this);
        },
        async getClientes(text) {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                text: text,
            };
            let data = await this.GET_CLIENTES2(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

        agregar() {
            if (
                String(this.numero) == "" &&
                String(this.cantidad) == "" &&
                String(this.precio) == ""
            ) {
                alert("Los campos no pueden estar vacios");
            } else {
                let total = 0;
                this.tarjetas.forEach((element) => {
                    if (String(element.numero) == String(this.numero)) {
                        total = total + 1;
                    }
                });
                if (total > 0) {
                    alert("El numero de tarjeta ya fue agregado a la venta");
                } else {
                    this.tarjetas.push({
                        numero: this.numero,
                        precio: this.precio,
                        cantidad: this.cantidad,
                    });
                    this.numero = "";
                    this.cantidad = "";
                    this.precio = "";
                }
            }
        },
        calcularPrecio() {
            let precio = 0;
            this.tarjetas.forEach((element) => {
                precio += Number(element.precio);
            });
            return precio;
        },
        async setAction() {

            if (Number(this.efectivo) + Number(this.td) + Number(this.tc) > (Number(this.calcularPrecio()) - Number(this.descuento))) {
                this.$root.$emit(
                    "alerta_show",
                    "La cantidad total de metodos de pago excede el total de la cuenta"
                );
                return;
            }

            this.$root.$emit("loading", true);
            if (this.newItemCliente == '' || this.newItemCliente == null) {
                this.$root.$emit(
                    "alerta_show",
                    "Es necesario seleccionar un cliente"
                );
                return;
            }
            let total = Number(this.calcularPrecio()) - Number(this.descuento);
            let subtotal = this.calcularPrecio();
            let dat = {
                data: this.tarjetas,
                metodo: this.metodo,
                efectivo: this.efectivo,
                td: this.td,
                tc: this.tc,
                total: total,
                clientes_id: this.newItemCliente.id,
                nombrecliente: this.nombrecliente,
                subtotal: subtotal,
                descuento: this.descuento,
                sucursal_id: this.sucursalsel
            };
            if (this.id) {
                dat.id = this.id;
                this.editar(dat)
            } else {
                this.cobrar(dat)
            }
        },

        async editar(dat) {

            let data = await this.EDICION_VENTAGIFTCARD(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.$parent.getData();
                    this.addGiftcard = false;
                    this.limpiar()

                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear venta de giftcard, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },

        async cobrar(dat) {

            let data = await this.VENTAGIFTCARD(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    console.log(response);

                    this.$parent.getData();
                    this.addGiftcard = false;
                    this.limpiar()

                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar crear venta de giftcard, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },

        closeModal() {
            this.modalShow = false;
        },
        openModal() {
            this.modalShow = true;
        },
        selectItem(item) {
            this.selectedItem = item;
        },

        async unirArray() {
            if (!this.selectedItem) {
                alert('Es necesario definir el cliente');
                return;
            }

            this.$root.$emit("loading", true);
            let clientes = [];
            this.arrayD.forEach(element => {
                if (element.id != this.selectedItem.id) {
                    clientes.push(element.id)
                }

            });

            let dat = {
                clientes: clientes,
                principal: this.selectedItem.id
            };
            let data = await this.CLIENTES_UNIR(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.$emit('unir-array-exitoso');
                    this.selectedItem = null;
                    this.modalShow = false;

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });

            // Aquí ejecuta la función que debe unir el array y notifica al padre que fue exitoso
            // Por ejemplo:
            // this.$emit('unir-array-exitoso');

            // Limpiar la selección
            // Cierra el modal después de ejecutar la función
        },
    },
};
</script>

<style>
/* Asegúrate de que Font Awesome esté importado */
</style>
