
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";

//@ts-ignore
import producto_servicios from '@/mixins/axios/producto_servicios'

//@ts-ignore
import categorias from '@/mixins/axios/categorias'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'


@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
        //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,
   swatches:
  [
        ['#F64272', '#F6648B', '#F493A7', '#F891A6', '#FFCCD5' ],
        ['#8b5aff', '#a27bff', '#b99cff', '#d0bdff', '#e8deff' ],
        ['#51e5db', '#74ebe3', '#96f0ea', '#b9f5f1', '#dcfaf8' ],
        ['#ffa51a', '#ffb748', '#ffc976', '#ffdba3', '#ffedd1' ],
        ['#D7F9F1','#AFBC88','#F3722C','#F9C74F','#F8961E'],
        ['#F94144','#90BE6D','#7678ed','#ff595e','#efc3e6'],
        ['#9df7e5','#a7c957','#cae9ff','#73d2de','#7ae582'],
        ['#64b5f6','#ff7f51','#f3dfa2','#cbe896','#b298dc'],
         ['#f6aa1c','#00f5d4','#ff86c8','#ffa3a5','#9bb1ff'],
        
      ],
        columnasd:[
             {
                tag:'id',
                data:'id',
                active:false
            },
            
           
            {
                tag:'nombre',
                data:'nombre',
                active:true
            },
             {
                tag:'tiempo',
                data:'tiempo',
                active:true
            },
            {
                tag:'precio',
                data:'precio',
                active:true
            },
              
              {
                tag:'pcomision',
                data:'comision',
                active:true
            },
            
             
              {
                tag:'categoria',
                data:'categoria',
                active:true
            },
           
            {
                tag:'color',
                data:'color',
                active:true
            },
            {
                tag:'imagen',
                data:'imagen',
                active:true
            },
            {
                tag:'online',
                data:'online',
                active:true
            },
           {
                tag:'edit',
                data:'edit',
                active:false
            },
              {
                tag:'delete',
                data:'delete',
                active:false
            },
            
           
        ],



        id:null as any,

      precio:'' as any,
     
      nombre:'' as any,
      
      comision:0 as any,
      
       color:'#1CA085' as any,
      online:0,
      data_file: null,
      imagen: '',
      tiempo:0 as any,
      
      categorias_id:0 as any,
      cloudName: "leadpatt",
            preset: "fdykxij4",
            tags: "poa-upload",

            fileContents: null,
            formData: null,
  
         
         
              producto_serviciosdata:[] as any,
              categoriasdata:[] as any,
                ComisionesData:[

                  {
                      id:5,
                      nombre:'15%'

                  },
                  {
                      id:10,
                      nombre:'20%'
                      
                  },
                  {
                      id:15,
                      nombre:'15%'

                  },
                  {
                      id:20,
                      nombre:'20%'
                      
                  },
                   {
                      id:25,
                      nombre:'15%'

                  },
                  {
                      id:30,
                      nombre:'20%'
                      
                  },
                  {
                      id:35,
                      nombre:'15%'

                  },
                  {
                      id:20,
                      nombre:'20%'
                      
                  }
                  
                  ],
              tiempoData:[

                  {
                      id:15,
                      nombre:'15 minutos'

                  },
                  {
                      id:20,
                      nombre:'20 minutos'
                      
                  },
                  {
                      id:25,
                      nombre:'25 minutos'
                      
                  },
                  {
                      id:30,
                      nombre:'30 minutos'
                      
                  },
                  {
                      id:45,
                      nombre:'45 minutos'
                      
                  },
                  {
                      id:60,
                      nombre:'1 hora'
                      
                  },
                    {
                      id:75,
                      nombre:'1 hora 15 minutos'
                      
                  }
                  ,
                    {
                      id:90,
                      nombre:'1 hora 30 minutos'
                      
                  }
                  ,
                    {
                      id:120,
                      nombre:'2 horas'
                      
                  }
                  ,
                    {
                      id:240,
                      nombre:'4 horas'
                      
                  }
              ]
      
    }
  },
     mixins: [producto_servicios,categorias],
     validations: {
    
           precio: {
      required,
    },
 
           nombre: {
      required,
    },
 
     
     
           comision: {
      required,
    },
 
    color: {
      required,
    },
 
     
     
           tiempo: {
      required,
    },
 
     
     
           categorias_id: {
      required,
    },

    online: {
      required,
    },
 
     
     

   
  },
  methods:{
    onPickFile() {
            this.$refs.fileInput.click()
        },
    handleFileChange: function (event) {
            console.log("handlefilechange", event.target.files);
            //returns an array of files even though multiple not used
            this.file = event.target.files[0];
            this.filesSelected = event.target.files.length;
            this.upload();
        },
        prepareFormData: function () {
            this.formData = new FormData();
            this.formData.append("upload_preset", this.preset);
            this.formData.append("tags", this.tags); // Optional - add tag for image admin in Cloudinary
            this.formData.append("file", this.file);
        },
    upload() {
            this.$root.$emit("loading", true);

            //let reader = new FileReader();
            // attach listener to be called when data from file

            // this.fileContents = reader.result;
            this.prepareFormData();
            let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/leadpatt/upload`;
            let requestObj = {
                url: cloudinaryUploadURL,
                method: "POST",
                data: this.formData
            };
            //show progress bar at beginning of post
            // this.showProgress = true;
            axios(requestObj)
                .then(response => {
                    //this.results = response.data;
                    //console.log(this.results);
                    //console.log("public_id", this.results.public_id);
                    //this.logotipo = this.results.secure_url;
                    this.$root.$emit("loading", false);
                    this.imagen = response.data.secure_url;
                    //this.subirComprobante()
                    //alert(response.data.secure_url)
                    //this.$root.$emit("loading", false);
                })
                .catch(error => {
                    //this.errors.push(error);
                    this.$root.$emit("loading", false);
                    console.log(this.error);
                    //this.$root.$emit("loading", false);
                });
        },
       async getData_Categorias() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')



let data= await this.GET_CATEGORIAS_CHILD().then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.categoriasdata=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},
      async eliminar(row){
         
this.$root.$emit('loading', true);
let data= await this.ELIMINAR_PRODUCTOS_SERVICIOS(row).then(response => {

                    if (response != null) {

 this.producto_serviciosdata=this.producto_serviciosdata.filter(item => item.id !== row.id);
 
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                });
      },

     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
      limpiar(){
this.id=null;
 
        
                 
                 this.precio=null;
           this.nombre=null;
                      this.comision=null;
                                 this.tiempo=null;
        
              this.type=null;
              this.imagen='';
              this.online=0;
           
      },
      addRow(){
          this.limpiar();
 $('#ac_modal_productos_servicios').modal('show')
      },
seteditar(row){



 this.id=row.id;
 this.precio=row.precio;
 this.imagen=row.imagen;
 this.nombre=row.nombre;
  this.color=row.color;
 this.comision=row.comision;
 this.online=row.online;
 this.tiempo=row.tiempo;
 this.categorias_id=row.categorias_id;
            
          
 $('#ac_modal_productos_servicios').modal('show')
    

},
     async crear(dat){
let data= await this.CREAR_PRODUCTOS_SERVICIOS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)

  this.producto_serviciosdata.push(response)
  $('#ac_modal_productos_servicios').modal('hide')
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar producto o servicio, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                    //loading.close();
                });

      },
     async editar(dat){


let data= await this.EDITAR_PRODUCTOS_SERVICIOS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)
this.$root.$emit("success", "Prodcuto o Servicio modificado exitosamente, refresque la pagina");
  this.producto_serviciosdata=this.producto_serviciosdata.filter(item => item.id !== dat.id);
 this.producto_serviciosdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear el producto o servicio, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                    //loading.close();
                });


      }
     ,
     async GuardarData(){



console.log('id:'+this.id)

let dat={
  id:this.id,
    
           precio:this.precio,
           nombre:this.nombre,
         
             comision:this.comision,
             online:this.online,
             imagen:this.imagen,
                    color:this.color,
           tiempo:this.tiempo,
             categorias_id:this.categorias_id,
    
        

}
console.log(dat)
 this.$root.$emit('loading', true);
 if(this.id==null){
     this.crear(dat);
 }else{
       this.editar(dat);
 }

      },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')



let data= await this.GET_PRODUCTOS_SERVICIOS().then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.producto_serviciosdata=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      this.getData();
      this.getData_Categorias();
      //alert('10')
  }
})
export default class Home extends Vue {}

