
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from 'vue-property-decorator';


//@ts-ignore
import ventas from '@/mixins/axios/ventas'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'


//@ts-ignore
import { email, required } from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'


@Component({
  components: {
   /*
    Vuetable,
    VuetablePagination,*/
    
    TablePPoa
  },
  data() {
    return {
        //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,
  
        columnasd:[
             {
                tag:'id',
                data:'id',
                active:false
            },
            
            {
                tag:'delete',
                data:'delete',
                active:false
            },
            {
                tag:'edit',
                data:'edit',
                active:false
            },
            {
                tag:'idvendedor',
                data:'idvendedor',
                active:true
            },
           {
                tag:'idempleada',
                data:'idempleada',
                active:true
            },
            {
                tag:'clientes_id',
                data:'clientes_id',
                active:true
            },
            {
                tag:'sucursales_id',
                data:'sucursales_id',
                active:true
            },
            {
                tag:'fecha_inicio',
                data:'fecha_inicio',
                active:true
            },
            {
                tag:'fecha_fin',
                data:'fecha_fin',
                active:true
            },
             
            
           
        ],




        id:null as any,

      idvendedor:'' as any,
idempleada:'' as any,
     clientes_id:'' as any,
     sucursales_id:'' as any,
     fecha_inicio:'' as any,
     fecha_fin:'' as any,

     
         
         
              ventasdata:[] as any,
      
    }
  },
     mixins: [ventas],
     validations: {
    
           idvendedor: {
      required,
    },
       idempleada: {
      required,
    },
       clientes_id: {
      required,
    },
       sucursales_id: {
      required,
    },
       fecha_inicio: {
      required,
    },
   fecha_fin: {
      required,
    },
     

   
  },
  methods:{
       
      async eliminar(row){
         
this.$root.$emit('loading', true);
let data= await this.ELIMINAR_VENTAS(row).then(response => {

                    if (response != null) {

 this.ventasdata=this.ventasdata.filter(item => item.id !== row.id);
 
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                });
      },
  /*     select(row){

//alert('pmd seleccionado');
 //this.$store.state.pmd_sel=row;
 this.$store.commit('SETPMD',row);
           //this.secretaria_sel=row;
//this.departamentos=true;
 //this.$store.commit('SETSECRETARIA',row);
   this.$router.push('/mun/general/pmd/ejes')
//this.getDataDepartamentos();

      
     },*/
     confirm(row){

let th=this;
Notiflix.Confirm.Show('Alerta de confirmacion','Desea eliminar este elemento?','Aceptar','Cancelar',function(){ 
    
    th.eliminar(row);
    },function(){ 
      
        
    });



      
     },
      limpiar(){
this.id=null;
  

           
                 this.idvendedor=null;
  this.idempleada=null;
  this.clientes_id=null;
  this.sucursales_id=null;
  this.fecha_inicio=null;
  this.fecha_fin=null;


        
    
           
      },
      addRow(){
          this.limpiar();
 $('#ac_modal_indicador_area').modal('show')
      },
seteditar(row){


 this.id=row.id;
 this.idvendedor=row.idvendedor;
 this.idempleada=row.idempleada;
  this.clientes_id=row.clientes_id;
   this.sucursales_id=row.sucursales_id;
    this.fecha_inicio=row.fecha_inicio;
     this.fecha_fin=row.fecha_fin;

            
          
 $('#ac_modal_indicador_area').modal('show')
    

},
     async crear(dat){
let data= await this.CREAR_INDICADOR_AREAS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)

  this.ventasdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar indicador areas, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                    //loading.close();
                });

      },
     async editar(dat){


let data= await this.EDITAR_VENTAS(dat).then(response => {

                    if (response != null) {
                        //this.plantas = response;
console.log(response)
 this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear pmd, refresque la pagina");
  this.ventasdata=this.ventasdata.filter(item => item.id !== dat.id);
 this.ventasdata.push(response)
                    } else {
                        this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear pmd, refresque la pagina");

                    }
 this.$root.$emit('loading', false);
                    //loading.close();
                });


      }
     ,
     async GuardarData(){



console.log('id:'+this.id)

let dat={
  id:this.id,

           
           idvendedor:this.idvendedor,
           idempleada:this.idempleada,  
           clientes_id:this.clientes_id,  
           sucursales_id:this.sucursales_id,  
           fecha_inicio:this.fecha_inicio,  
           fecha_fin:this.fecha_fin,  
               
}
console.log(dat)
 this.$root.$emit('loading', true);
 if(this.id==null){
     this.crear(dat);
 }else{
       this.editar(dat);
 }

      },
async getData() {
  
 this.$root.$emit('loading', true);
 console.log('loaddding')

let datas={
idmunicipio:this.$store.state.user.usuario.id_municipio
};

let data= await this.GET_VENTAS(datas).then(response => {

                    if (response != null) {
                        //this.plantas = response;
                        
this.ventasdata=response;
                    } else {
                        //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
console.log('ocurrio un error')
                    }
                    this.$root.$emit('loading', false);

                    //loading.close();
                });
},



  },
  mounted(){
      this.getData();
      //alert('10')
  }
})
export default class Home extends Vue {}

