
//@ts-nocheck
//@ts-ignore
import { Component, Vue } from "vue-property-decorator";

//@ts-ignore
import usuarios from "@/mixins/axios/user";
//@ts-ignore
import TablePPoa from "@/components/tables/tablev1";
//@ts-ignore
import { email, required } from "vuelidate/lib/validators";
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

//@ts-ignore
import sucursales from '@/mixins/axios/sucursales'
@Component({
  components: {
  
  },
  data() {
    return {
       temas:[
            {key:'version1',text:'Tema #1'},
            {key:'version2',text:'Tema #2'},
            {key:'version3',text:'Tema #3'}
        ],
     horarios_user:[],
     dias:{1:"Lunes",2:"Martes",3:"Miercoles",4:"Jueves",5:"Viernes",6:"Sabado",0:"Domingo"}
    };
  },
  mixins: [usuarios,sucursales],
  validations: {
   
  },
  methods: {
     tema_sel(){
if(this.$store.state.user.usuario.idtema==null){
return this.$store.state.user.municipio.idtema;
}
return this.$store.state.user.usuario.idtema;
      },
    	SelectInitTime(event,index){
			  console.log(event)
		
    this.horarios_user[index].hora_inicio=String(String(event).replace('mm', '00')).replace('HH','12');
    },
    SelectInitDescanso(event,index){
			  console.log(event)
		
    this.horarios_user[index].hora_inicio_descanso=String(String(event).replace('mm', '00')).replace('HH','12');
    },
    SelectFinDescanso(event,index){
			  console.log(event)
		
    this.horarios_user[index].hora_fin_descanso=String(String(event).replace('mm', '00')).replace('HH','12');
    },
    SelectEndTime(event,index){
			  console.log(event)
		
    this.horarios_user[index].hora_fin=String(String(event).replace('mm', '00')).replace('HH','12');
    },
   async Guardar(){

      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data1={
        id:this.$store.state.usuario_sel.id,
        data:this.horarios_user
      }
      let dataa = await this.GUARDAR_USER_HORARIO(data1).then((response) => {
        if (response != null) {
          //this.plantas = response;
          //this.comisiones = response;
          this.$root.$emit("success", "Comisiones guardadas excitosamente");
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });

    },
   
    confirm(row) {
      let th = this;
      Notiflix.Confirm.Show(
        "Alerta de confirmacion",
        "Desea eliminar este elemento?",
        "Aceptar",
        "Cancelar",
        function () {
          th.eliminar(row);
        },
        function () {}
      );
    },
    limpiar() {
      this.id = null;

      this.role = "";
      this.password_plano = "";
      this.name = "";
      this.email = "";
       this.idmunicipio = 0;
    },
   
   

 
    async getData() {
      this.$root.$emit("loading", true);
      console.log("loaddding");
      let data1={
        id:this.$store.state.usuario_sel.id
      }
      let data = await this.GET_HORARIOS_USER(data1).then((response) => {
        if (response != null) {
          //this.plantas = response;
          this.horarios_user = response;
        } else {
          //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
          console.log("ocurrio un error");
        }
        this.$root.$emit("loading", false);

        //loading.close();
      });
    },
  },
  mounted() {
    this.getData();
    
  
    //alert('10')
  },
})
export default class Home extends Vue {}
