
    //@ts-nocheck
    
    import {
        Component,
        Vue
    } from "vue-property-decorator";
    import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
    
    //@ts-ignore
    import usuarios from "@/mixins/axios/user";
    //@ts-ignore
    import menu from "@/mixins/menu";
    import palabras from "@/mixins/palabras"
    @Component({
        components: {
            HelloWorld,
        },
        mixins: [usuarios, menu, palabras],
        data() {
            return {
    
                menuactual: [],
    
                expand: false,
                showel: false,
                showChildren: false,
                render_menu: true,
                shc_tema: false,
                temas: [{
                        key: "version1",
                        text: "Tema #1"
                    },
                    {
                        key: "version2",
                        text: "Tema #2"
                    },
                    {
                        key: "version3",
                        text: "Tema #3"
                    },
                ],
            };
        },
        created() {
           
            switch (this.$store.state.user.usuario.role) {
                case 'admin':
                    this.menuactual = this.menus.admin;
                    break;
                case 'sucursal':
                    this.menuactual = this.menus.sucursales;
                    break;
                case 'empleado':
                    this.menuactual = this.menus.caja;
                    break;
                case 'caja':
                    this.menuactual = this.menus.caja;
                    break;
    
                case 'master':
                    this.menuactual = this.menus.master;
                    break;
                    case 'pedidos':
                    this.menuactual = this.menus.pedidos;
                    break;
    
                default:
                    break;
            }
    
        },
        mounted() {
          
        },
       
        methods: {
            salir() {
                this.$store.commit('SETUSER', {});
                this.$store.commit('SETUSERSUCURSAL', {});
                this.$router.push('/');
            },
            showMenu1(id) {
    
                let actual = false;
                if ($('#' + id).hasClass('hidden')) {
                    actual = true;
                }
    
                $('.genmen').removeClass('hidden');
    
                $('.genmen').addClass('hidden');
                // let menu1 = document.getElementById(id);
                let icon1 = document.getElementById("icon" + id);
                let menu1 = document.getElementById(id);
                if (actual) {
    
                    $('#' + id).removeClass('hidden');
                    //$('#icon'+id).addClass('hidden');
                    //$('#'+id).addClass('hidden');
                } else {
    
                    //$('#'+id).removeClass('hidden');
                    //$('#icon'+id).addClass('hidden');
                    $('#' + id).addClass('hidden');
                }
                icon1.classList.toggle("rotate-180");
                //menu1.classList.toggle("hidden");
    
            },
    
            showMenu2(flag) {
                let icon2 = document.getElementById("icon2");
                let menu2 = document.getElementById("menu2");
                if (flag) {
                    icon2.classList.toggle("rotate-180");
                    menu2.classList.toggle("hidden");
                }
            },
    
            showMenu3(flag) {
                let icon3 = document.getElementById("icon3");
                if (flag) {
                    icon3.classList.toggle("rotate-180");
                }
            },
    
            showNav(flag) {
    
                let Main = document.getElementById("Main");
                let open = document.getElementById("open");
                let close = document.getElementById("close");
                if (flag) {
                    Main.classList.toggle("-translate-x-full");
                    Main.classList.toggle("translate-x-0");
                    open.classList.toggle("hidden");
                    close.classList.toggle("hidden");
                }
            },
            collapse() {
                $("body").toggleClass("collapsed-sidebar");
                $(".btncol").toggleClass("hide_b");
            },
            setRole(type) {
                this.render_menu = false;
                this.$store.state.user.usuario.role = type;
                this.$store.commit("SETUSER", this.$store.state.user);
                this.render_menu = true;
                console.log(type);
            },
            show_l(index, type) {
                this.render_menu = false;
                console.log(index);
                if (type == "admin") {
                    this.$store.state.menus.admin[index].active = !this.$store.state.menus.admin[index].active;
                } else {
                    console.log(this.$store.state.menus.sucursales[index]);
                    this.$store.state.menus.sucursales[index].active = !this.$store.state.menus.sucursales[index].active;
                }
                this.$store.commit("SETUSER", this.$store.state.user);
                this.render_menu = true;
            },
            ToggleTemaC() {
                this.shc_tema = !this.shc_tema;
                console.log(this.shc_tema);
            },
            async setTema(key) {
                this.$root.$emit("loading", true);
                let dat = {
                    tema: key
                };
                let data = await this.EDITAR_USERTEMA(dat).then((response) => {
                    if (response != null) {
                        //this.plantas = response;
                        console.log("holsss");
                        console.log(response);
                        this.$root.$emit("success", "Tema modificado correctamentea");
                        this.$store.state.user.usuario.idtema = key;
                        this.$store.commit("SETUSER", this.$store.state.user);
                    } else {
                        this.$root.$emit(
                            "alerta_show",
                            "Ocurrio un error al intentar seleccionar nuevo tema, refresque la pagina"
                        );
                    }
                    this.$root.$emit("loading", false);
                    //loading.close();
                });
            },
            tema_sel() {
                if (this.$store.state.user.usuario.idtema == null) {
                    return this.$store.state.user.municipio.idtema;
                }
                return this.$store.state.user.usuario.idtema;
            },
            activechild(index) {
                console.log(index);
                this.$store.state.menus.sucursales[index].active = !this.$store.state.menus.sucursales[index].active;
                console.log(this.$store.state.menus.sucursales[index]);
            },
        },
    })
    export default class Home extends Vue {}
    