
//@ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator';

//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import generales from "@/mixins/functions/generales";
@Component({
  mixins:[palabras,generales],
    data() {
    return {
    
       
                                
     
    }},
components: {
 
},
mounted(){

    
 

},
methods:{
   
   
 
}})
export default class ListaPagosCobros extends Vue {
  @Prop() private rows!: any;
  @Prop() private tipo!: any;
  
          
      
 
}
