
//@ts-nocheck
//@ts-ignore
//@ts-ignore
import generales from '@/mixins/functions/generales'
import producto_servicios from '@/mixins/axios/producto_servicios'
import giftcards from '@/mixins/axios/giftcards'

import PreviewGiftcard from "@/components/PreviewGiftcard.vue"; 
import {
    Component,
    Vue
} from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Component({
    components: {
      
        PreviewGiftcard
    },
    data() {
        return {

            
          

        }
    },
    mixins: [producto_servicios, giftcards, generales],

    methods: {
        descargar(){
           
	 html2canvas(document.querySelector("#dvContainer"),{useCORS: true}).then(canvas => {
		let a = document.createElement('a'); 
		document.body.appendChild(a); 
		a.download = "giftcardluna27.png"; 
		a.href =  canvas.toDataURL();
		a.click();
	});
        },
        imprimir(){
            html2canvas(document.querySelector("#capture")).then(canvas => {
                document.body.appendChild(canvas)
            });
        }


    },
    created() {
        
    }
})
export default class Home extends Vue {}
