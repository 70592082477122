
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_GASTOSDIA_DATA: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"gastos/data/dia/"+data.dia+"/sucursal/"+data.sucursal,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_GASTOSDIA: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"gastos/dia/"+data.dia+"/sucursal/"+data.sucursal,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
  
      GET_GASTOS_SUCURSAL: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"gastos/sucursal/"+data.sucursal,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_GASTOS: async function() {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"gastos/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },








    CREAR_GASTOS: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"gastos/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          nota:data.nota,
          cantidad:data.cantidad,
          sucursal:data.sucursal,
       
          

             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_GASTOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"gastos/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            nota:data.nota,
            cantidad:data.cantidad,
  
               
             
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_GASTOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"gastos/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }