<template>
<div @click.self="CerrarModal" class=" h-full  items-center  bg-black bg-opacity-20  w-3/6 margin-0 p-0">
   
        <div  class="cursor-auto flex-1   w-full  h-full  rounded-lg relative overflow-hidden bg-gray-100 px-2">

            <div class="flex flex-wrap  h-full   w-full space-y-1  overflow-y-scroll bg-gray-100 py-20">
                <div class="flex  z-10 sticky top-0 h-12 bg-gray-100 justify-between px-10">

                    <div class="flex flex-wrap text-gray-400 text-2xl font-extrabold ">Registrar Citas 🖐

                     
                    </div>
                    <span @click="addAppointment" class=" cursor-pointer transition ease-all 2s bg-blue-500 text-blue-800 hover:bg-blue-600 hover:text-blue-400 h-8  px-2 text-xs rounded ">
                        <div class="w-full h-full flex flex-wrap justify-center content-center "><strong>Agregar Servicio</strong>
                        
                        </div>
                    </span>
                    

                </div>
                <div class="h-full w-full bg-white">

                    <div class="bg-white  rounded-md p-4 shadow-sm w-full ">
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 bg-white p-1 rounded  px-4">

                            <div>
                                <label class="text-xs text-black font-bold">Sucursal</label>
                                <select class="custom-select custom-select-sm w-full input-md h-12" v-model="sucursalselchange" @change="SelectSucursalChange">
                                    <option v-for="(row,index) in sucursalesdata" :value="row.id" :selected="(row.id===sucursalselchange)">{{row.nombre}}</option>
                                </select>
                            </div>
                          
                            <div>
                                <div class="text-xs text-black font-bold flex flex-wrap"> 

                                    <!--<span v-if="cliente!=null && cliente!=''" @click="selCliente(cliente)" class="text-xs text-blue-600 font-bold mb-2 mt-2">Ver Historial</span>-->
                                    <div class="w-auto m-0 p-0 text-base">Cliente</div>
                                    <ModalHistorialCliente class="px-2" v-if="cliente!=null && cliente!=''" :Cliente="cliente"></ModalHistorialCliente> 
                                    <ModalCrearCliente class="px-2"></ModalCrearCliente> 
                                    <span v-if="verifiedNote()" class="text-red-500 text-xs font-bold w-full flex flex-wrap"> {{cliente.notas}}</span><br v-if="verifiedNote()">

                                     <!--<span v-on:click="showCreateClient" style="cursor:pointer" class="text-success underline text-xs w-full text-start">Nuevo Cliente</span>-->
                                    </div>
                                    
                                <v-autocomplete class="w-full rounded h-12 bg-gray-100 border-2 border-radius" id="clienteinput" :min-len="0" :cache-items="false" :items="clientesdata" v-model="cliente" :get-label="getLabelCliente" :component-item='template_cliente' @update-items="onSearch">
                                </v-autocomplete>
    <!--
                                <span v-if="newItemCliente!=null && newItemCliente!=''" @click="selCliente(newItemCliente)" class="text-xs text-blue-600 font-bold mb-2 mt-2">Ver Historial</span>
                                <span v-if="verifiedNote()" class="text-red-500 text-xs font-bold w-full flex flex-wrap"> {{newItemCliente.notas}}</span><br v-if="verifiedNote()">
    -->
                                
    
                            </div>
                            <div>
                                <label class="text-xs text-black font-bold">Dia</label>
                                <input v-model="fechastart" class="w-full h-12 bg-gray-100 mb-2" type="date">
                            </div>
                        </div>

                        <div class="  w-full p-4 bg-gray-100 space-y-1 gap-2">
                            <div v-if="appointments.length>0" class="grid grid-cols-1 md:grid-cols-7  gap-4 bg-white p-1 rounded drop-shadow-xl px-4 z-30" style="" v-for="(cita,i) in appointments">

                               
                                <div class="md:col-span-2">
                                    <div>
                                        <label class="text-xs text-black font-bold">Empleado</label>
                                        <select class="custom-select custom-select-sm w-full" v-model="cita.empleado">
                                            <option v-for="(row,index) in EmpleadosSucursal" :value="row.id" :selected="(row.id===selEmpleadoSucursal)">{{row.name}}</option>
                                        </select>
                                    </div>
                                    <div>
                                        
                                    
                                    <label class="text-xs text-black font-bold">Servicio</label>

<SelectSearch :opcionesData="productosData"  @update="ServiceChange(i, $event)" ></SelectSearch>
                                    </div>
                                   <!-- <v-select class="w-full text-xs" style="background:white" :filter="fuseSearchProductos" :options="productosData" @input="SelectProduct(i,$event)" @change="SelectProduct(i,$event)"  v-model="cita.servicio2" :getOptionLabel="option => option.nombre">
                                        <template #option="{ categoria, nombre }" class="text-xs">
                                            {{ nombre }}
                                        </template>
                                    </v-select>-->
                                </div>
                                <div class="md:col-span-2" >
                                <div v-if="cita.timestart!=null" class="w-full">
                                    <label class="text-xs text-black font-bold">Hora Inicio</label>
                                    <SelectorHora class="text-center w-full" :initialTime="cita.timestart" @update-time="handleTimeChange(i,'start', $event)"></SelectorHora>
                                </div>
                                <div v-if="cita.timeend!=null" v-bind:key="cita.timeend" class="w-full">
                                    <label class="text-xs text-black font-bold">Hora fin</label>
                                    <SelectorHora class="text-center w-full" :initialTime="cita.timeend" @update-time="handleTimeChange(i,'end', $event)"></SelectorHora>

                                </div>
                                </div>
                                <div class="md:col-span-2">
                                    <label class="text-xs text-black font-bold">Nota <strong class="text-xs text-green-500">Letras restantes: <span :class="{'text-danger': limitmaxCount - cita.nota.length< 0 }">{{limitmaxCount - cita.nota.length}}</span></strong></label>

                                    <textarea class="border w-full h-10 p-1 text-xs" maxlength="50" v-model="cita.nota" placeholder=""></textarea>
                                </div>

                                <div>

                                </div>
                            </div>
                            <div v-if="appointments.length==0">

                                <div class="h-24 w-full bg-gray-200 flex flex-wrap justify-center content-center font-extrabold">
                                    <span>No ha agregado ningun servicio, para agregar uno presione aqui <strong class="text-blue-500 cursor-pointer font-extrabold underline" @click="addAppointment">Agregar Servicio</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class=" border-0  h-20 rounded-b-md flex flex-wrap w-full items-start  justify-end  py-4  z-10 sticky bottom-0 bg-white">

                <button type="button" class="w-4/12 hover:bg-opacity-70 transition ease-all 2s  bg-green-500 bg-opacity-50 border border-green-500 text-sm  font-semibold items-center justify-center ml-3 mr-3 px-4 py-2 rounded-md gap-2" @click="crear_venta()">

                    <span class="text-green-700">Guardar citas</span>
                </button>
                <button type="button" class="w-4/12 hover:bg-opacity-70 transition ease-all 2s     bg-red-500 bg-opacity-50 border border-red-500 text-sm  font-semibold items-center justify-center ml-3 mr-3 px-4 py-2 rounded-md gap-2" @click="CerrarModal">
                    <span class="text-red-700">Cancelar</span>

                </button>
            </div>
        </div>
    </div>

</div>




</div>

</template>

<script>
//@ts-ignore
import ventas from "@/mixins/axios/ventas";
//@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import generales from "@/mixins/functions/generales"
import SelectorHora from "@/components/calendario/SelectorHora.vue";
import SelectSearch from "@/components/calendario/SelectSearch.vue";
import ModalCrearCliente from "@/components/calendario/ModalCrearCliente.vue";
import ModalHistorialCliente from "@/components/calendario/ModalHistorialCliente.vue";

import ItemTemplate from "@/views/admin/templateselect.vue";
import Fuse from "fuse.js";
export default {
    components: {
        SelectorHora,
        SelectSearch,
        ModalCrearCliente,
        ModalHistorialCliente
    },
    mixins: [generales, ventas,clientes],
    props: {
        sucursalselchange:{
            type: Number, // Assuming it's an array
            required: true,
        },
        productosData: {
            type: Array, // Assuming it's an array
            required: true,
        },
        sucursalesdata: {
            type: Array, // Assuming it's an array
            required: true,
        },
        EmpleadosSucursal: {
            type: Array, // Assuming it's an array
            required: true,
        },
    },
    data() {

        return {
            appointments: [],
            objectVenta: {},
            id: 0,
            editing: false,
            fechastart: new Date().toISOString().slice(0, 10),
            
            cliente:"",
            clientesdata:[],
            sucursalchangesel: "",
            nota: "",
          
template_cliente:ItemTemplate,
            limitmaxCount: 50,
            totalRemainCount: 50,
            generateErr: false,

            timestart: null,
            timeend: null,
            newItemProducto: {},
            productos_sel: [],
            selEmpleadoSucursal: "",
            // Rest of the data properties...
        };
    },
    mounted() {
        console.log(this.EmpleadosSucursal)
        if (this.objectVenta) {

        }
    },
    methods: {
        showCreateClient(){

        },
        getLabelCliente(item) {
            if (item != null) {
                return item.nombre + "-" + item.telefono;
            } else {
                return "";
            }
        },
       
        async onSearch(search) {
            console.log(search,'---search')
            if (search.length) {
                this.getClientes(search);
            } else {
                this.clientesdata = [];
            }
            // loading(false);
            //this.search(loading, search, this);
        },
        async getClientes(text) {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let dat = {
                text: text,
            };
            let data = await this.GET_CLIENTES2(dat).then((response) => {
                console.log(response,'-clientes 22--')
                if (response != null) {
                    console.log(response,'-clientes--')
                   
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
        addAppointment() {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();

            // Suma 30 minutos a la hora actual
            const endHours = hours + Math.floor((minutes + 30) / 60);
            const endMinutes = (minutes + 30) % 60;

            // Convierte las horas, minutos y segundos a formato de 24 horas (agregando ceros a la izquierda si es necesario)
            const formattedStartHours = hours < 10 ? `0${hours}` : hours;
            const formattedStartMinutes = minutes < 10 ? `0${minutes}` : minutes;

            const formattedEndHours = endHours < 10 ? `0${endHours}` : endHours;
            const formattedEndMinutes = endMinutes < 10 ? `0${endMinutes}` : endMinutes;

            // Genera la hora de inicio en formato de 24 horas (HH:mm)
            const startTime24 = `${formattedStartHours}:${formattedStartMinutes}:00`;

            // Genera la hora de finalización en formato de 24 horas (HH:mm)
            const endTime24 = `${formattedEndHours}:${formattedEndMinutes}:00`;

            // Obtiene la zona horaria de tu sistema en minutos
            const timezoneOffsetMinutes = now.getTimezoneOffset();

            // Calcula la fecha y hora local teniendo en cuenta la zona horaria
            const localMillis = now.getTime() - timezoneOffsetMinutes * 60 * 1000;
            const localDate = new Date(localMillis);

            // Genera la fecha de hoy en formato "YYYY-MM-DD"
            const todayDate = localDate.toISOString().slice(0, 10);
            const defaultAppointment = {
                servicio: null,
                servicio2:"",
                nota: '',
                empleado: null,

                timestart: startTime24,
                timeend: endTime24
            };

            console.log(this.defaultAppointment, '---sppooo')
            this.appointments.push(defaultAppointment);
        },
        removeAppointment(index) {
            this.appointments.splice(index, 1);
        },
        SelectInitTime(i,producto) {
        this.appointments[i].timeend=null;
            let data = this.addMinutes(new Date(this.fechastart + ' ' +    this.appointments[i].timestart), producto.tiempo);

            data = data.split(" ");

            if (data.length > 0) {
                console.log(data[1],'-')
                this.appointments[i].timeend = data[1]
                console.log (this.appointments[i].timeend,'-asignado-')
            
            }

        },
        formatTime(time) {
            return time < 10 ? "0" + time : time;
        },

        addMinutes(date, minutes) {
            const data_set = new Date(date.getTime() + minutes * 60000);

            const year = data_set.getFullYear();
            const month = this.formatTime(data_set.getMonth() + 1);
            const day = this.formatTime(data_set.getDate());
            const hours = this.formatTime(data_set.getHours());
            const minutesFormatted = this.formatTime(data_set.getMinutes());
            const seconds = this.formatTime(data_set.getSeconds());

            return `${year}-${month}-${day} ${hours}:${minutesFormatted}:${seconds}`;
        },

        CerrarModal($event) {
            this.$emit("close-mv", false);
        },
        refreshCalendario($event) {
            this.$emit("refresh-calendario", true);
        },

        SelectSucursalChange($event) {
            this.$emit("cambio-sucursal", this.sucursalselchange);
        },
        fuseSearchProductos(options, search) {
            const fuse = new Fuse(options, {
                keys: ["nombre", "categoria"],
                shouldSort: true,
            });
            return search.length ?
                fuse.search(search).map(({
                    item
                }) => item) :
                fuse.list;
        },
        fuseSearchClientes(options, search) {
            const fuse = new Fuse(options, {
                keys: ["nombre", "telefono"],
                shouldSort: true,
            });
            return fuse.list;

        },
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["name", "name", "email"],
                shouldSort: true,
            });
            return search.length ?
                fuse.search(search).map(({
                    item
                }) => item) :
                fuse.list;
        },

        SelectProduct(i, val) {
            console.log(val, '------------prpducto-sel');
            // console.log(event.target.value)
            //this.formula=this.productosData[event.target.value];
            this.appointments[i].servicio = {
                duracion:val.duracion,
                nombre: val.nombre,
                id: val.id,
                precio: val.precio,
                cantidad: 1,
            };

            this.SelectInitTime(i,val);
            //this.SelectInitTime({target:{value:this.newItemStartTime}})
            //this.indicador_administradordata=this.indicador_administradordata.filter(item => item.id !== row.id);
        },
        
        ServiceChange(i, val) {
            console.log(val, '------------prpducto-sel');
            this.appointments[i].servicio  = {
                duracion:val.tiempo,
                nombre: val.nombre,
                id: val.id,
                precio: val.precio,
                cantidad: 1,
            };
            this.SelectInitTime(i,val);

        },
        handleTimeChange(index,key, newTime) {
            //console.log(newTime,this.appointments[index], '---hora--',index)

            switch (key) {
                case 'start':
                console.log(this.appointments[index].servicio, '---------sel--hora inicio');
                this.appointments[index].timestart = newTime;
console.log(this.appointments[index].servicio);

                if(this.appointments[index].servicio!=null){

                    //console.log(this.appointments[index].servicio, '---------entre');
                    this.appointments[index].timeend = this.calcularHoraFin(newTime,  this.appointments[index].servicio.duracion);
                   // console.log(this.calcularHoraFin(newTime,  this.appointments[index].servicio.duracion), '---------hora fin--');
                }
               
                    break;
                case 'end':
                this.appointments[index].timeend = newTime

                    break;

                default:
                    break;
            }

        },
calcularHoraFin(horaInicio, duracionMinutos) {
  const [hora, minutos] = horaInicio.split(':');
  const horaInicioMs = new Date().setHours(parseInt(hora), parseInt(minutos), 0, 0);
  const duracionMs = duracionMinutos * 60000; // 1 minuto = 60000 ms

  const horaFinMs = horaInicioMs + duracionMs;
  const horaFin = new Date(horaFinMs);
  const segundosFormato = horaFin.getSeconds().toString().padStart(2, '0');

  return `${horaFin.getHours()}:${horaFin.getMinutes()}:${segundosFormato}`;
},
        verifiedNote() {
            if (this.cliente == null) {
                return false;
            }
            if (this.cliente == 0) {
                return false;
            } else {
                if (this.cliente.notas == null) {
                    return false;
                }
                if (this.cliente.notas == "0") {
                    return false;
                }else
                if (this.cliente.notas == 0) {
                    return false;
                }else

                if (this.cliente.notas == "") {
                    return false;
                } else {
                    return true;
                }
            }
        },
        async crear_venta() {
            console.log("loaddding");
            if (this.appointments.length == 0) {
                this.$root.$emit(
                    "alerta_show",
                    "Es necesario agregar uno o mas servicios/productos a la venta"
                );
                return;
            }
            if(this.cliente==""||this.cliente==null){
                this.$root.$emit(
                    "alerta_show",
                    "Es necesario selecionar un cliente"
                );
                return;
            }
            //await this.SelectInitTimeend(this.newItemEndTime);
            console.log(this.newItemStartTime, "----------------start time---------");
            this.$root.$emit("loading", true);
            let data_c = {
                sucursal: this.sucursalselchange,
                productos: this.appointments,
                clientes_id: this.cliente.id,
                fecha: this.fechastart 
            };

            let data = await this.CREAR_VENTAS_MULTIPLE(data_c).then((response) => {
                console.log(response)
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("errors")) {

                        const errors = response.errors;
                const errorMessagesHtml = this.getErrorMessagesHtml(errors);
               
console.log(errorMessagesHtml,'---------ss---')

                        this.$root.$emit("alerta_show", errorMessagesHtml);
                    } else {
                        this.refreshCalendario()
                        this.CerrarModal();
                    }
                    this.ConfirmMultiple = true;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },
         getErrorMessagesHtml(errors) {
    let html = '';

    for (const field in errors) {
        if (errors.hasOwnProperty(field)) {
            const messages = errors[field];
            for (const message of messages) {
                html += `${message}<br>`;
            }
        }
    }

    html += '';
    return html;
},
        async CambiarSucursal() {

            console.log(this.timestart, this.timeend, this.fechastart);
            console.log(this.sucursalchangesel, "-----el----");

            this.$root.$emit("loading", true);
            let dat = {
                id: this.sucursalchangesel.id,
                productos: this.productos_sel,
                sucursal: this.sucursalselchange,
                idempleada: this.selEmpleadoSucursal,
                nota: this.nota,
                fechainicio: this.fechastart + " " + this.timestart,
                fechafin: this.fechastart + " " + this.timeend

                // direccion:this.direccion,
            };
            if (dat.fechainicio > dat.fechafin) {
                this.$root.$emit(
                    "alerta_show",
                    "Error en fechas, La hora de inicio no puede ser mayor a la hora fin "
                );
                this.$root.$emit("loading", false);
                return;
            }
            let data = await this.EDITAR_SUCURSAL(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    if (response.hasOwnProperty("message")) {
                        this.$root.$emit("alerta_show", response.message);
                    } else {
                        this.refreshCalendario()
                        this.CerrarModal();
                        //this.showchangue = false;
                        // 	$('#cambiarSucursales').modal('hide')
                    }

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
            console.log("cambiar");
        },

        submitForm() {
            // Form submission logic, handle create/update
        },
    },
};
</script>

<style >
.v-autocomplete-input {
    width: 100%;
    padding: 4px;
    border: 2px solid gray;
}
.v-autocomplete-list {
    background: #cadbda;
    padding: 2px;
    border: 1px solid black;
    width: 100%;
    z-index: 999999999;
}
.v-autocomplete-input-group{
    height:100%;
    background: rgba(0,0,0,0);
    overflow: hidden;
}
.v-autocomplete-list-item {
    background: rgba(0,0,0,0);
    padding-top: 2px;
    padding-bottom: 2px;
    padding: 4px;
    border-bottom: 1px solid gray;
}
.v-autocomplete-input {
    width: 100%;
    padding: 4px;
    height:100%;
   border:0px;
}

/* Your component styles here */
</style>
