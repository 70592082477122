<template>
<div class="object-cover p-20 h-screen w-screen flex flex-col-reverse md:flex-row items-center justify-center ">
    <img v-if=" this.info.hasOwnProperty('banner_login')" class="w-full h-full object-cover object-bottom absolute  " :src="this.info.banner_login">

    <div class="content text-xl text-center md:text-left">

        <p class="font-bold"></p>
    </div>
    <div class="container mx-auto flex flex-col items-center">

        <form class="shadow-lg w-80 p-4 flex flex-col bg-gray-300  rounded-lg">

            <h1 class="text-3xl mb-5 text-yellow-500 font-bold w-full flex-wrap flex content-center justify-center">
               <div class="w-full flex flex-wrap justify-center my-2"><span class="self-center">Agenda</span></div> 
                <template v-if=" this.info.hasOwnProperty('logo')">
                     <img v-bind:key="this.info.logo" class="w-40 mx-2 self-center " :src="
                   this.info.logo">
                </template>
               

            </h1>
            <input type="text" v-model="usuario" placeholder="Usuario" class="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
            <input type="password" v-model="contrasena" placeholder="Contrasena" class="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500" />
            <button @click="login()" type="button" class="w-full bg-yellow-500 text-white p-3 rounded-lg font-semibold text-lg">Ingresar</button>
            <a class="text-yellow-400 text-center my-2"></a>
        </form>
        <p class="text-center text-sm my-4">
            <span class="font-semibold text-center w-full">Powered</span> by <strong>popcing</strong>
        </p>
    </div>
</div>
</template>

<script>
import axios from "axios";
//@ts-nocheck
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import general from '@/mixins/axios/general'
export default {

    props: {
        cordenadaspermitidas: {
            type: Object
        }
    },

    components: {

    },
    data: () => ({
        info: {},
        comp: 0,
        elemento: null,

        usuario: "",
        contrasena: "",
        cliente: {},
        os: {},
        ip: {
            loc: '',
            city: '',
            region: '',
            postal: '',
            hostname: '',
            org: ''

        },

    }),
    mixins: [general],
    mounted() {
        let url = 'https://ipinfo.io/json?token=9f9b649b8e3ecf'
        axios.get(url).then(response => {
            //this.results = response.data
            console.log(response.data,'----response----')
            this.ip = response.data;
            this.getinfo();
           
        })
        //fetch('https://api.ipify.org?format=json')
        //.then(x => {

        //console.log(x)
        //this.ip=ip
        // this.getinfo();

        //})

        //console.log(this.$device)

        //console.log(this.$device.os)
        //console.log(this.$device.os_name)

        // This will open permission popup
        /*
      const RUTA_API = "http://localhost:8000"
const $estado = document.querySelector("#estado"),
    $listaDeImpresoras = document.querySelector("#listaDeImpresoras"),
    $btnLimpiarLog = document.querySelector("#btnLimpiarLog"),
    $btnRefrescarLista = document.querySelector("#btnRefrescarLista"),
    $btnEstablecerImpresora = document.querySelector("#btnEstablecerImpresora"),
    $texto = document.querySelector("#texto"),
    $impresoraSeleccionada = document.querySelector("#impresoraSeleccionada"),
    //$btnImprimir = document.querySelector("#btnImprimir");

const establecerImpresoraComoPredeterminada = nombreImpresora => {
    loguear("Estableciendo impresora...");
    Impresora.setImpresora(nombreImpresora)
        .then(respuesta => {
            refrescarNombreDeImpresoraSeleccionada();
            if (respuesta) {
                loguear(`Impresora ${nombreImpresora} establecida correctamente`);
            } else {
                loguear(`No se pudo establecer la impresora con el nombre ${nombreImpresora}`);
            }
        });
};

const refrescarNombreDeImpresoraSeleccionada = () => {
    Impresora.getImpresora()
        .then(nombreImpresora => {
            $impresoraSeleccionada.textContent = nombreImpresora;
        });
}

const loguear = texto => $estado.textContent += (new Date()).toLocaleString() + " " + texto + "\n";
const limpiarLog = () => $estado.textContent = "";

$btnLimpiarLog.addEventListener("click", limpiarLog);

const limpiarLista = () => {
    for (let i = $listaDeImpresoras.options.length; i >= 0; i--) {
        $listaDeImpresoras.remove(i);
    }
};

const obtenerListaDeImpresoras = () => {
    loguear("Cargando lista...");
    Impresora.getImpresoras()
        .then(listaDeImpresoras => {
            refrescarNombreDeImpresoraSeleccionada();
            loguear("Lista cargada");
            limpiarLista();
            listaDeImpresoras.forEach(nombreImpresora => {
                const option = document.createElement('option');
                option.value = option.text = nombreImpresora;
                $listaDeImpresoras.appendChild(option);
            })
        });
}

obtenerListaDeImpresoras();

refrescarNombreDeImpresoraSeleccionada();*/

    },
    methods: {
        async getinfo() {

            this.$root.$emit('loading', true);
           
            let data = await this.GET_INFO().then(response => {

                if (response != null) {

                    this.info = {};
                    response.forEach(element => {
                        this.info[element.id] = element.valor;
                    });
                    //this.comp=this.comp+1;
                    console.log(this.info, '----eeee----')
                    this.$store.commit('SETINFO', this.info);
                    this.$root.$emit('loading', false);
                }
            });
        },

        estab() {
            console.log('-----sel--------')

            const $estado = document.querySelector("#estado"),
                $listaDeImpresoras = document.querySelector("#listaDeImpresoras");

            const indice = $listaDeImpresoras.selectedIndex;
            if (indice === -1) return loguear("No hay ninguna impresora seleccionada")
            const opcionSeleccionada = $listaDeImpresoras.options[indice];

            const loguear = texto => $estado.textContent += (new Date()).toLocaleString() + " " + texto + "\n";
            const limpiarLog = () => $estado.textContent = "";
            const establecerImpresoraComoPredeterminada = nombreImpresora => {
                loguear("Estableciendo impresora...");
                Impresora.setImpresora(nombreImpresora)
                    .then(respuesta => {
                        refrescarNombreDeImpresoraSeleccionada();
                        if (respuesta) {
                            loguear(`Impresora ${nombreImpresora} establecida correctamente`);
                        } else {
                            loguear(`No se pudo establecer la impresora con el nombre ${nombreImpresora}`);
                        }
                    });
            };

            const refrescarNombreDeImpresoraSeleccionada = () => {
                Impresora.getImpresora()
                    .then(nombreImpresora => {
                        $impresoraSeleccionada.textContent = nombreImpresora;
                    });
            }
            $texto = document.querySelector("#texto"),
                $impresoraSeleccionada = document.querySelector("#impresoraSeleccionada"),
                establecerImpresoraComoPredeterminada(opcionSeleccionada.value);
        },
        imprmirr() {
            console.log('------imprimir------')
            const RUTA_API = "http://localhost:8000"
            let impresora = new Impresora(RUTA_API);
            impresora.setFontSize(1, 1);
            impresora.setEmphasize(0);
            impresora.setAlign("center");
            impresora.write("Parzibyte's blog\n");
            impresora.write("Blog de un programador\n");
            impresora.write("Telefono: 123456789\n");
            impresora.write("Fecha/Hora: 2019-08-01 13:21:22\n");
            impresora.write("--------------------------------\n");
            impresora.write("Venta de plugin para impresora\n");
            impresora.setAlign("right");
            impresora.write("25 USD\n");
            impresora.write("--------------------------------\n");
            impresora.write("TOTAL: 25 USD\n");
            impresora.write("--------------------------------\n");
            impresora.write("\n");
            impresora.setAlign("center");
            impresora.write("***Gracias por su compra***");
            impresora.write("\n");

            impresora.cut();
            impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial
            impresora.end()
                .then(valor => {
                    console.log('------imprimir------', valor)
                    //loguear("Al imprimir: " + valor);
                })
        },
        async login() {
            //const loading = this.$vs.loading({type:'square',
            //  text: 'cargando...'})

            if (this.$clipboardConfig.ip == '') {
                console.log('es necesario autorizar los permisos de gps para acceder a la plataforma')

                return
            }

            await this.$store
                .dispatch("LOGIN", {
                    client_tag:this.$store.state.client_tag,
                    usuario: this.usuario,
                    contrasena: this.contrasena,
                    tipo: this.$device.type,
                    nombre_cliente: this.$device.client.name,
                    version_cliente: this.$device.client.version,
                    marca_os: this.$device.machine.brand,
                    nombre_os: this.$device.machine.os_name,
                    version_os: this.$device.machine.os_version,
                    lat: this.cordenadaspermitidas.lat,
                    lng: this.cordenadaspermitidas.long,
                    ip: this.ip.ip,
                    ciudad: this.ip.city,
                    estado: this.ip.region,
                    country: this.ip.country,
                    codigo_postal: this.ip.postal,
                    proveedor: this.ip.org,
                    latlng_ip: this.ip.loc,

                })
                .then(response => {
                    console.log(response)

                    if (response == null) {

                    } else {
                        this.$store.state.user = response;
                        console.log(this.$store.state.user)
                        this.$store.commit('SETUSER', this.$store.state.user);
                        this.$store.commit('SETUSERSUCURSAL', this.$store.state.sucursal);

                        if (this.$store.state.app_name == 'korabe') {
                            this.$router.push('/app')
                        } else {

                            if (this.$store.state.user.usuario.role == 'admin') {

                                this.$router.push('/su')
                            } else if (this.$store.state.user.usuario.role == 'sucursal') {
                                //console.log('entre')
                                this.$router.push('/sucursal/')
                            } else if (this.$store.state.user.usuario.role == 'caja') {
                                //console.log('entre caja')
                                this.$router.push('/su/caja')
                            } else if (this.$store.state.user.usuario.role == 'master') {
                                //console.log('entre tablero')
                                this.$router.push('/ms/tablero')
                            }
                            else if (this.$store.state.user.usuario.role == 'pedidos') {
                                //console.log('entre tablero')
                                this.$router.push('/su/pedidos')
                            }

                        }

                    }

                    //loading.close();

                });

        },

    }
}
</script>
