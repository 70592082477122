
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
     
     GET_ORDENES_SUCURSAL: async function() {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"ordenes_pedidos/sucursal/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

         
    GET_ORDENES: async function() {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"ordenes_pedidos/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },




    
    SUBIR_COMPROBANTE_ORDENES: async function(data) {

      var  _axios_config={
        headers: {
        
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer ' +this.$store.state.user.token
  
        }
      }
      var result =  await axios.post(this.$store.state.baseapi+"ordenes_pedidos/comprobante/"+data.id,
      data.data,_axios_config
      ) .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },
    
    GUARDAR_AJUSTE_ORDENES: async function(data) {
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ordenes_pedidos/ajuste/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          productos:data.productos,      
          comentario:data.comentario,       
          status:data.status,      
          }    
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },

    MARCAR_ENTREGA: async function(data) {
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ordenes_pedidos/marcar_entrega/"+data.id ,
          responseType: 'json',
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token
          },
          }
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },
    
    ACEPTAR_PAGO: async function(data) {
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ordenes_pedidos/aceptar_pago/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },})
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },
    RECHAZAR_PAGO: async function(data) {
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ordenes_pedidos/rechazar_pago/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
           
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },


    ACEPTAR_ORDENES: async function(data) {
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"ordenes_pedidos/aceptar/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            
          }    
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },

    CREAR_ORDENES: async function(data) {
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ordenes_pedidos/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            usuario:data.usuario,
          //QUITAR COMILLAS SOLO EN DATA 
          productos:data.productos,       
          }    
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;
    },





    EDITAR_ORDENES: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"ordenes_pedidos/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            productos:data.productos,
          
  
               
             
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_ORDENES: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"ordenes_pedidos/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }