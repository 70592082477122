
//@ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

//@ts-ignore
import usuarios from "@/mixins/axios/user";
@Component({
  components: {
    HelloWorld,
  },
   mixins: [usuarios],
 data() {
    return {
        
    }},
  methods:{
     gotoHome(){
         

     this.$store.commit('SETDATA_CLIENTE',{
      datosgenerales:{
        
        
      },
      habitosdesalud:{},
      antecedentesmedicos:{},
      servicios:{},
      firma:null

    });
         this.$router.push("/app");
         return;

     }
  }
})
export default class Home extends Vue {}
