
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_VENTAS_RANGUE: async function(data) {
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"ventas/sucursal/"+data.id+"/rangue/"+data.inicio+'/'+data.fin,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },  
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
      },
      GET_VENTASDIA: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"ventas/sucursal/"+data.sucursal+"/dia/"+data.dia,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_VENTAS: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"ventas/"+data.sucursal,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },




    CREAR_VENTAS_MULTIPLE: async function(data) {



      try {
      var response =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ventas/multiple" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          sucursal:data.sucursal,
          idempleada:data.idempleada,
          clientes_id:data.clientes_id,
          productos:data.productos,
          fecha:data.fecha,
        
       
              
               
          }
            
          }
          
        )
        
        return response.data;
} catch (error) {
    if (error.response && error.response.status === 422) {
        // Capturar y manejar los errores específicos aquí
        return error.response.data;
     
    } else {
        // Manejar otros errores
        return null
        console.error("Error en la llamada:", error);
    }
    return null; // Otra acción en caso de error
}









    },



    CREAR_VENTAS: async function(data) {



      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"ventas/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          sucursal:data.sucursal,
          idempleada:data.idempleada,
          clientes_id:data.clientes_id,
          productos:data.productos,
          fecha_inicio:data.fecha_inicio,
          fecha_fin:data.fecha_fin,
          nota:data.nota
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },



    EDITAR_SUCURSAL: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"ventas/sucursal/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          
            sucursal:data.sucursal,
            idempleada:data.idempleada,
            fechainicio:data.fechainicio,
            fechafin:data.fechafin,
            nota:data.nota,
            productos:data.productos,
           
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    
    EDITAR_ESTATUS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"ventas/estatus/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          
            estatus:data.estatus,
            fecha:data.fecha,
           
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    EDITAR_VENTASCALENDAR: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"ventas/calendar/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
         
          
            idempleada:data.idempleada,
          
            fecha_inicio:data.fecha_inicio,
            fecha_fin:data.fecha_fin,
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    EDITAR_VENTAS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"ventas/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            id:data.id,
            sucursal:data.sucursal,
            idempleada:data.idempleada,
            clientes_id:data.clientes_id,
            productos:data.productos,
            fecha_inicio:data.fecha_inicio,
            fecha_fin:data.fecha_fin,
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_VENTAS: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"ventas/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }