<template>
    <div>
      <button @click="openModal" class="bg-blue-500 hover:bg-blue-600 text-white rounded-full font-bold py-2 px-4  bottom-2 fixed ml-2">
        <i class="las la-user-friends mx-2"></i>{{ arrayD.length }}
      </button>
  
      <div v-if="modalShow" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div class="bg-white p-2 rounded shadow-lg max-w-md w-3/6  h-78 " >
          <div class="text-lg font-semibold mb-2 flex flex-wrap justify-between"><strong>Clientes a unir</strong>  <i @click="closeModal" class="cursor-pointer la la-times-circle text-red-500 mx-2"></i></div> 
          <span class="text-xs text-red-500 font-semibold mb-4">
            • Seleccionar el cliente que queremos conservar en el sistema<br>
• Los demás usuarios serán eliminados del sistema<br>
• La información de los usuarios se almacenara en el perfil principal 
          </span>
          <ul class=" pl-6 overflow-y-scroll h-64 w-full bg-gray-200 ">
            <div class="grid grid-cols-1  gap-4 w-full">
              <div class="flex flex-wrap -mx-4 py-2">
                <div 
                  v-for="(item, index) in arrayD" 
                  :key="index" 
                  @click="selectItem(item)"
                  class="flex flex-wrap p-4 mb-4 items-center space-x-4 cursor-pointer"
                  :class="{'bg-yellow-200 shadow-lg': selectedItem && item.id === selectedItem.id, 'bg-white shadow-md': !selectedItem || item.id !== selectedItem.id}">
                  <div class="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center">
                    <span>{{ item.id }}</span>
                  </div>
  
                  <div class="w-4/12">
                    <p class="text-gray-900 text-base font-semibold mb-1">{{ item.nombre }}</p>
                    <p class="text-gray-500 text-xs">{{ item.created_at }}</p>
                  </div>
                  <div class="flex flex-col text-right text-xs">
                    <a :href="'mailto:'+item.correo" class="text-blue-500 hover:underline">Correo: {{ item.correo }}</a>
                    <a :href="'tel:' + item.telefono" class="text-blue-500 hover:underline">Teléfono: {{ item.telefono }}</a>
                  </div>
                </div>
              </div>
            </div>
          </ul>
          <button @click="unirArray" class="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Unir
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //@ts-ignore
import palabras from "@/mixins/palabras";
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
//@ts-ignore
import generales from "@/mixins/functions/generales"
  export default {
    props: {
      arrayD: {
        type: Array,
        required: true,
      },
    },
    mixins:[clientes,palabras,generales],
    data() {
      return {
        modalShow: false,
        selectedItem: null,
      };
    },
    methods: {

        closeModal(){
            this.modalShow = false;
        },
      openModal() {
        this.modalShow = true;
      },
      selectItem(item) {
        this.selectedItem = item;
      },
     
     async unirArray() {
        if(!this.selectedItem) {
          alert('Es necesario definir el cliente');
          return;
        }

        this.$root.$emit("loading", true);
            let clientes=[];
this.arrayD.forEach(element => {
    if(element.id!=this.selectedItem.id){
        clientes.push(element.id)
    }
    
  
});


            let dat = {
               clientes:clientes,
               principal:this.selectedItem.id
            };
            let data = await this.CLIENTES_UNIR(dat).then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.$emit('unir-array-exitoso');
                    this.selectedItem = null;
        this.modalShow = false;
                    
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
  
        // Aquí ejecuta la función que debe unir el array y notifica al padre que fue exitoso
        // Por ejemplo:
        // this.$emit('unir-array-exitoso');
        
        // Limpiar la selección
         // Cierra el modal después de ejecutar la función
      },
    },
  };
  </script>
  
  <style>
  /* Asegúrate de que Font Awesome esté importado */
  
  </style>
  