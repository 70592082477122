
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';
import axios from "axios";
//@ts-ignore
import ordenes from '@/mixins/axios/ordenes'

//@ts-ignore
import materias_primas from '@/mixins/axios/materias_primas'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import generales from '@/mixins/functions/generales'
//@ts-ignore
import PmdHeader from '@/components/headersSelect/pmd'

//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";
//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {
            //idmunicipio,nombre, fecha_alta, 
            // tipo, total_empleados, nombre_titular, correo, abreviacion,

            columnasd: [{
                    tag: 'id',
                    data: 'id',
                    active: false
                },
                {
                    tag: 'fecha',
                    data: 'created_at',
                    active: false
                },
                {
                    tag: 'total',
                    data: 'total',
                    class: 'bg-green-500 rounded-md text-black font-extrabold px-2 py-1',
                    active: false
                },
                {
                    tag: 'subtotal',
                    data: 'subtotal',
                    active: false
                },
                {
                    tag: 'envio',
                    data: 'costo_envio',
                    class: 'bg-yellow-500 rounded-md text-black font-extrabold px-2 py-1',
                    active: false
                },
                {
                    tag: 'ver_perido',
                    data: 'id',
                    class: 'bg-green-500 rounded-md text-black font-extrabold px-2 py-1',
                    active: false
                },

                /* {
                     tag:'color',
                     data:'color',
                     active:true
                 },*/
                {
                    tag: 'estatus_actual',
                    data: 'status_orden',
                    active: false
                },
                {
                    tag: 'status_entrega',
                    data: 'status_orden',
                    active: false
                },

                {
                    tag: 'delete',
                    data: 'delete',
                    active: false
                },

            ],

            id: null as any,
            data_file: null,
            imageUrl: '',
            precio: '',
            txtbuscador: '',
            nombre: '',
            openModalDp: false,
            comprobanteModal: false,
            openModal: false,
            ordenes_data: [],
            usuario:'',
            cantidad_agregar: 0,
            color_sel: '',
            materias_primas_data: [] as any,
            materia_sel: null,
            //clowdinary
            porpedido: false,
            file: null,
            filesSelected: 0,
            usuario_ped:null,
            comentario:'',
            created_at:new Date(),
            sucursales_sel:{nombre:''},
            cloudName: "leadpatt",
            preset: "fdykxij4",
            tags: "poa-upload",

            fileContents: null,
            formData: null
        }
    },
    mixins: [materias_primas, ordenes, generales],
    validations: {

        precio: {
            required,
        },

        nombre: {
            required,
        },

        comision: {
            required,
        },

        color: {
            required,
        },

        categorias_id: {
            required,
        },

    },
    methods: {

        //clowdinary

        handleFileChange: function (event) {
            console.log("handlefilechange", event.target.files);
            //returns an array of files even though multiple not used
            this.file = event.target.files[0];
            this.filesSelected = event.target.files.length;
            this.upload();
        },
        prepareFormData: function () {
            this.formData = new FormData();
            this.formData.append("upload_preset", this.preset);
            this.formData.append("tags", this.tags); // Optional - add tag for image admin in Cloudinary
            this.formData.append("file", this.file);
        },

        upload() {
            this.$root.$emit("loading", true);

            //let reader = new FileReader();
            // attach listener to be called when data from file

            // this.fileContents = reader.result;
            this.prepareFormData();
            let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/leadpatt/upload`;
            let requestObj = {
                url: cloudinaryUploadURL,
                method: "POST",
                data: this.formData
            };
            //show progress bar at beginning of post
            // this.showProgress = true;
            axios(requestObj)
                .then(response => {
                    //this.results = response.data;
                    //console.log(this.results);
                    //console.log("public_id", this.results.public_id);
                    //this.logotipo = this.results.secure_url;
                    this.$root.$emit("loading", false);
                    this.data_file = response.data.secure_url;
                    this.subirComprobante()
                    //alert(response.data.secure_url)
                    //this.$root.$emit("loading", false);
                })
                .catch(error => {
                    //this.errors.push(error);
                    this.$root.$emit("loading", false);
                    console.log(this.error);
                    //this.$root.$emit("loading", false);
                });
        },

        //clowdinary

        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            this.subirComprobante();

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        puedoAgregar(colores_data, cantidad, cantidad_agregar) {
            let calc = 0;
            colores_data.forEach(element => {
                calc = calc + Number(element.cantidad);
            });

            return cantidad_agregar <= (Number(cantidad) - Number(calc))
        },
        espacios(colores_data, cantidad) {
            let calc = 0;
            colores_data.forEach(element => {
                calc = calc + Number(element.cantidad);
            });

            return (Number(cantidad) - Number(calc))
        },
        calcularDisponibleColores(colores_data, cantidad) {
            let calc = 0;
            colores_data.forEach(element => {
                calc = cantidad + Number(element.cantidad);
            });

            return (Number(calc) < Number(cantidad))
        },
        agregarColor() {

            this.materias_primas_data.forEach(element => {
                if (element.id == this.materia_sel.id) {
                    element.colores_data.push({
                        color: this.color_sel,
                        cantidad: this.cantidad_agregar
                    })
                }
            });
            this.materia_sel = null;
            this.color_sel = '';
            cantidad_agregar = 1;

        },
        DetallePedido(row) {

            if (this.materias_primas_data.length < 1) {
                this.$root.$emit("alerta_show", "No se han cargado las materias primas");
                return
            }
            console.log(row, '----info---')
            this.id = row.id;
            this.sucursales_sel=row.sucursales;
            this.usuario_ped=row.usuario;
            this.comentario=row.observaciones;
            this.created_at=row.created_at;
            this.precio = row.precio;
            this.subtotal = row.subtotal;
            this.nombre = row.nombre;
            this.color = row.color;
            this.comision = row.comision;

            this.tiempo = row.tiempo;
            this.categorias_id = row.categorias_id;

            let mt_det = row.mp_detalle_ordenes;
            console.log(this.materias_primas_data, '----o----')
            this.materias_primas_data.forEach(element => {

                mt_det.forEach(prod => {
                    if (prod.materias_primas_id == element.id) {
                        element.cantidad = parseInt(prod.cantidad_pedido);
                        element.cantidad_surtir = parseInt(prod.cantidad_surtir);
                        element.colores_data = JSON.parse(prod.colores);
                    }
                });

            });
            this.openModalDp = true;

        },
        OpcionesPedido(row) {
            switch (row.status_orden) {
                case 'ACEPTADO':
                    this.id = row.id;
                    this.comprobanteModal = true;
                    break;
                case 'COMPROBANTE INVALIDO':
                    this.id = row.id;
                    this.comprobanteModal = true;
                    break;
                case 'POR CONFIRMAR':
                    this.id = row.id;
                    this.seteditar(row)
                    break;

                default:
                    break;
            }
        },

        calcularSubTotal() {
            let total = 0;
            this.materias_primas_data.forEach(element => {
                if (element.cantidad > 0) {
                    total = Number(total) + (Number(element.cantidad) * Number(element.precio_venta))
                }

            });
            return total;
        },
        async getMaterias_primas() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_MATERIAS_PRIMAS().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.materias_primas_data = response;

                    this.materias_primas_data.forEach(element => {
                        element.colores_data = [];
                        if(element.colores!=null&&element.colores!='null'&&element.colores!=''){
                        element.colores = String(element.colores).split(',');
                        }else{
                            element.colores=[]
                        }

                    });

                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },
        async eliminar(row) {

            this.$root.$emit('loading', true);
            let data = await this.ELIMINAR_ORDENES(row).then(response => {

                if (response != null) {

                    this.ordenes_data = this.ordenes_data.filter(item => item.id !== row.id);

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar eliminar paquete, refresque la pagina");

                }
                this.$root.$emit('loading', false);
            });
        },

        confirm(row) {

            let th = this;
            Notiflix.Confirm.Show('Alerta de confirmacion', 'Desea eliminar este elemento?', 'Aceptar', 'Cancelar', function () {

                th.eliminar(row);
            }, function () {

            });

        },
        limpiar() {
            this.id = null;

            this.precio = null;
            this.nombre = null;
            this.comision = null;
            this.tiempo = null;

            this.type = null;
            this.openModal = false;

            this.materias_primas_data.forEach(element => {
                element.cantidad = 0
                element.cantidad_pedida = 0

            });
        },
        addRow() {
            this.limpiar();
            this.openModal = true;
        },

        seteditar(row) {
            if (this.materias_primas_data.length < 1) {
                this.$root.$emit("alerta_show", "No se han cargado las materias primas");
                return
            }
            this.id = row.id;
            this.precio = row.precio;
            this.subtotal = row.subtotal;
            this.nombre = row.nombre;
            this.color = row.color;
            this.comision = row.comision;

            this.tiempo = row.tiempo;
            this.categorias_id = row.categorias_id;

            let mt_det = row.mp_detalle_ordenes;

            this.materias_primas_data.forEach(element => {

                mt_det.forEach(prod => {
                    if (prod.materias_primas_id == element.id) {

                        element.cantidad = parseInt(prod.cantidad_surtir);
                        element.cantidad_surtir = parseInt(prod.cantidad_surtir);
                        element.cantidad_old = parseInt(prod.cantidad_pedido);
                        element.colores_data = JSON.parse(prod.colores);
                    }
                });

            });
            this.openModal = true;

        },

        async subirComprobante() {
            this.$root.$emit('loading', true);
            var formData = new FormData();

            formData.append("url", this.data_file, );

            let dat = {
                id: this.id,
                data: formData
            };
            let data = await this.SUBIR_COMPROBANTE_ORDENES(dat).then(response => {

                if (response != null) {
                    this.getData();
                    this.comprobanteModal = false;
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar editar producto, refresque la pagina");

                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

        async EditarPedido() {
            let prod_sel = [];
            this.materias_primas_data.forEach(element => {
                if (Number(this.isNullTo0(element.cantidad_surtir)) > 0 || Number(this.isNullTo0(element.cantidad)) > 0) {

                    prod_sel.push(element)
                }

            });
            if (prod_sel.length == 0) {
                this.$root.$emit("alerta_show", "Es necesario agregar al menos un producto");
                return;
            }

            let dat = {
                id: this.id,
                productos: prod_sel
            }
            let data = await this.EDITAR_ORDENES(dat).then(response => {

                if (response != null) {
                    this.openModal = false;
                    this.$root.$emit('loading', false);
                    this.getData();

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar guardar ajuste de orden, refresque la pagina");

                }
                this.$root.$emit('loading', false);

            });
        },

        async GenerarPedido() {
            this.$root.$emit('loading', true);
            let prod_sel = [];
            this.materias_primas_data.forEach(element => {
                if (Number(this.isNullTo0(element.cantidad)) > 0) {
                    prod_sel.push(element)
                }

            });
            if (prod_sel.length == 0) {
                this.$root.$emit("alerta_show", "Es necesario agregar al menos un producto");
                return;
            }
            if (this.usuario == '') {
                this.$root.$emit("alerta_show", "Es necesario ingresar el nombre de usuario que realiza el pedido");
                return;
            }

            let dat = {
                usuario:this.usuario,
                productos: prod_sel
            }
            let data = await this.CREAR_ORDENES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;
                    console.log(response)
                    this.getData();
                    this.limpiar();
                    this.$root.$emit('loading', false);

                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar crear orden, refresque la pagina");
                    this.$root.$emit('loading', false);

                }

            });
        },

        async GuardarData() {

            console.log('id:' + this.id)

            let dat = {
                id: this.id,

                precio: this.precio,
                nombre: this.nombre,
                comision: this.comision,
                color: this.color,
                tiempo: this.tiempo,
                categorias_id: this.categorias_id,

            }
            console.log(dat)
            this.$root.$emit('loading', true);
            if (this.id == null) {
                this.crear(dat);
            } else {
                this.editar(dat);
            }

        },
        async getData() {

            this.$root.$emit('loading', true);
            console.log('loaddding')

            let data = await this.GET_ORDENES_SUCURSAL().then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.ordenes_data = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.$root.$emit('loading', false);

                //loading.close();
            });
        },

    },
    mounted() {
        this.getData();
        this.getMaterias_primas();
        //alert('10')
    }
})
export default class Home extends Vue {}
