
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_EMPLEADOSSUCURSAL: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"sucursales/"+data.id+"/empleados" ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_EMPLEADOS_DIA: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"sucursales/"+data.id+"/empleados/"+data.dia ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_USEREMPLEADOS: async function(context) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"User/empleados" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    
    CREAR_USER_EMPLEADOS: async function(data) {


      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"User/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          name:data.name,
          idmunicipio:data.idmunicipio,
          email:data.email,
         
          role:data.role,
          password_plano:data.password_plano
             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },




    EDITAR_USER_EMPLEADOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"User/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'name':data.name,
             'idmunicipio':data.idmunicipio,
             'email':data.email,
             'role':data.role,
         'password_plano':data.password_plano,
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_USER_EMPLEADOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"User/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
     EDITAR_USERTEMA_EMPLEADOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"User/tema" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'tema':data.tema,
       
          }
            
          }
          
        )
        .then(
          response => {
           console.log(response)
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            console.log(err)
            return null;
          }
        );
        return result;





    },
    },
  }