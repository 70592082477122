
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      GET_PERIODOSEMPLEADO: async function(data) {


                
                
                      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"User/empleados/"+data.id+"/periodos",
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_EMPLEADOS: async function(data) {




      
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"sucursales/"+data.sucursal+"/empleados" ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     
     GET_USER: async function(context) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"User/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    GET_USER_MUNICIPIOS: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"sucursales/"+data.idmunicipio +"/User/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    ELIMINAR_ASIGNACIONEMPLEADO: async function(data) {
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"prestamos_empleados/"+data.id,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    EDIT_ASIGNACIONEMPLEADO: async function(data) {
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"prestamos_empleados/"+data.id,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            //QUITAR COMILLAS SOLO EN DATA 
            user_id:data.user_id,
            sucursal_id:data.sucursal_id, 
            fecha_inicio:data.fecha_inicio,
            fecha_fin:data.fecha_fin,   
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    CREATE_ASIGNACIONEMPLEADO: async function(data) {
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"prestamos_empleados/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            //QUITAR COMILLAS SOLO EN DATA 
            user_id:data.user_id,
            sucursal_id:data.sucursal_id, 
            fecha_inicio:data.fecha_inicio,
            fecha_fin:data.fecha_fin,   
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },


    GUARDAR_USER_HORARIO: async function(data) {
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"horarios_empleado/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            //QUITAR COMILLAS SOLO EN DATA 
            id:data.id,
            data:data.data,   
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },


    
    GUARDAR_USER_COMISIONES_COMISIONES: async function(data) {




      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"User/producto/"+data.id +"/comisiones/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            //QUITAR COMILLAS SOLO EN DATA 
            usuarios:data.data,
            comision:data.comision,
           
               
                
                 
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    GUARDAR_USER_COMISIONES: async function(data) {




      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"User/"+data.id +"/comisiones/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            //QUITAR COMILLAS SOLO EN DATA 
            data:data.data,
           
               
                
                 
            }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },




    GET_HORARIOS_USER: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"User/"+data.id+"/horarios/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },


    GET_USER_PRODUCTO_COMISIONES: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"User/producto/"+data.id +"/comisiones/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },


    GET_USER_COMISIONES: async function(data) {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"User/"+data.id +"/comisiones/",
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },


    CREAR_USER_MUNICIPIOS: async function(data) {


      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"sucursales/"+ data.idmunicipio + "User/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          name:data.name,
          idmunicipio:data.idmunicipio,
          email:data.email,
         
          role:data.role,
          password_plano:data.password_plano
             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    CREAR_USER: async function(data) {


      
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"User/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
          //QUITAR COMILLAS SOLO EN DATA 
          name:data.name,
          sucursales_id:data.sucursales_id,
          email:data.email,
         
          role:data.role,
          password_plano:data.password_plano
             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },




    EDITAR_USER: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"User/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'name':data.name,
             'sucursales_id':data.sucursales_id,
             'email':data.email,
             'role':data.role,
         'password_plano':data.password_plano,
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_USER: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"User/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
     EDITAR_USERTEMA: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"User/tema" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
          data:{
            'tema':data.tema,
       
          }
            
          }
          
        )
        .then(
          response => {
           console.log(response)
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            console.log(err)
            return null;
          }
        );
        return result;





    },
    },
  }