<template>
<div class="   m-0 p-0">
    <span @click="mostrarPicker" class=" rounded cursor-pointer text-blue-500 underline ">
        <i class="las text-base la-external-link-square-alt"></i>
    </span>
    <div v-if="mostrarModal" @click.self="cerrarPicker()" class="fixed bg-black bg-opacity-30 border rounded  justify-center z-40 justify-center content-center flex flex-wrap  h-full w-full top-0 left-0">

        <div class="min-h-72 max-w-full h-5/6 p-2 rounded shadow-md w-4/6 min-w-3/6 max-w-4/6 overflow-y-scroll bg-white">
            <LInComponents v-if="loaders.crear"></LInComponents>
            <div v-else class="w-full h-full">

                <div  class="bg-gray-50 flex flex-wrap w-full h-8 justify-between px-4 content-center">

                  
                    <div class="text-base">
                        <span class="font-bold text-gray-700">Cliente : </span>
                        <span class="text-gray-500"> {{this.Cliente.nombre}}</span>
                    </div>

                </div>
                <TablePPoa :rows="clientesdata" :columns="columnasd" :add="false" nameButton="Agregar Cliente"></TablePPoa>
            </div>

        </div>

    </div>

</div>
</template>

<script>
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'
//@ts-ignore
import clientes from "@/mixins/axios/clientes";
export default {
    mixins: [clientes],
    props: {
        Cliente: {
            type: Object,
            required: true,
        },
       
    },
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    data() {
        return {

            columnasd: [

                {
                    tag: 'fecha',
                    data: 'startDate',
                    active: true
                },
                {
                    tag: 'productService',
                    data: '',
                    active: true
                },
                {
                    tag: 'idempleada',
                    data: 'empleada',
                    active: true
                },

                {
                    tag: 'precio',
                    data: 'precio',
                    active: true
                },
                {
                    tag: 'descuentop',
                    data: 'pdescuento',
                    active: true
                },
                {
                    tag: 'propina',
                    data: 'propina',
                    active: true
                },
                {
                    tag: 'giftcard',
                    data: 'giftcards',
                    active: true
                },

                {
                    tag: 'productos',
                    data: 'detalle_cobros',
                    active: true
                },
                {
                    tag: 'metodos',
                    data: 'metodos',
                    active: true
                },
                {
                    tag: 'sucursal',
                    data: 'sucursales',
                    active: true
                },

                /* {
                tag:'direccion',
                data:'direccion',
                active:true
            },*/

            ],
            loaders: {
                crear: false
            },
            mostrarModal: false,
            clientesdata: []

        }
    },
    mixins: [clientes],

    methods: {
        mostrarPicker() {
            this.mostrarModal = true;
            this.getData();
        },
        cerrarPicker() {
            this.mostrarModal = false;
            this.nombre = '';
            this.telefono = '';
        },

        
        async getData() {

            this.loaders.crear = true;
            console.log('loaddding')

            let dat = {
                id: this.Cliente.id
            }
            let data = await this.GET_HISTORIAL_CLIENTES(dat).then(response => {

                if (response != null) {
                    //this.plantas = response;

                    this.clientesdata = response;
                } else {
                    //this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las plantas, refresque la pagina");
                    console.log('ocurrio un error')
                }
                this.loaders.crear = false;

                //loading.close();
            });
        },
       
    }
};
</script>

<style>
/* Puedes dejar esta sección vacía, ya que los estilos están en línea con Tailwind CSS */
</style>
