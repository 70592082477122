
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
  data() {
    return {
      menus: {
        caja: [
          {
            id: 'cajagen', titulo: 'General', rutas: [
              { tag: 'calendario', ruta: '/su/calendario', active: false, child: false, icon: 'la-calendar' },

              { tag: 'caja', ruta: '/su/caja', active: false, child: false, icon: 'la-cash-register' },
              { tag: 'tarjetas', ruta: '/su/giftcards/admin', active: false, child: false, icon: 'la-money-check-alt' },


        
              { tag: 'productos', ruta: '/su/productos', active: false, child: false, icon: 'la-spa' },

          
            ]



          },
        ], pedidos: [
          {
            id: 'pedidogen', titulo: 'General', rutas: [
              { tag:'pedidos',ruta:'/su/pedidos',active:false,child:false,icon:'la-shipping-fast'},
            ]



          },
        ],

        empleado: [
          {
            id: 'empleadogen', titulo: 'General', rutas: [
              { tag: 'gastos', ruta: '/su/gastos', active: false, child: false, icon: 'create-outline' },
            ]



          },
        ],

        admin: [
          {
            id: 'adminoperativo', titulo: 'Operativo', rutas: [
              { tag: 'calendario', ruta: '/su/calendario', active: false, child: false, icon: 'la-calendar' },
             // { tag: 'calendario_multiple', ruta: '/su/calendario_multiple', active: false, child: false, icon: 'la-calendar' },

              { tag: 'caja', ruta: '/su/caja', active: false, child: false, icon: 'la-cash-register' },
             // { tag: 'cobros', ruta: '/su/cobros', active: false, child: false, icon: 'la-hand-holding-usd' },
              { tag: 'cobros', ruta: '/su/historial_ventas', active: false, child: false, icon: 'la-percentage' },

            ]
          },
          {
            id: 'adminograficas', titulo: 'Graficas y datos', rutas: [
              { tag: 'tablero', ruta: '/su/tablero', active: false, child: false, icon: 'la-chart-line' },
            
              { tag: 'dashboard', ruta: '/su', active: false, child: false, icon: 'la-percentage' },
              { tag: 'dash_graficas', ruta: '/su/estadisticas', active: false, child: false, icon: 'la-chart-line' },
           
            ]
          },
          {
            id: 'admingen', titulo: 'General', rutas: [
          
              { tag: 'marketing', ruta: '/su/marketing', active: false, child: false, icon: 'la-paper-plane' },
              { tag:'pedidos',ruta:'/su/pedidos',active:false,child:false,icon:'la-shipping-fast'},
              { tag:'materia_prima',ruta:'/su/materia_prima',active:false,child:false,icon:'la-boxes'},
              //{ tag:'gastos',ruta:'/su/gastos',active:false,child:false},

              { tag: 'sucursales', ruta: '/su/sucursales', active: false, child: false, icon: 'la-store-alt' },
              { tag: 'promociones', ruta: '/su/promociones', active: false, child: false, icon: 'la-percentage' },
              { tag: 'categorias', ruta: '/su/categorias', active: false, child: false, icon: 'la-tags' },
              // { tag:'clientes',ruta:'/su/clientes',active:false,child:false,icon:'person-outline'},
              { tag: 'clientes', ruta: '/su/clientesadmin', active: false, child: false, icon: 'la-users' },
              // { tag:'ventas',ruta:'/su/ventas',active:false,child:false},
              { tag: 'usuarios', ruta: '/su/usuarios', active: false, child: false, icon: 'la-user-circle' },
              { tag: 'procutos_servicios', ruta: '/su/productoservicios', active: false, child: false, icon: 'la-icons' },
              { tag: 'productos', ruta: '/su/productos', active: false, child: false, icon: 'la-spa' },
              { tag: 'configuraciones', ruta: '/su/configuraciones', active: false, child: false, icon: 'la-cog' },
              { tag: 'preview_giftcard', ruta: '/su/preview_giftcard', active: false, child: false, icon: 'la-id-card-alt' },
           
              
            ]



          },
          {
            id: 'admingif', titulo: 'Giftcards', rutas: [
              { tag: 'tarjetas', ruta: '/su/giftcards/admin', active: false, child: false, icon: 'la-money-check-alt' },
              { tag: 'admin_reutilizar_giftcards', ruta: '/su/giftcards/reutilizar', active: false, child: false, icon: 'la-money-check-alt' },
              { tag: 'analitica_giftcards', ruta: '/su/analitica_giftcards', active: false, child: false, icon: 'la-money-check-alt' },
              { tag: 'tarjetasControl', ruta: '/su/giftcards/control', active: false, child: false, icon: 'la-funnel-dollar' },
              { tag: 'admin_certificados', ruta: '/su/certificados/admin', active: false, child: false, icon: 'la-money-check-alt' },

            ]
          },
          {
            id: 'adminnom', titulo: 'Nómina', rutas: [
              { tag: 'nomina', ruta: '/su/nomina', active: false, child: false, icon: 'la-dollar-sign' },

              { tag: 'historial', ruta: '/su/servicios', active: false, child: false, icon: 'la-history' },



            ]
          },
          {
            id: 'adminest', titulo: 'Estadísticas', rutas: [

              { tag: 'historialServicios', ruta: '/su/historial_servicios', active: false, child: false, icon: 'la-list-alt' },

            ]
          },
          //{ tag:'dashboard',ruta:'/su/suscripcion',active:false,child:false, icon:'rocket-outline'},
          //{ tag:'graficas',ruta:'/su/graficas',active:false,child:false},

          // { tag:'Administradorcom',ruta:'/su/administrador/comisiones',active:false,child:false},

        ],
        sucursales: [
          {
            id: 'sucursalesgen', titulo: 'General', rutas: [
              { tag: 'tablero', ruta: '/su/tablero', active: false, child: false, icon: 'la-chart-line' },
              { tag: 'calendario', ruta: '/su/calendario', active: false, child: false, icon: 'la-calendar' },

              { tag: 'caja', ruta: '/su/caja', active: false, child: false, icon: 'la-cash-register' },
              { tag:'ordenes',ruta:'/su/ordenes',active:false,child:false,icon:'la-shipping-fast'},
              { tag: 'tarjetas', ruta: '/su/giftcards/admin', active: false, child: false, icon: 'la-money-check-alt' },

              { tag: 'historialServicios', ruta: '/su/historial_servicios', active: false, child: false, icon: 'la-list-alt' },
              { tag: 'nomina', ruta: '/su/servicios', active: false, child: false, icon: 'la-history' },
            
              // { tag:'categorias',ruta:'/su/categorias',active:false,child:false},
            
          
           
          
             // { tag:'clientes',ruta:'/su/clientesadmin',active:false,child:false,icon:'person-outline'},
            
            
            ]
          },

        ],
        master: [
          {
            id: 'sucursalesgen', titulo: 'General', rutas: [
              { tag: 'categorias_sucursales', ruta: '/ms/categorias/sucursales', active: false, child: false, icon: 'la-tags' },
             // { tag: 'tablero_master', ruta: '/ms/tablero', active: false, child: false, icon: 'cellular-outline' },
              { tag: 'usuarios_master', ruta: '/ms/usuarios', active: false, child: false, icon: 'la-user-circle' },
              { tag: 'log_master', ruta: '/ms/log', active: false, child: false, icon: 'la-eye' },
              { tag: 'accesos_master', ruta: '/ms/accesos', active: false, child: false, icon: 'cellular-outline' },
              { tag: 'bloqueos', ruta: '/ms/bloqueos', active: false, child: false, icon: 'la-key' },

            ]
          },

        ]


      },
    }
  },

  created() {

  },


}