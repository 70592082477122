
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {
      ELIMINAR_CATEGORIAS_SUCURSALES_USUARIO: async function(data) {
        var result =  await axios
          ({
            method: 'DELETE',
            url: this.$store.state.baseapi+"categorias_sucursales/"+data.id+"/usuario" ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
           
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      CREATE_CATEGORIAS_SUCURSALES_USUARIO: async function(data) {
        var result =  await axios
          ({
            method: 'POST',
            url: this.$store.state.baseapi+"categorias_sucursales/usuario/"+data.id ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
            data:{
              categoria:data.categoria
            }
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
      GET_CATEGORIAS_SUCURSALES_USUARIO: async function(data) {
        var result =  await axios
          ({
            method: 'GET',
            url: this.$store.state.baseapi+"categorias_sucursales/usuario/"+data.id ,
            responseType: 'json',
            headers: {
          
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' +this.$store.state.user.token
  
            },
              
            }
            
          )
          .then(
            response => {
           
              //context.commit('SET_USER_DEALS', { deals: response.data.data })
              return response.data;
            },
            err => {
              
              return null;
            }
          );
          return result;
  
  
  
  
  
      },
     GET_CATEGORIAS_SUCURSALES: async function() {




      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"categorias_sucursales/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },








    CREAR_CATEGORIAS_SUCURSALES: async function( data) {



      console.log(data)
      var result =  await axios
        ({
          method: 'POST',
          url: this.$store.state.baseapi+"categorias_sucursales/" ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:{
             descripcion:data.descripcion,
             nombre:data.nombre,
             relaciones:data.relaciones
            
             
              
               
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },





    EDITAR_CATEGORIAS_SUCURSALES: async function(data) {




      
      var result =  await axios
        ({
          method: 'PUT',
          url: this.$store.state.baseapi+"categorias_sucursales/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          },
          data:{
            descripcion:data.descripcion,
            nombre:data.nombre,
            relaciones:data.relaciones
          
          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },

    ELIMINAR_CATEGORIAS_SUCURSALES: async function(data) {




      
      var result =  await axios
        ({
          method: 'delete',
          url: this.$store.state.baseapi+"categorias_sucursales/"+data.id ,
          responseType: 'json',
          headers: {
        
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + this.$store.state.user.token

          }
            
          }
          
        )
        .then(
          response => {
         
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            
            return null;
          }
        );
        return result;





    },
    },
  }