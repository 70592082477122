
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component(
  {
      data() {
    return {

     
        elements_f:{},
       empleados:[
         {nombre:'carlos Nunez',id:1,
         horario:{in:'2020-12-04 10:25:00',fin:'2020-12-04 16:00:00'},
         data: [{in:'2020-12-04 11:25:00',fin:'2020-12-04 12:00:00',descripcion:'<h5>hola 243<strong>block</strong><h5>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h6>hola 4444<strong>block</strong><h6>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         },
         {nombre:'Diego delgado',id:2,
         horario:{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00'},
         data: [{in:'2020-12-04 16:25:00',fin:'2020-12-04 18:00:00',descripcion:'<h1>hola <strong>block</strong><h2>'},{in:'2020-12-04 12:25:00',fin:'2020-12-04 14:00:00',descripcion:'<h3>hola <strong>block</strong><h3>'}]
         }


       ] ,
        timedata: [
       /* {text:'1:00 am',minutos:60},
        {text:'1:30 am',minutos:90},
        {text:'2:00 am',minutos:120},
        {text:'2:30 am',minutos:150},
        {text:'3:00 am',minutos:180},
        {text:'3:30 am',minutos:220},
        {text:'4:00 am',minutos:250},
        {text:'4:30 am',minutos:280},
        {text:'5:00 am',minutos:310},
        {text:'5:30 am',minutos:340},
        {text:'6:00 am',minutos:370},
        {text:'6:30 am',minutos:400},
        {text:'7:00 am',minutos:430},
        {text:'7:30 am',minutos:460},
        {text:'8:00 am',minutos:490},
        {text:'8:30 am',minutos:520},
        {text:'9:00 am',minutos:550},*/
        {text:'9:00 am',minutos:0},//580
        {text:'09:30 am',minutos:30},
        {text:'10:00 am',minutos:60},
        {text:'10:30 am',minutos:90},
        {text:'11:00 am',minutos:120},
        {text:'11:30 pm',minutos:150},
        {text:'12:00 pm',minutos:180},
       {text:'12:30 pm',minutos:210},
        {text:'1:00 pm',minutos:240},
        {text:'1:30 pm',minutos:270},
        {text:'2:00 pm',minutos:300},
        {text:'2:30 pm',minutos:330},
        {text:'3:00 pm',minutos:360},
        {text:'3:30 pm',minutos:390},
        {text:'4:00 pm',minutos:420},
        {text:'4:30 pm',minutos:450},
        {text:'5:00 pm',minutos:480},
        {text:'5:30 pm',minutos:510},
        {text:'6:00 pm',minutos:540},
        {text:'6:30 pm',minutos:570},
        {text:'7:00 pm',minutos:600},
        {text:'7:30 pm',minutos:630},
        {text:'8:00 pm',minutos:660},
        {text:'8:30 pm',minutos:690},
        /*{text:'9:30 pm',minutos:1290},
        {text:'10:00 pm',minutos:1310},
        {text:'10:30 pm',minutos:1340},
        {text:'11:00 pm',minutos:1370},
        {text:'11:30 pm',minutos:1400},
        {text:'00:00 am',minutos:0},
        {text:'00:30 am',minutos:30}*/]
    }
    },
    created(){
//console.log(this.hora,'----------- hora calendario---------')
    },
    methods:{
     alertd(id){
       //console.log(id)
       


     },
      setColor(el){
       //console.log(el)
          
//console.log(el,'//---------el--------')
   if(el.detalle_ventas.length>0){
       if(el.estatus==2){
   return '#16cc64';
   }
     return el.detalle_ventas[0].producto_servicios.color;
   }
  
   return '#a0ca8e';


      
        
      },
      ParseCard(el){
       
 function parseTimeDate(date){
      

let fechas={
  "00":"00",
  "01":"01",
  "02":"02",
  "03":"03",
  "04":"04",
  "05":"05",
  "06":"06",
  "07":"07",
  "08":"08",
  "09":"09",
  "10":"10",
  "11":"11",
  "12":"12",
  "13":"01",
  "14":"02",
  "15":"03",
  "16":"04",
  "17":"05",
  "18":"06",
  "19":"07",
  "20":"08",
  "21":"09",
  "22":"10",
  "23":"11"}

//console.log(date,'---------time-------')
let timeArray=date.split(' ')
//console.log(timeArray,'---------time-------')
let time=timeArray[1].split(':')
//console.log(time,'---------time-------')
let dataString=fechas[time[0]]+":"+time[1]+" ";
if(Number(time[0])>11||Number(time[0])==0){
dataString+="PM";
}else{
dataString+="AM";
}
 return dataString;



      }
       let cardHtml="<div>";
      //console.log(el,'--------prod----------')
      if(el.hasOwnProperty('detalle_ventas')){
        if(el.detalle_ventas.length>0){
          cardHtml+="<strong style='color:black'>"+el.clientes.nombre+"</strong><br>";
          cardHtml+="<label style='color:black'><strong>Hora:</strong> "+(parseTimeDate(el.fecha_inicio))+"<strong> Servicio:</strong>"+el.detalle_ventas[0].producto_servicios.nombre+"";
          cardHtml+="</label>";
          cardHtml+="</div>";
          return cardHtml;
        }
         
      }else{
        console.log('no data')
      }

      },
     parsedateTime2(time){

if(	String(time).includes('am')){
	time=String(String(String(time).replace('mm', '30')).replace('hh','09')).replace(' am','');
		}else{
let date=String(String(String(time).replace('mm', '30')).replace('hh','12')).replace(' pm','');
date=date.replace('01:', '13:')
date=date.replace('02:', '14:')
date=date.replace('03:', '15:')
date=date.replace('04:', '16:')
date=date.replace('05:', '17:')
date=date.replace('06:', '18:')
date=date.replace('07:', '19:')
time=date;
		}


//console.log(time,'---------time-------')
        let timeArray=time.split(':')
       /// console.log(timeArray)
 let total=((Number(timeArray[0])*60)+Number(timeArray[1]));
           //console.log((total)+'---------hh-----')
          // console.log(total)
          //console.log(total,'------------t-t----------')
        return ((total)*2.1)-(540*2.1);
      },
      parsedateTime(time){


        let timeArray=time.split(':')
       /// console.log(timeArray)
 let total=((Number(timeArray[0])*60)+Number(timeArray[1]));
           //console.log((total)+'---------hh-----')
          // console.log(total)
        return ((total)*2.1)-(540*2.1);
      },
      parsedate(date){
        //console.log(date,'-------------')
        var theMinutes = new Date(date).getMinutes();
          var theMinutes2 = new Date(date).getHours()*60;
         //console.log(theMinutes,'-----m1--------')
          // console.log(theMinutes2,'-------m2------')
           let total=(theMinutes+theMinutes2);
          // console.log((total)+'---------hh-----')
        return ((total)*2.1)-(540*2.1);
      }
    }
  }
)
export default class HelloWorld extends Vue {
  @Prop() private msg!:string;
   @Prop() private dataempleados!:any;
   @Prop() private hora!:any;
    @Prop() private showid!:any;
   

}
