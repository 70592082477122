
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import configuraciones from '@/mixins/axios/configuraciones'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'

//import {Vuetable,VuetablePagination} from 'vuetable-2'

@Component({
    components: {
        /*
         Vuetable,
         VuetablePagination,*/

        TablePPoa
    },
    mixins: [configuraciones],
    data() {

        return {
            options: {

                placeholder: 'valor ...',

            },
            headings: [{
                    data: 'nombre',
                    tag: 'Nombre',
                    class: 'text-xs text-blue-500 font-extrabold text-left',
                    show: true
                },
                {
                    data: 'valor',
                    tag: 'valor',
                    class: 'text-xs text-green-600   font-extrabold ',
                    show: true
                },
                {
                    data: 'tipo_elemento',
                    tag: 'Tipo elemento',
                    class: 'text-xs text-yellow-600  uppercase font-extrabold ',
                    show: true
                },

                {
                    tag: 'edit',
                    data: 'edit',
                    show: true
                },
                
            ],
            buscar: '',
            open: false,

            data: [],
            actionModal: null,
            showM: false,
            id: 0,
            data_file: null,
            imageUrl: '',
            url: '',
            tipo: '',
            nombre: '',
            descripcion: '',
            valor: '',

            color: 'rgba(255,30,100,1)',

        }

    },
    mounted() {

        console.log('data---')
    },
    created() {
        this.getData()
    },
    methods: {
        async onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.valor = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.data_file = files[0];
            console.log(e)

        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        addRow() {
            this.showM = !this.showM;
            this.limpiar()
        },

        limpiar() {

            this.id = 0;
            this.nombre = '';
            this.valor = '';
            this.tipo = '';

            this.data_file = null;

        },
        seteditar(row) {
            this.id = row.id;
            this.nombre = row.nombre;
            this.valor = row.valor;
            this.tipo = row.tipo;

            this.showM = true;
            this.actionModal = null;
            $('#modal_config').modal('show')

        },
        async getData() {
            this.showM = false;
            this.actionModal = null;
            this.$root.$emit("loading", true)

            await this.GET_CONFIGURACIONES().then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.data = response
                    //this.$router.push('/app/'); 
                } else {

                    // this.$root.$emit("alerta_show",response.msg);

                }
                this.$root.$emit("loading", false)
            });
        },
        async guardar() {
            var formData = new FormData();

            formData.append("doc", this.data_file, );
            formData.append("id", this.id, );
            formData.append("nombre", this.nombre, );

            console.log(this.valor, '----f----')
            formData.append("valor", this.valor, );

            let dat = {
                id: this.id,
                data: formData
            };

            if (this.id == 0) {
                this.crear(dat)
            } else {
                this.editar(dat)
            }
        },
        async editar(dat) {
            this.$root.$emit("loading", true)

            await this.EDITAR_CONFIGURACIONES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    this.limpiar();
                    $('#modal_config').modal('hide')
                    //this.$router.push('/app/'); 
                } else {

                    // this.$root.$emit("alerta_show",response.msg);

                }
              
               
                this.$root.$emit("loading", false)
            });
        },
        async crear(dat) {
            this.$root.$emit("loading", true)

            await this.CREAR_CONFIGURACIONES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // this.$root.$emit("alerta_show",response.msg);

                }
                this.showM = false;
                this.limpiar();
                this.$root.$emit("loading", false)
            });
        },
        async eliminar(id) {
            this.$root.$emit("loading", true)
            let dat = {
                id: id,

            }
            await this.ELIMINAR_CONFIGURACIONES(dat).then(response => {

                if (response != null && !response.hasOwnProperty('error')) {

                    this.getData();
                    //this.$router.push('/app/'); 
                } else {

                    // this.$root.$emit("alerta_show",response.msg);

                }
                this.actionModal = null
                this.$root.$emit("loading", false)
            });
        },

    }

})
export default class Home extends Vue {}
