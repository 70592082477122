
import VueAxios from "vue-axios";
import axios from "axios";
//Vue.use(VueAxios, axios);
//Vue.use(Vuex)
//const this.$store.state.baseapi = "http://127.0.0.1:8000/api/";
export default {
    data() {
      return {
       
      }
    },
  
    created() {
     
    },
  
    methods: {

      
      SEND_HTML: async function(data) {
        var divContents = document.getElementById(data.id).innerHTML;
        let doc = '<script src="https://cdn.tailwindcss.com">';
        doc += '</scri';
        doc += 'pt>' + divContents + '';

        let url = `https://v2.api2pdf.com/chrome/pdf/html?apikey=d1ee403a-92aa-4b4d-a95f-bd66ca84c1c4`;
    
        let requestObj = {
          url: url,
          method: "post",
          data: {
            "html": doc,
            "inline": true,
            "fileName": "test.pdf",
            "options": {
              "delay": 0,
              "puppeteerWaitForMethod": "WaitForNavigation",
              "puppeteerWaitForValue": "Load",
              "usePrintCss": true,
              "landscape": data.landscape,
              "printBackground": true,
              "displayHeaderFooter": false,
              "headerTemplate": "<span></span>",
              "footerTemplate": "<span></span>",
              "width": "8.27in",
              "height": "11.69in",
              "marginTop": ".4in",
              "marginBottom": ".4in",
              "marginLeft": ".4in",
              "marginRight": ".4in",
              "pageRanges": "1-10000",
              "scale": data.scale,
              "omitBackground": false
            },
            "useCustomStorage": false,
            
          }
        };
        //show progress bar at beginning of post
        // this.showProgress = true;
      let r=  await axios(requestObj)
          .then((response) => {
          

            var temp_link = document.createElement("a");

            // Download csv file
            // temp_link.download = "pdf.pdf";
            temp_link.setAttribute("download", 'name.pdf');
            temp_link.setAttribute("target", '_blank');

            temp_link.href = response.data.FileUrl;

            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);

            // Automatically click the link to trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

    
         
            return response;
          })
          .catch((error) => {
            console.log(error)
            return {error:error}
            //return { error: error };
          });
          return r
      },

     GET_INFO: async function(data) {



      console.log('get-info')
      
      var result =  await axios
        ({
          method: 'GET',
          url: this.$store.state.baseapi+"info/",
          responseType: 'json',
          headers: {
        
           // "Content-Type": "application/json",
            //'Authorization': 'Bearer ' +this.$store.state.user.token

          },
            
          }
          
        )
        .then(
          response => {
            console.log('get-info2')
            //context.commit('SET_USER_DEALS', { deals: response.data.data })
            return response.data;
          },
          err => {
            console.log('get-in3fo',err,this.$store.state.baseapi+"info/")
            return null;
          }
        );
        return result;





    },






  }
  }