export default {
  data() {
    return {};
  },

  created() {},

  methods: {
    descargarCSV(id,etiqueta) {
      const fechaHoy = new Date().toLocaleDateString('es-ES');
    

      // Obtener datos de la tabla y convertirlos a CSV
      const tabla = document.getElementById(id);
      const filas = tabla.querySelectorAll('tr');
      let csv = '';
      for (const fila of filas) {
        const celdas = fila.querySelectorAll('td, th');
        const valores = Array.from(celdas).map(celda => celda.textContent);
        csv += valores.join(',') + '\n';
      }

      // Crear un enlace temporal para descargar el CSV
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const enlaceTemporal = document.createElement('a');
      enlaceTemporal.href = url;
      enlaceTemporal.setAttribute('download', `${etiqueta}_${fechaHoy}.csv`);
      document.body.appendChild(enlaceTemporal);
      enlaceTemporal.click();
      document.body.removeChild(enlaceTemporal);
    },
    actualformatDate() {
      var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
  },
    foormatlocal(date) {
      let datef = new Date(date);
      const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
      };
      return datef.toLocaleDateString("es-MX", options);
  },

  
 
    validation(data) {
      let text = "";
      for (var [key, value] of Object.entries(data.errors)) {
        text +=
          '<div class="w-full flex flex-wrap text-center justify-center content-center"><span>' +
          value +
          "</span> </div><br>"; // "a 5", "b 7", "c 9"
      }
      return text;
      //emitter.emit("alerta_show",text);
    },
    /**calculos de datos */

    CalcularAbonos(abonos) {
      let total = 0;
      abonos.forEach((element) => {
        total = Number(total) + Number(element.cantidad);
      });
      return total;
    },

    /**--------- */

    getFirstDayOfMonth() {
      var date = new Date();
      var d = new Date(date.getFullYear(), date.getMonth(), 1);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getLastDayOfMonth() {
      var date = new Date();

      var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
      // return lastDay;
    },

    getfisrtDay() {
      var myCurrentDate = new Date();
      var myPastDate = new Date(myCurrentDate);
      myPastDate.setDate(myPastDate.getDate() - 7);
      return myPastDate;
    },
    isNullTo0(value) {
      if (value === null) {
        return 0;
      } else {
        return value;
      }
    },
    formatDat(date) {
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let dat = new Date(date);
      return dat.toLocaleDateString("es-ES", options);
    },
    pad0(num, size) {
      var s = "000000000000" + num;
      return s.substr(s.length - size);
    },
    pad(num, size) {
      var s = "          " + num;
      return s.substr(s.length - size);
    },
    formatPrice(value) {
      value = Number(value).toFixed(2);
      value = value.toString().replace(".00", "");
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    /*formatPrice(value) {
            value = value.toString().replace(".00", "");
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          },*/
    setMasOneDay(date_d) {
      var myPastDate = new Date(date_d);
      myPastDate.setDate(myPastDate.getDate() + 1);
      return myPastDate;
    },
    formatFullDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatFullDateTime(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return (
        [year, month, day].join("-") +
        " " +
        d.getUTCHours() +
        ":" +
        d.getUTCMinutes() +
        ":" +
        d.getUTCSeconds()
      );
    },
  },
};
