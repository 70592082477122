
//@ts-nocheck
import {
    Component,
    Vue
} from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import landing from '@/components/pages/landing.vue'; // @ is an alias to /src
import homeapp from '@/components/pages/homeapp.vue'; // @ is an alias to /src
import tucita from '@/components/pages/tucita.vue'; // @ is an alias to /src
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

@Component({
    components: {
        HelloWorld,
        homeapp,
        landing,
        tucita,
        Loading

    },
    data: () => ({
        fullscreen: false,
        isLoading: true,
        fullPage: true,
        elemento: null as any,
        cordenadaspermitidas: null,
        usuario: "",
        contrasena: "",
        subdomain: '',
        host: '',
    }),
    created() {

     

        this.host = (window.location.host).replace(':8080', '');
        if (this.host == 'localhost') {
            this.subdomain = 'ss';
        } else {
            this.subdomain = this.host.split('.')[0]
        }
        //this.$store.commit('subdomain',this.subdomain);

    },
    methods: {

init(){
    console.log(this.$route.params,'....jj....')
        //this.$store.commit('SETCLIENT_TAG', this.$route.params.origen);


        const success = (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            this.cordenadaspermitidas = {
                lat: latitude,
                long: longitude
            }
            // Do something with the position
        };

        const error = (err) => {
            //this.init();
            console.log(err)
           // this.cordenadaspermitidas=true;
        };

        navigator.geolocation.getCurrentPosition(success, error);
}

    },
    mounted() {
        if(this.cordenadaspermitidas==null){
            this.init()
        }

        
    }

})
export default class Home extends Vue {}
