
//@ts-nocheck
//@ts-ignore
import {
    Component,
    Vue
} from 'vue-property-decorator';

//@ts-ignore
import accesos from '@/mixins/axios/accesos'
//@ts-ignore
import TablePPoa from '@/components/tables/tablev1'

//@ts-ignore
import tablero from '@/mixins/axios/tablero'
//@ts-ignore
import {
    email,
    required
} from 'vuelidate/lib/validators';
//@ts-ignore
import Notiflix from "notiflix";

@Component({
    components: {

        TablePPoa
    },
    mixins: [tablero,accesos],
    mounted() {

    },
    data() {
        return {
 columnasd: [{
                    tag: "id_session",
                    data: "id",
                    active: false,
                },

                
                {
                    tag: "user_rel",
                    data: "user",
                    active: false,
                },
                {
                    tag: "os",
                    data: "os",
                    active: false,
                },
                {
                    tag: "cliente_software",
                    data: "cliente",
                    active: false,
                },
                
                {
                    tag: "fecha",
                    data: "created_at",
                    active: false,
                },
                 {
          tag: "delete",
          data: "delete",
          active: false,
        },

                
              

            ],
            usuariosdata:[]
        }
    },
    created(){
this.getData()
    },
    methods: {
        confirm(row) {
            let th = this;
            Notiflix.Confirm.Show(
                "Alerta de confirmacion",
                "Esta segur@ de eliminar este elemento si es asi el usuario ya no tendra acceso a la plataforma",
                "Aceptar",
                "Cancelar",
                function () {
                    th.eliminar(row);
                },
                function () {}
            );
        },
         async eliminar(row) {
            this.$root.$emit("loading", true);
            let data = await this.ELIMINAR_SESSIONES(row).then((response) => {
                if (response != null) {
                    this.usuariosdata = this.usuariosdata.filter(
                        (item) => item.id !== row.id
                    );
                } else {
                    this.$root.$emit(
                        "alerta_show",
                        "Ocurrio un error al intentar eliminar session, refresque la pagina"
                    );
                }
                this.$root.$emit("loading", false);
            });
        },
        async getData() {
            this.$root.$emit("loading", true);
            console.log("loaddding");
            let data = await this.GET_SESSIONES().then((response) => {
                if (response != null) {
                    //this.plantas = response;
                    this.usuariosdata = response;
                } else {
                    this.$root.$emit("alerta_show", "Ocurrio un error al intentar cargar las sessiones, refresque la pagina");
                    //console.log("ocurrio un error");
                }
                this.$root.$emit("loading", false);

                //loading.close();
            });
        },

    },

})
export default class AccesosGeneral extends Vue {}
